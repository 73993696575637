import { interfaces } from 'inversify';
import { Logic, LogicWrapper, selectors } from 'kea';

import { VisualizationType } from '@/types/visualization';

import { VisLogicProps } from './../../../types/visualization';

export type IVisualizationLogicProps = VisLogicProps;

export interface IVisualization extends Logic {
  readonly props: IVisualizationLogicProps;
  readonly actions: {
    readonly updateSystemApiValue: (
      systemApiKey: string,
      systemApiValue: Record<string, unknown>,
    ) => void;
  };
  readonly values: {
    readonly visualization: VisualizationType;
    readonly imageBaseURL: string;
    readonly isStorybook?: boolean | undefined;
    readonly loadSuccess: boolean;
    readonly loadError: boolean;
  };
  readonly selectors: {
    readonly systemApiValues: (
      state: Record<string, Record<string, unknown>>,
    ) => Record<string, Record<string, unknown>>;
  };
}

export interface IVisualizationLogic extends LogicWrapper<IVisualization> {}

export const IVisualizationLogicSymbol: interfaces.ServiceIdentifier<IVisualizationLogic> =
  Symbol('IVisualizationLogic');

export namespace IVisualizationLogic {
  export const $: interfaces.ServiceIdentifier<IVisualizationLogic> =
    IVisualizationLogicSymbol;
}
