/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  ResponsiveTileComponent,
  TabMenuComponent,
} from '@/types/custom/responsiveBar';

import { ResponsiveTile } from './responsiveTile/responsiveTile';
import { TabMenuTile } from './responsiveTile/tabMenuTile';
import { StaticTile } from './staticTile/staticTile';
import './style.scss';

enum ElementID {
  ResponsiveLeft = 'responsiveLeft',
  TabMenu = 'tabMenu',
  ResponsiveLeftComponents = 'responsiveLeftComponents',
  DynamicContainer = 'dynamicContainer',
  Center = 'centerContainer',
  ResponsiveRight = 'responsiveRight',
}

interface ResponsiveBarProps {
  staticLeftElements?: (ResponsiveTileComponent | JSX.Element)[];
  leftElements?: (ResponsiveTileComponent | JSX.Element)[];
  tabMenu?: TabMenuComponent;
  centerElements?: (ResponsiveTileComponent | JSX.Element)[];
  rightElements?: (ResponsiveTileComponent | JSX.Element)[];
  staticRightElements?: (ResponsiveTileComponent | JSX.Element)[];
}

export const ResponsiveBar: FC<ResponsiveBarProps> = (props) => {
  const elementRef = {
    staticLeft: useRef<HTMLDivElement>(null),
    responsiveLeft: useRef<HTMLDivElement>(null),
    tabMenu: useRef<HTMLDivElement>(null),
    dynamicContainer: useRef<HTMLDivElement>(null),
    center: useRef<HTMLDivElement>(null),
    responsiveRight: useRef<HTMLDivElement>(null),
    staticRight: useRef<HTMLDivElement>(null),
  };

  const [foldFlag, setFoldFlag] = useState(false);
  const [tabMenuFlag, setTabMenuFlag] = useState(false);

  const [safetyMarginSize] = useState(25);
  const [foldSize] = useState(28);

  const [staticWidth, setStaticWidth] = useState<number | null>(null);
  const [leftWidth, setLeftWidth] = useState(0);
  const [tabMenuWidth, setTabMenuWidth] = useState(0);
  const [centerWidth, setCenterWidth] = useState(0);
  const [rightWidth, setRightWidth] = useState(0);

  const [dynamicContainerWidth, setDynamicWidth] = useState(0);

  useEffect(() => {
    //Observes for changes of elements sizes
    const resizeObserver = new ResizeObserver((entries) => {
      const elementSize: Record<string, number> = {};

      for (const entry of entries) {
        elementSize[entry.target.id] = entry.contentRect.width;
      }

      if (elementSize[ElementID.Center] != null) {
        setCenterWidth(Math.round(elementSize[ElementID.Center]));
      }
      if (elementSize[ElementID.DynamicContainer] != null) {
        setDynamicWidth(Math.round(elementSize[ElementID.DynamicContainer]));
      }
      // if (elementSize[ElementID.ResponsiveLeft] != null && centerWidth === 0) {
      //   setLeftWidth(Math.round(elementSize[ElementID.ResponsiveLeft]));
      // }
      if (elementSize[ElementID.TabMenu] != null) {
        setTabMenuWidth(Math.round(elementSize[ElementID.TabMenu]));
      }
      if (elementSize[ElementID.ResponsiveRight] != null && centerWidth === 0) {
        setRightWidth(Math.round(elementSize[ElementID.ResponsiveRight]));
      }
    });

    if (elementRef.responsiveLeft.current != null)
      resizeObserver.observe(elementRef.responsiveLeft.current);

    if (elementRef.responsiveRight.current != null)
      resizeObserver.observe(elementRef.responsiveRight.current);

    if (elementRef.center.current != null)
      resizeObserver.observe(elementRef.center.current);

    if (elementRef.tabMenu.current != null) {
      resizeObserver.observe(elementRef.tabMenu.current);
    }
    if (elementRef.dynamicContainer.current != null)
      resizeObserver.observe(elementRef.dynamicContainer.current);

    return () => resizeObserver.disconnect();
  }, [props]);

  useLayoutEffect(() => {
    //Gets width of static elements after DOM render
    const leftWidth =
      elementRef.staticLeft.current != null
        ? elementRef.staticLeft.current.offsetWidth
        : 0;

    const rightWidth =
      elementRef.staticRight.current != null
        ? elementRef.staticRight.current.offsetWidth
        : 0;

    const width = leftWidth >= rightWidth ? leftWidth : rightWidth;
    setStaticWidth(width);
  }, []);

  useEffect(() => {
    //Calculates size of responsive containers
    if (centerWidth === 0) return;

    const responsiveWidth = Math.round(
      dynamicContainerWidth / 2 - centerWidth / 2,
    );

    setLeftWidth(responsiveWidth);
    setRightWidth(responsiveWidth);
  }, [dynamicContainerWidth, centerWidth, leftWidth, rightWidth]);

  return (
    <div className="OCS-responsive-bar">
      {/* Static left */}
      <div
        ref={elementRef.staticLeft}
        className="OCS-responsive-bar-static-container"
        style={staticWidth ? { width: staticWidth } : {}}
      >
        {
          <StaticTile
            elementKey={'staticLeftTile'}
            elements={props.staticLeftElements}
          />
        }
      </div>
      {/* Dynamic container */}
      <div
        id={ElementID.DynamicContainer}
        ref={elementRef.dynamicContainer}
        className="OCS-responsive-bar-dynamic-container"
      >
        {/* Responsive left */}
        <div
          id={ElementID.ResponsiveLeft}
          ref={elementRef.responsiveLeft}
          className="OCS-responsive-bar-left"
        >
          {props.tabMenu != null ? (
            <div id={ElementID.TabMenu} ref={elementRef.tabMenu}>
              <TabMenuTile
                model={props.tabMenu.model}
                tabMenuLogic={props.tabMenu.logic}
                logicKey={props.tabMenu.logicKey}
                accessibleSize={
                  props.centerElements != null
                    ? leftWidth - foldSize * 2 - safetyMarginSize
                    : dynamicContainerWidth - foldSize * 2
                }
                dynamicSize={dynamicContainerWidth}
                setTabMenuFlag={setTabMenuFlag}
                foldSize={foldSize}
                dynamicContainerRef={elementRef.dynamicContainer}
              />
            </div>
          ) : (
            <></>
          )}
          <ResponsiveTile
            components={props.leftElements}
            accessibleSize={
              props.centerElements != null && centerWidth != 0
                ? leftWidth - tabMenuWidth - foldSize - safetyMarginSize
                : dynamicContainerWidth - foldSize * 2 - tabMenuWidth
            }
            dynamicSize={dynamicContainerWidth - tabMenuWidth}
            foldAlignment={'left'}
            foldFlag={{
              isActive: foldFlag,
              setFoldFlag: setFoldFlag,
              responsiveTile: 'side',
            }}
            tabMenuFlag={{
              isActive: tabMenuFlag,
              responsiveTile: 'left',
            }}
            foldSize={foldSize}
            dynamicContainerRef={elementRef.dynamicContainer}
          />
        </div>
        {/* Responsive center */}
        <div className="OCS-responsive-bar-center-wrapper">
          <div
            id={ElementID.Center}
            ref={elementRef.center}
            className="OCS-responsive-bar-center"
          >
            {
              <ResponsiveTile
                components={props.centerElements}
                accessibleSize={
                  props.centerElements != null && centerWidth != 0
                    ? dynamicContainerWidth -
                      tabMenuWidth -
                      safetyMarginSize * 2 -
                      foldSize * 3
                    : 0
                }
                dynamicSize={dynamicContainerWidth}
                foldFlag={{
                  isActive: foldFlag,
                  setFoldFlag: setFoldFlag,
                  responsiveTile: 'center',
                }}
                tabMenuFlag={{
                  isActive: tabMenuFlag,
                }}
                foldSize={foldSize}
                dynamicContainerRef={elementRef.dynamicContainer}
              />
            }
          </div>
        </div>
        {/* Responsive right */}
        <div
          id={ElementID.ResponsiveRight}
          ref={elementRef.responsiveRight}
          className="OCS-responsive-bar-right"
        >
          <ResponsiveTile
            components={props.rightElements}
            accessibleSize={rightWidth - foldSize - safetyMarginSize}
            dynamicSize={dynamicContainerWidth}
            responsiveClassName={'OCS-flex-flow-reverse'}
            foldAlignment={'right'}
            foldFlag={{
              isActive: foldFlag,
              setFoldFlag: setFoldFlag,
              responsiveTile: 'side',
            }}
            tabMenuFlag={{
              isActive: tabMenuFlag,
            }}
            foldSize={foldSize}
            dynamicContainerRef={elementRef.dynamicContainer}
          />
        </div>
      </div>
      {/* Static right */}
      <div
        ref={elementRef.staticRight}
        className="OCS-responsive-bar-static-container"
        style={staticWidth ? { width: staticWidth } : {}}
      >
        {
          <StaticTile
            elementKey={'staticRightTile'}
            elements={props.staticRightElements}
          />
        }
      </div>
    </div>
  );
};
