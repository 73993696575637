import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import Dependencies from '@/deps';
import {
  IDesktopLogic,
  IDeviceDetailContainerLogic,
  IDeviceViewContainerLogic,
  IEventAlarmContainerLogic,
  IEventListLogic,
  IObjectDetailContainerLogic,
  IObjectViewContainerLogic,
} from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import {
  IDeviceService,
  IEventService,
  IObjectService,
} from '@/services/interfaces';
import { EventView } from '@/types/models/event';

import { IEventDictContainerLogic } from './../../../interfaces/eventDictContainer';
import type { logicType } from './indexType';

export interface EventViewLogicProps {
  eventId: string;
  deps: {
    eventAlarmContainerLogic: IEventAlarmContainerLogic;
    eventDictContainerLogic: IEventDictContainerLogic;
    objectViewContainerLogic: IObjectViewContainerLogic;
    objectDetailContainerLogic: IObjectDetailContainerLogic;
    deviceViewContainerLogic: IDeviceViewContainerLogic;
    deviceDetailContainerLogic: IDeviceDetailContainerLogic;
    desktopLogic: IDesktopLogic;
    objectService: IObjectService;
    deviceService: IDeviceService;
    eventService: IEventService;
    eventListLogic: IEventListLogic;
  };
}

const logic = kea<logicType>([
  path(['events', 'views']),
  key((props) => props.eventId),
  props({} as EventViewLogicProps),
  actions({
    loadDevice: true,
    loadDeviceSuccess: true,
    loadDeviceFailure: true,
    loadObject: true,
    loadObjectSuccess: true,
    loadObjectFailure: true,
    loadResources: true,
    useResources: true,
    releaseResources: true,
    confirm: (input: string) => ({ input }),
    confirmSuccess: true,
    confirmError: true,
  }),
  reducers({
    confirmSending: [
      false,
      {
        confirm: () => true,
        confirmSuccess: () => false,
        confirmError: () => false,
      },
    ],
    confirmSuccess: [
      false,
      {
        confirmSuccess: () => true,
        confirmError: () => false,
      },
    ],
    confirmError: [
      false,
      {
        confirmSuccess: () => false,
        confirmError: () => true,
      },
    ],
  }),
  selectors(({ props }) => ({
    eventId: [
      () => [(_: unknown, props: EventViewLogicProps) => props.eventId],
      (eventId) => eventId,
    ],
    event: [
      (selectors) => [
        selectors.eventId,
        props.deps.eventAlarmContainerLogic.selectors.events,
      ],
      (eventId, events) => {
        if (eventId in events) {
          return events[eventId.toString()];
        }
        throw Error(
          'Event View logic has been mounted, without event present in container',
        );
      },
    ],
    eventLevel: [
      (selectors) => [
        selectors.event,
        props.deps.eventDictContainerLogic.selectors.eventLevels,
      ],
      (event, eventLevels) => {
        if (event.eventLevelId in eventLevels) {
          return eventLevels[event.eventLevelId];
        }
        return null;
      },
    ],
    device: [
      (selectors) => [
        selectors.event,
        props.deps.deviceViewContainerLogic.selectors.devices,
      ],
      (event, devices) => {
        if (event.deviceUuid != null && event.deviceUuid in devices) {
          return devices[event.deviceUuid];
        }
        return null;
      },
    ],
    object: [
      (selectors) => [
        selectors.event,
        props.deps.objectViewContainerLogic.selectors.objects,
      ],
      (event, objects) => {
        if (event.objectUuid in objects) {
          return objects[event.objectUuid];
        }
        return null;
      },
    ],
    eventView: [
      (selectors) => [
        selectors.event,
        selectors.object,
        selectors.device,
        selectors.eventLevel,
      ],
      (event, object, device, eventLevel) => {
        if (
          eventLevel != null &&
          object != null &&
          (event.deviceUuid == null || device != null)
        ) {
          const eventDetails =
            (object.base.name != null ? object.base.name + ' / ' : '') +
            (device != null ? device.base.name : '');
          const eventView: EventView = {
            id: event.alarmEventId,
            icon: eventLevel.icon,
            color: event.color ? event.color : eventLevel.color,
            title: event.text,
            details: eventDetails,
            startDatetime: event.startDatetime,
            sound: event.sound,
            endDatetime: event.endDatetime,
            detailsOnClick: () => {
              //if (event.deviceUuid === undefined) {
              props.deps.desktopLogic.actions.openWindow('objectWindow', {
                initialData: { objectUuid: event.objectUuid },
                windowKey: event.objectUuid,
                title: object.base.name,
              });
              //} else {
              //}
            },
          };
          return eventView;
        }
        return null;
      },
    ],
  })),
  listeners(({ props, actions, values }) => ({
    loadResources: () => {
      actions.loadObject();
      actions.loadDevice();
    },
    loadObject: async () => {
      if (values.object == null) {
        const objectResponse = await props.deps.objectService.get({
          objectUuid: values.event.objectUuid,
        });
        if (objectResponse.success) {
          props.deps.objectDetailContainerLogic.actions.addObjects([
            objectResponse.data,
          ]);
        } else {
          await await new Promise((r) => setTimeout(r, 100));
          actions.loadObject();
        }
      }
    },
    loadDevice: async () => {
      if (values.event.deviceUuid != null && values.device == null) {
        const deviceResponse = await props.deps.deviceService.get({
          deviceUuid: values.event.deviceUuid,
        });
        if (deviceResponse.success) {
          props.deps.deviceDetailContainerLogic.actions.addDevices([
            deviceResponse.data,
          ]);
        } else {
          await await new Promise((r) => setTimeout(r, 100));
          actions.loadDevice();
        }
      }
    },
    useResources: async () => {
      while (values.object == null) {
        await new Promise((r) => setTimeout(r, 100));
      }
      props.deps.objectViewContainerLogic.actions.useObjects([
        values.event.objectUuid,
      ]);
      if (values.event.deviceUuid != null) {
        while (values.device == null) {
          await new Promise((r) => setTimeout(r, 100));
        }
        props.deps.deviceViewContainerLogic.actions.useDevices([
          values.event.deviceUuid,
        ]);
      }
    },
    releaseResources: () => {
      if (values.event.deviceUuid != null) {
        props.deps.deviceViewContainerLogic.actions.releaseDevices([
          values.event.deviceUuid,
        ]);
      }
      props.deps.objectViewContainerLogic.actions.releaseObjects([
        values.event.objectUuid,
      ]);
    },
    confirm: async ({ input }) => {
      const response = await props.deps.eventService.confirmAlarmEvent({
        alarmEventId: values.event.alarmEventId,
        confirmUserText: input,
      });

      if (response.success) {
        actions.confirmSuccess();
        props.deps.eventListLogic.actions.removeAlarmEvent(props.eventId);
      } else {
        actions.confirmError();
      }
    },
  })),
  selectors(() => ({})),
  afterMount(({ actions }) => {
    actions.loadResources();
    actions.useResources();
  }),
  beforeUnmount(({ actions }) => {
    actions.releaseResources();
  }),
]);

export const eventViewLogic = injectDepsToLogic(logic, () => ({
  eventAlarmContainerLogic: Dependencies.get(IEventAlarmContainerLogic.$),
  eventDictContainerLogic: Dependencies.get(IEventDictContainerLogic.$),
  objectViewContainerLogic: Dependencies.get(IObjectViewContainerLogic.$),
  objectDetailContainerLogic: Dependencies.get(IObjectDetailContainerLogic.$),
  deviceViewContainerLogic: Dependencies.get(IDeviceViewContainerLogic.$),
  deviceDetailContainerLogic: Dependencies.get(IDeviceDetailContainerLogic.$),
  desktopLogic: Dependencies.get(IDesktopLogic.$),
  objectService: Dependencies.get(IObjectService.$),
  deviceService: Dependencies.get(IDeviceService.$),
  eventService: Dependencies.get(IEventService.$),
  eventListLogic: Dependencies.get(IEventListLogic.$),
}));
