import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import {
  ButtonContent,
  ConfirmStatus,
  EditorForm,
  EditorTile,
  EditorValue,
  EnumContent,
  EnumExcludeOptions,
  LoadingStatus,
} from '@/types/custom/editorTypes';

export interface IObjectEditorProps extends Record<string, unknown> {
  initialData: {
    objectUuid: string;
  };
}

interface IObjectEditor extends Logic {
  readonly props: IObjectEditorProps;
  readonly actions: {
    readonly updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: any,
    ) => void;
    readonly confirm: () => void;
    readonly setConfirmStatus: (value: ConfirmStatus) => void;
    readonly onButtonClick: (tabKey: string, valueKey: string) => void;
    readonly refresh: () => void;
  };
  readonly values: {
    readonly model: EditorForm;
    readonly editorUuid: string;
    readonly translationPrefix: string;
    readonly tileLayout: Record<string, EditorTile>;
    readonly isSaving: boolean;
    readonly enumExcludeOptionsMap?: EnumExcludeOptions;
    readonly confirmStatus: ConfirmStatus;
    readonly loadStatus: LoadingStatus;
    readonly buttonMap: Record<string, Record<string, ButtonContent>>;
  };
  readonly selectors: {
    readonly value: (
      value: unknown,
    ) => Record<string, Record<string, Record<string, EditorValue>>>;
  };
}

export interface IObjectEditorLogic extends LogicWrapper<IObjectEditor> {}

export namespace IObjectEditorLogic {
  export const $: interfaces.ServiceIdentifier<IObjectEditorLogic> =
    Symbol('IObjectEditorLogic');
}
