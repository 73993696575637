import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { TabMenuLogicProps } from './../core/tabMenu/index';

interface ITabMenu extends Logic {
  readonly props: TabMenuLogicProps;
  readonly actions: {
    readonly setActiveIndex: (index: number) => void;
  };
  readonly values: {
    activeIndex: number;
  };
}

export interface ITabMenuLogic extends LogicWrapper<ITabMenu> {}

export namespace ITabMenuLogic {
  export const $: interfaces.ServiceIdentifier<ITabMenuLogic> =
    Symbol('ITabMenuLogic');
}
