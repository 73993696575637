import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useMountedLogic, useValues } from 'kea';
import { Dropdown } from 'primereact/dropdown';
import { FC, useMemo } from 'react';

import { useTranslation } from '@/hooks';
import { IEditorLogic } from '@/logic/interfaces/editor';
import { IEditorFieldLogic } from '@/logic/interfaces/editorField';
import { EditorFieldKey, EnumContent } from '@/types/custom/editorTypes';

interface EnumEditorProps {
  name: string;
  options: EnumContent[];
  disabled?: boolean;
  editorLogic: IEditorLogic;
  editorKey: EditorFieldKey;
}

export const EnumEditor: FC<EnumEditorProps> = (props) => {
  const t = useTranslation();

  const editorLogic = useMountedLogic(props.editorLogic);

  const fieldLogic = useInjection(IEditorFieldLogic.$)({
    editorLogic: editorLogic as BuiltLogic<IEditorLogic>,
    editorKey: props.editorKey,
  });

  const { updateValue } = useActions(fieldLogic);
  const { value } = useValues(fieldLogic);

  const translatedOptions = useMemo(() => {
    const temp: EnumContent[] = [];

    props.options.forEach((option) => {
      temp.push({
        name: t(option.name),
        key: option.key,
      });
    });

    return temp;
  }, [props.options]);

  const option = useMemo(() => {
    return translatedOptions.find((element) => {
      return element.key == value;
    });
  }, [translatedOptions, value]);

  return (
    <div className="OCS-enum-editor">
      <div className="OCS-editor-value">{props.name}</div>
      <Dropdown
        value={
          option != undefined
            ? option
            : translatedOptions.length > 0
            ? translatedOptions[0]
            : ''
        }
        options={translatedOptions}
        disabled={props.disabled}
        optionLabel="name"
        onChange={(e) => {
          const value: EnumContent = e.value;
          updateValue({ value: value.key });
        }}
      />
    </div>
  );
};
