import { ColumnType, SortOptions } from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { AlarmEvent } from '@/types/models/event';
import { StringKeys } from '@/utility/types';

import { NotificationTableView } from '../core/definedAlarms/notificationTable';

export interface INotificationTableLogicProps extends Record<string, unknown> {
  objectUuid: string;
}

export interface INotificationTable extends Logic {
  readonly props: INotificationTableLogicProps;
  readonly actions: {
    readonly setCurrentPage: (currentPage: number) => void;
    readonly setRowsPerPage: (rowsPerPage: number) => void;
    readonly refresh: () => void;
    readonly setPaginator?: (value: boolean) => void;
    readonly setDialogVisibility: (value: boolean) => void;
  };
  readonly values: {
    readonly dataKey: 'eventId';
    readonly columns: {
      field: StringKeys<AlarmEvent>;
      header: string;
    }[];
    readonly columnTypes: {
      [key: string]: ColumnType;
    };
    readonly values: NotificationTableView[] | undefined;
    readonly loading: boolean;
    readonly error: boolean;
    readonly currentPage: number;
    readonly rowsPerPage: number;
    readonly amountOfRecords: number;
    readonly isPaginatorActive?: boolean;
    readonly sortOptions?: SortOptions;
    readonly chosenEvent: number | undefined;
    readonly dialogVisible: boolean;
  };
}
export interface INotificationTableLogic
  extends LogicWrapper<INotificationTable> {
  build: any;
}

export type INotificationTableLogicIdentifier =
  interfaces.ServiceIdentifier<INotificationTableLogic>;

export namespace INotificationTableLogic {
  export const $: INotificationTableLogicIdentifier = Symbol(
    'INotificationTableLogic',
  );
}
