import { Table } from '@ocs/ocs-components';
import { Card } from 'primereact/card';
import { FC } from 'react';

import { IDefinedAlarmsTableLogic } from '@/logic/interfaces/definedAlarmsTable';

import './../mainStyle.scss';

export const DefinedAlarmsDatatable: FC = () => {
  return (
    <div className="OCSdatatable">
      <Card className="OCSdatatable-card">
        <Table tableLogicIdentifier={IDefinedAlarmsTableLogic.$} />
      </Card>
    </div>
  );
};
