import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { FC } from 'react';

import { config } from '@/config';

import './style.scss';

interface FooterPopupProps {
  popupMessage: string;
  popupIcon?: string;
  accept?: () => void;
  acceptLabel?: string;
  reject?: () => void;
  rejectLabel?: string;

  buttonClassname?: string;

  buttonIcon?: string;
  buttonLabel?: string;
  disabled?: boolean;

  tooltipContent?: string;
}

export const FooterPopup: FC<FooterPopupProps> = (props) => {
  return (
    <>
      <Button
        className={`OCS-footer-text-button ${
          props.buttonClassname ? props.buttonClassname : ''
        }`}
        onClick={(event) => {
          confirmPopup({
            target: event.currentTarget,
            message: props.popupMessage,
            icon: props.popupIcon,
            accept: props.accept,
            acceptLabel: props.acceptLabel,
            reject: props.reject,
            rejectLabel: props.rejectLabel,
          });
        }}
        icon={props.buttonIcon}
        label={props.buttonLabel}
        disabled={props.disabled}
        tooltip={
          props.tooltipContent != null ? props.tooltipContent : undefined
        }
        tooltipOptions={{
          position: 'top',
          baseZIndex: config.tooltip.zIndex,
          showDelay: config.tooltip.showDelay,
          appendTo: config.tooltip.appendTo,
        }}
      />
      <ConfirmPopup className="OCS-footer-popup-button" />
    </>
  );
};
