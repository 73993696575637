import { MDevice } from '@/types/models';

import { IvisSystemApiStream } from '..';
import { genericDeviceStream } from '../streams/genericDevice';
import { vibrodetectorStream } from '../streams/vibrodetector';
import { DeviceAlias } from './../index';

export const getDeviceStream = async (
  deviceAlias: DeviceAlias,
  logicKey: {
    sourceUuid: string;
    sourceType: 'Object' | 'Device';
    visualizationUuid: string;
    name: string;
    systemapi_name: string;
  },
): Promise<IvisSystemApiStream | null> => {
  let stream: null | IvisSystemApiStream = null;
  switch (deviceAlias.deviceKind) {
    case MDevice.Kind.VibroDetector:
      stream = await vibrodetectorStream(
        deviceAlias.sourceUuid,
        deviceAlias.interval,
        logicKey,
      );
      break;

    case MDevice.Kind.GenericDevice:
      stream = await genericDeviceStream(
        deviceAlias.sourceUuid,
        deviceAlias.interval,
        logicKey,
      );
      break;

    default:
      console.error(
        'device of kind is not supported',
        deviceAlias.deviceKind,
        deviceAlias.sourceUuid,
      );
      break;
  }
  return stream;
};
