import { CompositeDate, TableValue } from '@ocs/ocs-components';

import { DeviceView as GrpcDeviceView } from '@/services/compiled/devices';
import {
  AssignSlotIn as MAssignSlotIn,
  GetByVisualizationAliasIn as MGetByVisualizationAliasIn,
  GetByVisualizationAliasOut as MGetByVisualizationAliasOut,
  GetIn as MGetIn,
  GetListIn as MGetListIn,
  GetListOut as MGetListOut,
  GetOut as MGetOut,
  GetSlotsIn as MGetSlotsIn,
  GetSlotsOut as MGetSlotsOut,
  UpdateVisualizationAliasIn as MUpdateVisualizationAliasIn,
} from '@/types/messages/device';

export namespace MDevice {
  export namespace Messages {
    export type GetIn = MGetIn;
    export type GetOut = MGetOut;
    export type GetByVisualizationAliasIn = MGetByVisualizationAliasIn;
    export type GetByVisualizationAliasOut = MGetByVisualizationAliasOut;
    export type GetListIn = MGetListIn;
    export type GetListOut = MGetListOut;
    export type GetSlotsIn = MGetSlotsIn;
    export type GetSlotsOut = MGetSlotsOut;
    export type AssignSlotIn = MAssignSlotIn;
    export type UpdateVisualizationAliasIn = MUpdateVisualizationAliasIn;
  }

  export enum Kind {
    PreInsulation = 0,
    Hub = 1,
    ImpulsMeter = 2,
    GenericDevice = 3,
    HeatMeter = 4,
    AccessControl = 5,
    SolarInverter = 6,
    VibroAccelerometers = 7,
    VibroDetector = 8,
  }

  export enum HysteresisMode {
    Percentage = 0,
    Absolute = 1,
  }

  export const TableViewSortKeys: (keyof GrpcDeviceView)[] = [
    'deviceUuid',
    'objectUuid',
    'parentDeviceUuid',
    'listKind',
    'listName',
    'statusNoTransmission',
    'temporaryLastDataDate',
  ];
  export type TableViewSortKeys = keyof GrpcDeviceView;

  export interface Base {
    objectUuid?: string;
    name: string;
    kind: Kind;
    parentDeviceUuid?: string;
  }

  export interface Temporary {
    lastDataDate?: Date;
  }

  export interface Status {
    noTransmission: boolean;
  }

  export interface View {
    deviceUuid: string;
    status: Status;
    base: Base;
    temp: Temporary;
  }

  export interface TableView extends TableValue {
    deviceUuid: string;
    objectUuid?: string;
    parentDeviceUuid?: string;
    listKind: Kind;
    listName: string;
    statusNoTransmission: boolean;
    temporaryLastDataDate?: CompositeDate;
  }

  export interface Settings {
    enabled: boolean;
    timeout?: number;
    minWriteInterval?: number;
    visualizationUuid?: string;
    rtuOverride?: number;
    valuesHysteresis?: number;
    hysteresisMode: HysteresisMode;
    timeoutHysteresis?: number;
  }

  export interface Detail {
    deviceUuid: string;
    settings: Settings;
  }

  export interface Full extends View, Detail {}

  export interface Slot {
    name: string;
    number: number;
    compatibleKind: Kind[];
    parentDeviceUuid: string;
    childDeviceUuid?: string;
  }

  export interface Editor {
    data?: Base;
    settings?: Settings;
    temporary?: Temporary;
    status?: Status;
  }
}
