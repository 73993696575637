import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { MDefinedAlarms } from '@/types/models/definedAlarms';

import { IStream } from './../types/stream';

export interface IDefinedAlarmsService {
  getList: (
    input: MDefinedAlarms.Messages.GetListIn,
  ) => Promise<Response<MDefinedAlarms.Messages.GetListOut>>;

  getByVisalizationAlias: (
    input: MDefinedAlarms.Messages.GetByVisualizationAliasIn,
  ) => Promise<Response<MDefinedAlarms.Messages.GetByVisualizationAliasOut>>;

  stream: IStream<
    MDefinedAlarms.Messages.LiveIn,
    MDefinedAlarms.Messages.LiveOut
  >;

  getSettings: (
    input: MDefinedAlarms.Messages.DefinedAlarmUuid,
  ) => Promise<Response<MDefinedAlarms.Messages.StoreOut>>;

  store: (
    input: MDefinedAlarms.Messages.StoreIn,
  ) => Promise<Response<MDefinedAlarms.Messages.StoreOut>>;

  updateAlias: (
    input: MDefinedAlarms.Messages.UpdateDefinedAlarmAliasIn,
  ) => Promise<Response<void>>;

  getGroupList: (
    input: MDefinedAlarms.Messages.GetListGroupIn,
  ) => Promise<Response<MDefinedAlarms.Messages.GetListGroupOut>>;

  getGroup: (
    input: MDefinedAlarms.Messages.GetGroupIn,
  ) => Promise<Response<MDefinedAlarms.Messages.GetGroupOut>>;

  storeGroup: (
    input: MDefinedAlarms.Messages.StoreGroupIn,
  ) => Promise<Response<MDefinedAlarms.Messages.StoreGroupOut>>;

  removeGroup: (
    input: MDefinedAlarms.Messages.RemoveGroupIn,
  ) => Promise<Response<void>>;
}

export namespace IDefinedAlarmsService {
  export const $: interfaces.ServiceIdentifier<IDefinedAlarmsService> = Symbol(
    'IDefinedAlarmsService',
  );
}
