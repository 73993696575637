import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { MObject } from '@/types/models';

import { IStream } from '../types/stream';

export interface IObjectService {
  getWorkStates: () => Promise<Response<MObject.Messages.GetWorkStatesOut>>;
  getList: (
    input: MObject.Messages.GetListIn,
  ) => Promise<Response<MObject.Messages.GetListOut>>;
  getTypesLists: (
    input: MObject.Messages.GetTypesListIn,
  ) => Promise<Response<MObject.Messages.GetTypesListOut>>;
  store: (
    input: MObject.Messages.StoreIn,
  ) => Promise<Response<MObject.Messages.StoreOut>>;
  assignDevice: (
    input: MObject.Messages.AssignDeviceIn,
  ) => Promise<Response<void>>;
  get: (
    input: MObject.Messages.GetIn,
  ) => Promise<Response<MObject.Messages.GetOut>>;
  getGraphWorkStates: (
    input: MObject.Messages.GetGraphWorkStatesIn,
  ) => Promise<Response<MObject.Messages.GetGraphWorkStatesOut>>;
  download: (
    input: MObject.Messages.DownloadIn,
  ) => Promise<Response<MObject.Messages.DownloadOut>>;
  stream: IStream<
    MObject.Messages.LiveWorkStateIn,
    MObject.Messages.LiveWorkStateOut
  >;
}
export namespace IObjectService {
  export const $: interfaces.ServiceIdentifier<IObjectService> =
    Symbol('IObjectService');
}
