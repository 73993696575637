import { useInjection } from 'inversify-react';
import { useMountedLogic, useValues } from 'kea';
import { Tooltip } from 'primereact/tooltip';
import { FC, useMemo } from 'react';

import { IVisElementLogic } from '@/logic/interfaces/visualization/visElement';
import { DescriptionType, Dynamization } from '@/types/visualization';
import { GetRgbString } from '@/utils/colorUtils';
import { GetDynamicProps } from '@/utils/visualizationUtils';

import { visualizationContextLogic } from '.';

interface DescProps {
  desc: DescriptionType;
}

export const DescriptionVisualization: FC<DescProps> = (props) => {
  const visLogic = useMountedLogic(visualizationContextLogic);

  const visElementLogic = useInjection(IVisElementLogic.$)({
    sourceUuid: visLogic.props.sourceUuid,
    sourceType: visLogic.props.sourceType,
    visualizationUuid: visLogic.props.visualizationUuid,
    name: visLogic.props.name,
    elementUuid: props.desc.name,
    tasks: props.desc.tasks,
  });

  const { values } = useValues(visElementLogic);

  const dynamicProps = useMemo(() => {
    if (values != null && values[0])
      return GetDynamicProps(props.desc, values[0]) as DescriptionType;
    else return props.desc;
  }, [props.desc]);

  if (
    dynamicProps.dynamization === Dynamization.Visibility &&
    values != null &&
    values[0] == 0
  )
    return <></>;

  return (
    <>
      <span
        className={`${props.desc.name}`}
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: dynamicProps.alignment,

          top: dynamicProps.top,
          left: dynamicProps.left,
          height: dynamicProps.height,
          width: dynamicProps.width,

          wordWrap: dynamicProps.wordwrap ? 'break-word' : 'normal',

          backgroundColor: dynamicProps.transparent
            ? 'transparent'
            : GetRgbString(
                dynamicProps.color.red,
                dynamicProps.color.green,
                dynamicProps.color.blue,
              ),

          fontFamily: dynamicProps.font,
          color: GetRgbString(
            dynamicProps.fontColor.red,
            dynamicProps.fontColor.green,
            dynamicProps.fontColor.blue,
          ),
          fontWeight: `${dynamicProps.fontStyle.bold ? 'bold' : 'normal'}`,
          fontStyle: `${dynamicProps.fontStyle.italic ? 'italic' : 'normal'}`,
          textDecoration: `${
            dynamicProps.fontStyle.underline ? 'underline' : ''
          } ${dynamicProps.fontStyle.crossedOut ? 'line-through' : ''}`,
          fontSize: dynamicProps.fontSize,

          visibility: dynamicProps.hidden ? 'hidden' : 'visible',
        }}
      >
        {dynamicProps.description}
      </span>
      <Tooltip
        target={`.${dynamicProps.name}`}
        content={dynamicProps.tooltip}
      />
    </>
  );
};
