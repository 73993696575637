import { deepEqual } from 'fast-equals';
import produce from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  propsChanged,
  reducers,
  selectors,
} from 'kea';

import { ChartHandle } from '@/components/chart';
import Dependencies from '@/deps';
import {
  IDeviceViewContainerLogic,
  IGenericDevicesDictsLogic,
  IObjectViewContainerLogic,
} from '@/logic/interfaces';
import { MakeInitialDataOptional, injectDepsToLogic } from '@/logic/utils';
import { SearchRule } from '@/services/compiled/table';
import {
  IDeviceService,
  IGenericDeviceService,
  IVibrodetectorService,
} from '@/services/interfaces';
import { MDevice, MGenericDevice, MObject } from '@/types/models';
import { TableSearchRule } from '@/types/models/table';

import { IObjectService } from './../../../../services/interfaces/object';
import { IObjectDetailContainerLogic } from './../../../interfaces/objectDetailContainer';
import { IUnitsLogic } from './../../../interfaces/units';
import type { logicType } from './indexType';

export interface GenericDeviceChartLogicProps {
  deps: {
    deviceService: IDeviceService;
    deviceViewContainerLogic: IDeviceViewContainerLogic;
    genericDeviceService: IGenericDeviceService;
    genericDevicesDictsLogic: IGenericDevicesDictsLogic;
    vibrodetectorService: IVibrodetectorService;
    unitsLogic: IUnitsLogic;
    objectViewContainerLogic: IObjectViewContainerLogic;
    objectService: IObjectService;
    objectDetailContainerLogic: IObjectDetailContainerLogic;
  };
  initialData: {
    objectUuid: string;
  };
  chart?: ChartHandle | null;
}

const logic = kea<logicType>([
  props({} as GenericDeviceChartLogicProps),
  key((props) => props.initialData.objectUuid),
  path(['object', 'charts']),
  actions({
    prepareChart: true,
    setDateRange: (dateRange: { start: Date; end: Date }) => ({ dateRange }),
    setLoadedDateRange: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    toggleLive: true,
    startLive: true,
    startLiveListen: true,
    stopLive: true,
    liveError: true,
    saveToImage: true,
    loadDevicesData: true,
    loadDevicesDataSuccess: (deviceIds: string[]) => ({ deviceIds }),
    loadDevicesDataFailure: true,
    loadGenericDevices: true,
    loadGenericDevicesSuccess: (
      genericDevices: Record<string, MGenericDevice.View>,
    ) => ({ genericDevices }),
    loadGenericDevicesFailure: true,
    loadCurrentRange: true,
    focusToDateRange: (fitY?: boolean) => ({ fitY }),
    loadCurrentRangeSuccess: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    loadCurrentRangeFailure: true,
    setDeviceKeySelected: (deviceKey: string, column?: number) => ({
      deviceKey,
      column,
    }),
    setDeviceKeyNotSelected: (deviceKey: string, column?: number) => ({
      deviceKey,
      column,
    }),
    loadDeviceRange: (deviceKey: string, column?: number) => ({
      deviceKey,
      column,
    }),
    loadDeviceRangeSuccess: (deviceKey: string, column?: number) => ({
      deviceKey,
      column,
    }),
    loadDeviceRangeFailure: (deviceKey: string, column?: number) => ({
      deviceKey,
      column,
    }),
    setLivePreloadedRange: (
      dateRanges: Record<string, { start: Date; end: Date }>,
    ) => ({
      dateRanges,
    }),
    loadWorkStates: true,
    loadWorkStatesFailure: true,
    loadWorkStatesSuccess: true,
    loadWorkStatesSet: (workStates: MObject.WorkState[]) => ({ workStates }),
    startWorkStatesLive: true,
    stopWorkStatesLive: true,
    setSelectedWorkStates: (workStates: number[]) => ({ workStates }),
    setAvgTimeSeconds: (seconds: number | undefined) => ({ seconds }),
    saveToCSV: true,
    startDownloading: true,
    successDownloading: true,
    errorDownloading: true,
  }),
  reducers(() => ({
    dateRange: [
      { start: new Date(Date.now() - 86400000), end: new Date() },
      {
        setDateRange: (_, { dateRange }) => dateRange,
      },
    ],
    loadedDateRange: [
      null as null | { start: Date; end: Date },
      {
        loadCurrentRangeSuccess: (_, { dateRange }) => dateRange,
        setLoadedDateRange: (_, { dateRange }) => dateRange,
      },
    ],
    loadingState: [
      {
        running: false as boolean,
        success: false as boolean,
        error: false as boolean,
      },
      {
        loadCurrentRange: () => ({
          running: true,
          success: false,
          error: false,
        }),
        loadCurrentRangeSuccess: () => ({
          running: false,
          success: true,
          error: false,
        }),
        loadCurrentRangeFailure: () => ({
          running: false,
          success: false,
          error: true,
        }),
        loadDeviceRange: () => ({
          running: true,
          success: false,
          error: false,
        }),
        loadDeviceRangeSuccess: () => ({
          running: false,
          success: true,
          error: false,
        }),
        loadDeviceRangeFailure: () => ({
          running: false,
          success: false,
          error: true,
        }),
      },
    ],
    liveState: [
      {
        loading: false as boolean,
        error: false as boolean,
        running: false as boolean,
      },
      {
        startLive: () => ({ loading: true, error: false, running: false }),
        startLiveListen: () => ({
          loading: false,
          error: false,
          running: true,
        }),
        stopLive: () => ({ loading: false, error: false, running: false }),
      },
    ],
    downloadingState: [
      {
        loading: false as boolean,
        error: false as boolean,
        success: false as boolean,
      },
      {
        startDownloading: () => ({
          loading: true,
          error: false,
          success: false,
        }),
        successDownloading: () => ({
          loading: false,
          error: false,
          success: true,
        }),
        errorDownloading: () => ({
          loading: false,
          error: true,
          success: false,
        }),
      },
    ],
    deviceIds: [
      [] as string[],
      {
        loadDevicesDataSuccess: (_, { deviceIds }) => deviceIds,
      },
    ],
    genericDevices: [
      {} as Record<string, MGenericDevice.View>,
      {
        loadGenericDevicesSuccess: (_, { genericDevices }) => genericDevices,
      },
    ],
    selectedDeviceKeys: [
      {} as Record<string, boolean | Record<string, boolean>>,
      {
        setDeviceKeySelected: (immutableState, { deviceKey, column }) =>
          produce(immutableState, (state) => {
            if (column != null) {
              if (!(deviceKey in state)) {
                state[deviceKey] = {};
              }
              const columnState = state[deviceKey];
              if (typeof columnState != 'boolean') {
                columnState[column] = true;
              }
            } else {
              state[deviceKey] = true;
            }
          }),
        setDeviceKeyNotSelected: (immutableState, { deviceKey, column }) =>
          produce(immutableState, (state) => {
            if (column != null) {
              const columnState = state[deviceKey];
              if (!(deviceKey in state)) {
                state[deviceKey] = {};
              }
              if (typeof columnState != 'boolean') {
                columnState[column] = false;
              }
            } else {
              state[deviceKey] = false;
            }
          }),
      },
    ],
    livePreloadedRange: [
      {} as Record<string, { start: Date; end: Date }>,
      {
        setLivePreloadedRange: (_, { dateRanges }) => dateRanges,
      },
    ],
    workStates: [
      [] as MObject.WorkState[],
      {
        loadWorkStatesSet: (_, { workStates }) => workStates,
      },
    ],
    selectedWorkStates: [
      [] as number[],
      {
        setSelectedWorkStates: (_, { workStates }) => workStates,
      },
    ],
    avgTimeSeconds: [
      undefined as number | undefined,
      {
        setAvgTimeSeconds: (_, { seconds }) => seconds,
      },
    ],
  })),
  selectors(({ props }) => ({
    deviceViews: [
      (selectors) => [
        selectors.deviceIds,
        props.deps.deviceViewContainerLogic.selectors.devices,
      ],
      (deviceIds, devices) => {
        const deviceViews: Record<string, MGenericDevice.View> = {};

        for (const deviceId of deviceIds) {
          deviceViews[deviceId] = devices[deviceId];
        }

        return deviceViews;
      },
    ],
    chartHandle: [
      () => [(_, props: GenericDeviceChartLogicProps) => props.chart],
      (chart) => chart,
    ],
    devices: [
      (selectors) => [
        selectors.deviceViews,
        selectors.genericDevices,
        props.deps.genericDevicesDictsLogic.selectors.brands,
        props.deps.genericDevicesDictsLogic.selectors.modelColumns,
      ],
      (
        deviceViews: Record<string, MDevice.View>,
        genericDevices,
        brands,
        modelColumns,
      ) => {
        const devices: Record<
          string,
          {
            name: string;
            columns?: MGenericDevice.ModelColumn[];
          }
        > = {};

        if (
          Object.values(genericDevices).length == 0 ||
          Object.values(deviceViews).length == 0
        ) {
          return null;
        }

        for (const deviceId in deviceViews) {
          const deviceView = deviceViews[deviceId];
          if (deviceView.base.kind == MDevice.Kind.GenericDevice) {
            const genericDevice = genericDevices[deviceId];
            const brand = brands[genericDevice.base.brandId];
            const columns = modelColumns[brand.modelId];

            devices[deviceId] = {
              name: deviceView.base.name,
              columns: columns,
            };
          } else {
            devices[deviceId] = {
              name: deviceView.base.name,
            };
          }
        }

        return devices;
      },
    ],
  })),
  listeners(({ actions, props, values, cache }) => {
    let workStates: Record<string, MObject.WorkState> = {};

    const workStatesToObject = (
      workStates: MObject.WorkState[],
    ): Record<string, MObject.WorkState> => {
      const workStatesDict: Record<string, MObject.WorkState> = {};

      for (const workState of workStates) {
        workStatesDict[workState.workStateId.toString()] = workState;
      }

      return workStatesDict;
    };

    function clearChart() {
      values.chartHandle?.clearAllSeries();
    }

    const onWorkState = async (message: {
      value: MObject.WorkStateData;
      input: any;
    }) => {
      const newWorkStateId = message.value.newWorkStateId;
      const newDate = message.value.datetime.getTime();
      values.chartHandle?.removeInviniteXAxisBand(newDate);

      if (newWorkStateId != null) {
        if (Object.values(workStates).length == 0) {
          const response = await props.deps.objectService.getWorkStates();
          if (response.success && response.data != null) {
            workStates = workStatesToObject(response.data.workStates);
          }
        }
        const workState = workStates[newWorkStateId.toString()];
        if (workState.color != null) {
          // console.log('WORKSTATE COLOR');
          // console.log(workState.color);
          values.chartHandle?.addInviniteXAxisBand(
            newDate,
            workState.color,
            workState.name,
          );
        }
      }
    };

    const onMessage = (message: {
      value:
        | MGenericDevice.Messages.DataBytesPackage
        | [Float64Array, Float64Array];
      input:
        | {
            uuid: string;
          }
        | { deviceUuid: string };
    }) => {
      let deviceUuid: string;
      if ('uuid' in message.input) {
        deviceUuid = message.input.uuid;
      } else {
        deviceUuid = message.input.deviceUuid;
      }
      const device: MDevice.View = values.deviceViews[deviceUuid];
      if (device.base.kind == MDevice.Kind.GenericDevice) {
        if (!Array.isArray(message.value)) {
          const genericDevice = values.genericDevices[device.deviceUuid];
          const brand =
            props.deps.genericDevicesDictsLogic.values.brands[
              genericDevice.base.brandId
            ];
          const columns =
            props.deps.genericDevicesDictsLogic.values.modelColumns[
              brand.modelId
            ];

          const end = new Date(
            Object.values(message.value.numbers)[0][0][
              Object.values(message.value.numbers)[0][0].length - 1
            ],
          );
          const start = new Date(
            end.getTime() -
              Math.abs(
                values.dateRange.start.getTime() -
                  values.dateRange.end.getTime(),
              ),
          );

          if (values.loadedDateRange == null) {
            actions.setLoadedDateRange({
              start,
              end,
            });
          } else {
            if (
              start.getTime() > values.loadedDateRange.start.getTime() &&
              end.getTime() > values.loadedDateRange.end.getTime()
            ) {
              actions.setLoadedDateRange({
                start,
                end,
              });
            }
          }

          for (const column of columns) {
            if (column.modelColumnId in message.value.numbers) {
              const seriesKey =
                device.deviceUuid + column.modelColumnId.toString();

              values.chartHandle?.addValueArraysToSeries(
                seriesKey,
                message.value.numbers[column.modelColumnId][0],
                message.value.numbers[column.modelColumnId][1],
              );
            }
          }
        }
      } else {
        if (Array.isArray(message.value)) {
          const end = new Date(message.value[0][message.value[0].length - 1]);

          const start = new Date(
            end.getTime() -
              Math.abs(
                values.dateRange.start.getTime() -
                  values.dateRange.end.getTime(),
              ),
          );

          if (values.loadedDateRange == null) {
            actions.setLoadedDateRange({
              start,
              end,
            });
          } else {
            if (
              start.getTime() > values.loadedDateRange.start.getTime() &&
              end.getTime() > values.loadedDateRange.end.getTime()
            ) {
              actions.setLoadedDateRange({
                start,
                end,
              });
            }
          }

          if (Array.isArray(message.value)) {
            values.chartHandle?.addValueArraysToSeries(
              deviceUuid,
              message.value[0],
              message.value[1],
            );
          }
        }
      }
    };

    cache.onMessage = onMessage;

    return {
      saveToCSV: async () => {
        if (values.dateRange != null) {
          actions.startDownloading();
          const devices: Record<string, { columns: string[] }> = {};

          for (const deviceId in values.selectedDeviceKeys) {
            const device = values.selectedDeviceKeys[deviceId];
            if (typeof device == 'boolean') {
              if (device) {
                devices[deviceId] = { columns: [] };
              }
            } else {
              devices[deviceId] = {
                columns: Object.entries(device)
                  .filter(([_, value]) => value)
                  .map(([columnId]) => columnId),
              };
            }
          }

          console.log('test');

          const response = await props.deps.objectService.download({
            daterange: values.dateRange,
            devicesUuid: devices,
            objectUuid: props.initialData.objectUuid,
            workStateId: values.selectedWorkStates,
            avgTimeSeconds:
              values.avgTimeSeconds != 0 ? values.avgTimeSeconds : undefined,
          });

          if (response.success) {
            actions.successDownloading();
            if (process.env.NODE_ENV === 'development') {
              window.open(
                `https://localhost:8433/api/downloads/${response.data.fileUuid}`,
              );
            } else {
              window.open(
                `${window.location.origin}/api/downloads/${response.data.fileUuid}`,
              );
            }
          } else {
            actions.errorDownloading();
          }
        }
      },
      loadWorkStates: async () => {
        values.chartHandle?.clearAllAxisBands();
        const workStates = await props.deps.objectService.getWorkStates();
        values.chartHandle?.clearAllAxisBands();

        const workStatesData =
          await props.deps.objectService.getGraphWorkStates({
            daterange: values.dateRange,
            objectUuid: props.initialData.objectUuid,
          });
        if (
          workStates.success &&
          workStatesData.success &&
          workStatesData.data != null
        ) {
          actions.loadWorkStatesSet(workStates.data.workStates);
          let lastWorkStateData: null | MObject.WorkStateData = null;
          if (Object.values(workStatesData.data.data).length > 2) {
            const firstWorkState = Object.values(workStatesData.data.data)[0];
            const lastWorkState = Object.values(workStatesData.data.data)[
              Object.values(workStatesData.data.data).length - 1
            ];
            const workStateDict = workStatesToObject(
              workStates.data?.workStates as MObject.WorkState[],
            );

            if (firstWorkState.previousWorkStateId != null) {
              const workState =
                workStateDict[firstWorkState.previousWorkStateId.toString()];
              if (workState.color != null) {
                values.chartHandle?.addXAxisBand(
                  values.dateRange.start.getTime(),
                  firstWorkState.datetime.getTime(),
                  workState.color,
                  workState.name,
                );
              }
            }
            if (lastWorkState.newWorkStateId != null) {
              const workState =
                workStateDict[lastWorkState.newWorkStateId.toString()];
              if (workState.color != null) {
                values.chartHandle?.addXAxisBand(
                  lastWorkState.datetime.getTime(),
                  values.dateRange.end.getTime(),
                  workState.color,
                  workState.name,
                );
              }
            }
          }

          for (const workStateData of workStatesData.data.data) {
            if (lastWorkStateData != null) {
              const newWorkStateId = lastWorkStateData.newWorkStateId;
              const lastWorkStateId = workStateData.previousWorkStateId;
              if (
                lastWorkStateId != null &&
                newWorkStateId != null &&
                newWorkStateId == lastWorkStateId
              ) {
                const workStateDict = workStatesToObject(
                  workStates.data?.workStates as MObject.WorkState[],
                );
                const workState = workStateDict[newWorkStateId.toString()];

                if (workState != null && workState.color != null) {
                  values.chartHandle?.addXAxisBand(
                    lastWorkStateData.datetime.getTime(),
                    workStateData.datetime.getTime(),
                    workState.color,
                    workState.name,
                  );
                }
              }
            }
            lastWorkStateData = workStateData;
          }
          actions.loadWorkStatesSuccess();
          values.chartHandle?.updateLegend();
        } else {
          actions.loadWorkStatesFailure();
        }
      },

      saveToImage: async () => {
        let object: MObject.View | MObject.Full | null = null;
        if (
          props.initialData.objectUuid in
          props.deps.objectViewContainerLogic.values.objects
        ) {
          object =
            props.deps.objectViewContainerLogic.values.objects[
              props.initialData.objectUuid
            ];
        } else {
          const response = await props.deps.objectService.get({
            objectUuid: props.initialData.objectUuid,
          });
          if (response.success) {
            object = response.data;
            props.deps.objectDetailContainerLogic.actions.addObjects([
              object as MObject.Full,
            ]);
          }
        }
        if (object != null) {
          values.dateRange.start.toLocaleString('en-GB').replace(',', '') +
            '.' +
            values.dateRange.start
              .getMilliseconds()
              .toString()
              .padStart(3, '0');
          values.chartHandle?.saveToImage(
            (object.base.name != null ? object.base.name : '') +
              '_' +
              values.dateRange.start.toLocaleString('en-GB').replace(',', '') +
              '.' +
              values.dateRange.start
                .getMilliseconds()
                .toString()
                .padStart(3, '0') +
              '_' +
              values.dateRange.end.toLocaleString('en-GB').replace(',', '') +
              '.' +
              values.dateRange.end
                .getMilliseconds()
                .toString()
                .padStart(3, '0'),
          );
        }
      },
      setSelectedWorkStates: () => {
        actions.loadCurrentRange();
      },
      loadCurrentRange: async () => {
        actions.loadWorkStates();
        clearChart();
        for (const deviceId in values.deviceViews) {
          const device: MDevice.View = values.deviceViews[deviceId];

          if (device.base.kind == MDevice.Kind.GenericDevice) {
            if (!(deviceId in values.selectedDeviceKeys)) {
              continue;
            }
            const genericDevice = values.genericDevices[device.deviceUuid];
            const brand =
              props.deps.genericDevicesDictsLogic.values.brands[
                genericDevice.base.brandId
              ];
            const columns =
              props.deps.genericDevicesDictsLogic.values.modelColumns[
                brand.modelId
              ];
            const response = await props.deps.genericDeviceService.graphBytes({
              deviceUuid: device.deviceUuid,
              daterange: values.dateRange,
              workStateIds: values.selectedWorkStates,
            });
            if (response.success) {
              for (const column of columns) {
                if (
                  deviceId in values.selectedDeviceKeys &&
                  (
                    values.selectedDeviceKeys[deviceId] as Record<
                      string,
                      unknown
                    >
                  )[column.modelColumnId.toString()] != true
                ) {
                  continue;
                }
                if (column.modelColumnId in response.data.numbers) {
                  const data = response.data.numbers[column.modelColumnId];
                  values.chartHandle?.addValueArraysToSeries(
                    device.deviceUuid + column.modelColumnId.toString(),
                    data[0],
                    data[1],
                  );
                }
              }
            } else {
              actions.loadCurrentRangeFailure();
            }
          } else {
            if (values.selectedDeviceKeys[deviceId] != true) {
              continue;
            }
            const response = await props.deps.vibrodetectorService.getGraphData(
              {
                deviceUuid: device.deviceUuid,
                daterange: values.dateRange,
                workStateIds: values.selectedWorkStates,
              },
            );

            if (response.success) {
              values.chartHandle?.addValueArraysToSeries(
                device.deviceUuid,
                response.data[0],
                response.data[1],
              );
            } else {
              actions.loadCurrentRangeFailure();
            }
          }
        }
        actions.focusToDateRange();
        actions.loadCurrentRangeSuccess(values.dateRange);
      },
      prepareChart: () => {
        console.log('PREPARE CHART');
        values.chartHandle?.toggleLegend();
        values.chartHandle?.setRange(
          values.dateRange.start.getTime(),
          values.dateRange.end.getTime(),
          true,
        );
        values.chartHandle?.setDefaultColumnTitle('mm/s');
      },
      focusToDateRange: ({ fitY }) => {
        values.chartHandle?.setRange(
          values.dateRange.start.getTime(),
          values.dateRange.end.getTime(),
          fitY,
        );
        values.chartHandle?.setRange(
          values.dateRange.start.getTime(),
          values.dateRange.end.getTime(),
          fitY,
        );
      },
      startWorkStatesLive: async () => {
        const start = Object.values(values.livePreloadedRange)[0];
        await props.deps.objectService.stream.listen(
          { onMessage: onWorkState },
          {
            uuid: props.initialData.objectUuid,
            start: start.end,
            workStateId: [],
          },
        );
      },
      stopWorkStatesLive: async () => {
        const start = Object.values(values.livePreloadedRange)[0];
        props.deps.objectService.stream.cancel(onWorkState, {
          uuid: props.initialData.objectUuid,
          start: start.end,
          workStateId: [],
        });
      },
      startLive: async () => {
        const newDateRange = {
          start: new Date(
            new Date().getTime() -
              Math.abs(
                values.dateRange.start.getTime() -
                  values.dateRange.end.getTime(),
              ),
          ),
          end: new Date(),
        };
        actions.setLoadedDateRange(newDateRange);
        const lastLoadedDates: Record<string, { start: Date; end: Date }> = {};
        for (const deviceKey in values.deviceViews) {
          const device: MDevice.View = values.deviceViews[deviceKey];
          if (device.base.kind == MDevice.Kind.GenericDevice) {
            const genericDevice = values.genericDevices[deviceKey];
            const brand =
              props.deps.genericDevicesDictsLogic.values.brands[
                genericDevice.base.brandId
              ];
            const columns =
              props.deps.genericDevicesDictsLogic.values.modelColumns[
                brand.modelId
              ];
            const response = await props.deps.genericDeviceService.graphBytes({
              deviceUuid: device.deviceUuid,
              daterange: newDateRange,
            });

            if (response.success) {
              for (const column of columns) {
                if (
                  !(device.deviceUuid in values.selectedDeviceKeys) ||
                  !(
                    column.modelColumnId in
                    (values.selectedDeviceKeys[device.deviceUuid] as Record<
                      string,
                      unknown
                    >)
                  ) ||
                  (
                    values.selectedDeviceKeys[device.deviceUuid] as Record<
                      string,
                      unknown
                    >
                  )[column.modelColumnId] != true
                ) {
                  continue;
                }
                const seriesKey =
                  device.deviceUuid + column.modelColumnId.toString();

                if (column.modelColumnId in response.data.numbers) {
                  values.chartHandle?.clearSeries(seriesKey);

                  const data = response.data.numbers[column.modelColumnId];
                  values.chartHandle?.addValueArraysToSeries(
                    seriesKey,
                    data[0],
                    data[1],
                  );
                }
              }
              let start: Date;
              let end: Date;
              try {
                start = new Date(Object.values(response.data.numbers)[0][0][0]);
                end = new Date(
                  Object.values(response.data.numbers)[0][0][
                    Object.values(response.data.numbers)[0][0].length - 1
                  ],
                );
              } catch {
                start = values.dateRange.start;
                end = values.dateRange.end;
              }
              lastLoadedDates[deviceKey] = { start, end };
            } else {
              actions.liveError();
              return;
            }
          } else {
            if (values.selectedDeviceKeys[deviceKey] != true) {
              continue;
            }
            const response = await props.deps.vibrodetectorService.getGraphData(
              { deviceUuid: device.deviceUuid, daterange: newDateRange },
            );
            if (response.success) {
              lastLoadedDates[deviceKey] = {
                start: new Date(response.data[0][0]),
                end: new Date(response.data[0][response.data[0].length - 1]),
              };
              values.chartHandle?.clearSeries(deviceKey);
              values.chartHandle?.addValueArraysToSeries(
                deviceKey,
                response.data[0],
                response.data[1],
              );
            } else {
              actions.liveError();
              return;
            }
          }
        }
        actions.setLivePreloadedRange(lastLoadedDates);
        actions.focusToDateRange(false);
        actions.startLiveListen();
        actions.startWorkStatesLive();
      },
      startLiveListen: async () => {
        values.chartHandle?.toggleAutoScroll(true);
        values.chartHandle?.toggleMovement(false);
        for (const deviceKey in values.deviceViews) {
          const device: MDevice.View = values.deviceViews[deviceKey];
          if (device.base.kind == MDevice.Kind.GenericDevice) {
            const genericDevice = values.genericDevices[deviceKey];
            const brand =
              props.deps.genericDevicesDictsLogic.values.brands[
                genericDevice.base.brandId
              ];
            const columns =
              props.deps.genericDevicesDictsLogic.values.modelColumns[
                brand.modelId
              ];
            if (
              !(
                device.deviceUuid in values.selectedDeviceKeys &&
                typeof values.selectedDeviceKeys[device.deviceUuid] != 'boolean'
              )
            ) {
              continue;
            }
            for (const column of columns) {
              if (
                column.modelColumnId in
                (values.selectedDeviceKeys[device.deviceUuid] as Record<
                  string,
                  unknown
                >)
              ) {
                const seriesKey =
                  device.deviceUuid + column.modelColumnId.toString();

                values.chartHandle?.setDataCleaning(seriesKey, true);
                await props.deps.genericDeviceService.streamBytes.listen(
                  { onMessage: onMessage },
                  {
                    uuid: device.deviceUuid,
                    start: values.livePreloadedRange[device.deviceUuid].end,
                    workStateIds: values.selectedWorkStates,
                  },
                );
                break;
              }
            }
          } else {
            if (!(device.deviceUuid in values.selectedDeviceKeys)) {
              continue;
            }
            values.chartHandle?.setDataCleaning(device.deviceUuid, true);
            await props.deps.vibrodetectorService.stream.listen(
              {
                onMessage: onMessage,
              },
              {
                uuid: device.deviceUuid,
                start: values.livePreloadedRange[device.deviceUuid].end,
                workStateIds: values.selectedWorkStates,
              },
            );
          }
        }
      },
      stopLive: () => {
        for (const deviceKey in values.deviceViews) {
          const device: MDevice.View = values.deviceViews[deviceKey];
          if (device.base.kind == MDevice.Kind.GenericDevice) {
            const genericDevice = values.genericDevices[deviceKey];
            const brand =
              props.deps.genericDevicesDictsLogic.values.brands[
                genericDevice.base.brandId
              ];
            const columns =
              props.deps.genericDevicesDictsLogic.values.modelColumns[
                brand.modelId
              ];
            if (
              !(
                device.deviceUuid in values.selectedDeviceKeys &&
                typeof values.selectedDeviceKeys[device.deviceUuid] != 'boolean'
              )
            ) {
              continue;
            }
            for (const column of columns) {
              if (
                column.modelColumnId in
                (values.selectedDeviceKeys[device.deviceUuid] as Record<
                  string,
                  unknown
                >)
              ) {
                const seriesKey =
                  device.deviceUuid + column.modelColumnId.toString();
                values.chartHandle?.setDataCleaning(seriesKey, false);
                props.deps.genericDeviceService.streamBytes.cancel(onMessage, {
                  uuid: device.deviceUuid,
                  start: values.livePreloadedRange[device.deviceUuid].end,
                  workStateIds: values.selectedWorkStates,
                });
                break;
              }
            }
          } else {
            if (!(device.deviceUuid in values.selectedDeviceKeys)) {
              continue;
            }
            values.chartHandle?.setDataCleaning(device.deviceUuid, false);
            props.deps.vibrodetectorService.stream.cancel(onMessage, {
              uuid: device.deviceUuid,
              start: values.livePreloadedRange[device.deviceUuid].end,
              workStateIds: values.selectedWorkStates,
            });
          }
        }
        if (values.loadedDateRange != null) {
          actions.setDateRange(values.loadedDateRange);
        }
        values.chartHandle?.toggleAutoScroll(false);
        values.chartHandle?.toggleMovement(true);
        actions.stopWorkStatesLive();
      },
      toggleLive: () => {
        if (!values.liveState.running) {
          actions.startLive();
        } else {
          actions.stopLive();
        }
      },
      setDeviceKeySelected: ({ deviceKey, column }) => {
        const device: MDevice.View = values.deviceViews[deviceKey];
        if (column != null) {
          const genericDevice = values.genericDevices[device.deviceUuid];
          const brand =
            props.deps.genericDevicesDictsLogic.values.brands[
              genericDevice.base.brandId
            ];
          const columns =
            props.deps.genericDevicesDictsLogic.values.modelColumns[
              brand.modelId
            ];
          let name = '';
          let unitName = '';
          for (const columnDef of columns) {
            if (columnDef.modelColumnId == column) {
              console.log(columnDef);
              const unit = props.deps.unitsLogic.values.units[columnDef.unitId];
              unitName = unit.shortDescription;
              name =
                device.base.name +
                ' ' +
                columnDef.name +
                ' [' +
                unit.shortDescription +
                ']';
            }
          }
          console.log(unitName);
          values.chartHandle?.addSeries(
            deviceKey + column.toString(),
            name,
            unitName,
          );
        } else {
          values.chartHandle?.addSeries(deviceKey, device.base.name);
        }
        actions.loadDeviceRange(deviceKey, column);
      },
      setDeviceKeyNotSelected: ({ deviceKey, column }) => {
        if (column != null) {
          values.chartHandle?.removeSeries(deviceKey + column.toString());
        } else {
          values.chartHandle?.removeSeries(deviceKey);
        }
        values.chartHandle?.updateLegend();
      },
      loadDeviceRange: async ({ deviceKey, column }) => {
        const device: MDevice.View = values.deviceViews[deviceKey];
        if (device.base.kind == MDevice.Kind.GenericDevice && column != null) {
          const response = await props.deps.genericDeviceService.graphBytes({
            deviceUuid: device.deviceUuid,
            daterange: values.dateRange,
            workStateIds: values.selectedWorkStates,
          });
          if (response.success) {
            if (column in response.data.numbers) {
              values.chartHandle?.addValueArraysToSeries(
                deviceKey + column.toString(),
                response.data.numbers[column][0],
                response.data.numbers[column][1],
              );
            }
            values.chartHandle?.updateLegend();
            actions.loadDeviceRangeSuccess(deviceKey, column);
          } else {
            actions.loadDeviceRangeFailure(deviceKey, column);
          }
        } else {
          const response = await props.deps.vibrodetectorService.getGraphData({
            deviceUuid: device.deviceUuid,
            daterange: values.dateRange,
            workStateIds: values.selectedWorkStates,
          });
          if (response.success) {
            values.chartHandle?.addValueArraysToSeries(
              deviceKey,
              response.data[0],
              response.data[1],
            );
            values.chartHandle?.updateLegend();

            actions.loadDeviceRangeSuccess(deviceKey, column);
          } else {
            actions.loadDeviceRangeFailure(deviceKey, column);
          }
        }
      },
      loadDevicesData: async () => {
        const response = await props.deps.deviceService.getList({
          searchAll: [
            {
              column: 'objectUuid',
              rule: TableSearchRule.Equal,
              value: [props.initialData.objectUuid],
            },
          ],
          searchAny: [],
        });
        if (response.success) {
          const deviceIds = response.data.devices.map(
            (device) => device.deviceUuid,
          );

          props.deps.deviceViewContainerLogic.actions.addDevices(
            response.data.devices,
          );
          props.deps.deviceViewContainerLogic.actions.useDevices(deviceIds);

          actions.loadDevicesDataSuccess(deviceIds);
        } else {
          actions.loadDevicesDataFailure();
        }
      },
      loadDevicesDataFailure: async () => {
        await new Promise((r) => setTimeout(r, 1000));
        actions.loadDevicesData();
      },
      loadDevicesDataSuccess: () => {
        actions.loadGenericDevices();
      },
      loadGenericDevices: async () => {
        const genericDevices: Record<string, MGenericDevice.View> = {};
        for (const deviceId of values.deviceIds) {
          const device =
            props.deps.deviceViewContainerLogic.values.devices[deviceId];
          if (device.base.kind == MDevice.Kind.GenericDevice) {
            const response = await props.deps.genericDeviceService.get({
              deviceUuid: device.deviceUuid,
            });
            if (
              response.success &&
              response.data.deviceUuid != undefined &&
              response.data.info != undefined
            ) {
              genericDevices[device.deviceUuid] = {
                deviceUuid: response.data.deviceUuid,
                base: {
                  brandId: response.data.info.brandId,
                },
              };
            } else {
              actions.loadGenericDevicesFailure();
              return;
            }
          }
        }
        actions.loadGenericDevicesSuccess(genericDevices);
      },
      loadGenericDevicesFailure: async () => {
        await new Promise((r) => setTimeout(r, 1000));
        actions.loadGenericDevices();
      },
      loadGenericDevicesSuccess: () => {},
    };
  }),
  afterMount(({ actions, values }) => {
    actions.loadDevicesData();
  }),
  propsChanged(({ actions, props, cache }, oldProps) => {
    if (
      props.chart != null &&
      oldProps.chart == null &&
      cache.chartAdded != true
    ) {
      cache.chartAdded = true;

      actions.prepareChart();
      actions.loadWorkStates();
    }
  }),
  beforeUnmount(({ actions, cache, props, values }) => {
    for (const deviceKey in values.deviceViews) {
      const device: MDevice.View = values.deviceViews[deviceKey];
      if (device.base.kind == MDevice.Kind.GenericDevice) {
        const genericDevice = values.genericDevices[deviceKey];
        const brand =
          props.deps.genericDevicesDictsLogic.values.brands[
            genericDevice.base.brandId
          ];
        const columns =
          props.deps.genericDevicesDictsLogic.values.modelColumns[
            brand.modelId
          ];
        if (
          !(
            device.deviceUuid in values.selectedDeviceKeys &&
            typeof values.selectedDeviceKeys[device.deviceUuid] != 'boolean'
          )
        ) {
          continue;
        }
        for (const column of columns) {
          if (
            column.modelColumnId in
            (values.selectedDeviceKeys[device.deviceUuid] as Record<
              string,
              unknown
            >)
          ) {
            const seriesKey =
              device.deviceUuid + column.modelColumnId.toString();
            values.chartHandle?.setDataCleaning(seriesKey, false);
            props.deps.genericDeviceService.streamBytes.cancel(
              cache.onMessage,
              {
                uuid: device.deviceUuid,
                start: values.livePreloadedRange[device.deviceUuid].end,
              },
            );
            break;
          }
        }
      } else {
        if (!(device.deviceUuid in values.selectedDeviceKeys)) {
          continue;
        }
        values.chartHandle?.setDataCleaning(device.deviceUuid, false);
        props.deps.vibrodetectorService.stream.cancel(cache.onMessage, {
          uuid: device.deviceUuid,
          start: values.livePreloadedRange[device.deviceUuid].end,
        });
      }
    }
    if (values.loadedDateRange != null) {
      actions.setDateRange(values.loadedDateRange);
    }
    values.chartHandle?.toggleAutoScroll(false);
    values.chartHandle?.toggleMovement(true);
  }),
]);

const objectChartLogic = injectDepsToLogic(logic, () => ({
  deviceService: Dependencies.get(IDeviceService.$),
  deviceViewContainerLogic: Dependencies.get(IDeviceViewContainerLogic.$),
  genericDeviceService: Dependencies.get(IGenericDeviceService.$),
  genericDevicesDictsLogic: Dependencies.get(IGenericDevicesDictsLogic.$),
  vibrodetectorService: Dependencies.get(IVibrodetectorService.$),
  objectViewContainerLogic: Dependencies.get(IObjectViewContainerLogic.$),
  objectDetailContainerLogic: Dependencies.get(IObjectDetailContainerLogic.$),
  unitsLogic: Dependencies.get(IUnitsLogic.$),
  objectService: Dependencies.get(IObjectService.$),
}));

const objectChartLogicLogicInitialDataOptional =
  objectChartLogic as MakeInitialDataOptional<typeof objectChartLogic>;

export { objectChartLogicLogicInitialDataOptional as objectChartLogic };
