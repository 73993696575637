/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { FC, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

import { detectOutsideClick } from '@/utility/component';

import { ColorpickerField } from './colorpickerField';
import './style.scss';

interface ColorpickerProps {
  color: string;
  setColor: (color: string) => void;
}

export const Colorpicker: FC<ColorpickerProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);

  detectOutsideClick(overlayRef, () => {
    setVisible(false);
  });

  return (
    <div className="ocs-colorpicker">
      <ColorpickerField
        color={props.color}
        setColor={props.setColor}
        pickerVisible={visible}
        setPickerVisibility={setVisible}
      />
      <div
        style={{ display: visible ? '' : 'none' }}
        className="ocs-colorpicker-overlay"
        ref={overlayRef}
      >
        <SketchPicker
          color={props.color}
          onChange={(color: any) => {
            props.setColor(color.hex.slice(1));
          }}
        />
      </div>
    </div>
  );
};
