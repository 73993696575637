import { Dependencies } from '@/deps';
import { IObjectService } from '@/services/interfaces';

import { IvisSystemApiStream } from '..';

export const objectDescriptionStream = async (
  objectUuid: string,
  interval: number | undefined,
): Promise<IvisSystemApiStream> => {
  let logic_callback: null | ((data: Record<string, unknown>) => void) = null;

  const objectService = Dependencies.get(IObjectService.$);

  objectService
    .get({
      objectUuid,
    })
    .then((response) => {
      if (response.success && response.data !== undefined) {
        const object = response.data;
        if (logic_callback != null) {
          const data: Record<string, unknown> = {};
          for (let [k, v] of Object.entries(object)) {
            //TODO: replace that in vis
            if (k === 'base') {
              k = 'list';
            }
            if (typeof v === 'object') {
              for (const [k2, v2] of Object.entries(v)) {
                data[k + '_' + k2] = v2;
              }
            } else {
              data[k] = v;
            }
          }
          logic_callback(data);
        }
      }
    })
    .catch(null);

  return {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    cancel() {},
    callback(callback) {
      logic_callback = callback;
    },
  } as IvisSystemApiStream;
};
