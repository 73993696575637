import { FC, useMemo } from 'react';

import './style.scss';

interface DateViewProps {
  className?: string;
  beginDate?: Date | string | number;
  endDate?: Date | string | number;
}

export const DateView: FC<DateViewProps> = (props) => {
  const [beginDate, beginTime] = useMemo(
    () => getDateTime(props.beginDate),
    [props.beginDate],
  );

  const [endDate, endTime] = useMemo(
    () => getDateTime(props.endDate),
    [props.endDate],
  );

  return (
    <span className={`dateview ${props.className}`}>
      <div className="dateview-date-left">
        <span className="dateview-time">{` ${beginTime}` + '\u00A0'}</span>
        {beginDate}
      </div>
      {props.endDate != '' && props.endDate != undefined ? (
        <i className="dateview-icon ri-subtract-line" />
      ) : (
        ''
      )}
      <div className="dateview-date-right">
        <span className="dateview-time">{`${endTime}`}</span>
        {'\u00A0' + endDate}
      </div>
    </span>
  );
};

function getDateTime(date: Date | string | number | undefined) {
  if (date == null) return ['', ''];
  if (typeof date === 'string') return [date, ''];

  if (typeof date === 'number') {
    let tempDate = new Date(date);
    return [
      tempDate.toLocaleDateString('en-GB'),
      tempDate.toLocaleTimeString('en-GB'),
    ];
  } else {
    return [date.toLocaleDateString('en-GB'), date.toLocaleTimeString('en-GB')];
  }
}
