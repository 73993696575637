import { useInjection } from 'inversify-react';
import { useMountedLogic, useValues } from 'kea';
import { Tooltip } from 'primereact/tooltip';
import { FC, useMemo } from 'react';

import { IVisElementLogic } from '@/logic/interfaces/visualization/visElement';
import { Dynamization, ImageType } from '@/types/visualization';
import { GetDynamicProps } from '@/utils/visualizationUtils';

import { visualizationContextLogic } from '.';

interface ImageProps {
  image: ImageType;
}

export const ImageVisualization: FC<ImageProps> = (props) => {
  const visLogic = useMountedLogic(visualizationContextLogic);

  const visElementLogic = useInjection(IVisElementLogic.$)({
    sourceUuid: visLogic.props.sourceUuid,
    sourceType: visLogic.props.sourceType,
    visualizationUuid: visLogic.props.visualizationUuid,
    name: visLogic.props.name,
    elementUuid: props.image.name,
    tasks: props.image.tasks,
  });

  const { imageBaseURL, isStorybook } = useValues(visLogic);
  const { values } = useValues(visElementLogic);

  const imageSource = useMemo(() => {
    if (isStorybook) {
      return require(`/workspace/src/assets/${props.image.source}`);
    } else {
      return imageBaseURL + props.image.source;
    }
  }, [isStorybook, imageBaseURL, props]);

  const dynamicProps = useMemo(() => {
    if (values != null && values[0])
      return GetDynamicProps(props.image, values[0]) as ImageType;
    else return props.image;
  }, [props.image]);

  if (
    dynamicProps.dynamization === Dynamization.Visibility &&
    values != null &&
    values[0] == 0
  )
    return <></>;

  return (
    <>
      <img
        className={`${props.image.name}`}
        src={imageSource}
        style={{
          position: 'absolute',
          top: dynamicProps.top,
          left: dynamicProps.left,
          height: dynamicProps.height,
          width: dynamicProps.width,
          opacity: dynamicProps.isTransparent ? '50%' : '100%',
        }}
      />
      <Tooltip target={`.${props.image.name}`} content={props.image.tooltip} />
    </>
  );
};
