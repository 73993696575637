import { Button } from 'primereact/button';
import { FC } from 'react';

import './style.scss';

interface ReloadProps {
  errorLabel?: string;
  buttonLabel?: string;
  stateSetter?: () => void;
}

export const Reload: FC<ReloadProps> = (props) => {
  return (
    <div className="ocs-reload-container">
      <span className="ocs-reload-label">{props.errorLabel}</span>
      <Button
        className="ocs-reload-button"
        label={props.buttonLabel}
        onClick={props.stateSetter}
      />
    </div>
  );
};
