import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { memo, useCallback, useMemo } from 'react';

import { DateRangePicker } from '@/components/dateRangePicker';
import { config } from '@/config';
import { useTranslation } from '@/hooks';
import { IGenericDeviceChartLogic } from '@/logic/interfaces';

import './style.scss';

const GenericDeviceChartDatePicker = memo(
  function GenericDeviceChartDatePicker() {
    const genericDeviceChartLogic = useInjection(IGenericDeviceChartLogic.$);

    const { dateRange, liveState, loadingState } = useValues(
      genericDeviceChartLogic,
    );
    const { setDateRange } = useActions(genericDeviceChartLogic);

    const onStartDateChange = useCallback(
      (date: Date) => {
        setDateRange({ start: date, end: dateRange.end });
      },
      [dateRange.end, setDateRange],
    );
    const onEndDateChange = useCallback(
      (date: Date) => {
        setDateRange({ start: dateRange.start, end: date });
      },
      [dateRange.start, setDateRange],
    );

    return (
      <div className="generic-device-chart-date-picker">
        <BlockUI
          blocked={
            liveState.error ||
            liveState.loading ||
            liveState.running ||
            loadingState.running
          }
        >
          <DateRangePicker
            startDate={dateRange.start}
            endDate={dateRange.end}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        </BlockUI>
      </div>
    );
  },
);

const GenericDeviceChartLoadButton = memo(
  function GenericDeviceChartLoadButton() {
    const t = useTranslation();
    const genericDeviceChartLogic = useInjection(IGenericDeviceChartLogic.$);

    const { dateRange, loadedDateRange, loadingState, liveState } = useValues(
      genericDeviceChartLogic,
    );
    const { loadCurrentRange } = useActions(genericDeviceChartLogic);

    const isDateLoaded = useMemo(() => {
      return (
        dateRange.start.getTime() == loadedDateRange?.start.getTime() &&
        dateRange.end.getTime() == loadedDateRange.end.getTime()
      );
    }, [dateRange, loadedDateRange]);

    return (
      <BlockUI
        blocked={
          loadingState.running ||
          liveState.error ||
          liveState.loading ||
          liveState.running
        }
      >
        <Button
          className="generic-device-chart-load-button p-button-text"
          icon={
            loadingState.running
              ? 'ri-loader-2-line pi-spin'
              : isDateLoaded
              ? `ri-refresh-fill`
              : 'ri-refresh-fill'
          }
          onClick={loadCurrentRange}
          disabled={loadingState.running}
          tooltip={t('tooltips.chart.buttons.reload')}
          tooltipOptions={{
            position: 'top',
            showDelay: config.tooltip.showDelay,
            baseZIndex: config.tooltip.zIndex,
            appendTo: config.tooltip.appendTo,
          }}
        />
      </BlockUI>
    );
  },
);

const GenericDeviceChartLiveButton = memo(
  function GenericDeviceChartLiveButton() {
    const t = useTranslation();
    const genericDeviceChartLogic = useInjection(IGenericDeviceChartLogic.$);

    const { liveState } = useValues(genericDeviceChartLogic);
    const { toggleLive } = useActions(genericDeviceChartLogic);

    return (
      <BlockUI blocked={liveState.loading}>
        <Button
          icon={
            liveState.loading
              ? 'ri-loader-2-line pi-spin'
              : liveState.running
              ? 'ri-stop-fill'
              : 'ri-play-fill'
          }
          className={`generic-device-chart-live-button p-button-text ${
            liveState.running ? 'p-button-danger' : 'p-button-success'
          }`}
          onClick={toggleLive}
          tooltip={t('tooltips.chart.buttons.live')}
          tooltipOptions={{
            position: 'top',
            showDelay: config.tooltip.showDelay,
            appendTo: config.tooltip.appendTo,
            baseZIndex: config.tooltip.zIndex,
          }}
        />
      </BlockUI>
    );
  },
);

const GenericDeviceChartSaveToImageButton = memo(
  function GenericDeviceChartLiveButton() {
    const t = useTranslation();

    const genericDeviceChartLogic = useInjection(IGenericDeviceChartLogic.$);

    const { saveToImage } = useActions(genericDeviceChartLogic);

    return (
      <Button
        className={`generic-device-save-to-image-button p-button-text`}
        icon={'ri-image-fill'}
        onClick={saveToImage}
        tooltip={t('tooltips.chart.buttons.saveToImage')}
        tooltipOptions={{
          position: 'top',
          showDelay: config.tooltip.showDelay,
          appendTo: config.tooltip.appendTo,
          baseZIndex: config.tooltip.zIndex,
        }}
      />
    );
  },
);

const GenericDeviceChartDownloadButton = memo(
  function GenericDeviceChartLiveButton() {
    const t = useTranslation();
    const genericDeviceChartLogic = useInjection(IGenericDeviceChartLogic.$);

    const { download } = useActions(genericDeviceChartLogic);

    return (
      <Button
        className={`generic-device-save-to-image-button p-button-text`}
        icon={'ri-file-chart-fill'}
        onClick={download}
        tooltip={t('tooltips.chart.buttons.saveToCSV')}
        tooltipOptions={{
          position: 'top',
          showDelay: config.tooltip.showDelay,
          appendTo: config.tooltip.appendTo,
          baseZIndex: config.tooltip.zIndex,
        }}
      />
    );
  },
);

export const GenericDeviceChartFooterLeft: JSX.Element[] = [
  <GenericDeviceChartDatePicker key="GenericDeviceChartDatePicker" />,
  <GenericDeviceChartLoadButton key="GenericDeviceChartLoadButton" />,
  <GenericDeviceChartLiveButton key="GenericDeviceChartLiveButton" />,
  <GenericDeviceChartSaveToImageButton key="GenericDeviceChartSaveToImageButton" />,
  <GenericDeviceChartDownloadButton key="GenericDeviceChartDownloadButton" />,
];
export const GenericDeviceChartFooterCenter: JSX.Element[] = [];
export const GenericDeviceChartFooterRight: JSX.Element[] = [];
