import { current, produce } from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  listeners,
  path,
  reducers,
} from 'kea';

import { injectDepsToLogic } from '@/logic/utils';
import { MObject } from '@/types/models';

import type { logicType } from './indexType';

const logic = kea<logicType>([
  path(['objects', 'container', 'view']),
  actions({
    startGarbageCollection: true,
    stopGarbageCollection: true,
    addObjects: (objects: MObject.View[]) => ({
      objects,
    }),
    useObjects: (objectIds: string[]) => ({ objectIds }),
    releaseObjects: (objectIds: string[]) => ({ objectIds }),
    removeObjects: (objectIds: string[]) => ({ objectIds }),
  }),
  reducers({
    objects: [
      {} as Record<string, MObject.View>,
      {
        addObjects: (immutableState, { objects }) =>
          produce(immutableState, (state) => {
            for (const object of objects) {
              state[object.objectUuid] = object;
            }
          }),
        removeObjects: (immutableState, { objectIds }) =>
          produce(immutableState, (state) => {
            for (const objectId of objectIds) {
              if (objectId in state) {
                delete state[objectId];
              }
            }
          }),
      },
    ],
    usageCounters: [
      {} as Record<
        string,
        {
          counter: number;
          lastUseTime: Date;
        }
      >,
      {
        addObjects: (immutableState, { objects }) =>
          produce(immutableState, (state) => {
            for (const object of objects) {
              if (!(object.objectUuid in state)) {
                state[object.objectUuid] = {
                  counter: 0,
                  lastUseTime: new Date(),
                };
              }
            }
          }),
        useObjects: (immutableState, { objectIds }) =>
          produce(immutableState, (state) => {
            for (const id of objectIds) {
              if (id in state) {
                state[id].counter += 1;
                state[id].lastUseTime = new Date();
              } else {
                console.error(
                  'Using an object not present in container: ' + id,
                );
                throw Error('Using an object not present in container');
              }
            }
          }),
        releaseObjects: (immutableState, { objectIds }) =>
          produce(immutableState, (state) => {
            for (const id of objectIds) {
              if (id in state) {
                if (state[id].counter > 0) {
                  state[id].counter -= 1;
                  state[id].lastUseTime = new Date();
                }
              } else {
                throw Error('Releasing an object not present in container');
              }
            }
          }),
        removeObjects: (immutableState, { objectIds }) =>
          produce(immutableState, (state) => {
            for (const id of objectIds) {
              if (id in state) {
                delete state[id];
              }
            }
          }),
      },
    ],
    collectingGarbage: [
      false as boolean,
      {
        startGarbageCollection: (_) => true,
        stopGarbageCollection: (_) => false,
      },
    ],
  }),
  listeners(({ values, actions }) => ({
    startGarbageCollection: async (_, breakpoint) => {
      breakpoint();
      while (values.collectingGarbage) {
        const currentTime = new Date();
        const objectIdsToRemove: string[] = [];
        for (const objectKey in values.usageCounters) {
          const usageCounter = values.usageCounters[objectKey];
          if (
            usageCounter.counter <= 0 &&
            currentTime.getTime() - usageCounter.lastUseTime.getTime() >= 5000
          ) {
            console.warn('removing object: ' + objectKey);
            objectIdsToRemove.push(objectKey);
          }
        }
        if (objectIdsToRemove.length > 0) {
          actions.removeObjects(objectIdsToRemove);
        }
        await breakpoint(5000);
      }
    },
  })),
  afterMount(({ actions }) => {
    actions.startGarbageCollection();
  }),
  beforeUnmount(({ actions }) => {
    actions.stopGarbageCollection();
  }),
]);

export const objectViewContainerLogic = injectDepsToLogic(logic, () => ({}));
