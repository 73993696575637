import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { VisElementProps } from './../../../types/visualization';

export type IVisElementLogicProps = VisElementProps;

export interface IVisualizationElement extends Logic {
  readonly props: IVisElementLogicProps;
  readonly values: {
    readonly values: (number | string | null)[];
  };
}

export interface IVisElementLogic extends LogicWrapper<IVisualizationElement> {}

export const IVisElementLogicSymbol: interfaces.ServiceIdentifier<IVisElementLogic> =
  Symbol('IVisElementLogic');

export namespace IVisElementLogic {
  export const $: interfaces.ServiceIdentifier<IVisElementLogic> =
    IVisElementLogicSymbol;
}
