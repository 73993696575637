/* eslint-disable react-hooks/rules-of-hooks */
import { RefObject } from 'react';
import { useEffect } from 'react';

export function detectOutsideClick(
  ref: RefObject<HTMLDivElement | null>,
  onDetectFunc: () => void,
  optionalIgnoredRefs?: RefObject<HTMLDivElement | null>[],
) {
  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (optionalIgnoredRefs != null && optionalIgnoredRefs.length > 0) {
        optionalIgnoredRefs.forEach((optionalRef) => {
          if (
            ref.current &&
            !optionalRef.current?.contains(event.target) &&
            !ref.current.contains(event.target)
          ) {
            onDetectFunc();
          }
        });
      } else {
        if (ref.current && !ref.current.contains(event.target)) {
          onDetectFunc();
        }
      }
    }

    document.addEventListener('mousedown', handleOutsideClick, true);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
    };
  }, [ref]);
}
