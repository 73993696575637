import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { FC, MutableRefObject } from 'react';

import { ITabMenuLogic } from '@/logic/interfaces/tabMenu';

import './style.scss';

export interface MenuItem {
  label: string;
  index?: number;
  icon?: string;
}

interface TabMenuProps {
  model: MenuItem[];
  tabMenuLogic: interfaces.ServiceIdentifier<ITabMenuLogic>;
  logicKey: string;
  refs?: MutableRefObject<(HTMLDivElement | null)[]>;
}

export const TabMenu: FC<TabMenuProps> = (props) => {
  const tabLogic = useInjection(props.tabMenuLogic)({
    tabMenuKey: props.logicKey,
  });

  const { setActiveIndex } = useActions(tabLogic);
  const { activeIndex } = useValues(tabLogic);

  return (
    <div className="OCS-tabmenu">
      {props.model.map((modelItem, index) => {
        const currentIndex = modelItem.index ? modelItem.index : index;

        return (
          <div
            ref={(comp) => {
              if (props.refs != null) {
                props.refs.current[index] = comp;
              }
            }}
            tabIndex={modelItem.index ? modelItem.index : currentIndex}
            className={`${
              activeIndex === currentIndex ? 'OCS-tabmenu-item-highlight' : ''
            } OCS-tabmenu-item`}
            key={`${modelItem.label}${currentIndex}`}
            onClick={() => {
              setActiveIndex(currentIndex);
            }}
          >
            <span
              className={`${
                modelItem.icon ? modelItem.icon : ''
              } OCS-tabmenu-icon`}
            />
            <span className="OCS-tabmenu-label">{modelItem.label}</span>
          </div>
        );
      })}
    </div>
  );
};
