/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  DeviceData as GrpcData,
  MessageDeviceOut as GrpcFull,
  MessageVisualizationAlias as GrpcGetByVisualizationAliasIn,
  MessageDeviceOut as GrpcGetByVisualizationAliasOut,
  MessageDeviceUuid as GrpcGetIn,
  MessageDevicesList as GrpcGetListOut,
  MessageDeviceOut as GrpcGetOut,
  MessageSlotList as GrpcGetSlotsOut,
  Kind as GrpcKind,
  DeviceSettings as GrpcSettings,
  Slot as GrpcSlot,
  DeviceStatus as GrpcStatus,
  DeviceTemporary as GrpcTemporary,
  DeviceView as GrpcView,
} from '@/services/compiled/devices';
import { MDevice } from '@/types/models';

import { GrpcConverters } from './grpc';
import { TableConverters } from './table';

export class DeviceConverters {
  private grpcConverters: GrpcConverters;
  private tableConverters: TableConverters;

  constructor() {
    this.grpcConverters = new GrpcConverters();
    this.tableConverters = new TableConverters();
  }

  public kindFromGrpc(grpc: GrpcKind): MDevice.Kind {
    return grpc as unknown as MDevice.Kind;
  }

  public kindToGrpc(kind: MDevice.Kind): GrpcKind {
    return kind as unknown as GrpcKind;
  }

  public dataFromGrpc(grpc: GrpcData): MDevice.Base {
    return grpc;
  }

  public dataToGrpc(data: MDevice.Base): GrpcData {
    return data;
  }

  public temporaryFromGrpc(grpc: GrpcTemporary): MDevice.Temporary {
    return {
      ...grpc,
      lastDataDate:
        grpc.lastDataDate != null
          ? this.grpcConverters.timestampToDate(grpc.lastDataDate)
          : undefined,
    };
  }

  public temporaryToGrpc(temp: MDevice.Temporary): GrpcTemporary {
    return {
      ...temp,
      lastDataDate:
        temp.lastDataDate != null
          ? this.grpcConverters.dateToTimestamp(temp.lastDataDate)
          : undefined,
    };
  }

  public statusFromGrpc(grpc: GrpcStatus): MDevice.Status {
    return grpc;
  }

  public statusToGrpc(status: MDevice.Status): GrpcStatus {
    return status;
  }

  public viewFromGrpc(grpc: GrpcView): MDevice.View {
    return {
      deviceUuid: grpc.deviceUuid,
      base: {
        kind: grpc.listKind,
        name: grpc.listName,
        objectUuid: grpc.objectUuid,
        parentDeviceUuid: grpc.parentDeviceUuid,
      },
      status: {
        noTransmission: grpc.statusNoTransmission,
      },
      temp: {
        lastDataDate:
          grpc.temporaryLastDataDate != null
            ? this.grpcConverters.timestampToDate(grpc.temporaryLastDataDate)
            : undefined,
      },
    };
  }

  public viewToGrpc(view: MDevice.View): GrpcView {
    return {
      deviceUuid: view.deviceUuid,
      listKind: view.base.kind,
      listName: view.base.name,
      objectUuid: view.base.objectUuid,
      parentDeviceUuid: view.base.parentDeviceUuid,
      statusNoTransmission: view.status.noTransmission,
    };
  }

  public settingsFromGrpc(grpc: GrpcSettings): MDevice.Settings {
    return grpc;
  }

  public settingsToGrpc(settings: MDevice.Settings): GrpcSettings {
    return settings;
  }

  public fullFromGrpc(grpc: GrpcFull): MDevice.Full {
    return {
      ...grpc,
      base: this.dataFromGrpc(grpc.device!.data!),
      settings: this.settingsFromGrpc(grpc.device!.settings!),
      status: this.statusFromGrpc(grpc.device!.status!),
      temp: this.temporaryFromGrpc(grpc.device!.temporary!),
    };
  }

  public fullToGrpc(full: MDevice.Full): GrpcFull {
    return {
      ...full,
      device: {
        data: this.dataToGrpc(full.base),
        settings: this.settingsToGrpc(full.settings),
        status: this.statusToGrpc(full.status),
        temporary: this.temporaryToGrpc(full.temp),
      },
    };
  }

  public slotFromGrpc(grpc: GrpcSlot): MDevice.Slot {
    const compatibleKind: MDevice.Kind[] = [];

    for (const grpcKind of grpc.compatibleKind) {
      compatibleKind.push(this.kindFromGrpc(grpcKind));
    }

    return { ...grpc, compatibleKind: compatibleKind };
  }

  public slotToGrpc(slot: MDevice.Slot): GrpcSlot {
    const grpcCompatibleKind: GrpcKind[] = [];

    for (const kind of slot.compatibleKind) {
      grpcCompatibleKind.push(this.kindToGrpc(kind));
    }

    return { ...slot, compatibleKind: grpcCompatibleKind };
  }

  public getInToGrpc(input: MDevice.Messages.GetIn): GrpcGetIn {
    return input;
  }

  public getOutFromGrpc(grpc: GrpcGetOut): MDevice.Messages.GetOut {
    return this.fullFromGrpc(grpc);
  }

  public getByVisualizationAliasInToGrpc(
    input: MDevice.Messages.GetByVisualizationAliasIn,
  ): GrpcGetByVisualizationAliasIn {
    return input;
  }

  public getByVisualizationAliasOutFromGrpc(
    grpc: GrpcGetByVisualizationAliasOut,
  ): MDevice.Messages.GetByVisualizationAliasOut {
    return grpc;
  }

  public getListInToGrpc(input: MDevice.Messages.GetListIn) {
    return this.tableConverters.tableRequestInputToGrpc(input);
  }

  public getListOutFromGrpc(grpc: GrpcGetListOut): MDevice.Messages.GetListOut {
    const deviceView: MDevice.View[] = [];

    for (const grpcDevice of grpc.devices) {
      deviceView.push(this.viewFromGrpc(grpcDevice));
    }

    return { ...grpc, devices: deviceView };
  }

  public getSlotsInToGrpc(input: MDevice.Messages.GetSlotsIn) {
    return input;
  }

  public getSlotsOutFromGrpc(
    grpc: GrpcGetSlotsOut,
  ): MDevice.Messages.GetSlotsOut {
    const slots: MDevice.Slot[] = [];

    for (const grpcSlot of grpc.slots) {
      slots.push(this.slotFromGrpc(grpcSlot));
    }

    return { ...grpc, slots: slots };
  }

  public assignSlotInToGrpc(input: MDevice.Messages.AssignSlotIn) {
    return input;
  }

  public updateVisualizationAliasInToGrpc(
    input: MDevice.Messages.UpdateVisualizationAliasIn,
  ) {
    return input;
  }

  public viewToTableView(deviceView: MDevice.View): MDevice.TableView {
    return {
      deviceUuid: deviceView.deviceUuid,
      objectUuid: deviceView.base.objectUuid,
      parentDeviceUuid: deviceView.base.parentDeviceUuid,
      listName: deviceView.base.name,
      listKind: deviceView.base.kind,
      statusNoTransmission: deviceView.status.noTransmission,
      temporaryLastDataDate: { startDate: deviceView.temp.lastDataDate },
    };
  }

  public splitDeviceFull(device: MDevice.Full): [MDevice.View, MDevice.Detail] {
    return [
      {
        deviceUuid: device.deviceUuid,
        base: device.base,
        status: device.status,
        temp: device.temp,
      },
      {
        deviceUuid: device.deviceUuid,
        settings: device.settings,
      },
    ];
  }

  public mergeViewAndDetails(
    view: MDevice.View,
    details: MDevice.Detail,
  ): MDevice.Full {
    return {
      ...view,
      ...details,
    };
  }
}
