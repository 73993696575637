import { produce } from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { Dependencies } from '@/deps';
import { IObjectViewContainerLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { MObject } from '@/types/models';

import { ObjectConverters } from './../../../../../converters/object';
import type { logicType } from './indexType';

const logic = kea<logicType>([
  path(['objects', 'container', 'details']),
  props(
    {} as {
      deps: {
        objectViewContainerLogic: IObjectViewContainerLogic;
        objectConverters: ObjectConverters;
      };
    },
  ),
  actions({
    startGarbageCollection: true,
    stopGarbageCollection: true,
    addObjects: (objects: (MObject.Detail | MObject.Full)[]) => ({
      objects,
    }),
    useObjects: (objectIds: string[]) => ({ objectIds }),
    releaseObjects: (objectIds: string[]) => ({ objectIds }),
    addDetails: (details: MObject.Detail[]) => ({ details }),
    releaseDetails: (detailIds: string[]) => ({ detailIds }),
    removeDetails: (detailIds: string[]) => ({ detailIds }),
    useDetails: (detailIds: string[]) => ({ detailIds }),
  }),
  reducers({
    objectDetails: [
      new Map() as Map<string, MObject.Detail>,
      {
        addDetails: (immutableState, { details }) =>
          produce(immutableState, (state) => {
            for (const detail of details) {
              state.set(detail.objectUuid, detail);
            }
          }),
        removeDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              if (detailId in state) {
                state.delete(detailId);
              }
            }
          }),
      },
    ],
    usageCounters: [
      new Map() as Map<
        string,
        {
          counter: number;
          lastUseTime: Date;
        }
      >,
      {
        addDetails: (immutableState, { details }) =>
          produce(immutableState, (state) => {
            for (const detail of details) {
              state.set(detail.objectUuid, {
                counter: 0,
                lastUseTime: new Date(),
              });
            }
          }),
        useDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              const detailCounter = state.get(detailId);
              if (detailCounter != null) {
                state.set(detailId, {
                  counter: detailCounter.counter + 1,
                  lastUseTime: new Date(),
                });
              }
              throw Error('Using an object detail not present in container');
            }
          }),
        releaseDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              const detailCounter = state.get(detailId);
              if (detailCounter != null) {
                state.set(detailId, {
                  counter: detailCounter.counter - 1,
                  lastUseTime: new Date(),
                });
              }
              throw Error('Releasing an object not present in container');
            }
          }),
        removeDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              if (state.has(detailId)) {
                state.delete(detailId);
              }
            }
          }),
      },
    ],
    collectingGarbage: [
      false as boolean,
      {
        startGarbageCollection: () => true,
        stopGarbageCollection: () => false,
      },
    ],
  }),
  selectors(({ props }) => ({
    objectViews: [
      () => [props.deps.objectViewContainerLogic.selectors.objects],
      (objectViews) => objectViews,
    ],
    objects: [
      (selectors) => [selectors.objectDetails, selectors.objectViews],
      (objectDetails, objectViews) => {
        const objects: Record<string, MObject.Full> = {};
        objectDetails.forEach((objectDetail, objectKey) => {
          const objectView = objectViews[objectKey];
          objects[objectKey] = props.deps.objectConverters.mergeViewAndDetail(
            objectView,
            objectDetail,
          );
        });
        return objects;
      },
    ],
  })),
  listeners(({ props, values, actions }) => ({
    addObjects: ({ objects }) => {
      const objectDetails: MObject.Detail[] = [];
      const objectViews: MObject.View[] = [];
      for (const object of objects) {
        if ('data' in object) {
          const [objectView, objectDetail] =
            props.deps.objectConverters.splitObjectFull(object);
          objectDetails.push(objectDetail);
          objectViews.push(objectView);
        } else {
          if (!(object.objectUuid in values.objectViews)) {
            throw Error(
              'Adding object details without view present in view container',
            );
          }
          objectDetails.push(object);
        }
      }
      props.deps.objectViewContainerLogic.actions.addObjects(objectViews);
      props.deps.objectViewContainerLogic.actions.useObjects(
        objectViews.map((object) => object.objectUuid),
      );
      actions.addDetails(objectDetails);
    },
    useObjects: ({ objectIds }) => {
      actions.useDetails(objectIds);
    },
    releaseObjects: ({ objectIds }) => {
      actions.releaseDetails(objectIds);
    },
    startGarbageCollection: async (_, breakpoint) => {
      breakpoint();
      while (values.collectingGarbage) {
        const currentTime = new Date();
        const objectIdsToRemove: string[] = [];
        values.usageCounters.forEach((usageCounter, objectKey) => {
          if (
            usageCounter.counter <= 0 &&
            currentTime.getTime() - usageCounter.lastUseTime.getTime() >= 5000
          ) {
            objectIdsToRemove.push(objectKey);
          }
        });

        if (objectIdsToRemove.length > 0) {
          actions.removeDetails(objectIdsToRemove);
          props.deps.objectViewContainerLogic.actions.releaseObjects(
            objectIdsToRemove,
          );
        }
        await breakpoint(5000);
      }
    },
  })),
  afterMount(({ actions }) => {
    actions.startGarbageCollection();
  }),
  beforeUnmount(({ actions }) => {
    actions.stopGarbageCollection();
  }),
]);

export const objectDetailContainerLogic = injectDepsToLogic(logic, () => ({
  objectViewContainerLogic: Dependencies.get(IObjectViewContainerLogic.$),
  objectConverters: new ObjectConverters(),
}));
