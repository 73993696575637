import { SortOptions } from '@ocs/ocs-components';

import { TableOrder, TableSearch } from '@/types/models/table';

export interface RequestComposeProps<Column = string> {
  rowsPerPage: number;
  currentPage: number;
  searchAll?: TableSearch<Column>[];
  searchAny?: TableSearch<Column>[];
  sortOptions?: SortOptions;
}

export function composeRequestInput(composeProps: RequestComposeProps) {
  let input = {
    count: {
      count: composeProps.rowsPerPage,
      page: composeProps.currentPage,
    },
    searchAll: composeProps.searchAll ? composeProps.searchAll : [],
    searchAny: composeProps.searchAny ? composeProps.searchAny : [],
  };

  if (composeProps.sortOptions != null) {
    input = Object.assign(input, {
      sort: {
        order:
          composeProps.sortOptions.order == 1
            ? TableOrder.Asc
            : TableOrder.Desc,
        column: composeProps.sortOptions.field,
      },
    });
  }

  return input;
}
