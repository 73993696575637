/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IVisualizationService } from '@/services/interfaces';
import { MVisualization } from '@/types/models';

import { Response } from '../types/response';
import { BaseService } from './base';

@injectable()
export class VisualizationService
  extends BaseService
  implements IVisualizationService
{
  public async get(
    input: MVisualization.Messages.GetIn,
  ): Promise<Response<MVisualization.Messages.GetOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.visualizationGet(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async store(
    input: MVisualization.Messages.StoreIn,
  ): Promise<Response<MVisualization.Messages.StoreOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.visualizationStore(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async getList(
    input: MVisualization.Messages.GetListIn,
  ): Promise<Response<MVisualization.Messages.GetListOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.visualizationGetList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async open(
    input: MVisualization.Messages.OpenIn,
  ): Promise<Response<MVisualization.Messages.OpenOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.visualizationOpen(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
