import {
  DataBytesPackage as MDataBytesPackage,
  DownloadIn as MDownloadIn,
  DownloadOut as MDownloadOut,
  GetBrandModbusTaskColumnsIn as MGetBrandModbusTaskColumnsIn,
  GetBrandModbusTaskColumnsOut as MGetBrandModbusTaskColumnsOut,
  GetBrandModbusTasksIn as MGetBrandModbusTasksIn,
  GetBrandModbusTasksOut as MGetBrandModbusTasksOut,
  GetBrandsOut as MGetBrandsOut,
  GetIn as MGetIn,
  GetModelColumnsIn as MGetModelColumnsIn,
  GetModelColumnsOut as MGetModelColumnsOut,
  GetModelsOut as MGetModelsOut,
  GetNowIn as MGetNowIn,
  GetNowOut as MGetNowOut,
  GetOut as MGetOut,
  GraphBytesIn as MGraphBytesIn,
  GraphBytesOut as MGraphBytesOut,
  GraphIn as MGraphIn,
  GraphOut as MGraphOut,
  Lang as MLang,
  LiveBytesOut as MLiveBytesOut,
  LiveIn as MLiveIn,
  LiveOut as MLiveOut,
  StoreIn as MStoreIn,
  StoreOut as MStoreOut,
} from '../messages/genericdevice';

export namespace MGenericDevice {
  export namespace Messages {
    export type DataBytesPackage = MDataBytesPackage;
    export type GraphIn = MGraphIn;
    export type GraphOut = MGraphOut;
    export type GraphBytesIn = MGraphBytesIn;
    export type GraphBytesOut = MGraphBytesOut;
    export type GetIn = MGetIn;
    export type GetOut = MGetOut;
    export type GetBrandsOut = MGetBrandsOut;
    export type GetModelsOut = MGetModelsOut;
    export type GetModelColumnsIn = MGetModelColumnsIn;
    export type GetModelColumnsOut = MGetModelColumnsOut;
    export type GetBrandModbusTasksIn = MGetBrandModbusTasksIn;
    export type GetBrandModbusTasksOut = MGetBrandModbusTasksOut;
    export type GetBrandModbusTaskColumnsIn = MGetBrandModbusTaskColumnsIn;
    export type GetBrandModbusTaskColumnsOut = MGetBrandModbusTaskColumnsOut;
    export type GetNowOut = MGetNowOut;
    export type GetNowIn = MGetNowIn;
    export type LiveBytesOut = MLiveBytesOut;
    export type LiveIn = MLiveIn;
    export type LiveOut = MLiveOut;
    export type DownloadIn = MDownloadIn;
    export type DownloadOut = MDownloadOut;
    export const Lang = MLang;
    export type Lang = MLang;
    export type StoreIn = MStoreIn;
    export type StoreOut = MStoreOut;
  }

  export interface Data {
    datetime: Date;
    data: Record<string, unknown>;
    extra?: Record<string, unknown>;
    workStateId?: number;
    modelId: number;
  }

  export interface Base {
    brandId: number;
  }

  export interface View {
    deviceUuid: string;
    base: Base;
  }

  export enum BrandProtocol {
    Modbus = 0,
  }
  export interface Brand {
    brandId: number;
    name: string;
    protocol: BrandProtocol;
    modelId: number;
  }

  export enum BrandModbusTaskKind {
    Unsigned16 = 0,
  }

  export interface BrandModbusTask {
    brandModbusTaskId: number;
    rtuOverride?: number;
    address: number;
    count: number;
    kind: BrandModbusTaskKind;
    bank?: number;
    brandId: number;
  }

  export enum BrandModbusTaskColumnFormat {
    FInteger16 = 0,
    FUnsigned16 = 1,
  }

  export interface BrandModbusTaskColumn {
    brandModbusTaskColumnId: number;
    brandModbusTaskId: number;
    address: number;
    format: BrandModbusTaskColumnFormat;
    mask?: number;
    multiplier?: number;
    divider?: number;
    offset?: number;
    negation: boolean;
  }

  export interface Model {
    modelId: number;
    name: string;
  }

  export enum ModelColumnType {
    Integer = 0,
    Decimal = 1,
    Text = 2,
    Binary = 3,
  }

  export interface ModelColumn {
    modelColumnId: number;
    name: string;
    formatter?: string;
    type: ModelColumnType;
    colorTable?: string;
    colorGraph?: string;
    modelId: number;
    unitId: number;
  }

  export interface Info {
    brandId: number;
  }
}
