import { EditorValue } from '@/types/custom/editorTypes';

export function validateValues(
  modelValue: Record<string, Record<string, Record<string, EditorValue>>>,
): boolean {
  let temp = 0;

  Object.values(modelValue).forEach((tab) => {
    Object.values(tab).forEach((field) => {
      Object.values(field).forEach((value) => {
        if (value.error != undefined && value.error != '') {
          temp++;
        }
      });
    });
  });

  if (temp > 0) return false;
  else return true;
}
