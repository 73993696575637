import { shallowEqual } from 'fast-equals';
import { produce } from 'immer';
import {
  actions,
  afterMount,
  kea,
  listeners,
  path,
  props,
  reducers,
} from 'kea';

import Dependencies from '@/deps';
import { injectDepsToLogic } from '@/logic/utils';
import { IGenericDeviceService } from '@/services/interfaces';
import { MGenericDevice } from '@/types/models';
import { arrayToRecord } from '@/utility/types';

import type { logicType } from './indexType';

export interface GenericDevicesDictsLogicProps {
  deps: {
    genericDeviceService: IGenericDeviceService;
  };
}

const logic = kea<logicType>([
  props({} as GenericDevicesDictsLogicProps),
  path(['genericDevicesDicts']),
  actions({
    _load: true,
    _setBrands: (brands: Record<number, MGenericDevice.Brand>) => ({ brands }),
    _setBrandModbusTasks: (
      brandId: number,
      brandModbusTasks: MGenericDevice.BrandModbusTask[],
    ) => ({ brandId, brandModbusTasks }),
    _setModels: (models: Record<number, MGenericDevice.Model>) => ({ models }),
    _setModelColumns: (
      modelId: number,
      modelColumns: MGenericDevice.ModelColumn[],
    ) => ({ modelId, modelColumns }),
  }),
  reducers({
    brands: [
      {} as Record<number, MGenericDevice.Brand>,
      {
        _setBrands: (_, { brands }) => brands,
      },
    ],
    brandModbusTasks: [
      {} as Record<number, MGenericDevice.BrandModbusTask[]>,
      {
        _setBrandModbusTasks: (immutableState, { brandId, brandModbusTasks }) =>
          produce(
            immutableState,
            (state: Record<number, MGenericDevice.BrandModbusTask[]>) => {
              state[brandId] = brandModbusTasks;
            },
          ),
      },
    ],
    models: [
      {} as Record<number, MGenericDevice.Model>,
      {
        _setModels: (_, { models }) => models,
      },
    ],
    modelColumns: [
      {} as Record<number, MGenericDevice.ModelColumn[]>,
      {
        _setModelColumns: (immutableState, { modelId, modelColumns }) =>
          produce(
            immutableState,
            (state: Record<number, MGenericDevice.ModelColumn[]>) => {
              state[modelId] = modelColumns;
            },
          ),
      },
    ],
  }),
  listeners(({ props, actions }) => {
    return {
      _load: async () => {
        const result_brands = await props.deps.genericDeviceService.getBrands();
        if (result_brands.success) {
          for (const brand of result_brands.data.brands) {
            props.deps.genericDeviceService
              .getBrandModbusTasks({ brandId: brand.brandId })
              .then((result_brandModbusTasks) => {
                if (result_brandModbusTasks.success) {
                  actions._setBrandModbusTasks(
                    brand.brandId,
                    result_brandModbusTasks.data.brandModbusTasks,
                  );
                }
              })
              .catch(null);
          }
          actions._setBrands(
            arrayToRecord(result_brands.data.brands, 'brandId'),
          );
        }
        const result_models = await props.deps.genericDeviceService.getModels();
        if (result_models.success) {
          actions._setModels(
            arrayToRecord(result_models.data.models, 'modelId'),
          );
        }

        if (result_models.success) {
          for (const model of result_models.data.models) {
            props.deps.genericDeviceService
              .getModelColumns({
                modelId: model.modelId,
              })
              .then((result_modelColumns) => {
                if (result_modelColumns.success) {
                  actions._setModelColumns(
                    model.modelId,
                    result_modelColumns.data.modelColumns,
                  );
                }
              })
              .catch(null);
          }
        }
      },
    };
  }),
  afterMount(({ actions }) => {
    actions._load();
  }),
]);

export const genericDevicesDictsLogic = injectDepsToLogic(logic, () => ({
  genericDeviceService: Dependencies.get(IGenericDeviceService.$),
}));
