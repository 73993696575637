import { IObjectEditorLogic, IObjectEditorProps } from './objectEditor';

export { IAppLogic, IAppLogicProps } from '@/logic/interfaces/app';
export { IDesktopLogic, IDesktopLogicProps } from '@/logic/interfaces/desktop';
export { IWindowLogic, IWindowLogicProps } from '@/logic/interfaces/window';
export { IModuleLogic, IModuleLogicProps } from '@/logic/interfaces/module';
export { ILoginLogic, ILoginLogicProps } from '@/logic/interfaces/login';
export {
  IObjectViewContainerLogic,
  IObjectViewContainerLogicProps,
} from '@/logic/interfaces/objectViewContainer';
export {
  IDeviceViewContainerLogic,
  IDeviceViewContainerLogicProps,
} from '@/logic/interfaces/deviceViewContainer';
export {
  IObjectTableLogic,
  IObjectTableLogicProps,
} from '@/logic/interfaces/objectTable';
export {
  IDeviceTableLogic,
  IDeviceTableLogicProps,
} from '@/logic/interfaces/deviceTable';

export {
  IEventAlarmContainerLogic,
  IEventAlarmContainerLogicProps,
} from '@/logic/interfaces/eventAlarmContainer';

export {
  IEventDictContainerLogic,
  IEventDictContainerLogicProps,
} from '@/logic/interfaces/eventDictContainer';

export {
  IObjectDetailContainerLogic,
  IObjectDetailContainerLogicProps,
} from '@/logic/interfaces/objectDetailContainer';

export {
  IDeviceDetailContainerLogic,
  IDeviceDetailContainerLogicProps,
} from '@/logic/interfaces/deviceDetailContainer';

export {
  IEventViewLogic,
  IEventViewLogicProps,
} from '@/logic/interfaces/eventView';

export {
  IEventListLogic,
  IEventListLogicProps,
} from '@/logic/interfaces/eventList';

export {
  IVibrodetectorChartLogic,
  IVibrodetectorChartLogicProps,
} from '@/logic/interfaces/vibroDetectorChart';

export {
  IObjectWindowLogic,
  IObjectWindowLogicProps,
  objectWindowTabs,
  TobjectWindowTabsKeys,
} from '@/logic/interfaces/objectWindow';

export {
  IVibroDetectorsViewLogic,
  IVibroDetectorsViewLogicProps,
} from '@/logic/interfaces/vibroDetectorsView';

export {
  IGenericDevicesContainerLogic,
  IGenericDevicesContainerLogicProps,
  IGenericDeviceViewLogic,
  IGenericDeviceViewLogicProps,
} from '@/logic/interfaces/genericDevicesView';

export {
  IVisualizationLogic,
  IVisualizationElementLogic,
  IVisSystemApiLogic,
  IVisSystemApiLogicProps,
} from '@/logic/interfaces/visualization';

export {
  IDefinedAlarmsViewContainerLogic,
  IDefinedAlarmsViewContainerLogicProps,
} from '@/logic/interfaces/definedAlarmsViewContainer';

export { IUnitsLogic } from '@/logic/interfaces/units';

export { IGenericDevicesDictsLogic } from '@/logic/interfaces/genericDevicesDicts';

export {
  IGenericDeviceChartLogic,
  IGenericDeviceChartLogicProps,
} from '@/logic/interfaces/genericDeviceChart';

export {
  IObjectChartLogic,
  IObjectChartLogicProps,
} from '@/logic/interfaces/objectChart';

export {
  IObjectChartLogicNamed,
  IObjectChartNamedLogicProps,
} from './objectChartNamed';

export { IHeaderSearchLogic, IHeaderSearchLogicProps } from './headerSearchbar';

export {
  IGroupAlarmsTableLogic,
  IGroupAlarmsTableLogicProps,
} from './groupAlarmsTable';

export {
  IVibrodetectorEditorLogic,
  IVibrodetectorEditorProps,
} from './vibrodetectorEditor';

export {
  IGenericDeviceEditorLogic,
  IGenericDeviceEditorProps,
} from './genericDeviceEditor';

export { IHubsEditorLogic, IHubsEditorProps } from './hubsEditor';

export { IObjectEditorLogic, IObjectEditorProps } from './objectEditor';
