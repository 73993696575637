import { Dispatch, FC, SetStateAction } from 'react';

import { TextField } from '@/components/textField';

import './style.scss';

interface ColorpickerFieldProps {
  color: string;
  setColor: (color: string) => void;
  pickerVisible: boolean;
  setPickerVisibility: Dispatch<SetStateAction<boolean>>;
}

export const ColorpickerField: FC<ColorpickerFieldProps> = (props) => {
  return (
    <span className="ocs-colorfield">
      <TextField
        value={props.color}
        onChange={(value) => {
          if (/^$|^[a-fA-F0-9]+$/.test(value)) props.setColor(value);
        }}
        inputClassName="p-inputtext-sm ocs-colorfield-text"
        pattern="#[0-9a-fA-F]{3}([0-9a-fA-F]{3})?"
        maxLength={6}
      />
      <span className="ocs-colorfield-sign">#</span>
      <span
        style={{ backgroundColor: `#${props.color ? props.color : 'ffffff'}` }}
        tabIndex={0}
        className="ocs-colorfield-icon"
        onClick={() => {
          props.setPickerVisibility(!props.pickerVisible);
        }}
      />
    </span>
  );
};
