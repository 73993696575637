import { IPaginationLogic } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { useValues } from 'kea';
import { Button } from 'primereact/button';
import { FC } from 'react';

import { IObjectWindowLogic } from '@/logic/interfaces';
import { INotificationTableLogic } from '@/logic/interfaces/notificationTable';
import {
  FooterPaginator,
  PaginatorDropdown,
} from '@/widgets/toolBar/footerPaginator';
import { TableRefreshButton } from '@/widgets/toolBar/tableRefreshButton';

export const ObjectWindowPaginator: FC = () => {
  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(objectWindowLogic);

  const notificationPaginationLogic = useInjection(INotificationTableLogic.$)({
    objectUuid: objectUuid,
  });

  return (
    <FooterPaginator
      logic={notificationPaginationLogic as unknown as IPaginationLogic}
    />
  );
};

export const ObjectWindowPaginatorDropdown: FC = () => {
  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(objectWindowLogic);

  const notificationPaginationLogic = useInjection(INotificationTableLogic.$)({
    objectUuid: objectUuid,
  });

  return (
    <PaginatorDropdown
      logic={notificationPaginationLogic as unknown as IPaginationLogic}
    />
  );
};

export const ObjectWindowRefreshButton: FC = () => {
  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(objectWindowLogic);

  const notificationPaginationLogic = useInjection(INotificationTableLogic.$)({
    objectUuid: objectUuid,
  });

  return (
    <TableRefreshButton
      logic={notificationPaginationLogic as unknown as IPaginationLogic}
    />
  );
};