import { findLast } from 'lodash';

import {
  ButtonContent,
  EditorForm,
  EditorTabsLayout,
  EditorTile,
  EditorTypes,
  EditorValue,
} from '@/types/custom/editorTypes';
import { MObject } from '@/types/models';

export enum ObjectButtonKey {
  Add,
  Remove,
}

const objectValueModel = {
  workState: {
    name: 'editor.object.workState',
    type: EditorTypes.enum,
  },
  delay: {
    name: 'editor.object.delay',
    type: EditorTypes.float,
    valueUnit: '[s]',
  },
  button: {
    name: 'editor.object.button',
    type: EditorTypes.button,
  },
};

const dividerModel = {
  divider: {
    name: '',
    type: EditorTypes.divider,
  },
};

const objectLayoutModel: EditorTabsLayout = {
  layoutDirection: 'vertical',
  gridLayout: '1fr 1fr 0.4fr',
};

export function workStateSettingsToModel(
  model: EditorForm,
  workStateSettings: MObject.WorkStateSettings[],
  numberOfFields: number,
  maxFields: number,
) {
  const modelCopy = JSON.parse(JSON.stringify(model)) as EditorForm;

  if (workStateSettings.length == 0) {
    modelCopy.primaryData.workState0 = objectValueModel;
    return modelCopy;
  }

  let x = 0;
  for (x = 0; x < workStateSettings.length; x++) {
    modelCopy.primaryData[`workState${x}`] = objectValueModel;
  }

  modelCopy.primaryData.divider = dividerModel;

  if (numberOfFields >= maxFields) return modelCopy;

  modelCopy.primaryData[`workState${x}`] = objectValueModel;

  return modelCopy;
}

export function addToModel(
  model: EditorForm,
  numberOfFields: number,
  maxFields: number,
) {
  let newRecordId = '0';
  const modelCopy = JSON.parse(JSON.stringify(model)) as EditorForm;
  const keysArray = Object.keys(modelCopy.primaryData);
  const key = findLast(keysArray, (key) => {
    return key.toString().includes('workState');
  });

  if (key != undefined) newRecordId = key.replace(/\D/g, '');

  delete modelCopy.primaryData.divider;
  modelCopy.primaryData.divider = dividerModel;

  if (numberOfFields >= maxFields) return modelCopy;
  modelCopy.primaryData[`workState${+newRecordId + 1}`] = objectValueModel;

  return modelCopy;
}

export function removeFromModel(model: EditorForm, tabKey: string) {
  const modelCopy = JSON.parse(JSON.stringify(model)) as EditorForm;

  delete modelCopy.primaryData[tabKey];

  return modelCopy;
}

export function workStateSettingsToLayout(
  workStateSettings: MObject.WorkStateSettings[],
  numberOfFields: number,
  maxFields: number,
) {
  const layoutCopy: Record<string, EditorTile> = {
    primaryData: {
      tileLayout: {
        layoutDirection: 'horizontal',
      },
      tabs: {
        primaryData: {
          layoutDirection: 'horizontal',
        },
      },
    },
  };

  if (workStateSettings.length == 0) {
    layoutCopy.primaryData.tabs.divider = {
      layoutDirection: 'horizontal',
    };
    layoutCopy.primaryData.tabs.workState0 = objectLayoutModel;
    return layoutCopy;
  }

  let x = 0;
  for (x = 0; x < workStateSettings.length; x++) {
    layoutCopy.primaryData.tabs[`workState${x}`] = objectLayoutModel;
  }

  layoutCopy.primaryData.tabs.divider = {
    layoutDirection: 'horizontal',
  };

  if (numberOfFields >= maxFields) return layoutCopy;

  layoutCopy.primaryData.tabs[`workState${x}`] = objectLayoutModel;

  return layoutCopy;
}

export function addToLayout(
  layout: Record<string, EditorTile>,
  numberOfFields: number,
  maxFields: number,
) {
  let newRecordId = '0';
  const layoutCopy = JSON.parse(JSON.stringify(layout)) as Record<
    string,
    EditorTile
  >;
  const keysArray = Object.keys(layoutCopy.primaryData.tabs);

  const key = findLast(keysArray, (key) => {
    return key.toString().includes('workState');
  });

  if (key != undefined) newRecordId = key.replace(/\D/g, '');

  if (numberOfFields >= maxFields) return layoutCopy;

  layoutCopy.primaryData.tabs[`workState${+newRecordId + 1}`] =
    objectLayoutModel;

  return layoutCopy;
}

export function removeFromLayout(
  layout: Record<string, EditorTile>,
  tabKey: string,
) {
  const layoutCopy = { ...layout };

  delete layoutCopy.primaryData.tabs[tabKey];

  return layoutCopy;
}

export function modelToButtonMap(model: EditorForm) {
  const buttonMap: Record<string, Record<string, ButtonContent>> = {};
  let length = Object.keys(model.primaryData).length - 1;

  for (const key of Object.keys(model.primaryData)) {
    if (key.includes('workState')) {
      if (length != 0) {
        buttonMap[key] = {
          button: {
            icon: 'ri ri-subtract-line',
            key: ObjectButtonKey.Remove.toString(),
          },
        };
      } else {
        buttonMap[key] = {
          button: {
            icon: 'ri ri-add-line',
            key: ObjectButtonKey.Add.toString(),
          },
        };
      }
    }

    length--;
  }

  return buttonMap;
}

export function convertResponseToModel(
  data: MObject.Messages.GetOut,
  enumOptions: Record<number, string>,
  maxFields: number,
): [Record<string, Record<string, Record<string, EditorValue>>>, number] {
  let numberOfFields = 0;
  let index;
  const workStateCopy = data.workStateSettings;
  const enumOptionsCopy: number[] = [];

  for (const key of Object.keys(enumOptions)) {
    enumOptionsCopy.push(+key);
  }

  const model: Record<string, Record<string, Record<string, EditorValue>>> = {
    primaryData: {
      primaryData: {
        name: {
          value: data.base.name,
        },
      },
    },
  };

  if (workStateCopy.length == 0) {
    model.primaryData.workState0 = {
      workState: {
        value: +enumOptionsCopy[0],
      },
      delay: {
        value: '',
        error: '',
      },
    };
  } else {
    for (let x = 0; x < workStateCopy.length; x++) {
      model.primaryData[`workState${x}`] = {
        workState: {
          value: workStateCopy[x].workStateId,
        },
        delay: {
          value: workStateCopy[x].entryDelay,
        },
      };

      index = enumOptionsCopy.findIndex(
        (option) => option == workStateCopy[x].workStateId,
      );
      if (index !== -1) enumOptionsCopy.splice(index, 1);
      numberOfFields++;
    }

    if (numberOfFields >= maxFields) return [model, numberOfFields];

    model.primaryData[`workState${numberOfFields}`] = {
      workState: {
        value: +enumOptionsCopy[0],
      },
      delay: {
        value: '',
        error: '',
      },
    };
  }

  return [model, numberOfFields];
}

export function addToValue(
  currentValue: Record<string, Record<string, Record<string, EditorValue>>>,
  workStates: Record<number, string>,
  numberOfFields: number,
  maxFields: number,
): Record<string, Record<string, Record<string, EditorValue>>> {
  const valueCopy = JSON.parse(JSON.stringify(currentValue)) as Record<
    string,
    Record<string, Record<string, EditorValue>>
  >;
  const key = findLast(Object.keys(valueCopy.primaryData), (key) => {
    return key.toString().includes('workState');
  });

  let newRecordId = '0';

  if (key != undefined) newRecordId = key.replace(/\D/g, '');

  const newValue: Record<
    string,
    Record<string, Record<string, EditorValue>>
  > = {
    primaryData: {},
  };
  const enumOptions: number[] = [];
  let index;

  for (const key of Object.keys(workStates)) {
    enumOptions.push(+key);
  }

  for (const [key, value] of Object.entries(valueCopy.primaryData)) {
    newValue.primaryData[key] = value;

    if (key.includes('workState')) {
      index = enumOptions.findIndex(
        (option) => option == +value.workState.value,
      );
      if (index !== -1) enumOptions.splice(index, 1);
    }
  }

  if (numberOfFields >= maxFields) return newValue;

  newValue.primaryData[`workState${+newRecordId + 1}`] = {
    workState: {
      value: enumOptions[0],
    },
    delay: {
      value: '',
      error: '',
    },
  };

  return newValue;
}

export function removeFromValue(
  currentValue: Record<string, Record<string, Record<string, EditorValue>>>,
  tabKey: string,
): Record<string, Record<string, Record<string, EditorValue>>> {
  const valueCopy = { ...currentValue };

  const newValue: Record<
    string,
    Record<string, Record<string, EditorValue>>
  > = {
    primaryData: {},
  };

  for (const [key, value] of Object.entries(valueCopy.primaryData)) {
    if (tabKey != key) newValue.primaryData[key] = value;
  }

  return newValue;
}

export function convertDataToGrpc(
  data: Record<string, Record<string, EditorValue>>,
  dataCopy: MObject.Base,
): MObject.Base {
  return {
    ...dataCopy,
    name: data.primaryData.name.value,
  };
}

export function convertWorkStateToGrpc(
  data: Record<string, Record<string, EditorValue>>,
): MObject.WorkStateSettings[] {
  const workStates: MObject.WorkStateSettings[] = [];

  for (const key of Object.keys(data)) {
    if (key.includes('workState')) {
      if (data[key].delay.value != '' || data[key].delay.value != 0)
        workStates.push({
          workStateId: +data[key].workState.value,
          entryDelay: data[key].delay.value,
        });
    }
  }

  return workStates;
}
