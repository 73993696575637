import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { FC } from 'react';

import { useTranslation } from '@/hooks';
import { IHubsEditorLogic } from '@/logic/interfaces';
import { FooterPopup } from '@/widgets/toolBar/footerPopup';

export const HubsEditorConfirmButton: FC = () => {
  const t = useTranslation();

  const editorLogic = useInjection(IHubsEditorLogic.$);

  const { confirm } = useActions(editorLogic);
  const { isSaving } = useValues(editorLogic);

  return (
    <FooterPopup
      popupMessage={t('editor.footer.confirmMessage')}
      popupIcon={'pi pi-check-circle'}
      buttonLabel={t('editor.footer.confirm')}
      accept={confirm}
      acceptLabel={t('editor.footer.acceptLabel')}
      rejectLabel={t('editor.footer.rejectLabel')}
      disabled={isSaving}
      buttonIcon={'ri ri-check-line'}
    />
  );
};
