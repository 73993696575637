import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { AlarmEvent } from '@/types/models/event';

export interface IEventAlarmContainerLogicProps
  extends Record<string, unknown> {}

interface IEventAlarmContainer extends Logic {
  readonly props: IEventAlarmContainerLogicProps;
  readonly actions: {
    readonly addEvents: (events: AlarmEvent[]) => void;
    readonly useEvents: (eventIds: number[]) => void;
    readonly releaseEvents: (eventIds: number[]) => void;
    readonly removeEvents: (eventIds: number[]) => void;
  };
  readonly values: { events: Record<string, AlarmEvent> };
  readonly selectors: {
    readonly events: (state: unknown) => Record<string, AlarmEvent>;
  };
}

export interface IEventAlarmContainerLogic
  extends LogicWrapper<IEventAlarmContainer> {}

export namespace IEventAlarmContainerLogic {
  export const $: interfaces.ServiceIdentifier<IEventAlarmContainerLogic> =
    Symbol('IEventAlarmContainerLogic');
}
