import { t } from 'i18next';
import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';
import { FC, useEffect, useRef } from 'react';

import { Editor } from '@/components/editor';
import { IHubsEditorLogic } from '@/logic/interfaces';
import { IEditorLogic } from '@/logic/interfaces/editor';
import { ConfirmStatus, LoadingStatus } from '@/types/custom/editorTypes';

import { EditorRefreshButton, EditorSpinner } from '..';
import './../mainStyle.scss';
import { hubsEditorModel } from './model';

export const HubsEditor: FC = () => {
  const toast = useRef<Toast>(null);
  const editorLogic = useInjection(IHubsEditorLogic.$);

  const { setConfirmStatus } = useActions(editorLogic);
  const { isSaving, confirmStatus, loadStatus } = useValues(editorLogic);

  useEffect(() => {
    if (toast.current === null) return;

    if (confirmStatus === ConfirmStatus.success) {
      toast.current.show({
        severity: 'success',
        detail: t('editor.toast.success').toString(),
        life: 3000,
      });
    } else if (confirmStatus === ConfirmStatus.failure) {
      toast.current.show({
        severity: 'error',
        detail: t('editor.toast.failure').toString(),
        life: 3000,
      });
    }

    setConfirmStatus(ConfirmStatus.unknown);
  }, [confirmStatus]);

  return loadStatus === LoadingStatus.success ? (
    <div className="OCS-editor-blockui-wrapper">
      <BlockUI
        blocked={isSaving}
        template={<i className="pi pi-spin pi-spinner OCS-editor-spinner" />}
      >
        <div className="OCS-editor-wrapper">
          <div className="OCS-editor">
            <Editor
              modelTile={hubsEditorModel.primaryData}
              modelKey={'primaryData'}
              editorLogic={editorLogic as unknown as IEditorLogic}
            ></Editor>
          </div>
        </div>
      </BlockUI>
      <Toast ref={toast} position="top-right" />
    </div>
  ) : loadStatus === LoadingStatus.isLoading ? (
    <EditorSpinner />
  ) : (
    <EditorRefreshButton editorLogic={editorLogic as unknown as IEditorLogic} />
  );
};
