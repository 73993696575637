import { ColumnType, SortOptions } from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDefinedAlarms } from '@/types/models';
import { StringKeys } from '@/utility/types';

export interface IGroupAlarmsTableLogicProps extends Record<string, unknown> {
  objectUuid: string;
}

export interface IGroupAlarmsTable extends Logic {
  readonly props: IGroupAlarmsTableLogicProps;
  readonly actions: {
    readonly setCurrentPage: (currentPage: number) => void;
    readonly setRowsPerPage: (rowsPerPage: number) => void;
    readonly refresh: () => void;
    readonly setPaginator?: (value: boolean) => void;
  };
  readonly values: {
    readonly dataKey: 'definedAlarmGroupUuid';
    readonly columns?: {
      field: StringKeys<MDefinedAlarms.TableGroupView>;
      header: string;
    }[];
    readonly columnTypes: Record<string, ColumnType>;
    readonly values: MDefinedAlarms.TableGroupView[] | null;
    readonly loading: boolean;
    readonly error: boolean;
    readonly currentPage: number;
    readonly rowsPerPage: number;
    readonly amountOfRecords: number;
    readonly isPaginatorActive: boolean;
    readonly sortOptions?: SortOptions;
    readonly expandProps?: { [key: string]: any };
  };
  readonly selectors: {
    readonly values: (
      groupAlarms: MDefinedAlarms.GroupView[],
    ) => MDefinedAlarms.TableGroupView[] | null;
  };
}

export interface IGroupAlarmsTableLogic
  extends LogicWrapper<IGroupAlarmsTable> {
  build: any;
}

export type IGroupAlarmsTableLogicIdentifier =
  interfaces.ServiceIdentifier<IGroupAlarmsTableLogic>;

export namespace IGroupAlarmsTableLogic {
  export const $: IGroupAlarmsTableLogicIdentifier = Symbol(
    'IGroupAlarmsTableLogic',
  );
}
