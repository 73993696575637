/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ResponsiveTileComponent } from '@/types/custom/responsiveBar';

import { CenterFoldingTile } from './centerFoldingTile';
import { FoldingTile } from './foldingTile';
import './style.scss';

interface Component {
  responsiveComponent: ResponsiveTileComponent | JSX.Element;
  isVisible: boolean;
}

interface FoldFlags {
  isActive: boolean;
  setFoldFlag: Dispatch<SetStateAction<boolean>>;
  responsiveTile: 'side' | 'center';
}

interface TabMenuFlag {
  isActive: boolean;
  responsiveTile?: 'left';
}

interface ResponsiveTileProps {
  components?: (ResponsiveTileComponent | JSX.Element)[];
  foldAlignment?: 'left' | 'right';
  responsiveClassName?: string;
  foldFlag: FoldFlags;
  tabMenuFlag: TabMenuFlag;
  accessibleSize: number;
  dynamicSize: number;
  foldSize: number;

  dynamicContainerRef: MutableRefObject<HTMLDivElement | null>;
}

export const ResponsiveTile: FC<ResponsiveTileProps> = (props) => {
  const componentsRefs = useRef<(HTMLDivElement | null)[]>([]);
  const foldingTileRef = useRef<HTMLDivElement | null>(null);

  const [components, setComponents] = useState<Component[]>([]);
  const [foldedComponents, setFoldedComponents] = useState<JSX.Element[]>([]);

  const [dynamicSize, setDynamicSize] = useState(0);
  const [foldedComponentSize, setFoldedComponentSize] = useState(0);

  useEffect(() => {
    if (props.foldFlag.isActive && props.foldFlag.responsiveTile === 'side')
      return;

    if (
      props.tabMenuFlag.isActive &&
      props.tabMenuFlag.responsiveTile === 'left'
    ) {
      setDynamicSize(props.dynamicSize);
      return;
    }

    const [components, foldedComponents, dynamicSize, foldedComponentSize] =
      GetComponents(
        props.components,
        componentsRefs,
        props.accessibleSize,
        props.dynamicSize,
      );

    if (
      props.foldFlag.responsiveTile === 'center' &&
      foldedComponents.length != 0
    ) {
      props.foldFlag.setFoldFlag(true);
    } else if (
      props.foldFlag.responsiveTile === 'center' &&
      foldedComponents.length == 0
    ) {
      props.foldFlag.setFoldFlag(false);
    }

    setComponents(components);
    setFoldedComponents(foldedComponents);
    setDynamicSize(dynamicSize);
    setFoldedComponentSize(foldedComponentSize);
  }, [props.components, props.accessibleSize, props.dynamicSize]);

  return (
    <div
      className={`${
        props.foldAlignment === 'left' ? 'OCS-flex-flow-reverse' : ''
      } OCS-responsive-tile-wrapper`}
    >
      <div className="OCS-virtual-responsive-tile">
        {props.components?.map((component, index) => {
          return (
            <div
              key={`virtualComponent${index}`}
              ref={(comp) => (componentsRefs.current[index] = comp)}
            >
              {'component' in component ? component.component : component}
            </div>
          );
        })}
      </div>
      <div ref={foldingTileRef} className={`OCS-folding-responsive-tile`}>
        {foldedComponents.length != 0 ? (
          props.foldAlignment ? (
            <FoldingTile
              components={foldedComponents}
              size={props.foldSize}
              dynamicSize={dynamicSize}
              componentSize={foldedComponentSize}
              foldAlignment={props.foldAlignment}
              dynamicContainerRef={props.dynamicContainerRef}
            />
          ) : (
            <CenterFoldingTile
              components={foldedComponents}
              size={props.foldSize}
              dynamicSize={dynamicSize}
            />
          )
        ) : (
          <></>
        )}
      </div>
      <div
        className={`${
          props.responsiveClassName ? props.responsiveClassName : ''
        } OCS-responsive-tile`}
      >
        {components.map((component, index) => {
          return (
            <div
              className={component.isVisible ? '' : 'OCS-d-hidden'}
              key={`component${index}`}
            >
              {'component' in component.responsiveComponent
                ? component.responsiveComponent.component
                : component.responsiveComponent}
            </div>
          );
        })}
      </div>
    </div>
  );
};

function GetComponents(
  components: (ResponsiveTileComponent | JSX.Element)[] | undefined,
  componentsRefs: MutableRefObject<(HTMLDivElement | null)[]>,
  accessibleSize: number,
  dynamicSize: number,
): [Component[], JSX.Element[], number, number] {
  if (components == undefined) return [[], [], 0, 0];

  const visibleComponents: Component[] = [];
  const foldedComponents: JSX.Element[] = [];

  let temp;
  let component;
  let foldable;

  let currentSize = 0;
  let currentTileSize = 0;
  let foldedComponentSize = 0;

  for (let i = 0; i <= components.length - 1; i++) {
    currentSize = componentsRefs.current[i]?.offsetWidth as number;
    currentTileSize += currentSize;

    temp = components[i];

    if ('component' in temp) {
      component = temp.component;
      foldable = temp.foldable;
    } else {
      component = temp;
      foldable = true;
    }

    if (currentTileSize <= accessibleSize) {
      visibleComponents.push({
        responsiveComponent: component,
        isVisible: true,
      });
      dynamicSize -= currentSize;
    } else {
      visibleComponents.push({
        responsiveComponent: components[i],
        isVisible: false,
      });

      if (foldable == true || foldable == undefined) {
        foldedComponents.push(component);
        foldedComponentSize += currentSize;
      }
    }
  }

  return [
    visibleComponents,
    foldedComponents,
    dynamicSize,
    foldedComponentSize,
  ];
}
