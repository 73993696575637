import { actions, events, kea, listeners, path, reducers } from 'kea';

import { themeDict } from '@/themes';

import { Themes } from '../../../themes';
import type { logicType } from './indexType';

const logic = kea<logicType>([
  path(['app']),
  actions({
    setTheme: (theme: Themes) => ({ theme }),
    setFontSize: (fontSize: number) => ({ fontSize }),
    setAlarmsVisibility: (visibility: boolean) => ({ visibility }),
  }),
  reducers({
    theme: [
      Themes.ARYA_BLUE as Themes,
      { persist: true },
      {
        setTheme: (_, { theme }) => theme,
      },
    ],
    fontSize: [
      1,
      { persist: true },
      {
        setFontSize: (_, { fontSize }) => fontSize,
      },
    ],
    alarmsVisible: [
      false,
      {
        setAlarmsVisibility: (_, { visibility }) => visibility,
      },
    ],
  }),
  listeners({
    setTheme: ({ theme }) => {
      const themeLink = document.getElementById('theme-link');
      if (themeLink instanceof HTMLLinkElement) {
        themeLink.href = themeDict[theme];
      }
    },
    setFontSize: ({ fontSize }) => {
      const htmlElement = document.getElementById('html');
      if (htmlElement instanceof HTMLHtmlElement) {
        htmlElement.style.fontSize = fontSize.toString() + 'em';
      }
    },
  }),
  events(({ actions, values }) => ({
    afterMount: [
      () => actions.setFontSize(values.fontSize),
      () => actions.setTheme(values.theme),
    ],
  })),
]);

export const appLogic = logic;
