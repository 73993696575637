import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MObject } from '@/types/models';

export type IObjectDetailContainerLogicProps = Record<string, unknown>;

interface IObjectDetailContainer extends Logic {
  readonly props: IObjectDetailContainerLogicProps;
  readonly actions: {
    readonly addObjects: (objects: (MObject.Detail | MObject.Full)[]) => void;
    readonly useObjects: (objectIds: string[]) => void;
    readonly releaseObjects: (objectIds: string[]) => void;
  };
  readonly values: {
    objects: Record<string, MObject.Full>;
  };
  readonly selectors: {
    readonly objects: (state: unknown) => Record<string, MObject.Full>;
  };
}

export type IObjectDetailContainerLogic = LogicWrapper<IObjectDetailContainer>;

export namespace IObjectDetailContainerLogic {
  export const $: interfaces.ServiceIdentifier<IObjectDetailContainerLogic> =
    Symbol('IObjectDetailContainerLogic');
}
