import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDevice } from '@/types/models';
import { VibrodetectorData } from '@/types/models/vibrodetector';

export interface IVibroDetectorsViewLogicProps {
  objectUuid: string;
}

interface IVibroDetectorsView extends Logic {
  readonly props: IVibroDetectorsViewLogicProps;
  readonly actions: {
    connectLiveStream: (deviceUuid: string) => void;
    disconnectLiveStream: (deviceUuid: string) => void;
  };
  readonly values: {
    readonly devices: MDevice.TableView[] | null;
    readonly liveStreams: string[];
    readonly now: Record<string, VibrodetectorData>;
  };
}

export interface IVibroDetectorsViewLogic
  extends LogicWrapper<IVibroDetectorsView> {}

export namespace IVibroDetectorsViewLogic {
  export const $: interfaces.ServiceIdentifier<IVibroDetectorsViewLogic> =
    Symbol('IVibroDetectorView');
}
