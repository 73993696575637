import { injectable } from 'inversify';

import { MHubs } from '@/types/models/hubs';

import { IHubsService } from '../interfaces/hubs';
import { Response } from '../types/response';
import { BaseService } from './base';

@injectable()
export class HubsService extends BaseService implements IHubsService {
  public async get(
    input: MHubs.Messages.GetIn,
  ): Promise<Response<MHubs.Messages.GetOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.hubsGet(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async store(
    input: MHubs.Messages.StoreIn,
  ): Promise<Response<MHubs.Messages.StoreOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.hubsStore(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
