function clearSelection() {
  const selection = window.getSelection();
  if (selection !== null) {
    if ('empty' in selection) {
      selection.empty();
    }
  }
}

function isObjectOrArray(value: unknown): value is Record<string, unknown> {
  if (typeof value === 'object' && value !== null) {
    return true;
  } else if (Array.isArray(value)) {
    return true;
  }
  return false;
}

function compareObjectArrayLength(
  value1: Record<string, unknown>,
  value2: Record<string, unknown>,
) {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length != value2.length) {
      return false;
    }
    return true;
  }
  if (
    typeof value1 === 'object' &&
    value1 !== null &&
    typeof value2 === 'object' &&
    value2 !== null
  ) {
    if (Object.keys(value1).length != Object.keys(value2).length) {
      return false;
    }
    return true;
  }
}

function twoLayerEqual(
  newState: Record<string, unknown>,
  prevState: Record<string, unknown>,
) {
  if (isObjectOrArray(newState) && isObjectOrArray(prevState)) {
    if (!compareObjectArrayLength(newState, prevState)) {
      return false;
    }

    for (const key in newState) {
      const newValue = newState[key];
      const prevValue = prevState[key];
      if (isObjectOrArray(newValue) && isObjectOrArray(prevValue)) {
        if (!compareObjectArrayLength(newValue, prevValue)) {
          return false;
        }
        let subKey: string | number;
        for (subKey in newValue) {
          const newSubValue = newValue[subKey];
          const prevSubValue = prevValue[subKey];
          if (newSubValue !== prevSubValue) {
            return false;
          }
        }
      } else if (newValue !== prevValue) {
        return false;
      }
    }
    return true;
  } else if (newState !== prevState) {
    return false;
  }
  return true;
}

function isFullScreen() {
  if (!document.fullscreenElement) {
    return true;
  }
  return false;
}

function toggleFullscreen() {
  const doc = document.documentElement;
  if (isFullScreen()) {
    if (doc.requestFullscreen) {
      doc.requestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

function requestFullscreen() {
  if (isFullScreen()) {
    const doc = document.documentElement;
    if (doc.requestFullscreen) {
      doc.requestFullscreen();
    }
  }
}

function exitFullscreen() {
  if (!isFullScreen()) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object' && DEV_TOOLS != null) {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
};

export function dateToDatetimeString(date: Date): string {
  return (
    date.toLocaleString('en-GB').replace(',', '') +
    '.' +
    date.getMilliseconds().toString().padStart(3, '0')
  );
}

export {
  clearSelection,
  twoLayerEqual,
  toggleFullscreen,
  isFullScreen,
  requestFullscreen,
  exitFullscreen,
  disableReactDevTools,
};
