// @generated by protobuf-ts 2.8.0
// @generated from protobuf file "defined_alarms.proto" (package "defined_alarms", syntax proto2)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { MessageLive } from "./live";
import { MessageTableRequest } from "./table";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * @generated from protobuf message defined_alarms.MessageVisualizationAlias
 */
export interface MessageVisualizationAlias {
    /**
     * @generated from protobuf field: string object_uuid = 1;
     */
    objectUuid: string;
    /**
     * @generated from protobuf field: string visualization_alias = 2;
     */
    visualizationAlias: string;
}
/**
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmOut
 */
export interface MessageDefinedAlarmOut {
    /**
     * @generated from protobuf field: string defined_alarm_uuid = 1;
     */
    definedAlarmUuid: string;
    /**
     * @generated from protobuf field: defined_alarms.DefinedAlarmData data = 2;
     */
    data?: DefinedAlarmData; // / Dane alarmu
    /**
     * @generated from protobuf field: defined_alarms.DefinedAlarmSettings settings = 3;
     */
    settings?: DefinedAlarmSettings; // / Ustawienia alarmu
}
/**
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmsList
 */
export interface MessageDefinedAlarmsList {
    /**
     * @generated from protobuf field: int32 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
    /**
     * @generated from protobuf field: repeated defined_alarms.DefinedAlarmView defined_alarms = 3;
     */
    definedAlarms: DefinedAlarmView[];
}
/**
 * *
 * Typ przekoznywany do konfigurowania aliasu dla wizualizacji dla danego alarmu
 *
 * @generated from protobuf message defined_alarms.MessageUpdateDefinedAlarmAlias
 */
export interface MessageUpdateDefinedAlarmAlias {
    /**
     * @generated from protobuf field: string defined_alarm_uuid = 1;
     */
    definedAlarmUuid: string;
    /**
     * @generated from protobuf field: optional string visualization_alias = 2;
     */
    visualizationAlias?: string;
}
/**
 * *
 * Typ przekoznywany do konfigurowania aliasu dla wizualizacji dla danej grupy alarmów
 *
 * @generated from protobuf message defined_alarms.MessageUpdateDefinedAlarmGroupAlias
 */
export interface MessageUpdateDefinedAlarmGroupAlias {
    /**
     * @generated from protobuf field: string defined_alarm_group_uuid = 1;
     */
    definedAlarmGroupUuid: string;
    /**
     * @generated from protobuf field: optional string visualization_alias = 2;
     */
    visualizationAlias?: string;
}
/**
 * *
 * Widok podglądu akutalnego stanu alarmów
 *
 * @generated from protobuf message defined_alarms.DefinedAlarmView
 */
export interface DefinedAlarmView {
    /**
     * @generated from protobuf field: string defined_alarm_uuid = 1;
     */
    definedAlarmUuid: string; // / Uuid zdefinowanego alarmu
    /**
     * @generated from protobuf field: int32 event_level_id = 7;
     */
    eventLevelId: number; // / Poziom generowanego zdarzenia alarmu
    /**
     * @generated from protobuf field: string device_uuid = 8;
     */
    deviceUuid: string; // / Urzadzenie do którego przypisany jest alarm
    /**
     * @generated from protobuf field: optional string object_uuid = 10;
     */
    objectUuid?: string; // / Obiekt do którego jest przypisane urzadzenie do którego przypisany jest alarm
    /**
     * @generated from protobuf field: optional string defined_alarm_group_uuid = 9;
     */
    definedAlarmGroupUuid?: string; // / Grupa w której może znajdowac się alarm
    /**
     * @generated from protobuf field: string list_description = 2;
     */
    listDescription: string; // / Opis alarmu
    /**
     * @generated from protobuf field: optional string list_color = 3;
     */
    listColor?: string; // / Kolor alarmu w #hex
    /**
     * @generated from protobuf field: defined_alarms.State temporary_state = 4;
     */
    temporaryState: State; // / Stan akutalny alarmu
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp temporary_state_datetime = 5;
     */
    temporaryStateDatetime?: Timestamp; // / Ostatnia akutalizacja stanu alarmu
    /**
     * @generated from protobuf field: bool settings_active = 6;
     */
    settingsActive: boolean; // / Ustawienie aktywności alarmu
    /**
     * @generated from protobuf field: optional string settings_defined_alarm_group_uuid = 11;
     */
    settingsDefinedAlarmGroupUuid?: string; // / Przypisanie alarmu do grupy alarmów
}
/**
 * *
 * Dane zdefinowanego alarmu
 *
 * @generated from protobuf message defined_alarms.DefinedAlarmData
 */
export interface DefinedAlarmData {
    /**
     * @generated from protobuf field: string description = 1;
     */
    description: string; // / Opis alarmu
    /**
     * @generated from protobuf field: optional string color = 2;
     */
    color?: string; // / Kolor alarmu w formacie #hex
    /**
     * @generated from protobuf field: int32 event_level_id = 3;
     */
    eventLevelId: number; // / Poziom generowanego zdarzenia przez alarm
}
/**
 * *
 * Ustawienia alarmu
 *
 * @generated from protobuf message defined_alarms.DefinedAlarmSettings
 */
export interface DefinedAlarmSettings {
    /**
     * @generated from protobuf field: int32 priority = 1;
     */
    priority: number; // / Prioryteted dane alarmu w ramach obiektów (wip) 
    /**
     * @generated from protobuf field: bool active = 2;
     */
    active: boolean; // / Czy alarm ma być aktywny (wip)
    /**
     * @generated from protobuf field: bool sound = 3;
     */
    sound: boolean; // / Czy alarm ma generować dźwiek
    /**
     * @generated from protobuf field: string col = 4;
     */
    col: string; // / Nazwa pola z daną którą dany alarm ma obsługiwać z danego urządzenia
    /**
     * @generated from protobuf field: defined_alarms.Rule rule = 5;
     */
    rule: Rule; // / Reguła porówaniania alarmu
    /**
     * @generated from protobuf field: string value = 6;
     */
    value: string; // / Wartość stała do której ma być akutalną wartość alarmu z danego urzadzenia
    /**
     * @generated from protobuf field: optional defined_alarms.Math math = 7;
     */
    math?: Math; // / Dodatkowe obliczenia matematyczne do wykonania przed porówanie wartości alarmu z wartością stałą do porówniania
    /**
     * @generated from protobuf field: optional string math_col = 8;
     */
    mathCol?: string; // / (wip)
    /**
     * @generated from protobuf field: optional double on_delay = 9;
     */
    onDelay?: number; // / Czas w której reguła alarmu musi być przekroczona aby alarm stał się aktywny 
    /**
     * @generated from protobuf field: optional double off_delay = 10;
     */
    offDelay?: number; // / Czas w której reguła alarmu musi być niespełniona aby alarm stał się nieaktywny
    /**
     * @generated from protobuf field: optional int32 round = 11;
     */
    round?: number; // / Zakrągłenie wartości alarmu (wip)
    /**
     * @generated from protobuf field: string device_uuid = 12;
     */
    deviceUuid: string; // / Urzadzenie z którego pochodzi wartość alarmu
    /**
     * @generated from protobuf field: optional string math_device_uuid = 13;
     */
    mathDeviceUuid?: string; // / Opcjonalne urzadzenie do porówania wartości alarmu (wip)
    /**
     * @generated from protobuf field: optional int32 work_state_id = 14;
     */
    workStateId?: number; // / Tryb pracy obiektu w której alarm ma być aktywny
    /**
     * @generated from protobuf field: optional string defined_alarm_group_uuid = 15;
     */
    definedAlarmGroupUuid?: string; // / Opcjonalna grupa do której alarm ma należeć
}
/**
 * *
 * Dane wysłane przy tworzeniu lub akutalizowaniu danych alarmu
 *
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmIn
 */
export interface MessageDefinedAlarmIn {
    /**
     * @generated from protobuf field: optional string defined_alarm_uuid = 1;
     */
    definedAlarmUuid?: string; // / Jeśli niezdefiniowane zostanie utworzony nowy alarm z wysłanymi danymi, jeśli obecne zakutalizuje istniejący alarm
    /**
     * @generated from protobuf field: defined_alarms.DefinedAlarmData data = 2;
     */
    data?: DefinedAlarmData; // / Dane alarmu
    /**
     * @generated from protobuf field: defined_alarms.DefinedAlarmSettings settings = 3;
     */
    settings?: DefinedAlarmSettings; // / Ustawienia alarmu
}
/**
 * *
 * Typ wysłany lub zwracany przez zapytaniach o alarm per jego uuid
 *
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmUuid
 */
export interface MessageDefinedAlarmUuid {
    /**
     * @generated from protobuf field: string defined_alarm_uuid = 1;
     */
    definedAlarmUuid: string;
}
/**
 * *
 * Typ wysłany lub zwracany przez zapytaniach o grupę alarmów per jego uuid
 *
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmGroupUuid
 */
export interface MessageDefinedAlarmGroupUuid {
    /**
     * @generated from protobuf field: string defined_alarm_group_uuid = 1;
     */
    definedAlarmGroupUuid: string;
}
/**
 * *
 * Dane grupy alarmów
 *
 * @generated from protobuf message defined_alarms.DefinedAlarmGroupData
 */
export interface DefinedAlarmGroupData {
    /**
     * @generated from protobuf field: string description = 1;
     */
    description: string; // / Opis grupy
    /**
     * @generated from protobuf field: optional string color = 2;
     */
    color?: string; // / Kolor grupy w formacie #hex
    /**
     * @generated from protobuf field: defined_alarms.GroupRule rule = 3;
     */
    rule: GroupRule; // / Reguła porówaniania alarmów w grupie
    /**
     * @generated from protobuf field: int32 event_level_id = 4;
     */
    eventLevelId: number; // / Poziom generowanego zdarzenia przez grupę alarmów
    /**
     * @generated from protobuf field: string object_uuid = 5;
     */
    objectUuid: string; // / Uuid obiektu do którego przypisana jest grupa alarmów
}
/**
 * *
 * Dane wysłane przy tworzeniu lub akutalizowaniu danych grupy alarmów
 *
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmGroupIn
 */
export interface MessageDefinedAlarmGroupIn {
    /**
     * @generated from protobuf field: optional string defined_alarm_group_uuid = 1;
     */
    definedAlarmGroupUuid?: string; // / Jeśli niezdefiniowane zostanie utworzony nowy grupę z wysłanymi danymi, jeśli obecne zakutalizuje istniejący grupę
    /**
     * @generated from protobuf field: defined_alarms.DefinedAlarmGroupData data = 2;
     */
    data?: DefinedAlarmGroupData; // / Dane grupy alarmów
}
/**
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmGroupsList
 */
export interface MessageDefinedAlarmGroupsList {
    /**
     * @generated from protobuf field: int32 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
    /**
     * @generated from protobuf field: repeated defined_alarms.DefinedAlarmGroupView defined_alarm_groups = 3;
     */
    definedAlarmGroups: DefinedAlarmGroupView[];
}
/**
 * *
 * Widok podglądu akutalnego stanu alarmów
 *
 * @generated from protobuf message defined_alarms.DefinedAlarmGroupView
 */
export interface DefinedAlarmGroupView {
    /**
     * @generated from protobuf field: string defined_alarm_group_uuid = 1;
     */
    definedAlarmGroupUuid: string; // / Uuid zdefinowanego alarmu
    /**
     * @generated from protobuf field: int32 event_level_id = 7;
     */
    eventLevelId: number; // / Poziom generowanego zdarzenia alarmu
    /**
     * @generated from protobuf field: string object_uuid = 10;
     */
    objectUuid: string; // / Obiekt do którego jest przypisane urzadzenie do którego przypisany jest alarm
    /**
     * @generated from protobuf field: string list_description = 2;
     */
    listDescription: string; // / Opis alarmu
    /**
     * @generated from protobuf field: optional string list_color = 3;
     */
    listColor?: string; // / Kolor alarmu w #hex
    /**
     * @generated from protobuf field: defined_alarms.State groups_temporary_state = 4;
     */
    groupsTemporaryState: State; // / Stan akutalny alarmu
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp groups_temporary_state_datetime = 5;
     */
    groupsTemporaryStateDatetime?: Timestamp; // / Ostatnia akutalizacja stanu alarmu
}
/**
 * @generated from protobuf message defined_alarms.MessageDefinedAlarmGroupOut
 */
export interface MessageDefinedAlarmGroupOut {
    /**
     * @generated from protobuf field: string defined_alarm_group_uuid = 1;
     */
    definedAlarmGroupUuid: string;
    /**
     * @generated from protobuf field: defined_alarms.DefinedAlarmGroupData data = 2;
     */
    data?: DefinedAlarmGroupData; // / Dane groupy alarmu
}
/**
 * *
 * Reguła alarmu którą musi spełniać aby wystąpił stan alarmowy
 *
 * @generated from protobuf enum defined_alarms.Rule
 */
export enum Rule {
    /**
     * / Równy
     *
     * @generated from protobuf enum value: Equal = 0;
     */
    Equal = 0,
    /**
     * / Nierówny
     *
     * @generated from protobuf enum value: NotEqual = 1;
     */
    NotEqual = 1,
    /**
     * / Mniejszy
     *
     * @generated from protobuf enum value: Less = 2;
     */
    Less = 2,
    /**
     * / Mniejszy lub równy
     *
     * @generated from protobuf enum value: LessOrEqual = 3;
     */
    LessOrEqual = 3,
    /**
     * / Większy
     *
     * @generated from protobuf enum value: Greater = 4;
     */
    Greater = 4,
    /**
     * / Większy lub równy
     *
     * @generated from protobuf enum value: GreaterOrEqual = 5;
     */
    GreaterOrEqual = 5,
    /**
     * / Zmiana o zadaną wartość procentową poprzedniej wartości alarmu w czasie on_delay/off_delay (wip)
     *
     * @generated from protobuf enum value: Change = 6;
     */
    Change = 6,
    /**
     * / Brak zmiany o zadaną wartość procentową poprzedniej wartości alarmu w czasie on_delay/off_delay (wip)
     *
     * @generated from protobuf enum value: NoChange = 7;
     */
    NoChange = 7
}
/**
 * *
 * Dodatkowe obliczenia wykonywane na akutalnym stanie alarmu przed porówaniem do Rule
 *
 * @generated from protobuf enum defined_alarms.Math
 */
export enum Math {
    /**
     * / DOdawanie
     *
     * @generated from protobuf enum value: Add = 0;
     */
    Add = 0,
    /**
     * / Odejmowanie
     *
     * @generated from protobuf enum value: Sub = 1;
     */
    Sub = 1,
    /**
     * / Mnożenie
     *
     * @generated from protobuf enum value: Mul = 2;
     */
    Mul = 2,
    /**
     * / Dzielenie
     *
     * @generated from protobuf enum value: Div = 3;
     */
    Div = 3,
    /**
     * Reszta z dzielenia
     *
     * @generated from protobuf enum value: Modulo = 4;
     */
    Modulo = 4
}
/**
 * *
 * Akutalny stan alarmu
 *
 * @generated from protobuf enum defined_alarms.State
 */
export enum State {
    /**
     * / Alarm nieaktywny
     *
     * @generated from protobuf enum value: Off = 0;
     */
    Off = 0,
    /**
     * / Alarm oczekuje w off_delay na dezakytwowanie
     *
     * @generated from protobuf enum value: PendingOnToOff = 1;
     */
    PendingOnToOff = 1,
    /**
     * / Alarm oczekuje w off_delay na dezaktywnowanie po zatwierdzeniu
     *
     * @generated from protobuf enum value: ConfirmedPendingOnToOff = 5;
     */
    ConfirmedPendingOnToOff = 5,
    /**
     * / Alarm oczekuje w on_delay na aktywność
     *
     * @generated from protobuf enum value: PendingOffToOn = 2;
     */
    PendingOffToOn = 2,
    /**
     * / Alarm aktywny
     *
     * @generated from protobuf enum value: On = 3;
     */
    On = 3,
    /**
     * / Alarm aktywny ale zatwierdzony
     *
     * @generated from protobuf enum value: ConfirmedOn = 4;
     */
    ConfirmedOn = 4,
    /**
     * / Błąd wyliczania stan alarmu
     *
     * @generated from protobuf enum value: Error = 99;
     */
    Error = 99
}
/**
 * *
 * Reguła za pomocą której mają być porównany alarmy w grupie
 *
 * @generated from protobuf enum defined_alarms.GroupRule
 */
export enum GroupRule {
    /**
     * / Wszystkie alarmy w danej grupy muszą być aktywne żeby grupa stała się aktywna
     *
     * @generated from protobuf enum value: And = 0;
     */
    And = 0,
    /**
     * / Jakikolwiek alarm w danej grupy musi być aktywny żebu grupa stała się aktywna
     *
     * @generated from protobuf enum value: Or = 1;
     */
    Or = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class MessageVisualizationAlias$Type extends MessageType<MessageVisualizationAlias> {
    constructor() {
        super("defined_alarms.MessageVisualizationAlias", [
            { no: 1, name: "object_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "visualization_alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageVisualizationAlias>): MessageVisualizationAlias {
        const message = { objectUuid: "", visualizationAlias: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageVisualizationAlias>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageVisualizationAlias): MessageVisualizationAlias {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string object_uuid */ 1:
                    message.objectUuid = reader.string();
                    break;
                case /* string visualization_alias */ 2:
                    message.visualizationAlias = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageVisualizationAlias, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string object_uuid = 1; */
        if (message.objectUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.objectUuid);
        /* string visualization_alias = 2; */
        if (message.visualizationAlias !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.visualizationAlias);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageVisualizationAlias
 */
export const MessageVisualizationAlias = new MessageVisualizationAlias$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmOut$Type extends MessageType<MessageDefinedAlarmOut> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmOut", [
            { no: 1, name: "defined_alarm_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", T: () => DefinedAlarmData },
            { no: 3, name: "settings", kind: "message", T: () => DefinedAlarmSettings }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmOut>): MessageDefinedAlarmOut {
        const message = { definedAlarmUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmOut>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmOut): MessageDefinedAlarmOut {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_uuid */ 1:
                    message.definedAlarmUuid = reader.string();
                    break;
                case /* defined_alarms.DefinedAlarmData data */ 2:
                    message.data = DefinedAlarmData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* defined_alarms.DefinedAlarmSettings settings */ 3:
                    message.settings = DefinedAlarmSettings.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmOut, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_uuid = 1; */
        if (message.definedAlarmUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmUuid);
        /* defined_alarms.DefinedAlarmData data = 2; */
        if (message.data)
            DefinedAlarmData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* defined_alarms.DefinedAlarmSettings settings = 3; */
        if (message.settings)
            DefinedAlarmSettings.internalBinaryWrite(message.settings, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmOut
 */
export const MessageDefinedAlarmOut = new MessageDefinedAlarmOut$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmsList$Type extends MessageType<MessageDefinedAlarmsList> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmsList", [
            { no: 1, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "defined_alarms", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => DefinedAlarmView }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmsList>): MessageDefinedAlarmsList {
        const message = { total: 0, count: 0, definedAlarms: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmsList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmsList): MessageDefinedAlarmsList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 total */ 1:
                    message.total = reader.int32();
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                case /* repeated defined_alarms.DefinedAlarmView defined_alarms */ 3:
                    message.definedAlarms.push(DefinedAlarmView.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmsList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int32(message.total);
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        /* repeated defined_alarms.DefinedAlarmView defined_alarms = 3; */
        for (let i = 0; i < message.definedAlarms.length; i++)
            DefinedAlarmView.internalBinaryWrite(message.definedAlarms[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmsList
 */
export const MessageDefinedAlarmsList = new MessageDefinedAlarmsList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageUpdateDefinedAlarmAlias$Type extends MessageType<MessageUpdateDefinedAlarmAlias> {
    constructor() {
        super("defined_alarms.MessageUpdateDefinedAlarmAlias", [
            { no: 1, name: "defined_alarm_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "visualization_alias", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageUpdateDefinedAlarmAlias>): MessageUpdateDefinedAlarmAlias {
        const message = { definedAlarmUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageUpdateDefinedAlarmAlias>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageUpdateDefinedAlarmAlias): MessageUpdateDefinedAlarmAlias {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_uuid */ 1:
                    message.definedAlarmUuid = reader.string();
                    break;
                case /* optional string visualization_alias */ 2:
                    message.visualizationAlias = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageUpdateDefinedAlarmAlias, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_uuid = 1; */
        if (message.definedAlarmUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmUuid);
        /* optional string visualization_alias = 2; */
        if (message.visualizationAlias !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.visualizationAlias);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageUpdateDefinedAlarmAlias
 */
export const MessageUpdateDefinedAlarmAlias = new MessageUpdateDefinedAlarmAlias$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageUpdateDefinedAlarmGroupAlias$Type extends MessageType<MessageUpdateDefinedAlarmGroupAlias> {
    constructor() {
        super("defined_alarms.MessageUpdateDefinedAlarmGroupAlias", [
            { no: 1, name: "defined_alarm_group_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "visualization_alias", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageUpdateDefinedAlarmGroupAlias>): MessageUpdateDefinedAlarmGroupAlias {
        const message = { definedAlarmGroupUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageUpdateDefinedAlarmGroupAlias>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageUpdateDefinedAlarmGroupAlias): MessageUpdateDefinedAlarmGroupAlias {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_group_uuid */ 1:
                    message.definedAlarmGroupUuid = reader.string();
                    break;
                case /* optional string visualization_alias */ 2:
                    message.visualizationAlias = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageUpdateDefinedAlarmGroupAlias, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_group_uuid = 1; */
        if (message.definedAlarmGroupUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmGroupUuid);
        /* optional string visualization_alias = 2; */
        if (message.visualizationAlias !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.visualizationAlias);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageUpdateDefinedAlarmGroupAlias
 */
export const MessageUpdateDefinedAlarmGroupAlias = new MessageUpdateDefinedAlarmGroupAlias$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DefinedAlarmView$Type extends MessageType<DefinedAlarmView> {
    constructor() {
        super("defined_alarms.DefinedAlarmView", [
            { no: 1, name: "defined_alarm_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "event_level_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "device_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "object_uuid", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "defined_alarm_group_uuid", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "list_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "list_color", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "temporary_state", kind: "enum", T: () => ["defined_alarms.State", State] },
            { no: 5, name: "temporary_state_datetime", kind: "message", T: () => Timestamp },
            { no: 6, name: "settings_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "settings_defined_alarm_group_uuid", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DefinedAlarmView>): DefinedAlarmView {
        const message = { definedAlarmUuid: "", eventLevelId: 0, deviceUuid: "", listDescription: "", temporaryState: 0, settingsActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DefinedAlarmView>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DefinedAlarmView): DefinedAlarmView {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_uuid */ 1:
                    message.definedAlarmUuid = reader.string();
                    break;
                case /* int32 event_level_id */ 7:
                    message.eventLevelId = reader.int32();
                    break;
                case /* string device_uuid */ 8:
                    message.deviceUuid = reader.string();
                    break;
                case /* optional string object_uuid */ 10:
                    message.objectUuid = reader.string();
                    break;
                case /* optional string defined_alarm_group_uuid */ 9:
                    message.definedAlarmGroupUuid = reader.string();
                    break;
                case /* string list_description */ 2:
                    message.listDescription = reader.string();
                    break;
                case /* optional string list_color */ 3:
                    message.listColor = reader.string();
                    break;
                case /* defined_alarms.State temporary_state */ 4:
                    message.temporaryState = reader.int32();
                    break;
                case /* optional google.protobuf.Timestamp temporary_state_datetime */ 5:
                    message.temporaryStateDatetime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.temporaryStateDatetime);
                    break;
                case /* bool settings_active */ 6:
                    message.settingsActive = reader.bool();
                    break;
                case /* optional string settings_defined_alarm_group_uuid */ 11:
                    message.settingsDefinedAlarmGroupUuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DefinedAlarmView, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_uuid = 1; */
        if (message.definedAlarmUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmUuid);
        /* int32 event_level_id = 7; */
        if (message.eventLevelId !== 0)
            writer.tag(7, WireType.Varint).int32(message.eventLevelId);
        /* string device_uuid = 8; */
        if (message.deviceUuid !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.deviceUuid);
        /* optional string object_uuid = 10; */
        if (message.objectUuid !== undefined)
            writer.tag(10, WireType.LengthDelimited).string(message.objectUuid);
        /* optional string defined_alarm_group_uuid = 9; */
        if (message.definedAlarmGroupUuid !== undefined)
            writer.tag(9, WireType.LengthDelimited).string(message.definedAlarmGroupUuid);
        /* string list_description = 2; */
        if (message.listDescription !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.listDescription);
        /* optional string list_color = 3; */
        if (message.listColor !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.listColor);
        /* defined_alarms.State temporary_state = 4; */
        if (message.temporaryState !== 0)
            writer.tag(4, WireType.Varint).int32(message.temporaryState);
        /* optional google.protobuf.Timestamp temporary_state_datetime = 5; */
        if (message.temporaryStateDatetime)
            Timestamp.internalBinaryWrite(message.temporaryStateDatetime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool settings_active = 6; */
        if (message.settingsActive !== false)
            writer.tag(6, WireType.Varint).bool(message.settingsActive);
        /* optional string settings_defined_alarm_group_uuid = 11; */
        if (message.settingsDefinedAlarmGroupUuid !== undefined)
            writer.tag(11, WireType.LengthDelimited).string(message.settingsDefinedAlarmGroupUuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.DefinedAlarmView
 */
export const DefinedAlarmView = new DefinedAlarmView$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DefinedAlarmData$Type extends MessageType<DefinedAlarmData> {
    constructor() {
        super("defined_alarms.DefinedAlarmData", [
            { no: 1, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "color", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "event_level_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DefinedAlarmData>): DefinedAlarmData {
        const message = { description: "", eventLevelId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DefinedAlarmData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DefinedAlarmData): DefinedAlarmData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string description */ 1:
                    message.description = reader.string();
                    break;
                case /* optional string color */ 2:
                    message.color = reader.string();
                    break;
                case /* int32 event_level_id */ 3:
                    message.eventLevelId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DefinedAlarmData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string description = 1; */
        if (message.description !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.description);
        /* optional string color = 2; */
        if (message.color !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.color);
        /* int32 event_level_id = 3; */
        if (message.eventLevelId !== 0)
            writer.tag(3, WireType.Varint).int32(message.eventLevelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.DefinedAlarmData
 */
export const DefinedAlarmData = new DefinedAlarmData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DefinedAlarmSettings$Type extends MessageType<DefinedAlarmSettings> {
    constructor() {
        super("defined_alarms.DefinedAlarmSettings", [
            { no: 1, name: "priority", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "sound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "col", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "rule", kind: "enum", T: () => ["defined_alarms.Rule", Rule] },
            { no: 6, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "math", kind: "enum", opt: true, T: () => ["defined_alarms.Math", Math] },
            { no: 8, name: "math_col", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "on_delay", kind: "scalar", opt: true, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "off_delay", kind: "scalar", opt: true, T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "round", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "device_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "math_device_uuid", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "work_state_id", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "defined_alarm_group_uuid", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DefinedAlarmSettings>): DefinedAlarmSettings {
        const message = { priority: 0, active: false, sound: false, col: "", rule: 0, value: "", deviceUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DefinedAlarmSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DefinedAlarmSettings): DefinedAlarmSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 priority */ 1:
                    message.priority = reader.int32();
                    break;
                case /* bool active */ 2:
                    message.active = reader.bool();
                    break;
                case /* bool sound */ 3:
                    message.sound = reader.bool();
                    break;
                case /* string col */ 4:
                    message.col = reader.string();
                    break;
                case /* defined_alarms.Rule rule */ 5:
                    message.rule = reader.int32();
                    break;
                case /* string value */ 6:
                    message.value = reader.string();
                    break;
                case /* optional defined_alarms.Math math */ 7:
                    message.math = reader.int32();
                    break;
                case /* optional string math_col */ 8:
                    message.mathCol = reader.string();
                    break;
                case /* optional double on_delay */ 9:
                    message.onDelay = reader.double();
                    break;
                case /* optional double off_delay */ 10:
                    message.offDelay = reader.double();
                    break;
                case /* optional int32 round */ 11:
                    message.round = reader.int32();
                    break;
                case /* string device_uuid */ 12:
                    message.deviceUuid = reader.string();
                    break;
                case /* optional string math_device_uuid */ 13:
                    message.mathDeviceUuid = reader.string();
                    break;
                case /* optional int32 work_state_id */ 14:
                    message.workStateId = reader.int32();
                    break;
                case /* optional string defined_alarm_group_uuid */ 15:
                    message.definedAlarmGroupUuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DefinedAlarmSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 priority = 1; */
        if (message.priority !== 0)
            writer.tag(1, WireType.Varint).int32(message.priority);
        /* bool active = 2; */
        if (message.active !== false)
            writer.tag(2, WireType.Varint).bool(message.active);
        /* bool sound = 3; */
        if (message.sound !== false)
            writer.tag(3, WireType.Varint).bool(message.sound);
        /* string col = 4; */
        if (message.col !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.col);
        /* defined_alarms.Rule rule = 5; */
        if (message.rule !== 0)
            writer.tag(5, WireType.Varint).int32(message.rule);
        /* string value = 6; */
        if (message.value !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.value);
        /* optional defined_alarms.Math math = 7; */
        if (message.math !== undefined)
            writer.tag(7, WireType.Varint).int32(message.math);
        /* optional string math_col = 8; */
        if (message.mathCol !== undefined)
            writer.tag(8, WireType.LengthDelimited).string(message.mathCol);
        /* optional double on_delay = 9; */
        if (message.onDelay !== undefined)
            writer.tag(9, WireType.Bit64).double(message.onDelay);
        /* optional double off_delay = 10; */
        if (message.offDelay !== undefined)
            writer.tag(10, WireType.Bit64).double(message.offDelay);
        /* optional int32 round = 11; */
        if (message.round !== undefined)
            writer.tag(11, WireType.Varint).int32(message.round);
        /* string device_uuid = 12; */
        if (message.deviceUuid !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.deviceUuid);
        /* optional string math_device_uuid = 13; */
        if (message.mathDeviceUuid !== undefined)
            writer.tag(13, WireType.LengthDelimited).string(message.mathDeviceUuid);
        /* optional int32 work_state_id = 14; */
        if (message.workStateId !== undefined)
            writer.tag(14, WireType.Varint).int32(message.workStateId);
        /* optional string defined_alarm_group_uuid = 15; */
        if (message.definedAlarmGroupUuid !== undefined)
            writer.tag(15, WireType.LengthDelimited).string(message.definedAlarmGroupUuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.DefinedAlarmSettings
 */
export const DefinedAlarmSettings = new DefinedAlarmSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmIn$Type extends MessageType<MessageDefinedAlarmIn> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmIn", [
            { no: 1, name: "defined_alarm_uuid", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", T: () => DefinedAlarmData },
            { no: 3, name: "settings", kind: "message", T: () => DefinedAlarmSettings }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmIn>): MessageDefinedAlarmIn {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmIn>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmIn): MessageDefinedAlarmIn {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string defined_alarm_uuid */ 1:
                    message.definedAlarmUuid = reader.string();
                    break;
                case /* defined_alarms.DefinedAlarmData data */ 2:
                    message.data = DefinedAlarmData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* defined_alarms.DefinedAlarmSettings settings */ 3:
                    message.settings = DefinedAlarmSettings.internalBinaryRead(reader, reader.uint32(), options, message.settings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmIn, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string defined_alarm_uuid = 1; */
        if (message.definedAlarmUuid !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmUuid);
        /* defined_alarms.DefinedAlarmData data = 2; */
        if (message.data)
            DefinedAlarmData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* defined_alarms.DefinedAlarmSettings settings = 3; */
        if (message.settings)
            DefinedAlarmSettings.internalBinaryWrite(message.settings, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmIn
 */
export const MessageDefinedAlarmIn = new MessageDefinedAlarmIn$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmUuid$Type extends MessageType<MessageDefinedAlarmUuid> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmUuid", [
            { no: 1, name: "defined_alarm_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmUuid>): MessageDefinedAlarmUuid {
        const message = { definedAlarmUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmUuid>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmUuid): MessageDefinedAlarmUuid {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_uuid */ 1:
                    message.definedAlarmUuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmUuid, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_uuid = 1; */
        if (message.definedAlarmUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmUuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmUuid
 */
export const MessageDefinedAlarmUuid = new MessageDefinedAlarmUuid$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmGroupUuid$Type extends MessageType<MessageDefinedAlarmGroupUuid> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmGroupUuid", [
            { no: 1, name: "defined_alarm_group_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmGroupUuid>): MessageDefinedAlarmGroupUuid {
        const message = { definedAlarmGroupUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmGroupUuid>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmGroupUuid): MessageDefinedAlarmGroupUuid {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_group_uuid */ 1:
                    message.definedAlarmGroupUuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmGroupUuid, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_group_uuid = 1; */
        if (message.definedAlarmGroupUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmGroupUuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmGroupUuid
 */
export const MessageDefinedAlarmGroupUuid = new MessageDefinedAlarmGroupUuid$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DefinedAlarmGroupData$Type extends MessageType<DefinedAlarmGroupData> {
    constructor() {
        super("defined_alarms.DefinedAlarmGroupData", [
            { no: 1, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "color", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rule", kind: "enum", T: () => ["defined_alarms.GroupRule", GroupRule] },
            { no: 4, name: "event_level_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "object_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DefinedAlarmGroupData>): DefinedAlarmGroupData {
        const message = { description: "", rule: 0, eventLevelId: 0, objectUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DefinedAlarmGroupData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DefinedAlarmGroupData): DefinedAlarmGroupData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string description */ 1:
                    message.description = reader.string();
                    break;
                case /* optional string color */ 2:
                    message.color = reader.string();
                    break;
                case /* defined_alarms.GroupRule rule */ 3:
                    message.rule = reader.int32();
                    break;
                case /* int32 event_level_id */ 4:
                    message.eventLevelId = reader.int32();
                    break;
                case /* string object_uuid */ 5:
                    message.objectUuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DefinedAlarmGroupData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string description = 1; */
        if (message.description !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.description);
        /* optional string color = 2; */
        if (message.color !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.color);
        /* defined_alarms.GroupRule rule = 3; */
        if (message.rule !== 0)
            writer.tag(3, WireType.Varint).int32(message.rule);
        /* int32 event_level_id = 4; */
        if (message.eventLevelId !== 0)
            writer.tag(4, WireType.Varint).int32(message.eventLevelId);
        /* string object_uuid = 5; */
        if (message.objectUuid !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.objectUuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.DefinedAlarmGroupData
 */
export const DefinedAlarmGroupData = new DefinedAlarmGroupData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmGroupIn$Type extends MessageType<MessageDefinedAlarmGroupIn> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmGroupIn", [
            { no: 1, name: "defined_alarm_group_uuid", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", T: () => DefinedAlarmGroupData }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmGroupIn>): MessageDefinedAlarmGroupIn {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmGroupIn>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmGroupIn): MessageDefinedAlarmGroupIn {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string defined_alarm_group_uuid */ 1:
                    message.definedAlarmGroupUuid = reader.string();
                    break;
                case /* defined_alarms.DefinedAlarmGroupData data */ 2:
                    message.data = DefinedAlarmGroupData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmGroupIn, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional string defined_alarm_group_uuid = 1; */
        if (message.definedAlarmGroupUuid !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmGroupUuid);
        /* defined_alarms.DefinedAlarmGroupData data = 2; */
        if (message.data)
            DefinedAlarmGroupData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmGroupIn
 */
export const MessageDefinedAlarmGroupIn = new MessageDefinedAlarmGroupIn$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmGroupsList$Type extends MessageType<MessageDefinedAlarmGroupsList> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmGroupsList", [
            { no: 1, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "defined_alarm_groups", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => DefinedAlarmGroupView }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmGroupsList>): MessageDefinedAlarmGroupsList {
        const message = { total: 0, count: 0, definedAlarmGroups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmGroupsList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmGroupsList): MessageDefinedAlarmGroupsList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 total */ 1:
                    message.total = reader.int32();
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                case /* repeated defined_alarms.DefinedAlarmGroupView defined_alarm_groups */ 3:
                    message.definedAlarmGroups.push(DefinedAlarmGroupView.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmGroupsList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int32(message.total);
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        /* repeated defined_alarms.DefinedAlarmGroupView defined_alarm_groups = 3; */
        for (let i = 0; i < message.definedAlarmGroups.length; i++)
            DefinedAlarmGroupView.internalBinaryWrite(message.definedAlarmGroups[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmGroupsList
 */
export const MessageDefinedAlarmGroupsList = new MessageDefinedAlarmGroupsList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DefinedAlarmGroupView$Type extends MessageType<DefinedAlarmGroupView> {
    constructor() {
        super("defined_alarms.DefinedAlarmGroupView", [
            { no: 1, name: "defined_alarm_group_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "event_level_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "object_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "list_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "list_color", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "groups_temporary_state", kind: "enum", T: () => ["defined_alarms.State", State] },
            { no: 5, name: "groups_temporary_state_datetime", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<DefinedAlarmGroupView>): DefinedAlarmGroupView {
        const message = { definedAlarmGroupUuid: "", eventLevelId: 0, objectUuid: "", listDescription: "", groupsTemporaryState: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DefinedAlarmGroupView>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DefinedAlarmGroupView): DefinedAlarmGroupView {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_group_uuid */ 1:
                    message.definedAlarmGroupUuid = reader.string();
                    break;
                case /* int32 event_level_id */ 7:
                    message.eventLevelId = reader.int32();
                    break;
                case /* string object_uuid */ 10:
                    message.objectUuid = reader.string();
                    break;
                case /* string list_description */ 2:
                    message.listDescription = reader.string();
                    break;
                case /* optional string list_color */ 3:
                    message.listColor = reader.string();
                    break;
                case /* defined_alarms.State groups_temporary_state */ 4:
                    message.groupsTemporaryState = reader.int32();
                    break;
                case /* optional google.protobuf.Timestamp groups_temporary_state_datetime */ 5:
                    message.groupsTemporaryStateDatetime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.groupsTemporaryStateDatetime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DefinedAlarmGroupView, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_group_uuid = 1; */
        if (message.definedAlarmGroupUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmGroupUuid);
        /* int32 event_level_id = 7; */
        if (message.eventLevelId !== 0)
            writer.tag(7, WireType.Varint).int32(message.eventLevelId);
        /* string object_uuid = 10; */
        if (message.objectUuid !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.objectUuid);
        /* string list_description = 2; */
        if (message.listDescription !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.listDescription);
        /* optional string list_color = 3; */
        if (message.listColor !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.listColor);
        /* defined_alarms.State groups_temporary_state = 4; */
        if (message.groupsTemporaryState !== 0)
            writer.tag(4, WireType.Varint).int32(message.groupsTemporaryState);
        /* optional google.protobuf.Timestamp groups_temporary_state_datetime = 5; */
        if (message.groupsTemporaryStateDatetime)
            Timestamp.internalBinaryWrite(message.groupsTemporaryStateDatetime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.DefinedAlarmGroupView
 */
export const DefinedAlarmGroupView = new DefinedAlarmGroupView$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageDefinedAlarmGroupOut$Type extends MessageType<MessageDefinedAlarmGroupOut> {
    constructor() {
        super("defined_alarms.MessageDefinedAlarmGroupOut", [
            { no: 1, name: "defined_alarm_group_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", T: () => DefinedAlarmGroupData }
        ]);
    }
    create(value?: PartialMessage<MessageDefinedAlarmGroupOut>): MessageDefinedAlarmGroupOut {
        const message = { definedAlarmGroupUuid: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageDefinedAlarmGroupOut>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageDefinedAlarmGroupOut): MessageDefinedAlarmGroupOut {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defined_alarm_group_uuid */ 1:
                    message.definedAlarmGroupUuid = reader.string();
                    break;
                case /* defined_alarms.DefinedAlarmGroupData data */ 2:
                    message.data = DefinedAlarmGroupData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageDefinedAlarmGroupOut, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defined_alarm_group_uuid = 1; */
        if (message.definedAlarmGroupUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.definedAlarmGroupUuid);
        /* defined_alarms.DefinedAlarmGroupData data = 2; */
        if (message.data)
            DefinedAlarmGroupData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message defined_alarms.MessageDefinedAlarmGroupOut
 */
export const MessageDefinedAlarmGroupOut = new MessageDefinedAlarmGroupOut$Type();
/**
 * @generated ServiceType for protobuf service defined_alarms.DefinedAlarmsService
 */
export const DefinedAlarmsService = new ServiceType("defined_alarms.DefinedAlarmsService", [
    { name: "Get", options: {}, I: MessageDefinedAlarmUuid, O: MessageDefinedAlarmOut },
    { name: "GetList", options: {}, I: MessageTableRequest, O: MessageDefinedAlarmsList },
    { name: "GetListGroups", options: {}, I: MessageTableRequest, O: MessageDefinedAlarmGroupsList },
    { name: "GetGroup", options: {}, I: MessageDefinedAlarmGroupUuid, O: MessageDefinedAlarmGroupOut },
    { name: "GetByVisualizationAlias", options: {}, I: MessageVisualizationAlias, O: MessageDefinedAlarmUuid },
    { name: "Live", serverStreaming: true, options: {}, I: MessageLive, O: DefinedAlarmView },
    { name: "Store", options: {}, I: MessageDefinedAlarmIn, O: MessageDefinedAlarmUuid },
    { name: "StoreGroup", options: {}, I: MessageDefinedAlarmGroupIn, O: MessageDefinedAlarmGroupUuid },
    { name: "RemoveGroup", options: {}, I: MessageDefinedAlarmGroupUuid, O: Empty },
    { name: "UpdateDefinedAlarmAlias", options: {}, I: MessageUpdateDefinedAlarmAlias, O: Empty },
    { name: "UpdateDefinedAlarmGroupAlias", options: {}, I: MessageUpdateDefinedAlarmGroupAlias, O: Empty }
]);
