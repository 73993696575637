import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useMountedLogic, useValues } from 'kea';
import { InputSwitch } from 'primereact/inputswitch';
import { FC } from 'react';

import { IEditorLogic } from '@/logic/interfaces/editor';
import { IEditorFieldLogic } from '@/logic/interfaces/editorField';
import { EditorFieldKey } from '@/types/custom/editorTypes';

interface InputSwitchProps {
  name: string;
  editorLogic: IEditorLogic;
  editorKey: EditorFieldKey;
}

export const InputSwitchEditor: FC<InputSwitchProps> = (props) => {
  const editorLogic = useMountedLogic(props.editorLogic);

  const fieldLogic = useInjection(IEditorFieldLogic.$)({
    editorLogic: editorLogic as BuiltLogic<IEditorLogic>,
    editorKey: props.editorKey,
  });

  const { updateValue } = useActions(fieldLogic);
  const { value } = useValues(fieldLogic);

  return (
    <div className="OCS-inputswitch-editor">
      <label className="OCS-editor-value">{props.name}</label>
      <InputSwitch
        checked={value}
        onChange={(e) => {
          updateValue({ value: e.value });
        }}
      />
    </div>
  );
};
