import { IPaginationLogic } from '@ocs/ocs-components';
import { interfaces } from 'inversify';

import {
  IDeviceTableLogic,
  IGenericDeviceChartLogic,
  IGenericDeviceChartLogicProps,
  IGenericDeviceEditorLogic,
  IGenericDeviceEditorProps,
  IHeaderSearchLogic,
  IHubsEditorLogic,
  IHubsEditorProps,
  IObjectChartLogicNamed,
  IObjectEditorLogic,
  IObjectEditorProps,
  IObjectTableLogic,
} from '@/logic/interfaces';
import {
  IDefinedAlarmsEditorLogic,
  IDefinedAlarmsEditorProps,
} from '@/logic/interfaces/definedAlarmsEditor';
import { IDefinedAlarmsTableLogic } from '@/logic/interfaces/definedAlarmsTable';
import {
  IGroupAlarmsAddFormLogic,
  IGroupAlarmsAddFormProps,
} from '@/logic/interfaces/groupAlarmsAddForm';
import {
  IObjectWindowLogic,
  IObjectWindowLogicProps,
} from '@/logic/interfaces/objectWindow';
import {
  IVibrodetectorChartLogic,
  IVibrodetectorChartLogicProps,
} from '@/logic/interfaces/vibroDetectorChart';
import {
  IVibrodetectorEditorLogic,
  IVibrodetectorEditorProps,
} from '@/logic/interfaces/vibrodetectorEditor';
import {
  FooterPaginator,
  PaginatorDropdown,
} from '@/widgets/toolBar/footerPaginator';
import { HeaderSearchbar } from '@/widgets/toolBar/headerSearchBar';
import { TableRefreshButton } from '@/widgets/toolBar/tableRefreshButton';

import {
  GenericDeviceChart,
  GenericDeviceChartFooterLeft,
} from './charts/genericDevice';
import {
  ObjectChartNamed,
  ObjectChartNamedFooterCenter,
  ObjectChartNamedFooterLeft,
  ObjectChartNamedFooterRight,
} from './charts/object';
import {
  VibrodetectorChart,
  VibrodetectorChartFooterCenter,
  VibrodetectorChartFooterLeft,
  VibrodetectorChartFooterRight,
  VibrodetectorChartHeaderLeft,
  VibrodetectorChartHeaderRight,
} from './charts/vibrodetector';
import { DefinedAlarmsDatatable } from './datatables/definedAlarms';
import { DeviceDatatable } from './datatables/devices';
import { ObjectDatatable } from './datatables/objects';
import { ModuleFactory } from './factory';
import { DefinedAlarmsEditor } from './forms/definedAlarms/definedAlarmsEdit';
import {
  DefinedAlarmsConfirmButton,
  DefinedAlarmsResetButton,
} from './forms/definedAlarms/footer/definedAlarmFooter';
import { GenericDeviceEditor } from './forms/genericDevice';
import { GenericDeviceEditorConfirmButton } from './forms/genericDevice/footer';
import { GroupAlarmsAddForm } from './forms/groupAdd';
import {
  AddGroupConfirmButton,
  AddGroupRemoveButton,
} from './forms/groupAdd/footer';
import { HubsEditor } from './forms/hubs';
import { HubsEditorConfirmButton } from './forms/hubs/footer';
import { ObjectEditor } from './forms/object';
import { ObjectEditorConfirmButton } from './forms/object/footer';
import { VibrodetectorEditor } from './forms/vibrodetector';
import { VibrodetectorEditorConfirmButton } from './forms/vibrodetector/footer';
import {
  ObjectWindowPaginator,
  ObjectWindowPaginatorDropdown,
  ObjectWindowRefreshButton,
} from './object/footer';
import { ObjectWindow, ObjectWindowHeaderLeft } from './object/window';
import {
  GenericDeviceChartSaveToImageButton,
  GraphDeviceTreeSelect,
  ObjectChartDatePicker,
  ObjectChartLiveButton,
  ObjectChartLoadButton,
  ObjectChartSaveToCSVButton,
  ObjectOpenInNewChartButton,
  ObjectWorkStateSelectButton,
} from './object/window/graph';
import { Settings } from './settings';
import { VisualizationsViewWindow } from './visualizationsView/window';

export const modules = {
  users: ModuleFactory.createModule({
    taskbarData: { icon: 'ri-team-line', title: 'windowTypes.users' },
    defaultWindowData: {},
    content: () => <div></div>,
  }),
  settings: ModuleFactory.createModule({
    taskbarData: { icon: 'ri-team-line', title: 'windowTypes.settings' },
    defaultWindowData: {
      windowKey: 'settings',
      title: 'windowTypes.settings',
      icon: 'ri-settings-4-fill',
      geometry: {
        size: { width: 1000, height: 750 },
        minWidth: 850,
        minHeight: 200,
      },
    },
    content: () => <Settings />,
  }),
  devices: ModuleFactory.createModule({
    taskbarData: { icon: 'ri-team-line', title: 'windowTypes.devices' },
    defaultWindowData: {
      windowKey: 'devices',
      title: 'windowTypes.devices',
      icon: 'ri-briefcase-4-fill',
    },
    headerContent: {
      left: [
        <HeaderSearchbar
          key="devices-searchbar-header-left"
          logicIdentifier={IDeviceTableLogic.$ as IHeaderSearchLogic}
        />,
        // <FilterButton key="devices-filter-header-left" />,
      ],
    },
    content: () => <DeviceDatatable />,
    footerContent: {
      left: [
        <PaginatorDropdown
          logicIdentifier={IDeviceTableLogic.$ as IPaginationLogic}
          key={'devices-dropdown-footer-left'}
        />,
        <TableRefreshButton
          logicIdentifier={IDeviceTableLogic.$ as IPaginationLogic}
          key={'devices-refresh-footer-left'}
        />,
      ],
      center: [
        <FooterPaginator
          logicIdentifier={IDeviceTableLogic.$ as IPaginationLogic}
          key={'devices-paginator-footer-center'}
        />,
      ],
    },
  }),
  objects: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri ri-building-4-fill',
      title: 'windowTypes.objects',
    },
    defaultWindowData: {
      windowKey: 'objects',
      title: 'windowTypes.objects',
      icon: 'ri ri-building-4-fill',
    },
    headerContent: {
      left: [
        <HeaderSearchbar
          key="objects-searchbar-header-left"
          logicIdentifier={IObjectTableLogic.$ as IHeaderSearchLogic}
        />,
        // <FilterButton key="objects-filter-header-left" />,
      ],
    },
    content: () => <ObjectDatatable />,
    footerContent: {
      left: [
        <PaginatorDropdown
          logicIdentifier={IObjectTableLogic.$ as IPaginationLogic}
          key={'objects-dropdown-footer-left'}
        />,
        <TableRefreshButton
          logicIdentifier={IObjectTableLogic.$ as IPaginationLogic}
          key={'objects-refresh-footer-left'}
        />,
      ],
      center: [
        <FooterPaginator
          logicIdentifier={IObjectTableLogic.$ as IPaginationLogic}
          key={'objects-paginator-footer-center'}
        />,
      ],
    },
  }),
  objectChartNamed: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri ri-building-4-fill',
      title: 'windowTypes.objects',
    },
    defaultWindowData: { icon: 'ri ri-building-4-fill' },
    footerContent: {
      left: ObjectChartNamedFooterLeft,
      center: ObjectChartNamedFooterCenter,
      right: ObjectChartNamedFooterRight,
    },
    content: () => <ObjectChartNamed />,
    logicData: {
      logicIdentifier: IObjectChartLogicNamed.$,
      logicPropsValidator: (props): props is any => {
        return (
          typeof props == 'object' &&
          props != null &&
          'objectUuid' in props &&
          'name' in props
        );
      },
    },
  }),
  definedAlarms: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri-notification-2-fill',
      title: 'windowTypes.definedAlarms',
    },
    defaultWindowData: {
      windowKey: 'definedAlarms',
      title: 'windowTypes.definedAlarms',
      icon: 'ri-notification-2-fill',
    },
    logicData: {
      logicIdentifier: IDefinedAlarmsTableLogic.$,
    },
    headerContent: {
      left: [
        <HeaderSearchbar
          key="definedAlarms-searchbar-header-left"
          logicIdentifier={IDefinedAlarmsTableLogic.$ as IHeaderSearchLogic}
        />,
        // <FilterButton key="definedAlarms-filter-header-left" />,
      ],
    },
    content: () => <DefinedAlarmsDatatable />,
    footerContent: {
      left: [
        <PaginatorDropdown
          logicIdentifier={IDefinedAlarmsTableLogic.$ as IPaginationLogic}
          key={'definedAlarms-paginatorDropdown-footer-left'}
          isDefinedAlarms={true}
        />,
        <TableRefreshButton
          logicIdentifier={IDefinedAlarmsTableLogic.$ as IPaginationLogic}
          key={'definedAlarms-refresh-footer-left'}
          isDefinedAlarms={true}
        />,
      ],
      center: [
        <FooterPaginator
          logicIdentifier={IDefinedAlarmsTableLogic.$ as IPaginationLogic}
          key={'definedAlarms-paginator-footer-center'}
          isDefinedAlarms={true}
        />,
      ],
    },
  }),
  definedAlarmsEdit: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri-edit-2-fill',
      title: 'windowTypes.definedAlarmsEdit',
    },
    defaultWindowData: {
      icon: 'ri-edit-2-fill',
    },
    logicData: {
      logicIdentifier: IDefinedAlarmsEditorLogic.$,
      logicPropsValidator: (
        props,
      ): props is IDefinedAlarmsEditorProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'editorUuid') &&
          typeof (props as Record<string, unknown>).editorUuid == 'string'
        );
      },
    },
    content: () => <DefinedAlarmsEditor />,
    footerContent: {
      right: [
        <DefinedAlarmsConfirmButton
          key={'defined-alarm-editor-acceptButton'}
        />,
        <DefinedAlarmsResetButton key={'defined-alarm-editor-resetButton'} />,
      ],
    },
  }),
  alarmsGroupAddForm: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri-add-box-line',
      title: 'windowTypes.alarmsGroupAddForm',
    },
    defaultWindowData: {
      icon: 'ri-add-box-line',
      geometry: {
        size: { width: 420, height: 500 },
        minWidth: 420,
        minHeight: 500,
      },
    },
    logicData: {
      logicIdentifier: IGroupAlarmsAddFormLogic.$,
      logicPropsValidator: (
        props,
      ): props is IGroupAlarmsAddFormProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'objectUuid') &&
          typeof (props as Record<string, unknown>).objectUuid == 'string'
        );
      },
    },
    content: () => <GroupAlarmsAddForm />,
    footerContent: {
      right: [
        <AddGroupConfirmButton key="add-group-form-confirm-button" />,
        <AddGroupRemoveButton key="add-group-form-remove-button" />,
      ],
    },
  }),
  vibrodetectorChart: ModuleFactory.createModule({
    taskbarData: { icon: 'ri-pulse-fill', title: 'windowTypes.vibrodetector' },
    defaultWindowData: {
      icon: 'ri-pulse-fill',
    },
    logicData: {
      logicIdentifier: IVibrodetectorChartLogic.$,
      logicPropsValidator: (
        props,
      ): props is IVibrodetectorChartLogicProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'deviceUuid') &&
          typeof (props as Record<string, unknown>).deviceUuid == 'string' &&
          (props as Record<string, unknown>).deviceUuid != ''
        );
      },
    },
    headerContent: {
      left: VibrodetectorChartHeaderLeft,
      // center: VibrodetectorChartHeaderCenter,
      right: VibrodetectorChartHeaderRight,
    },
    footerContent: {
      left: VibrodetectorChartFooterLeft,
      center: VibrodetectorChartFooterCenter,
      right: VibrodetectorChartFooterRight,
    },
    content: () => <VibrodetectorChart />,
  }),
  genericDeviceChart: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri-sensor-line',
      title: 'windowTypes.genericDeviceChart',
    },
    logicData: {
      logicIdentifier: IGenericDeviceChartLogic.$,
      logicPropsValidator: (
        props,
      ): props is IGenericDeviceChartLogicProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'deviceUuid') &&
          typeof (props as Record<string, unknown>).deviceUuid == 'string' &&
          (props as Record<string, unknown>).deviceUuid != ''
        );
      },
    },
    headerContent: {
      // left: GenericDeviceChartHeaderLeft,
      //center: GenericDeviceChartHeaderCenter,
      // right: GenericDeviceChartHeaderRight,
    },
    footerContent: {
      left: GenericDeviceChartFooterLeft,
      // center: GenericDeviceChartFooterCenter,
      // right: GenericDeviceChartFooterRight,
    },
    defaultWindowData: {
      icon: 'ri-sensor-line',
    },
    content: () => <GenericDeviceChart />,
  }),
  objectWindow: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri-projector-fill',
      title: 'windowTypes.objectView',
    },
    defaultWindowData: {
      icon: 'ri-projector-fill',
      geometry: {
        size: { width: 1150, height: 380 },
        minWidth: 950,
        minHeight: 150,
      },
    },
    headerContent: {
      left: [
        {
          component: <ObjectWindowHeaderLeft key="ObjectWindowHeaderLeft" />,
        },
      ],
    },
    logicData: {
      logicIdentifier: IObjectWindowLogic.$,
      logicPropsValidator: (
        props,
      ): props is IObjectWindowLogicProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'objectUuid') &&
          typeof (props as Record<string, unknown>).objectUuid == 'string' &&
          (props as Record<string, unknown>).objectUuid != ''
        );
      },
    },
    content: () => <ObjectWindow />,
    footerContent: {
      left: [
        <ObjectWindowPaginatorDropdown
          key={'object-window-footer-paginatorDropdown'}
        />,
        <ObjectWindowRefreshButton key="object-window-footer-refreshTable" />,
        <GraphDeviceTreeSelect key="GraphDeviceTreeSelect" />,
        <ObjectChartDatePicker key="ObjectChartDatePicker" />,
        <ObjectChartLoadButton key="ObjectChartLoadButton" />,
        <ObjectChartLiveButton key="ObjectChartLiveButton" />,
        <ObjectWorkStateSelectButton key="ObjectWorkStateSelectButton" />,
        <GenericDeviceChartSaveToImageButton key="GenericDeviceChartSaveToImageButton" />,
        <ObjectChartSaveToCSVButton key="ObjectChartSaveToCSVButton" />,
        <ObjectOpenInNewChartButton key="ObjectOpenInNewChartButton" />,
      ],
      center: [<ObjectWindowPaginator key="object-window-footer-paginator" />],
    },
  }),
  visualizationsView: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri-image-line',
      title: 'windowTypes.visualizationsView',
    },
    defaultWindowData: {
      windowKey: 'visualizationsView',
      icon: 'ri-image-line',
      title: 'windowTypes.visualizationsView',
      geometry: {
        size: { width: 1150, height: 380 * 2 },
      },
    },
    headerContent: {},
    footerContent: {},
    content: () => <VisualizationsViewWindow />,
  }),
  vibrodetectorEditor: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri ri-edit-2-fill',
      title: 'windowTypes.vibrodetectorEditor',
    },
    defaultWindowData: {
      icon: 'ri ri-edit-2-fill',
      geometry: {
        size: { width: 420, height: 500 },
        minWidth: 420,
        minHeight: 500,
      },
    },
    logicData: {
      logicIdentifier: IVibrodetectorEditorLogic.$,
      logicPropsValidator: (
        props,
      ): props is IVibrodetectorEditorProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'deviceUuid') &&
          typeof (props as Record<string, unknown>).deviceUuid == 'string'
        );
      },
    },
    content: () => <VibrodetectorEditor />,
    footerContent: {
      right: [
        <VibrodetectorEditorConfirmButton key="vibrodetector-editor-confirm-button" />,
      ],
    },
  }),
  genericDeviceEditor: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri ri-edit-2-fill',
      title: 'windowTypes.genericDeviceEditor',
    },
    defaultWindowData: {
      icon: 'ri ri-edit-2-fill',
      geometry: {
        size: { width: 420, height: 500 },
        minWidth: 420,
        minHeight: 500,
      },
    },
    logicData: {
      logicIdentifier: IGenericDeviceEditorLogic.$,
      logicPropsValidator: (
        props,
      ): props is IGenericDeviceEditorProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'deviceUuid') &&
          typeof (props as Record<string, unknown>).deviceUuid == 'string'
        );
      },
    },
    content: () => <GenericDeviceEditor />,
    footerContent: {
      right: [
        <GenericDeviceEditorConfirmButton key="generic-device-editor-confirm-button" />,
      ],
    },
  }),
  objectEditor: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri ri-edit-2-fill',
      title: 'windowTypes.objectEditor',
    },
    defaultWindowData: {
      icon: 'ri ri-edit-2-fill',
      geometry: {
        size: { width: 450, height: 600 },
        minWidth: 450,
        minHeight: 600,
      },
    },
    logicData: {
      logicIdentifier: IObjectEditorLogic.$,
      logicPropsValidator: (
        props,
      ): props is IObjectEditorProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'objectUuid') &&
          typeof (props as Record<string, unknown>).objectUuid == 'string'
        );
      },
    },
    content: () => <ObjectEditor />,
    footerContent: {
      right: [<ObjectEditorConfirmButton key="object-editor-confirm-button" />],
    },
  }),
  hubsEditor: ModuleFactory.createModule({
    taskbarData: {
      icon: 'ri ri-edit-2-fill',
      title: 'windowTypes.hubsEditor',
    },
    defaultWindowData: {
      icon: 'ri ri-edit-2-fill',
      geometry: {
        size: { width: 420, height: 500 },
        minWidth: 420,
        minHeight: 500,
      },
    },
    logicData: {
      logicIdentifier: IHubsEditorLogic.$,
      logicPropsValidator: (
        props,
      ): props is IHubsEditorProps['initialData'] => {
        return (
          typeof props == 'object' &&
          props != null &&
          Object.prototype.hasOwnProperty.call(props, 'deviceUuid') &&
          typeof (props as Record<string, unknown>).deviceUuid == 'string'
        );
      },
    },
    content: () => <HubsEditor />,
    footerContent: {
      right: [
        <HubsEditorConfirmButton key="generic-device-editor-confirm-button" />,
      ],
    },
  }),
};

export type IModules = typeof modules;
export type IModulesKeys = keyof IModules | keyof IModules;

export namespace IModules {
  export const $: interfaces.ServiceIdentifier<IModules> = Symbol('IModules');
}
