import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { memo, useCallback, useEffect, useState } from 'react';

export interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
}

export const DateRangePicker = memo(function DateRangePicker(
  props: DateRangePickerProps,
) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    let mounted = true;
    const interval = setInterval(() => {
      if (mounted) {
        setCurrentTime(new Date());
      }
    }, 1000);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, []);

  const onStartDateChange = useCallback(
    (e: CalendarChangeParams) => {
      if (e.value != null && typeof e.value !== 'string') {
        if (!Array.isArray(e.value)) {
          props.onStartDateChange(e.value);
        }
      }
    },
    [props],
  );

  const onEndDateChange = useCallback(
    (e: CalendarChangeParams) => {
      if (e.value != null && typeof e.value !== 'string') {
        if (!Array.isArray(e.value)) {
          props.onEndDateChange(e.value);
        }
      }
    },
    [props],
  );

  return (
    <div className="date-range-picker">
      <Calendar
        onChange={onStartDateChange}
        value={props.startDate}
        showTime
        maxDate={currentTime}
        dateFormat="dd/mm/yy"
      />
      <Calendar
        onChange={onEndDateChange}
        value={props.endDate}
        showTime
        maxDate={currentTime}
        dateFormat="dd/mm/yy"
      />
    </div>
  );
});
