/* eslint-disable react/display-name */
import { FC, memo, useEffect, useMemo } from 'react';

import { useStorybookTranslation } from '@/i18next';
import { EventView } from '@/types/eventView';
import { darkenColor, isColorDark } from '@/utils/colorUtils';

import { EventViewContent } from '../content';
import './style.scss';

type EventViewButton = {
  icon: string;
  onClick: () => void;
};

type EventViewChildren = {
  children: JSX.Element;
  color: string;
};

export interface EventViewContainerProps {
  className?: string;

  event?: EventView;
  children?: EventViewChildren;

  ripple?: boolean;
  disableSelection?: boolean;
  closable?: boolean;
  backgroundOnClick?: () => void;

  buttons?: EventViewButton[];

  boxSize?: 'o-box-none' | 'o-box-sm' | 'o-box-md' | 'o-box-lg';
  textSize?: 'o-text-sm' | 'o-text-md' | 'o-text-lg';
}

export const EventViewContainer: FC<EventViewContainerProps> = memo((props) => {
  const t = useStorybookTranslation();

  useEffect(() => {
    if (
      (props.event?.details == null && props.children == null) ||
      (props.event?.details != null && props.children != null)
    )
      throw new Error('Only event or children should be given');
  }, []);

  const color = useMemo(() => {
    let color = '';

    if (props.event != null) color = props.event.color;
    else if (props.children != null) color = props.children.color;

    return color;
  }, [props.event?.color, props.children?.color]);

  const ratio = useMemo(() => {
    const isDark = isColorDark(color);
    return isDark ? 2 : 0.5;
  }, [color]);

  const borderColor = useMemo(() => darkenColor(color, ratio), [color, ratio]);

  const textColor = useMemo(
    () => darkenColor(borderColor, ratio),
    [color, ratio],
  );

  const buttons = useMemo(() => {
    let tempButtons: EventViewButton[] = [];

    if (props.buttons != null) tempButtons = [...props.buttons];

    if (props.closable === true) {
      tempButtons.unshift({
        icon: 'ri-close-line',
        onClick: () => {},
      });
    }

    return tempButtons;
  }, [props.buttons]);

  return (
    <div
      onClick={props.backgroundOnClick}
      className={`eventView-container 
      ${props.ripple ? 'p-ripple' : ''} 
      ${props.className ? props.className : ''}`}
      style={{
        backgroundColor: color,
        borderColor: borderColor,
        color: textColor,
      }}
    >
      <EventViewContent
        event={props.event}
        children={props.children?.children}
        disableSelection={props.disableSelection}
        ripple={props.ripple}
        closable={props.closable}
        boxSize={props.boxSize}
        textSize={props.textSize}
      />
      <div className="eventView-buttons">
        {buttons?.map((currentElement, index) => {
          return (
            <EventViewButton
              key={`eventViewButton${index}`}
              icon={currentElement.icon}
              color={textColor}
              onClick={currentElement.onClick}
            />
          );
        })}
      </div>
    </div>
  );
});

EventViewContainer.defaultProps = {
  closable: true,
  boxSize: 'o-box-lg',
  textSize: 'o-text-lg',
};

interface EventViewButtonProps {
  icon: string;
  color: string;
  onClick: () => void;
}

const EventViewButton: FC<EventViewButtonProps> = memo((props) => {
  return (
    <button
      type="button"
      className={`eventView-close p-link`}
      onClick={props.onClick}
    >
      <span className={props.icon} style={{ color: props.color }} />
    </button>
  );
});
