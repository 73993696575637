import { produce } from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { DeviceConverters } from '@/converters/device';
import { Dependencies } from '@/deps';
import { IDeviceViewContainerLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { MDevice } from '@/types/models';

import type { logicType } from './indexType';

const logic = kea<logicType>([
  path(['devices', 'container', 'details']),
  props(
    {} as {
      deps: {
        deviceViewContainerLogic: IDeviceViewContainerLogic;
        deviceConverters: DeviceConverters;
      };
    },
  ),
  actions({
    startGarbageCollection: true,
    stopGarbageCollection: true,
    addDevices: (devices: (MDevice.Detail | MDevice.Full)[]) => ({
      devices,
    }),
    useDevices: (deviceIds: string[]) => ({ deviceIds }),
    releaseDevices: (deviceIds: string[]) => ({ deviceIds }),
    addDetails: (details: MDevice.Detail[]) => ({ details }),
    releaseDetails: (detailIds: string[]) => ({ detailIds }),
    removeDetails: (detailIds: string[]) => ({ detailIds }),
    useDetails: (detailIds: string[]) => ({ detailIds }),
  }),
  reducers({
    deviceDetails: [
      new Map() as Map<string, MDevice.Detail>,
      {
        addDetails: (immutableState, { details }) =>
          produce(immutableState, (state) => {
            for (const detail of details) {
              state.set(detail.deviceUuid, detail);
            }
          }),
        removeDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              if (detailId in state) {
                state.delete(detailId);
              }
            }
          }),
      },
    ],
    usageCounters: [
      new Map() as Map<
        string,
        {
          counter: number;
          lastUseTime: Date;
        }
      >,
      {
        addDetails: (immutableState, { details }) =>
          produce(immutableState, (state) => {
            for (const detail of details) {
              state.set(detail.deviceUuid, {
                counter: 0,
                lastUseTime: new Date(),
              });
            }
          }),
        useDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              const detailCounter = state.get(detailId);
              if (detailCounter != null) {
                state.set(detailId, {
                  counter: detailCounter.counter + 1,
                  lastUseTime: new Date(),
                });
              }
              throw Error('Using an device detail not present in container');
            }
          }),
        releaseDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              const detailCounter = state.get(detailId);
              if (detailCounter != null) {
                state.set(detailId, {
                  counter: detailCounter.counter - 1,
                  lastUseTime: new Date(),
                });
              }
              throw Error('Releasing an device not present in container');
            }
          }),
        removeDetails: (immutableState, { detailIds }) =>
          produce(immutableState, (state) => {
            for (const detailId of detailIds) {
              if (state.has(detailId)) {
                state.delete(detailId);
              }
            }
          }),
      },
    ],
    collectingGarbage: [
      false as boolean,
      {
        startGarbageCollection: () => true,
        stopGarbageCollection: () => false,
      },
    ],
  }),
  selectors(({ props }) => ({
    deviceViews: [
      () => [props.deps.deviceViewContainerLogic.selectors.devices],
      (deviceViews) => deviceViews,
    ],
    devices: [
      (selectors) => [selectors.deviceDetails, selectors.deviceViews],
      (deviceDetails, deviceViews) => {
        const devices: Record<string, MDevice.Full> = {};
        deviceDetails.forEach((deviceDetail, deviceKey) => {
          const deviceView = deviceViews[deviceKey];
          devices[deviceKey] = props.deps.deviceConverters.mergeViewAndDetails(
            deviceView,
            deviceDetail,
          );
        });
        return devices;
      },
    ],
  })),
  listeners(({ props, values, actions }) => ({
    addDevices: ({ devices }) => {
      const deviceDetails: MDevice.Detail[] = [];
      const deviceViews: MDevice.View[] = [];
      for (const device of devices) {
        if ('base' in device) {
          const [deviceView, deviceDetail] =
            props.deps.deviceConverters.splitDeviceFull(device);
          deviceDetails.push(deviceDetail);
          deviceViews.push(deviceView);
        } else {
          if (!(device.deviceUuid in values.deviceViews)) {
            throw Error(
              'Adding device details without view present in view container',
            );
          }
          deviceDetails.push(device);
        }
      }
      props.deps.deviceViewContainerLogic.actions.addDevices(deviceViews);
      props.deps.deviceViewContainerLogic.actions.useDevices(
        deviceViews.map((device) => device.deviceUuid),
      );
      actions.addDetails(deviceDetails);
    },
    useDevices: ({ deviceIds }) => {
      actions.useDetails(deviceIds);
    },
    releaseDevices: ({ deviceIds }) => {
      actions.releaseDetails(deviceIds);
    },
    startGarbageCollection: async (_, breakpoint) => {
      breakpoint();
      while (values.collectingGarbage) {
        const currentTime = new Date();
        const deviceIdsToRemove: string[] = [];
        values.usageCounters.forEach((usageCounter, objectKey) => {
          if (
            usageCounter.counter <= 0 &&
            currentTime.getTime() - usageCounter.lastUseTime.getTime() >= 5000
          ) {
            deviceIdsToRemove.push(objectKey);
          }
        });

        if (deviceIdsToRemove.length > 0) {
          actions.removeDetails(deviceIdsToRemove);
          props.deps.deviceViewContainerLogic.actions.releaseDevices(
            deviceIdsToRemove,
          );
        }
        await breakpoint(5000);
      }
    },
  })),
  afterMount(({ actions }) => {
    actions.startGarbageCollection();
  }),
  beforeUnmount(({ actions }) => {
    actions.stopGarbageCollection();
  }),
]);

export const deviceDetailContainerLogic = injectDepsToLogic(logic, () => ({
  deviceViewContainerLogic: Dependencies.get(IDeviceViewContainerLogic.$),
  deviceConverters: new DeviceConverters(),
}));
