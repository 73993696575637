/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { MDefinedAlarms } from '@/types/models/definedAlarms';

import { Response } from '../types/response';
import { IDefinedAlarmsService } from './../interfaces/definedAlarms';
import { ServerStream } from './../stream';
import { BaseService } from './base';

@injectable()
export class DefinedAlarmsService
  extends BaseService
  implements IDefinedAlarmsService
{
  public async getList(
    input: MDefinedAlarms.Messages.GetListIn,
  ): Promise<Response<MDefinedAlarms.Messages.GetListOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmsGetList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getByVisalizationAlias(
    input: MDefinedAlarms.Messages.GetByVisualizationAliasIn,
  ): Promise<Response<MDefinedAlarms.Messages.GetByVisualizationAliasOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmGetByVisualizationAlias(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public stream = new ServerStream((input: MDefinedAlarms.Messages.LiveIn) =>
    BaseService.apiPool.queue(async (worker) => {
      return { observer: worker.definedAlarmsLive(input) };
    }),
  );

  public async getSettings(
    input: MDefinedAlarms.Messages.DefinedAlarmUuid,
  ): Promise<Response<MDefinedAlarms.Messages.StoreOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmsGetSettings(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async store(
    input: MDefinedAlarms.Messages.StoreIn,
  ): Promise<Response<MDefinedAlarms.Messages.StoreOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmsStore(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async updateAlias(
    input: MDefinedAlarms.Messages.UpdateDefinedAlarmAliasIn,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmsUpdateAlias(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getGroupList(
    input: MDefinedAlarms.Messages.GetListGroupIn,
  ): Promise<Response<MDefinedAlarms.Messages.GetListGroupOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmGetGroupList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getGroup(
    input: MDefinedAlarms.Messages.GetGroupOut,
  ): Promise<Response<MDefinedAlarms.Messages.GetGroupOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmsGetGroup(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async storeGroup(
    input: MDefinedAlarms.Messages.StoreGroupIn,
  ): Promise<Response<MDefinedAlarms.Messages.StoreGroupOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmsStoreGroup(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async removeGroup(
    input: MDefinedAlarms.Messages.RemoveGroupIn,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.definedAlarmsRemoveGroup(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
