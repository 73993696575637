import { RpcError, RpcMetadata } from '@protobuf-ts/runtime-rpc';
import { SerializerImplementation } from 'threads';

interface SerializedRpcError {
  rpcError: true;
  message: string;
  code: string;
  meta: RpcMetadata;
  name: string;
  methodName?: string;
  serviceName?: string;
}

function isSerializedRpcError(value: unknown): value is SerializedRpcError {
  return (
    typeof value == 'object' &&
    value != null &&
    'rpcError' in value &&
    (value as { rpcError: unknown }).rpcError == true
  );
}

export const MySerializer: SerializerImplementation = {
  deserialize(message, defaultHandler) {
    if (isSerializedRpcError(message)) {
      const rpcError = new RpcError(
        message.message,
        message.code,
        message.meta,
      );
      rpcError.name = message.name;
      rpcError.methodName = message.methodName;
      rpcError.serviceName = message.serviceName;
      return rpcError;
    }
    return defaultHandler(message) as unknown;
  },
  serialize(thing, defaultHandler) {
    if (thing instanceof RpcError) {
      return {
        rpcError: true,
        message: thing.message,
        code: thing.code,
        meta: thing.meta,
        name: thing.name,
        methodName: thing.methodName,
        serviceName: thing.serviceName,
      };
    }

    return defaultHandler(thing);
  },
};
