import { CompositeDate, Icon, TableValue } from '@ocs/ocs-components';

import { ObjectView as GrpcObjectView } from '@/services/compiled/objects';
import {
  AssignDeviceIn as MAssignDeviceIn,
  DownloadIn as MDownloadIn,
  DownloadOut as MDownloadOut,
  GetGraphWorkStatesIn as MGetGraphWorkStatesIn,
  GetGraphWorkStatesOut as MGetGraphWorkStatesOut,
  GetIn as MGetIn,
  GetListIn as MGetListIn,
  GetListOut as MGetListOut,
  GetOut as MGetOut,
  GetTypesListIn as MGetTypesListIn,
  GetTypesListOut as MGetTypesListOut,
  GetWorkStatesOut as MGetWorkStatesOut,
  LiveWorkStateIn as MLiveWorkStateIn,
  LiveWorkStateOut as MLiveWorkStateOut,
  StoreIn as MStoreIn,
  StoreOut as MStoreOut,
} from '@/types/messages/object';

export namespace MObject {
  export namespace Messages {
    export type GetWorkStatesOut = MGetWorkStatesOut;
    export type GetListIn = MGetListIn;
    export type GetListOut = MGetListOut;
    export type GetTypesListIn = MGetTypesListIn;
    export type GetTypesListOut = MGetTypesListOut;
    export type StoreIn = MStoreIn;
    export type StoreOut = MStoreOut;
    export type AssignDeviceIn = MAssignDeviceIn;
    export type GetIn = MGetIn;
    export type GetOut = MGetOut;
    export type GetGraphWorkStatesIn = MGetGraphWorkStatesIn;
    export type GetGraphWorkStatesOut = MGetGraphWorkStatesOut;
    export type LiveWorkStateIn = MLiveWorkStateIn;
    export type LiveWorkStateOut = MLiveWorkStateOut;
    export type DownloadIn = MDownloadIn;
    export type DownloadOut = MDownloadOut;
  }

  export const TableViewSortKeys: (keyof GrpcObjectView)[] = [
    'objectUuid',
    'listRegion',
    'listTypeId',
    'listCustomer',
    'listCity',
    'listAddress',
    'listName',
    'temporaryLastDataDate',
    'statusPartialNoTransmission',
    'statusTotalNoTransmission',
    'statusDefinedAlarm',
    'statusDefinedAlarmColor',
    'statusHeatmeter',
    'statusPreinsulation',
    'statusAccesscontrol',
    'statusLowBattery',
    'statusWatermeter',
  ];

  export type TableViewSortKeys = keyof GrpcObjectView;

  export interface TableView extends TableValue {
    objectUuid: string;
    listRegion?: string;
    listTypeId?: number;
    listCustomer?: string;
    listCity?: string;
    listAddress?: string;
    listName?: string;
    temporaryLastDataDate?: CompositeDate;
    status: Icon;
  }

  export interface Status {
    partialNoTransmission: boolean;
    totalNoTransmission: boolean;
    definedAlarm: boolean;
    definedAlarmColor?: string;
    heatmeter: boolean;
    preinsulation: boolean;
    accesscontrol: boolean;
    lowBattery: boolean;
    watermeter: boolean;
  }

  export interface Base {
    region?: string;
    typeId?: number;
    customer?: string;
    city?: string;
    address?: string;
    name?: string;
  }

  export interface Temporary {
    lastDataDate?: Date;
    workStateId?: number;
  }

  export interface View {
    objectUuid: string;
    status: Status;
    base: Base;
    temp: Temporary;
  }

  export interface Info {
    note?: string;
  }

  export enum PowerType {
    Mains = 0,
    Battery = 1,
  }

  export interface Settings {
    enabled: boolean;
    lat?: number;
    lon?: number;
    timeout?: number;
    powerType?: PowerType;
    visualizationUuid?: string;
  }

  export interface Detail {
    objectUuid: string;
    deviceUuids: string[];
    info: Info;
    settings: Settings;
    workStateSettings: WorkStateSettings[];
  }

  export interface Type {
    typeId: number;
    name: string;
  }

  export interface Full extends View, Detail {}

  export interface WorkState {
    workStateId: number;
    name: string;
    desc?: string;
    color?: string;
  }

  export interface WorkStateData {
    datetime: Date;
    newWorkStateId?: number;
    previousWorkStateId?: number;
    objectUuid: string;
  }

  export interface WorkStateSettings {
    workStateId: number;
    entryDelay?: number;
  }
}
