import { InputText } from 'primereact/inputtext';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useStorybookTranslation } from '@/i18next';
import { TextFilterType, TextFilterValue } from '@/types/filter';
import { GetValueIndex } from '@/utils/filterUtils';

interface TextFIlterProps {
  textData: TextFilterType;
  filterValue: TextFilterValue[];
  setFilterValue: Dispatch<SetStateAction<TextFilterValue[]>>;
  isNumber?: boolean;
}

export const TextFilter: FC<TextFIlterProps> = (props) => {
  const t = useStorybookTranslation();
  const [filterIndex, setIndex] = useState(-1);

  useEffect(() => {
    const index = GetValueIndex(
      props.filterValue,
      props.textData,
      props.setFilterValue,
      '',
    );

    setIndex(index);
  }, [props]);

  return (
    <div>
      {props.textData.label ? <label>{props.textData.label}</label> : <></>}
      <InputText
        value={
          props.filterValue[filterIndex]?.filteredValue
            ? props.filterValue[filterIndex].filteredValue
            : ''
        }
        onChange={(e) => {
          const newFilterValue = [...props.filterValue];
          newFilterValue[filterIndex].filteredValue = e.target.value;
          props.setFilterValue(newFilterValue);
        }}
        placeholder={
          props.isNumber ? t('table.filters.number') : t('table.filters.text')
        }
      />
    </div>
  );
};
