import { FC } from 'react';

import { ResponsiveTileComponent } from '@/types/custom/responsiveBar';

import { ResponsiveBar } from '../responsiveBar';
import './style.scss';

interface CardFooterProps {
  leftElements?: (JSX.Element | ResponsiveTileComponent)[];
  centerElements?: (JSX.Element | ResponsiveTileComponent)[];
  rightElements?: (JSX.Element | ResponsiveTileComponent)[];
}

export const CardFooter: FC<CardFooterProps> = (props) => {
  return (
    <div className="ocs-card-footer">
      <ResponsiveBar
        leftElements={props.leftElements}
        centerElements={props.centerElements}
        rightElements={props.rightElements}
      />
    </div>
  );
};
