import produce from 'immer';
import {
  actions,
  afterMount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { Dependencies } from '@/deps';
import { IDesktopLogic, IDeviceTableLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IHubsService } from '@/services/interfaces';
import {
  ConfirmStatus,
  EditorTile,
  EditorValue,
  LoadingStatus,
} from '@/types/custom/editorTypes';
import { MHubs } from '@/types/models/hubs';
import { delay } from '@/utility/delay';

import { convertResponseToModel } from './converters';
import type { logicType } from './indexType';

interface HubsLogicProps {
  initialData: {
    deviceUuid: string;
  };
  deps: {
    hubsService: IHubsService;
    deviceTableLogic: IDeviceTableLogic;
    desktopLogic: IDesktopLogic;
  };
}

const logic = kea<logicType>([
  path(['hubs', 'editor']),
  props(
    {} as {
      initialData: {
        deviceUuid: string;
      };
      deps: {
        hubsService: IHubsService;
        deviceTableLogic: IDeviceTableLogic;
        desktopLogic: IDesktopLogic;
      };
    },
  ),
  key((props) => `hubs/${props.initialData.deviceUuid}`),
  actions({
    updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: EditorValue,
    ) => ({
      tileKey,
      tabKey,
      valueKey,
      value,
    }),

    refresh: true,

    confirm: true,
    setConfirmStatus: (status: ConfirmStatus) => ({ status }),

    loadHubs: true,
    setLoadStatus: (status: LoadingStatus) => ({ status }),

    setInitialValue: (
      value: Record<string, Record<string, Record<string, EditorValue>>>,
    ) => ({ value }),

    setDataCopy: (value: MHubs.Messages.GetOut) => ({ value }),
  }),
  reducers({
    value: [
      {} as Record<string, Record<string, Record<string, EditorValue>>>,
      {
        updateValue: (immutableStore, { tileKey, tabKey, valueKey, value }) =>
          produce(immutableStore, (state) => {
            state[tileKey][tabKey][valueKey] = value;
          }),
        setInitialValue: (_, { value }) => value,
      },
    ],
    dataCopy: [
      {} as MHubs.Messages.GetOut,
      {
        setDataCopy: (_, { value }) => value,
      },
    ],
    loadStatus: [
      LoadingStatus.isLoading as LoadingStatus,
      {
        setLoadStatus: (_, { status }) => status,
      },
    ],
    confirmStatus: [
      ConfirmStatus.unknown as ConfirmStatus,
      {
        setConfirmStatus: (_, { status }) => status,
      },
    ],
    isSaving: [
      false,
      {
        confirm: () => true,
        setConfirmStatus: () => false,
      },
    ],
    translationPrefix: ['hubs', {}],
    tileLayout: [
      {
        primaryData: {
          tileLayout: {
            layoutDirection: 'horizontal',
          },
          tabs: {
            primaryData: {
              layoutDirection: 'horizontal',
            },
          },
        },
      } as Record<string, EditorTile>,
      {},
    ],
  }),
  selectors({
    editorUuid: [
      () => [(_, props: HubsLogicProps) => props.initialData.deviceUuid],
      (deviceUuid) => deviceUuid,
    ],
  }),
  listeners(({ props, values, actions }) => ({
    loadHubs: async () => {
      const response = await props.deps.hubsService.get({
        deviceUuid: props.initialData.deviceUuid,
      });

      if (response.success) {
        actions.setInitialValue(convertResponseToModel(response.data));
        actions.setDataCopy(response.data);
        actions.setLoadStatus(LoadingStatus.success);
      } else {
        actions.setLoadStatus(LoadingStatus.failure);
      }
    },
    confirm: async () => {
      const device = values.dataCopy.device;
      const settings = values.dataCopy.settings;

      const response = await props.deps.hubsService.store({
        deviceUuid: props.initialData.deviceUuid,
        device: device
          ? {
              data: device.data
                ? {
                    objectUuid: device.data.objectUuid,
                    name: values.value.primaryData.primaryData.name.value,
                    kind: device.data.kind,
                    parentDeviceUuid: device.data.parentDeviceUuid,
                  }
                : undefined,
              settings: device.settings,
              temporary: device.temporary,
              status: device.status,
            }
          : undefined,
        info: values.dataCopy.info,
        settings: settings
          ? {
              ...settings,
              readTimeSecs: values.value.primaryData.primaryData.readTime.value,
            }
          : undefined,
      });

      if (response.success) {
        actions.setConfirmStatus(ConfirmStatus.success);

        props.deps
          .deviceTableLogic({
            objectUuid: null,
          })
          .actions.refresh();

        await delay(500);
        props.deps.desktopLogic.actions.removeWindow(
          `hubsEditor_${props.initialData.deviceUuid}`,
        );
      }
    },
    refresh: () => {
      actions.setLoadStatus(LoadingStatus.isLoading);
      actions.loadHubs();
    },
  })),
  afterMount(({ actions }) => {
    actions.loadHubs();
  }),
]);

export const hubsEditorLogic = injectDepsToLogic(logic, () => ({
  hubsService: Dependencies.get(IHubsService.$),
  deviceTableLogic: Dependencies.get(IDeviceTableLogic.$),
  desktopLogic: Dependencies.get(IDesktopLogic.$),
}));
