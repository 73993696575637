import { useInjection } from 'inversify-react';
import { useValues } from 'kea';
import { FunctionComponent } from 'react';

import { ILoginLogic } from '@/logic/interfaces/login';
import { LoginCheckStatus } from '@/types/models/user';

import './style.scss';

export const Loader: FunctionComponent = () => {
  const loginLogic = useInjection(ILoginLogic.$);

  const { loginCheckStatus } = useValues(loginLogic);

  return (
    <div>
      <div className="loader">
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
      </div>
      <div className="loader-error">
        {loginCheckStatus == LoginCheckStatus.ERROR && (
          <p className="p-component animated-dots select-none">
            Brak komunikacji z serwerem...
          </p>
        )}
      </div>
    </div>
  );
};
