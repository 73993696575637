/* eslint-disable @typescript-eslint/ban-types */
import { LogicWrapper } from 'kea';
import { FC } from 'react';

import {
  ModuleDefaultWindowData,
  ModuleHeaderFooterData,
  ModuleLogicData,
  ModuleNavbarData,
  ModuleTaskbarData,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ModuleFactory {
  public static createModule<
    DefaultWindowData extends ModuleDefaultWindowData,
    TaskbarData extends ModuleTaskbarData,
    NavbarData extends ModuleNavbarData | undefined,
    ModuleLogic extends LogicWrapper | undefined,
    HeaderData extends ModuleHeaderFooterData | undefined,
    FooterData extends ModuleHeaderFooterData | undefined,
  >(moduleData: {
    content: FC;
    defaultWindowData: DefaultWindowData;
    taskbarData: TaskbarData;
    navbarData?: NavbarData;
    logicData?: ModuleLogicData<ModuleLogic>;
    headerContent?: HeaderData;
    footerContent?: FooterData;
  }): { defaultWindowData: DefaultWindowData; content: FC } & {
    taskbarData: TaskbarData;
  } & (NavbarData extends undefined
      ? { navbarData?: NavbarData }
      : { navbarData: NavbarData }) &
    (ModuleLogic extends undefined
      ? { logicData?: ModuleLogicData<ModuleLogic> }
      : { logicData: ModuleLogicData<ModuleLogic> }) &
    (HeaderData extends undefined
      ? { headerContent?: ModuleHeaderFooterData }
      : { headerContent: ModuleHeaderFooterData }) &
    (FooterData extends undefined
      ? { footerContent?: ModuleHeaderFooterData }
      : { footerContent: ModuleHeaderFooterData }) {
    return {
      content: moduleData.content,
      defaultWindowData: moduleData.defaultWindowData,
      taskbarData: moduleData.taskbarData,
      navbarData: moduleData.navbarData,
      logicData: moduleData.logicData,
      headerContent: moduleData.headerContent,
      footerContent: moduleData.footerContent,
    } as unknown as { defaultWindowData: DefaultWindowData; content: FC } & {
      taskbarData: TaskbarData;
    } & (NavbarData extends undefined ? {} : { navbarData: NavbarData }) &
      (ModuleLogic extends undefined
        ? {}
        : { logicData: ModuleLogicData<ModuleLogic> }) &
      (HeaderData extends undefined
        ? {}
        : { headerContent: ModuleHeaderFooterData }) &
      (FooterData extends undefined
        ? {}
        : { footerContent: ModuleHeaderFooterData });
  }
}
