import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { Card } from 'primereact/card';
import { Slider } from 'primereact/slider';
import { FC } from 'react';

import { useTranslation } from '@/hooks';
import { IAppLogic } from '@/logic/interfaces';
import { ThemeData, Themes, UIthemeDict } from '@/themes';

import './style.scss';

const themesMap = Object.entries(UIthemeDict) as [Themes, ThemeData][];

export const Appearance: FC = () => {
  return (
    <div className="settings-content-container">
      <div className="settings-flex"></div>
      <div className="settings-column">
        <ThemeComponent />
        <FontComponent />
        <div className="settings-bottom-margin">&nbsp;</div>
      </div>
      <div className="settings-flex"></div>
    </div>
  );
};

const ThemeComponent: FC = () => {
  const t = useTranslation();

  const appLogic = useInjection(IAppLogic.$);
  const { theme } = useValues(appLogic);
  const { setTheme } = useActions(appLogic);

  return (
    <div className="theme-settings-container">
      <div className="settings-theme-title">
        <b>{t('settings.visualization.themes')}</b>
      </div>
      <Card>
        <div className="grid">
          {themesMap.map((currTheme) => {
            return (
              <div key={currTheme[0]} className="col-4">
                <div className="theme-data">
                  <div>
                    <img
                      className={
                        theme == currTheme[0]
                          ? 'theme-image selected-theme-image'
                          : 'theme-image'
                      }
                      src={currTheme[1].source.toString()}
                      onClick={() => {
                        setTheme(currTheme[0]);
                      }}
                    />
                  </div>
                  <div>
                    <span>{currTheme[1].name}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

const FontComponent: FC = () => {
  const t = useTranslation();

  const appLogic = useInjection(IAppLogic.$);

  const { fontSize } = useValues(appLogic);
  const { setFontSize } = useActions(appLogic);

  return (
    <div className="font-settings-container">
      <div className="settings-theme-title">
        <b>{t('settings.visualization.font.options')}</b>
      </div>
      <Card>
        <i className="pi pi-cog"></i>
        <div className="font-label">
          <b>{t('settings.visualization.font.size')}</b>
        </div>
        <div className="font-slider">
          <Slider
            value={fontSize}
            min={0.75}
            max={1.5}
            step={0.05}
            onChange={(e) => {
              setFontSize(e.value.valueOf() as number);
            }}
          />
        </div>
      </Card>
    </div>
  );
};
