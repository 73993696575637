import { EditorValue } from '@/types/custom/editorTypes';
import { MDefinedAlarms } from '@/types/models/definedAlarms';

export function convertDataToGrpc(
  data: Record<string, Record<string, EditorValue>>,
): MDefinedAlarms.Data {
  return {
    description: data.data.description.value,
    eventLevelId: data.data.eventLevelId.value,
    color: data.color.color.value,
  };
}

export function convertSettingsToGrpc(
  data: Record<string, Record<string, EditorValue>>,
  advancedSettings: Record<string, Record<string, EditorValue>>,
  deviceUuid: string,
): MDefinedAlarms.Settings {
  return {
    priority: data.data.priority.value,
    active: data.inputSwitch.active.value,
    sound: data.inputSwitch.sound.value,
    col: advancedSettings.advancedSettings.col.value,
    rule: advancedSettings.advancedSettings.rule.value,
    value: advancedSettings.advancedSettings.value.value,
    //math: advancedSettings.advancedSettings.math.value,
    //mathCol: advancedSettings.advancedSettings.mathCol.value,
    onDelay: advancedSettings.advancedSettings.onDelay.value,
    offDelay: advancedSettings.advancedSettings.offDelay.value,
    round: advancedSettings.advancedSettings.round.value,
    deviceUuid: deviceUuid,
    definedAlarmGroupUuid:
      data.data.group.value === '' ? undefined : data.data.group.value,
  };
}
