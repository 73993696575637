import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectItem } from 'primereact/selectitem';
import { FC, RefObject, useMemo } from 'react';

import { useTranslation } from '@/hooks';

import './style.scss';

interface ChartConfirmProps {
  dropdownValue: number | undefined;
  onDropdownChange: (seconds: number | undefined) => void;
  onConfirm: () => void;
  overlayPanel: RefObject<OverlayPanel>;
}

export const ChartConfirmOverlayPanel: FC<ChartConfirmProps> = (props) => {
  const t = useTranslation();

  const options = useMemo(() => {
    return [
      { label: t('chart.csv.noTime'), value: 0 },
      { label: '1s', value: 1 },
      { label: '1m', value: 60 },
      { label: '1h', value: 3600 },
      { label: '6h', value: 21600 },
      { label: '12h', value: 43200 },
      { label: '24h', value: 86400 },
    ];
  }, []);

  return (
    <div className="overlay-panel-container">
      <span className="overlay-panel-message">{t('chart.csv.label')}</span>
      <Dropdown
        optionLabel="label"
        placeholder={options[0].label}
        value={props.dropdownValue}
        options={options}
        onChange={(e) => {
          props.onDropdownChange(e.value as number);
        }}
      />
      <div className="overlay-panel-buttons">
        <Button
          className="p-button-text p-button-sm"
          label={t('chart.csv.cancel')}
          onClick={() => props.overlayPanel.current?.hide()}
        />
        <Button
          className="p-button-sm"
          label={t('chart.csv.confirm')}
          onClick={props.onConfirm}
        />
      </div>
    </div>
  );
};
