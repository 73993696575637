import { EditorValue } from '@/types/custom/editorTypes';
import { MDefinedAlarms } from '@/types/models';

export function convertDataToGrpc(
  data: Record<string, Record<string, EditorValue>>,
  objectUuid: string,
): MDefinedAlarms.GroupData {
  return {
    description: data.groupData.description.value,
    color: data.groupData.color.value,
    rule: data.groupData.rule.value,
    eventLevelId: data.groupData.eventLevelId.value,
    objectUuid: objectUuid,
  };
}

export function convertResponseToModel(
  data: MDefinedAlarms.GroupData,
): Record<string, Record<string, Record<string, EditorValue>>> {
  return {
    groupData: {
      groupData: {
        description: {
          value: data.description,
        },
        rule: {
          value: data.rule,
        },
        eventLevelId: {
          value: data.eventLevelId,
        },
        color: {
          value: data.color,
        },
      },
    },
  };
}
