import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { FC } from 'react';

import { useTranslation } from '@/hooks';
import { IDefinedAlarmsEditorLogic } from '@/logic/interfaces/definedAlarmsEditor';
import { FooterButton } from '@/widgets/toolBar/footerButtons';
import { FooterPopup } from '@/widgets/toolBar/footerPopup';

export const DefinedAlarmsConfirmButton: FC = () => {
  const t = useTranslation();
  const editorLogic = useInjection(IDefinedAlarmsEditorLogic.$);

  const { confirm } = useActions(editorLogic);
  const { isSaving } = useValues(editorLogic);

  return (
    <FooterPopup
      popupMessage={t('editor.footer.confirmMessage')}
      popupIcon={'pi pi-check-circle'}
      buttonLabel={t('editor.footer.confirm')}
      accept={confirm}
      acceptLabel={t('editor.footer.acceptLabel')}
      rejectLabel={t('editor.footer.rejectLabel')}
      disabled={isSaving}
      buttonIcon={'ri ri-check-line'}
    />
  );
};

export const DefinedAlarmsResetButton: FC = () => {
  const t = useTranslation();

  const editorLogic = useInjection(IDefinedAlarmsEditorLogic.$);

  const { resetToInitialValues } = useActions(editorLogic);
  const { isSaving } = useValues(editorLogic);

  return (
    <FooterButton
      label={t('editor.footer.reset')}
      onClick={resetToInitialValues}
      disabled={isSaving}
      icon={'ri ri-restart-line'}
    />
  );
};
