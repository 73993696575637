const __config = {
  animationTime: 500,
  desktop: {
    maxZIndex: 500,
    syncDelay: 500,
  },
  events: {
    maxDisplayedEvents: 100,
  },
  tooltip: {
    showDelay: 600,
    zIndex: 2001,
    appendTo: document.getElementById('overlays'),
  },
};

export const config: Readonly<typeof __config> = __config;
