import { deepEqual } from 'fast-equals';
import {
  actions,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  propsChanged,
  reducers,
  selectors,
} from 'kea';

import { ChartHandle } from '@/components/chart';
import Dependencies from '@/deps';
import { getLanguage } from '@/i18n';
import {
  IDeviceViewContainerLogic,
  IGenericDevicesDictsLogic,
} from '@/logic/interfaces';
import { MakeInitialDataOptional, injectDepsToLogic } from '@/logic/utils';
import { IDeviceService, IGenericDeviceService } from '@/services/interfaces';
import { MDevice, MGenericDevice } from '@/types/models';

import { IUnitsLogic } from './../../../interfaces/units';
import type { logicType } from './indexType';

export interface GenericDeviceChartLogicProps {
  deps: {
    genericDeviceService: IGenericDeviceService;
    genericDevicesDictLogic: IGenericDevicesDictsLogic;
    deviceViewContainerLogic: IDeviceViewContainerLogic;
    deviceService: IDeviceService;
    unitsLogic: IUnitsLogic;
  };
  initialData: {
    deviceUuid: string;
  };
  chart: ChartHandle | null;
}

const logic = kea<logicType>([
  props({} as GenericDeviceChartLogicProps),
  key((props) => props.initialData.deviceUuid),
  path(['genericDevice', 'charts']),
  actions({
    createSeries: true,
    removeSeries: true,
    focusToDateRange: (fitY?: boolean) => ({ fitY }),
    setDateRange: (dateRange: { start: Date; end: Date }) => ({ dateRange }),
    setLoadedDateRange: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    loadCurrentRange: true,
    loadCurrentRangeSuccess: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    loadCurrentRangeFailure: true,
    toggleLive: true,
    startLive: true,
    startLiveListen: true,
    stopLive: true,
    liveError: true,
    saveToImage: true,
    setLivePreloadedRange: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    loadDeviceData: true,
    loadDeviceDataSuccess: (deviceData: MGenericDevice.View) => ({
      deviceData,
    }),
    loadDeviceDataFailure: true,
    download: true,
  }),
  reducers(() => ({
    deviceData: [
      null as MGenericDevice.View | null,
      {
        loadDeviceDataSuccess: (_, { deviceData }) => deviceData,
      },
    ],
    dateRange: [
      { start: new Date(Date.now() - 86400000), end: new Date() },
      {
        setDateRange: (_, { dateRange }) => dateRange,
      },
    ],
    loadedDateRange: [
      null as null | { start: Date; end: Date },
      {
        loadCurrentRangeSuccess: (_, { dateRange }) => dateRange,
        setLoadedDateRange: (_, { dateRange }) => dateRange,
      },
    ],
    loadingState: [
      {
        running: false as boolean,
        success: false as boolean,
        error: false as boolean,
      },
      {
        loadCurrentRange: () => ({
          running: true,
          success: false,
          error: false,
        }),
        loadCurrentRangeSuccess: () => ({
          running: false,
          success: true,
          error: false,
        }),
        loadCurrentRangeFailure: () => ({
          running: false,
          success: false,
          error: true,
        }),
      },
    ],
    liveState: [
      {
        loading: false as boolean,
        error: false as boolean,
        running: false as boolean,
      },
      {
        startLive: () => ({ loading: true, error: false, running: false }),
        startLiveListen: () => ({
          loading: false,
          error: false,
          running: true,
        }),
        stopLive: () => ({ loading: false, error: false, running: false }),
      },
    ],
    livePreloadedRange: [
      null as null | {
        start: Date;
        end: Date;
      },
      {
        setLivePreloadedRange: (_, { dateRange }) => dateRange,
      },
    ],
  })),
  selectors(({ props }) => ({
    deviceUuid: [
      () => [
        (_, props: GenericDeviceChartLogicProps) =>
          props.initialData.deviceUuid,
      ],
      (deviceUuid) => deviceUuid,
    ],
    chartHandle: [
      () => [(_, props: GenericDeviceChartLogicProps) => props.chart],
      (chart) => chart,
    ],
    columns: [
      (selectors) => [
        selectors.deviceData,
        props.deps.genericDevicesDictLogic.selectors.brands,
        props.deps.genericDevicesDictLogic.selectors.modelColumns,
      ],
      (deviceData, brands, modelColumns) => {
        if (deviceData != null) {
          const brand = brands[deviceData.base.brandId];
          const columns = modelColumns[brand.modelId];
          return columns as MGenericDevice.ModelColumn[];
        }
        return null;
      },
    ],
  })),
  listeners(({ actions, props, values, cache }) => {
    const onMessage = (message: {
      value: MGenericDevice.Messages.DataBytesPackage;
    }) => {
      if (values.columns != null) {
        for (const dataKey in message.value.numbers) {
          const seriesData = message.value.numbers[dataKey];
          values.chartHandle?.addValueArraysToSeries(
            dataKey,
            seriesData[0],
            seriesData[1],
          );
        }
      }
    };

    cache.onMessage = onMessage;

    const onDisconnect = async () => {
      if (values.livePreloadedRange != null) {
        props.deps.genericDeviceService.streamBytes.cancel(onMessage, {
          uuid: props.initialData.deviceUuid,
          start: values.dateRange.start,
        });
        await new Promise((r) => setTimeout(r, 500));
        await props.deps.genericDeviceService.streamBytes.listen(
          {
            onMessage: onMessage,
            onDisconnect: onDisconnect,
          },
          {
            uuid: props.initialData.deviceUuid,
            start: values.livePreloadedRange.start,
          },
        );
      }
    };

    return {
      download: async () => {
        let lang: MGenericDevice.Messages.Lang;
        const langStr = getLanguage();
        if (langStr == 'pl') {
          lang = MGenericDevice.Messages.Lang.Pl;
        } else if (langStr == 'en') {
          lang = MGenericDevice.Messages.Lang.En;
        } else {
          lang = MGenericDevice.Messages.Lang.En;
        }
        const response = await props.deps.genericDeviceService.download({
          lang: lang,
          range: {
            device_uuid: props.initialData.deviceUuid,
            start: values.dateRange.start,
            end: values.dateRange.end,
          },
        });
        if (response.success) {
          process.env.NODE_ENV === 'development'
            ? window.open(
                `https://localhost:8433/api/downloads/${response.data.file_uuid}`,
              )
            : window.open(
                `${window.location.origin}/api/downloads/${response.data.file_uuid}`,
              );
        }
      },
      saveToImage: async () => {
        let device: MDevice.View | null = null;
        if (
          props.initialData.deviceUuid in
          props.deps.deviceViewContainerLogic.values.devices
        ) {
          device =
            props.deps.deviceViewContainerLogic.values.devices[
              props.initialData.deviceUuid
            ];
        } else {
          const response = await props.deps.deviceService.get({
            deviceUuid: props.initialData.deviceUuid,
          });
          if (response.success) {
            device = response.data;
            props.deps.deviceViewContainerLogic.actions.addDevices([device]);
          }
        }
        if (device != null) {
          values.dateRange.start.toLocaleString('en-GB').replace(',', '') +
            '.' +
            values.dateRange.start
              .getMilliseconds()
              .toString()
              .padStart(3, '0');
          values.chartHandle?.saveToImage(
            device.base.name +
              '_' +
              values.dateRange.start.toLocaleString('en-GB').replace(',', '') +
              '.' +
              values.dateRange.start
                .getMilliseconds()
                .toString()
                .padStart(3, '0') +
              '_' +
              values.dateRange.end.toLocaleString('en-GB').replace(',', '') +
              '.' +
              values.dateRange.end
                .getMilliseconds()
                .toString()
                .padStart(3, '0'),
          );
        }
      },
      createSeries: () => {
        if (values.columns != null) {
          for (const column of values.columns) {
            values.chartHandle?.addSeries(
              column.modelColumnId.toString(),
              column.name +
                ' [' +
                props.deps.unitsLogic.values.units[column.unitId]
                  .shortDescription +
                ']',
            );
          }
          if (Object.values(values.columns).length > 1) {
            values.chartHandle?.toggleLegend();
          }
        }
      },
      removeSeries: () => {
        if (values.columns != null) {
          for (const column of values.columns) {
            values.chartHandle?.removeSeries(column.modelColumnId.toString());
          }
        }
      },
      focusToDateRange: ({ fitY }) => {
        values.chartHandle?.setRange(
          values.dateRange.start.getTime(),
          values.dateRange.end.getTime(),
          fitY,
        );
        values.chartHandle?.setRange(
          values.dateRange.start.getTime(),
          values.dateRange.end.getTime(),
          fitY,
        );
      },
      startLiveListen: async () => {
        if (values.livePreloadedRange != null && values.columns != null) {
          for (const column of values.columns) {
            values.chartHandle?.setDataCleaning(
              column.modelColumnId.toString(),
              true,
            );
          }
          values.chartHandle?.toggleAutoScroll(true);
          values.chartHandle?.toggleMovement(false);
          await props.deps.genericDeviceService.streamBytes.listen(
            {
              onMessage: onMessage,
              onDisconnect: onDisconnect,
            },
            {
              uuid: props.initialData.deviceUuid,
              start: values.livePreloadedRange.end,
            },
          );
        }
      },
      startLive: async () => {
        const newDateRange = {
          start: new Date(
            new Date().getTime() -
              Math.abs(
                values.dateRange.start.getTime() -
                  values.dateRange.end.getTime(),
              ),
          ),
          end: new Date(),
        };
        const response = await props.deps.genericDeviceService.graphBytes({
          deviceUuid: props.initialData.deviceUuid,
          daterange: newDateRange,
        });
        if (response.success) {
          const start = new Date(Object.values(response.data.numbers)[0][0][0]);
          const end = new Date(
            Object.values(response.data.numbers)[0][0][
              Object.values(response.data.numbers)[0][0].length - 1
            ],
          );

          actions.setLivePreloadedRange({
            start: start,
            end: end,
          });
          for (const dataKey in response.data.numbers) {
            values.chartHandle?.clearSeries(dataKey);

            const seriesData = response.data.numbers[dataKey];
            values.chartHandle?.addValueArraysToSeries(
              dataKey,
              seriesData[0],
              seriesData[1],
            );
          }
          actions.focusToDateRange(false);
          actions.startLiveListen();
        } else {
          actions.liveError();
        }
      },
      stopLive: () => {
        if (values.columns != null) {
          for (const column of values.columns) {
            values.chartHandle?.setDataCleaning(
              column.modelColumnId.toString(),
              false,
            );
          }
          if (values.livePreloadedRange != null) {
            props.deps.genericDeviceService.streamBytes.cancel(onMessage, {
              uuid: props.initialData.deviceUuid,
              start: values.livePreloadedRange.end,
            });
          }
          if (values.loadedDateRange != null) {
            actions.setDateRange(values.loadedDateRange);
          }
        }

        values.chartHandle?.toggleAutoScroll(false);
        values.chartHandle?.toggleMovement(true);
      },
      toggleLive: () => {
        if (!values.liveState.running) {
          actions.startLive();
        } else {
          actions.stopLive();
        }
      },
      loadDeviceDataSuccess: () => {
        actions.createSeries();
        actions.loadCurrentRange();
      },
      loadCurrentRange: async () => {
        if (values.columns != null) {
          const response = await props.deps.genericDeviceService.graphBytes({
            deviceUuid: props.initialData.deviceUuid,
            daterange: values.dateRange,
          });
          if (response.success) {
            for (const seriesKey in response.data.numbers) {
              const seriesData = response.data.numbers[seriesKey];
              values.chartHandle?.clearSeries(seriesKey);
              values.chartHandle?.addValueArraysToSeries(
                seriesKey,
                seriesData[0],
                seriesData[1],
              );
            }
            let start: Date;
            let end: Date;
            try {
              start = new Date(Object.values(response.data.numbers)[0][0][0]);
              end = new Date(
                Object.values(response.data.numbers)[0][0][
                  Object.values(response.data.numbers)[0][0].length - 1
                ],
              );
            } catch {
              start = values.dateRange.start;
              end = values.dateRange.end;
            }

            actions.loadCurrentRangeSuccess({ start: start, end: end });
          } else {
            actions.loadCurrentRangeFailure();
          }
          actions.focusToDateRange();
        }
      },
      loadDeviceData: async () => {
        const response = await props.deps.genericDeviceService.get({
          deviceUuid: props.initialData.deviceUuid,
        });
        if (
          response.success &&
          response.data.deviceUuid != undefined &&
          response.data.info != undefined
        ) {
          actions.loadDeviceDataSuccess({
            deviceUuid: response.data.deviceUuid,
            base: {
              brandId: response.data.info.brandId,
            },
          });
        } else {
          actions.loadDeviceDataFailure();
        }
      },
      loadCurrentRangeFailure: async () => {
        await new Promise((r) => setTimeout(r, 1000));
        actions.loadDeviceData();
      },
    };
  }),
  propsChanged(({ actions, props, cache }, oldProps) => {
    if (
      (props.chart != null && cache.loaded == null) ||
      cache.loaded == false
    ) {
      actions.createSeries();
      actions.loadDeviceData();
      cache.loaded = true;
    }
    if (!deepEqual(props.chart, oldProps.chart)) {
      actions.loadCurrentRange();
    }
  }),
  beforeUnmount(({ actions, cache, values, props }) => {
    actions.removeSeries();
    if (values.livePreloadedRange !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.deps.genericDeviceService.streamBytes.cancel(cache.onMessage, {
        uuid: props.initialData.deviceUuid,
        start: values.livePreloadedRange.end,
      });
    }
  }),
]);

const genericDeviceChartLogic = injectDepsToLogic(logic, () => ({
  genericDeviceService: Dependencies.get(IGenericDeviceService.$),
  genericDevicesDictLogic: Dependencies.get(IGenericDevicesDictsLogic.$),
  deviceViewContainerLogic: Dependencies.get(IDeviceViewContainerLogic.$),
  deviceService: Dependencies.get(IDeviceService.$),
  unitsLogic: Dependencies.get(IUnitsLogic.$),
}));

const genericDeviceChartLogicInitialDataOptional =
  genericDeviceChartLogic as MakeInitialDataOptional<
    typeof genericDeviceChartLogic
  >;

export { genericDeviceChartLogicInitialDataOptional as genericDeviceChartLogic };
