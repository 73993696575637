export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type RequiredBy<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;

export type StringKeys<Interface> = Extract<keyof Interface, string>;

export type ChangeTypeOfKeys<
  T extends object,
  Keys extends keyof T,
  NewType,
> = {
  [key in keyof T]: key extends Keys ? NewType : T[key];
};

export type RecursiveChangeTypeOfKeys<T, OldType, NewType> = {
  [P in keyof T]: T[P] extends (infer U)[]
    ? RecursiveChangeTypeOfKeys<U, OldType, NewType>[]
    : T[P] extends OldType | undefined
    ? NewType
    : T[P] extends object | undefined
    ? RecursiveChangeTypeOfKeys<T[P], OldType, NewType>
    : T[P];
};

export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type RemoveKeysWithType<Type, RemoveType> = {
  [Key in keyof Type as Type[Key] extends RemoveType ? never : Key]: Type[Key];
};

export type RecursiveRemoveKeysWithType<Type, RemoveType> = {
  [Key in keyof Type as Type[Key] extends RemoveType
    ? never
    : Key]: Type[Key] extends object
    ? RecursiveRemoveKeysWithType<Type[Key], RemoveType>
    : Type[Key];
};

export type ClassProperties<Class> = RecursiveRemoveKeysWithType<
  Class,
  Function
>;

export type KeepKeysWithType<Type, KeepType> = {
  [Key in keyof Type as Type[Key] extends KeepType ? Key : never]: Type[Key];
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends number | string | symbol | undefined
    ? T[P]
    : RecursivePartial<T[P]>;
};

export type ReplaceReturnType<T extends (...a: any) => any, TNewReturn> = (
  ...a: Parameters<T>
) => TNewReturn;

export type ChangeTypeOfSubKeys<
  T extends object,
  Key extends keyof T,
  SubKeys extends keyof T[Key],
  NewType,
> = {
  [key in keyof T]: key extends Key
    ? {
        [subkey in keyof T[Key]]: subkey extends SubKeys
          ? NewType
          : T[key][subkey];
      }
    : T[key];
};

export function arrayToRecord<
  T extends { [K in keyof T]: string | number | symbol },
  K extends keyof T,
>(array: T[], selector: K): Record<T[K], T> {
  return array.reduce(
    (acc, item) => ({ ...acc, [item[selector]]: item }),
    {} as Record<T[K], T>,
  );
}
