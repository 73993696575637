/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  MessageAssingDeviceRequest as GrpcAssignDeviceIn,
  MessageObjectDownloadRequest as GrpcDownload,
  MessageObjectUuid as GrpcGetIn,
  MessageObjectsList as GrpcGetListOut,
  MessageObjectOut as GrpcGetOut,
  MessageTypesList as GrpcGetTypesListOut,
  MessageWorkStates as GrpcGetWorkStatesOut,
  ObjectData as GrpcObjectData,
  MessageObjectOut as GrpcObjectFull,
  ObjectInfo as GrpcObjectInfo,
  ObjectPowerType as GrpcObjectPowerType,
  ObjectSettings as GrpcObjectSettings,
  ObjectStatus as GrpcObjectStatus,
  ObjectTemporary as GrpcObjectTemporary,
  Type as GrpcObjectType,
  ObjectView as GrpcObjectView,
  WorkState as GrpcObjectWorkState,
  MessageObjectIn as GrpcStoreIn,
  MessageObjectUuid as GrpcStoreOut,
  WorkStateSettings as GrpcWorkStateSettings,
} from '@/services/compiled/objects';
import { MObject } from '@/types/models';

import { GrpcConverters } from './grpc';
import { TableConverters } from './table';

export class ObjectConverters {
  private grpcConverters: GrpcConverters;
  private tableConverters: TableConverters;

  constructor() {
    this.grpcConverters = new GrpcConverters();
    this.tableConverters = new TableConverters();
  }

  public statusFromGrpc(grpc: GrpcObjectStatus): MObject.Status {
    return grpc;
  }

  public statusToGrpc(status: MObject.Status): GrpcObjectStatus {
    return status;
  }

  public dataFromGrpc(grpc: GrpcObjectData): MObject.Base {
    return grpc;
  }

  public dataToGrpc(data: MObject.Base): GrpcObjectData {
    return data;
  }

  public temporaryFromGrpc(grpc: GrpcObjectTemporary): MObject.Temporary {
    return {
      ...grpc,
      lastDataDate:
        grpc.lastDataDate != null
          ? this.grpcConverters.timestampToDate(grpc.lastDataDate)
          : undefined,
    };
  }

  public temporaryToGrpc(temp: MObject.Temporary): GrpcObjectTemporary {
    return {
      ...temp,
      lastDataDate:
        temp.lastDataDate != null
          ? this.grpcConverters.dateToTimestamp(temp.lastDataDate)
          : undefined,
    };
  }

  public viewFromGrpc(grpc: GrpcObjectView): MObject.View {
    return {
      objectUuid: grpc.objectUuid,
      base: {
        address: grpc.listAddress,
        city: grpc.listCity,
        customer: grpc.listCustomer,
        name: grpc.listName,
        region: grpc.listRegion,
        typeId: grpc.listTypeId,
      },
      status: {
        accesscontrol: grpc.statusAccesscontrol,
        definedAlarm: grpc.statusDefinedAlarm,
        definedAlarmColor: grpc.statusDefinedAlarmColor,
        heatmeter: grpc.statusHeatmeter,
        lowBattery: grpc.statusLowBattery,
        partialNoTransmission: grpc.statusPartialNoTransmission,
        preinsulation: grpc.statusPreinsulation,
        totalNoTransmission: grpc.statusTotalNoTransmission,
        watermeter: grpc.statusWatermeter,
      },
      temp: {
        workStateId: grpc.temporaryWorkStateId,
        lastDataDate:
          grpc.temporaryLastDataDate != null
            ? this.grpcConverters.timestampToDate(grpc.temporaryLastDataDate)
            : undefined,
      },
    };
  }

  public viewToGrpc(view: MObject.View): GrpcObjectView {
    return {
      objectUuid: view.objectUuid,
      listAddress: view.base.address,
      listCity: view.base.city,
      listCustomer: view.base.customer,
      listName: view.base.name,
      listRegion: view.base.region,
      listTypeId: view.base.typeId,
      statusAccesscontrol: view.status.accesscontrol,
      statusDefinedAlarm: view.status.definedAlarm,
      statusDefinedAlarmColor: view.status.definedAlarmColor,
      statusHeatmeter: view.status.heatmeter,
      statusLowBattery: view.status.lowBattery,
      statusPartialNoTransmission: view.status.partialNoTransmission,
      statusPreinsulation: view.status.preinsulation,
      statusTotalNoTransmission: view.status.totalNoTransmission,
      statusWatermeter: view.status.watermeter,
      temporaryLastDataDate:
        view.temp.lastDataDate != null
          ? this.grpcConverters.dateToTimestamp(view.temp.lastDataDate)
          : undefined,
    };
  }

  public infoFromGrpc(grpc: GrpcObjectInfo): MObject.Info {
    return grpc;
  }

  public infoToGrpc(info: MObject.Info): GrpcObjectInfo {
    return info;
  }

  public workStateSettingsToGrpc(
    workStateSettings: MObject.WorkStateSettings,
  ): GrpcWorkStateSettings {
    return workStateSettings;
  }

  public powerTypeFromGrpc(grpc: GrpcObjectPowerType): MObject.PowerType {
    return grpc as unknown as MObject.PowerType;
  }

  public powerTypeToGrpc(powerType: MObject.PowerType): GrpcObjectPowerType {
    return powerType as unknown as GrpcObjectPowerType;
  }

  public settingsFromGrpc(grpc: GrpcObjectSettings): MObject.Settings {
    return {
      ...grpc,
      powerType:
        grpc.powerType != null
          ? this.powerTypeFromGrpc(grpc.powerType)
          : undefined,
    };
  }

  public settingsToGrpc(settings: MObject.Settings): GrpcObjectSettings {
    return {
      ...settings,
      powerType:
        settings.powerType != null
          ? this.powerTypeToGrpc(settings.powerType)
          : undefined,
    };
  }

  public workStateFromGrpc(grpc: GrpcObjectWorkState): MObject.WorkState {
    return grpc;
  }

  public workStateToGrpc(workState: MObject.WorkState): GrpcObjectWorkState {
    return workState;
  }

  public typeFromGrpc(grpc: GrpcObjectType): MObject.Type {
    return grpc;
  }

  public typeToGrpc(type: MObject.Type): GrpcObjectType {
    return type;
  }

  public fullFromGrpc(grpc: GrpcObjectFull): MObject.Full {
    return {
      ...grpc,
      base: this.dataFromGrpc(grpc.data!),
      info: this.infoFromGrpc(grpc.info!),
      settings: this.settingsFromGrpc(grpc.settings!),
      status: this.statusFromGrpc(grpc.status!),
      temp: this.temporaryFromGrpc(grpc.temporary!),
    };
  }

  public fullToGrpc(grpc: MObject.Full): GrpcObjectFull {
    return {
      ...grpc,
      data: this.dataToGrpc(grpc.base!),
      info: this.infoToGrpc(grpc.info!),
      settings: this.settingsToGrpc(grpc.settings!),
      status: this.statusToGrpc(grpc.status!),
      temporary: this.temporaryToGrpc(grpc.temp!),
      deviceUuids: [],
    };
  }

  public getWorkStatesFromGrpc(
    grpc: GrpcGetWorkStatesOut,
  ): MObject.Messages.GetWorkStatesOut {
    const workStates: MObject.WorkState[] = [];
    for (const grpcWorkState of grpc.workStates) {
      workStates.push(this.workStateFromGrpc(grpcWorkState));
    }
    return {
      workStates: workStates,
    };
  }

  public getListInToGrpc(input: MObject.Messages.GetListIn) {
    return this.tableConverters.tableRequestInputToGrpc(input);
  }

  public getListOutFromGrpc(out: GrpcGetListOut): MObject.Messages.GetListOut {
    const views: MObject.View[] = [];

    for (const objectView of out.objects) {
      views.push(this.viewFromGrpc(objectView));
    }

    return {
      ...out,
      objects: views,
    };
  }

  public getTypesListInToGrpc(input: MObject.Messages.GetTypesListIn) {
    return this.tableConverters.tableRequestInputToGrpc(input);
  }

  public getTypesListOutFromGrpc(
    grpc: GrpcGetTypesListOut,
  ): MObject.Messages.GetTypesListOut {
    const types: MObject.Type[] = [];

    for (const grpcTypes of grpc.types) {
      types.push(this.typeFromGrpc(grpcTypes));
    }

    return {
      ...grpc,
      types: types,
    };
  }

  public storeInToGrpc(input: MObject.Messages.StoreIn): GrpcStoreIn {
    return {
      ...input,
      data: this.dataToGrpc(input.data),
      info: this.infoToGrpc(input.info),
      settings: this.settingsToGrpc(input.settings),
      workStateSettings: input.workStateSettings,
    };
  }

  public storeOutFromGrpc(grpc: GrpcStoreOut): MObject.Messages.StoreOut {
    return grpc;
  }

  public assignDeviceInToGrpc(
    input: MObject.Messages.AssignDeviceIn,
  ): GrpcAssignDeviceIn {
    return input;
  }

  public getInToGrpc(input: MObject.Messages.GetIn): GrpcGetIn {
    return input;
  }

  public getOutFromGrpc(grpc: GrpcGetOut): MObject.Messages.GetOut {
    return this.fullFromGrpc(grpc);
  }

  public mergeViewAndDetail(
    view: MObject.View,
    detail: MObject.Detail,
  ): MObject.Full {
    return { ...view, ...detail };
  }

  public splitObjectFull(object: MObject.Full): [MObject.View, MObject.Detail] {
    return [
      {
        objectUuid: object.objectUuid,
        base: object.base,
        status: object.status,
        temp: object.temp,
      },
      {
        objectUuid: object.objectUuid,
        deviceUuids: object.deviceUuids,
        info: object.info,
        settings: object.settings,
        workStateSettings: object.workStateSettings,
      },
    ];
  }

  public download(input: MObject.Messages.DownloadIn): GrpcDownload {
    return {
      ...input,
      daterange: {
        start: this.grpcConverters.dateToTimestamp(input.daterange.start),
        end: this.grpcConverters.dateToTimestamp(input.daterange.end),
      },
    };
  }
}
