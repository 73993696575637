import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { SyncStatus } from '@/enums';
import { IModulesKeys } from '@/modules';
import {
  CreateWindowData,
  Desktop,
  Layout,
  Window,
  WindowGeometry,
} from '@/types/models/desktop';
import { RecursivePartial } from '@/utility/types';

export interface IDesktopLogicProps extends Record<string, unknown> {}

interface IDesktop extends Logic {
  readonly props: IDesktopLogicProps;
  readonly actions: {
    readonly fetch: () => void;
    readonly addLayout: (name: string) => void;
    readonly openWindow: <Type extends IModulesKeys>(
      type: Type,
      windowData: CreateWindowData<Type>,
    ) => void;
    readonly createWindow: (windowKey: string, windowData: Window) => void;
    readonly focusWindow: (windowKey: string) => void;
    readonly removeWindow: (windowKey: string) => void;
    readonly updateWindowGeometry: (
      windowKey: string,
      geometry: RecursivePartial<WindowGeometry>,
    ) => void;
    readonly updateMultipleGeometries: (
      geometries: Record<string, RecursivePartial<WindowGeometry>>,
    ) => void;
  };
  readonly values: {
    readonly status: SyncStatus;
    readonly desktop: Desktop;
    readonly currentLayoutId: number | undefined;
    readonly layouts: Record<number, Layout>;
    readonly layoutIds: number[];
    readonly currentLayout: Layout | null;
    readonly windowKeys: string[];
    readonly currentWindowTypes: IModulesKeys[];
  };
  readonly selectors: {
    readonly currentLayout: (state: unknown) => Layout | null;
  };
}

export interface IDesktopLogic extends LogicWrapper<IDesktop> {}

export namespace IDesktopLogic {
  export const $: interfaces.ServiceIdentifier<IDesktopLogic> =
    Symbol('IDesktopLogic');
}
