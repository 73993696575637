import { EditorModel, EditorTypes } from '@/types/custom/editorTypes';

export const vibrodetectorEditorModel: Record<
  string,
  Record<string, Record<string, EditorModel>>
> = {
  primaryData: {
    primaryData: {
      name: {
        name: 'editor.vibrodetector.name',
        type: EditorTypes.string,
        inputValidator: (value) => {
          if (value === '') return false;
          else return true;
        },
        errorMessage: 'editor.validator.emptyFieldMessage',
      },
      hysteresis: {
        name: 'editor.vibrodetector.hysteresis',
        type: EditorTypes.number,
        errorMessage: 'editor.validator.emptyFieldMessage',
      },
      hysteresisMode: {
        name: 'editor.vibrodetector.hysteresisMode',
        type: EditorTypes.enum,
      },
      timeoutHysteresis: {
        name: 'editor.vibrodetector.timeoutHysteresis',
        type: EditorTypes.float,
        valueUnit: '[s]',
      },
    },
  },
};
