import produce from 'immer';
import {
  actions,
  afterMount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { Dependencies } from '@/deps';
import {
  IDesktopLogic,
  IDeviceViewContainerLogic,
  IGenericDevicesDictsLogic,
} from '@/logic/interfaces';
import { IDefinedAlarmsTableLogic } from '@/logic/interfaces/definedAlarmsTable';
import { IGroupAlarmsTableLogic } from '@/logic/interfaces/groupAlarmsTable';
import { injectDepsToLogic } from '@/logic/utils';
import { IDeviceService, IGenericDeviceService } from '@/services/interfaces';
import { IDefinedAlarmsService } from '@/services/interfaces/definedAlarms';
import {
  ConfirmStatus,
  EditorTile,
  EditorValue,
  EnumOptions,
  LoadingStatus,
  RecordTypes,
} from '@/types/custom/editorTypes';
import { MDefinedAlarms } from '@/types/models/definedAlarms';
import { TableSearchRule } from '@/types/models/table';
import { delay } from '@/utility/delay';
import {
  convertDeviceToEditorArray,
  convertEnumToEditorArray,
  convertEventLevelToEditorArray,
  convertGroupToEditorArray,
} from '@/utility/editor';
import { validateValues } from '@/validators/core/editor';

import { IEventDictContainerLogic } from './../../../interfaces/eventDictContainer';
import { getColumns } from './columns';
import { convertDataToGrpc, convertSettingsToGrpc } from './converters';
import type { logicType } from './indexType';

interface DefinedAlarmEditorProps {
  initialData: {
    definedAlarmsUuid: string;
    editorUuid: string;
    objectUuid: string;
  };
  deps: {
    definedAlarmsTableLogic: IDefinedAlarmsTableLogic;
    definedAlarmsService: IDefinedAlarmsService;
    eventDictContainerLogic: IEventDictContainerLogic;

    desktopLogic: IDesktopLogic;

    groupAlarmsTableLogic: IGroupAlarmsTableLogic;

    deviceViewContainerLogic: IDeviceViewContainerLogic;
    deviceService: IDeviceService;

    genericDeviceService: IGenericDeviceService;
    genericDevicesDictsLogic: IGenericDevicesDictsLogic;
  };
}

const logic = kea<logicType>([
  path(['definedAlarms', 'editor']),
  props(
    {} as {
      initialData: {
        definedAlarmsUuid: string;
        editorUuid: string;
        objectUuid: string;
      };
      deps: {
        definedAlarmsTableLogic: IDefinedAlarmsTableLogic;
        definedAlarmsService: IDefinedAlarmsService;
        eventDictContainerLogic: IEventDictContainerLogic;

        desktopLogic: IDesktopLogic;

        groupAlarmsTableLogic: IGroupAlarmsTableLogic;

        deviceViewContainerLogic: IDeviceViewContainerLogic;
        deviceService: IDeviceService;

        genericDeviceService: IGenericDeviceService;
        genericDevicesDictsLogic: IGenericDevicesDictsLogic;
      };
    },
  ),
  key((props) => {
    return props.initialData.editorUuid
      ? props.initialData.editorUuid
      : 'newDefinedAlarm';
  }),
  actions({
    updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: EditorValue,
    ) => ({
      tileKey,
      tabKey,
      valueKey,
      value,
    }),

    loadGroups: true,
    setGroups: (groups: Record<string, string>) => ({ groups }),

    loadAlarm: true,
    setLoadStatus: (status: LoadingStatus) => ({ status }),
    setInitialValue: (
      value: Record<string, Record<string, Record<string, EditorValue>>>,
    ) => ({ value }),
    resetToInitialValues: true,

    loadDevices: true,
    setDevicesStatus: (status: LoadingStatus) => ({ status }),
    setDeviceIds: (deviceIds: string[]) => ({ deviceIds }),

    loadGenericDevices: true,
    setGenericDevicesStatus: (status: LoadingStatus) => ({ status }),
    setModelId: (modelId: number) => ({ modelId }),

    refresh: true,

    confirm: true,
    setConfirmStatus: (status: ConfirmStatus) => ({ status }),
  }),
  reducers({
    value: [
      {} as Record<string, Record<string, Record<string, EditorValue>>>,
      {
        updateValue: (immutableStore, { tileKey, tabKey, valueKey, value }) =>
          produce(immutableStore, (state) => {
            state[tileKey][tabKey][valueKey] = value;
          }),
        setInitialValue: (_, { value }) => value,
      },
    ],
    initialValue: [
      {} as Record<string, Record<string, Record<string, EditorValue>>>,
      {
        setInitialValue: (_, { value }) => value,
      },
    ],
    loadStatus: [
      LoadingStatus.isLoading as LoadingStatus,
      {
        setLoadStatus: (_, { status }) => status,
      },
    ],
    genericDevicesStatus: [
      LoadingStatus.isLoading as LoadingStatus,
      {
        setGenericDevicesStatus: (_, { status }) => status,
      },
    ],
    devicesStatus: [
      LoadingStatus.isLoading as LoadingStatus,
      {
        setDevicesStatus: (_, { status }) => status,
      },
    ],
    confirmStatus: [
      ConfirmStatus.unknown as ConfirmStatus,
      {
        setConfirmStatus: (_, { status }) => status,
      },
    ],
    groups: [
      {} as Record<string, string>,
      {
        setGroups: (_, { groups }) => groups,
      },
    ],
    isSaving: [
      false,
      {
        confirm: () => true,
        setConfirmStatus: () => false,
      },
    ],
    deviceIds: [
      [],
      {
        setDeviceIds: (_, { deviceIds }) => deviceIds,
      },
    ],
    modelId: [
      0,
      {
        setModelId: (_, { modelId }) => modelId,
      },
    ],
    translationPrefix: ['definedAlarms', {}],
    tileLayout: [
      {
        data: {
          tileLayout: {
            layoutDirection: 'horizontal',
          },
          tabs: {
            data: {
              layoutDirection: 'horizontal',
            },
            inputSwitch: {
              layoutDirection: 'vertical',
              gridLayout: 'repeat(2,1fr)',
            },
            color: {
              layoutDirection: 'horizontal',
            },
          },
        },
        advancedSettings: {
          tileLayout: {
            layoutDirection: 'horizontal',
          },
          tabs: {
            advancedSettings: {
              layoutDirection: 'vertical',
              gridLayout: 'repeat(2,1fr)',
            },
          },
        },
      } as Record<string, EditorTile>,
      {},
    ],
  }),
  selectors({
    expandProps: [
      () => [
        (_, props: DefinedAlarmEditorProps) =>
          props.deps.groupAlarmsTableLogic({
            objectUuid: props.initialData.objectUuid,
          }).values.expandProps,
      ],
      (expandProps) => expandProps,
    ],
    definedAlarmsUuid: [
      () => [
        (_, props: DefinedAlarmEditorProps) =>
          props.initialData.definedAlarmsUuid,
      ],
      (definedAlarmsUuid) => definedAlarmsUuid,
    ],
    editorUuid: [
      () => [
        (_, props: DefinedAlarmEditorProps) => props.initialData.editorUuid,
      ],
      (editorUuid) => editorUuid,
    ],
    objectUuid: [
      () => [
        (_, props: DefinedAlarmEditorProps) => props.initialData.objectUuid,
      ],
      (objectUuid) => objectUuid,
    ],
    deviceUuid: [
      (s) => [s.value],
      (value) => {
        return value.data.data.device.value;
      },
    ],
    eventLevels: [
      () => [
        (_, props: DefinedAlarmEditorProps) =>
          props.deps.eventDictContainerLogic.values.eventLevels,
      ],
      (eventLevels) => eventLevels,
    ],
    devices: [
      () => [
        (_, props: DefinedAlarmEditorProps) =>
          props.deps.deviceViewContainerLogic.values.devices,
      ],
      (devices) => devices,
    ],
    deviceKind: [
      (s) => [
        s.deviceUuid,
        (_, props: DefinedAlarmEditorProps) =>
          props.deps.deviceViewContainerLogic.values.devices,
      ],
      (deviceUuid, devices) => {
        return devices[deviceUuid].base.kind;
      },
    ],
    modelColumns: [
      () => [
        (_, props: DefinedAlarmEditorProps) =>
          props.deps.genericDevicesDictsLogic.values.modelColumns,
      ],
      (models) => models,
    ],
    enumOptionsMap: [
      (s) => [
        s.eventLevels,
        s.devices,
        s.deviceIds,
        s.deviceKind,
        s.modelColumns,
        s.modelId,
        s.groups,
      ],
      (
        eventLevels,
        devices,
        deviceIds,
        deviceKind,
        modelColumns,
        modelId,
        groups,
      ) => {
        const enumOptions: EnumOptions = {
          type: RecordTypes.EnumOptions,
          options: {
            eventLevelId: convertEventLevelToEditorArray(eventLevels),
            device: convertDeviceToEditorArray(devices, deviceIds),
            col: getColumns(deviceKind, modelColumns, modelId),
            rule: convertEnumToEditorArray(MDefinedAlarms.Rule, 'rule'),
            group: convertGroupToEditorArray(groups),
          },
        };

        return enumOptions;
      },
    ],
  }),
  listeners(({ props, actions, values }) => ({
    loadGroups: async () => {
      const groupResponse = await props.deps.definedAlarmsService.getGroupList({
        searchAll: [
          {
            column: 'objectUuid' as MDefinedAlarms.TableGroupViewSortKeys,
            rule: TableSearchRule.Equal,
            value: [props.initialData.objectUuid],
          },
        ],
        searchAny: [],
      });

      if (groupResponse.success) {
        const groups: Record<string, string> = {};

        groupResponse.data.definedAlarmGroups.forEach((group) => {
          groups[group.definedAlarmGroupUuid] = group.data.description;
        });

        actions.setGroups(groups);
        actions.loadAlarm();
      } else {
        actions.setLoadStatus(LoadingStatus.failure);
      }
    },
    loadAlarm: async () => {
      const alarmResponse = await props.deps.definedAlarmsService.getSettings({
        definedAlarmUuid: props.initialData.editorUuid,
      });

      if (alarmResponse.success) {
        const value: Record<
          string,
          Record<string, Record<string, EditorValue>>
        > = {
          data: {
            data: {
              description: {
                value: alarmResponse.data.data?.description,
              },
              group: {
                value:
                  alarmResponse.data.settings?.definedAlarmGroupUuid != null
                    ? alarmResponse.data.settings.definedAlarmGroupUuid
                    : '',
              },
              eventLevelId: {
                value: alarmResponse.data.data?.eventLevelId,
              },
              device: {
                value: alarmResponse.data.settings?.deviceUuid,
              },
              priority: {
                value: alarmResponse.data.settings?.priority,
              },
            },
            inputSwitch: {
              active: {
                value: alarmResponse.data.settings?.active,
              },
              sound: {
                value: alarmResponse.data.settings?.sound,
              },
            },
            color: {
              color: {
                value: alarmResponse.data.data?.color,
              },
            },
          },
          advancedSettings: {
            advancedSettings: {
              col: {
                value: alarmResponse.data.settings?.col,
              },
              rule: {
                value: alarmResponse.data.settings?.rule,
              },
              value: {
                value: alarmResponse.data.settings?.value,
              },
              // math: {
              //   value: response.data.settings?.math,
              // },
              // mathCol: {
              //   value: response.data.settings?.mathCol,
              // },
              onDelay: {
                value: alarmResponse.data.settings?.onDelay,
              },
              offDelay: {
                value: alarmResponse.data.settings?.offDelay,
              },
              round: {
                value: alarmResponse.data.settings?.round,
              },
            },
          },
        };

        actions.setInitialValue(value);
        actions.setLoadStatus(LoadingStatus.success);
        actions.loadDevices();
      } else {
        actions.setLoadStatus(LoadingStatus.failure);
      }
    },
    loadDevices: async () => {
      const response = await props.deps.deviceService.getList({
        searchAll: [
          {
            column: 'objectUuid',
            value: [props.initialData.objectUuid],
            rule: TableSearchRule.Equal,
          },
        ],
        searchAny: [],
      });

      if (response.success) {
        const deviceIds = response.data.devices.map(
          (device) => device.deviceUuid,
        );

        props.deps.deviceViewContainerLogic.actions.releaseDevices(deviceIds);
        props.deps.deviceViewContainerLogic.actions.addDevices(
          response.data.devices,
        );
        props.deps.deviceViewContainerLogic.actions.useDevices(deviceIds);

        actions.setDeviceIds(deviceIds);

        actions.loadGenericDevices();
        actions.setDevicesStatus(LoadingStatus.success);
      } else {
        actions.setDevicesStatus(LoadingStatus.failure);
      }
    },
    loadGenericDevices: async () => {
      const response = await props.deps.genericDeviceService.get({
        deviceUuid: values.deviceUuid,
      });

      if (response.success) {
        const brand = Object.values(
          props.deps.genericDevicesDictsLogic.values.brands,
        ).find((brand) => brand.brandId === response.data.info?.brandId);

        if (brand != null) {
          actions.setModelId(brand.modelId);
        }

        actions.setGenericDevicesStatus(LoadingStatus.success);
      } else {
        actions.setGenericDevicesStatus(LoadingStatus.failure);
      }
    },
    updateValue: ({ tileKey, tabKey, valueKey, value }) => {
      if (
        tileKey == 'data' &&
        tabKey == 'data' &&
        valueKey == 'device' &&
        value !== values.deviceUuid
      ) {
        actions.loadGenericDevices();
      }
    },
    confirm: async () => {
      const response = await props.deps.definedAlarmsService.store({
        definedAlarmUuid: props.initialData.editorUuid,
        data: convertDataToGrpc(values.value.data),
        settings: convertSettingsToGrpc(
          values.value.data,
          values.value.advancedSettings,
          values.deviceUuid as string,
        ),
      });

      if (response.success && validateValues(values.value)) {
        actions.setConfirmStatus(ConfirmStatus.success);
        props.deps
          .definedAlarmsTableLogic({
            objectUuid: props.initialData.objectUuid,
          })
          .actions.refresh();

        if (values.expandProps != null) {
          props.deps
            .definedAlarmsTableLogic(values.expandProps)
            .actions.refresh();
        }

        await delay(500);
        props.deps.desktopLogic.actions.removeWindow(
          `definedAlarmsEdit_${props.initialData.definedAlarmsUuid}`,
        );
      } else {
        actions.setConfirmStatus(ConfirmStatus.failure);
      }
    },
    resetToInitialValues: () => {
      actions.setInitialValue(values.initialValue);
    },
    refresh: () => {
      actions.setLoadStatus(LoadingStatus.isLoading);
      actions.loadGroups();
    },
  })),
  afterMount(({ actions }) => {
    actions.loadGroups();
  }),
]);

export const definedAlarmEditorLogic = injectDepsToLogic(logic, () => ({
  definedAlarmsTableLogic: Dependencies.get(IDefinedAlarmsTableLogic.$),
  definedAlarmsService: Dependencies.get(IDefinedAlarmsService.$),
  groupAlarmsTableLogic: Dependencies.get(IGroupAlarmsTableLogic.$),
  desktopLogic: Dependencies.get(IDesktopLogic.$),
  eventDictContainerLogic: Dependencies.get(IEventDictContainerLogic.$),
  deviceViewContainerLogic: Dependencies.get(IDeviceViewContainerLogic.$),
  deviceService: Dependencies.get(IDeviceService.$),
  genericDeviceService: Dependencies.get(IGenericDeviceService.$),
  genericDevicesDictsLogic: Dependencies.get(IGenericDevicesDictsLogic.$),
}));
