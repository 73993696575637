import { Visualization as OCSVisualization } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { useValues } from 'kea';
import { FC, memo, useEffect, useMemo } from 'react';

import {
  IObjectTableLogic,
  IObjectWindowLogic,
  IVisualizationLogic,
} from '@/logic/interfaces';

interface VisualizationObjectViewProps {
  objectUuid: string;
}

const VisualizationObjectView = memo(function VisualizationObjectViewProps(
  props: VisualizationObjectViewProps,
) {
  const viewLogic = useInjection(IObjectWindowLogic.$)({
    initialData: {
      objectUuid: props.objectUuid,
    },
  });
  const { objectUuid, object, visualizationName } = useValues(viewLogic);
  const visualizationUuid = object?.settings.visualizationUuid;
  if (visualizationUuid !== undefined) {
    return (
      <div className="visualization-view">
        <OCSVisualization
          visualizationLogicIdentifier={IVisualizationLogic.$}
          properties={{
            sourceUuid: objectUuid,
            sourceType: 'Object',
            visualizationUuid,
            name: visualizationName,
          }}
        ></OCSVisualization>
      </div>
    );
  } else {
    return <div></div>;
  }
});

export const VisualizationsViewWindow = memo(
  function VisualizationsViewWindow() {
    const objectTableLogic = useInjection(IObjectTableLogic.$);
    const { values } = useValues(objectTableLogic);
    values?.sort((a, b) => ((a.listName || '') > (b.listName || '') ? 1 : -1));
    return (
      <div className="object-view">
        {values?.map((object) => (
          <VisualizationObjectView
            key={object.objectUuid}
            objectUuid={object.objectUuid}
          ></VisualizationObjectView>
        ))}
      </div>
    );
  },
);
