import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { EventView } from '@/types/models/event';

export interface IEventListLogicProps {}

interface IEventList extends Logic {
  readonly props: IEventListLogicProps;
  readonly actions: {
    readonly setMuted: (value: boolean) => void;
    readonly setDisplayed: (alarmEventsIds: string[]) => void;
    readonly removeAlarmEvent: (alarmEvent: string) => void;
    readonly removeAll: () => void;
    readonly confirmAll: (confirmMessage: string) => void;
  };
  readonly values: {
    readonly isMuted: boolean;
    readonly alarmEvents: EventView[];
    readonly alarmEventsToDisplay: EventView[];
    readonly alarmEventsCount: number;
    readonly isSending: boolean;
  };
  readonly selectors: {
    readonly alarmEvents: (state: unknown) => EventView[];
  };
}

export interface IEventListLogic extends LogicWrapper<IEventList> {}

export namespace IEventListLogic {
  export const $: interfaces.ServiceIdentifier<IEventListLogic> =
    Symbol('IEventListLogic');
}
