/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IObjectService } from '@/services/interfaces/object';
import { Response } from '@/services/types/response';
import { DownloadIn, DownloadOut } from '@/types/messages/object';
import { MObject } from '@/types/models';

import { ServerStream } from '../stream';
import { BaseService } from './base';

@injectable()
export class ObjectService extends BaseService implements IObjectService {
  public async getWorkStates(): Promise<
    Response<MObject.Messages.GetWorkStatesOut>
  > {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectGetWorkStates();
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async getList(
    input: MObject.Messages.GetListIn,
  ): Promise<Response<MObject.Messages.GetListOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectGetList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async getTypesLists(
    input: MObject.Messages.GetTypesListIn,
  ): Promise<Response<MObject.Messages.GetTypesListOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectGetTypesLists(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async store(
    input: MObject.Messages.StoreIn,
  ): Promise<Response<MObject.Messages.StoreOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectStore(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async assignDevice(
    input: MObject.Messages.AssignDeviceIn,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectAssignDevice(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async get(
    input: MObject.Messages.GetIn,
  ): Promise<Response<MObject.Messages.GetOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectGet(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getGraphWorkStates(
    input: MObject.Messages.GetGraphWorkStatesIn,
  ): Promise<Response<MObject.Messages.GetGraphWorkStatesOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectGetGraphWorkStates(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public stream = new ServerStream((input: MObject.Messages.LiveWorkStateIn) =>
    BaseService.apiPool.queue(async (worker) => {
      return { observer: worker.objectLiveWorkStates(input) };
    }),
  );

  public async download(
    input: DownloadIn,
  ): Promise<Response<MObject.Messages.DownloadOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.objectDownload(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
