import { EditorValue, EnumContent } from '@/types/custom/editorTypes';
import { MDevice } from '@/types/models/device';
import { EventLevel } from '@/types/models/event';

export function convertEnumToEditorArray(
  e: Record<string, string | number>,
  translationName: string,
): EnumContent[] {
  const array: EnumContent[] = [];

  Object.keys(e).forEach((key) => {
    const value = e[key];
    if (typeof value === 'string') {
      array.push({
        name: `editor.enums.${translationName}.${value.toLowerCase()}`,
        key: e[value] as number,
      });
    }
  });

  return array;
}

export function convertEventLevelToEditorArray(
  e: Record<string, EventLevel>,
): EnumContent[] {
  const array: EnumContent[] = [];

  Object.values(e).forEach((value) => {
    array.push({
      name: `editor.enums.${value.name}`,
      key: value.eventLevelId,
    });
  });

  return array;
}

export function convertDeviceToEditorArray(
  e: Record<string, MDevice.View>,
  devicedIds: string[],
): EnumContent[] {
  const array: EnumContent[] = [];

  Object.values(e).forEach((option) => {
    if (devicedIds.includes(option.deviceUuid)) {
      array.push({
        name: option.base.name,
        key: option.deviceUuid,
      });
    }
  });

  return array;
}

export function convertGroupToEditorArray(
  e: Record<string, string>,
): EnumContent[] {
  const array: EnumContent[] = [];

  for (const [key, value] of Object.entries(e)) {
    array.push({
      name: value,
      key: key,
    });
  }

  array.push({
    name: 'editor.enums.group.noGroup',
    key: '',
  });

  return array;
}

export function convertWorkStatesToEditorArray(
  data: Record<string, Record<string, EditorValue>>,
  currentKey: string,
  e: Record<number, string>,
): EnumContent[] {
  const array: EnumContent[] = [];
  const usedWorkStateIds = [];

  for (const key of Object.keys(data)) {
    if (key.includes('workState') && currentKey != key) {
      usedWorkStateIds.push(+data[key].workState.value);
    }
  }

  for (const [key, value] of Object.entries(e)) {
    if (!usedWorkStateIds.includes(+key))
      array.push({
        name: value,
        key: key,
      });
  }

  return array;
}
