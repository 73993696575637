import {
  actions,
  afterMount,
  kea,
  listeners,
  path,
  props,
  reducers,
} from 'kea';

import Dependencies from '@/deps';
import { injectDepsToLogic } from '@/logic/utils';
import { IGlobalService } from '@/services/interfaces';
import { MGlobal } from '@/types/models';
import { arrayToRecord } from '@/utility/types';

import type { logicType } from './indexType';

export interface UnitsLogicProps {
  deps: {
    globalService: IGlobalService;
  };
}

const logic = kea<logicType>([
  props({} as UnitsLogicProps),
  path(['units']),
  actions({
    _load: true,
    _setUnits: (units: Record<number, MGlobal.Unit>) => ({ units }),
  }),
  reducers({
    units: [
      {} as Record<number, MGlobal.Unit>,
      {
        _setUnits: (_, { units }) => units,
      },
    ],
  }),
  listeners(({ props, actions }) => {
    return {
      _load: async () => {
        const result_units = await props.deps.globalService.getUnitsList({
          searchAll: [],
          searchAny: [],
        });
        if (result_units.success) {
          actions._setUnits(arrayToRecord(result_units.data.units, 'unitId'));
        }
      },
    };
  }),
  afterMount(({ actions }) => {
    actions._load();
  }),
]);

export const unitsLogic = injectDepsToLogic(logic, () => ({
  globalService: Dependencies.get(IGlobalService.$),
}));
