import { VisualizationView as GrpcVisualizationView } from '@/services/compiled/visualizations';

import {
  GetIn as MGetIn,
  GetListIn as MGetListIn,
  GetListOut as MGetListOut,
  GetOut as MGetOut,
  OpenIn as MOpenIn,
  OpenOut as MOpenOut,
  StoreIn as MStoreIn,
  StoreOut as MStoreOut,
} from '../messages/visualizations';

export namespace MVisualization {
  export namespace Messages {
    export type GetIn = MGetIn;
    export type GetOut = MGetOut;
    export type StoreIn = MStoreIn;
    export type StoreOut = MStoreOut;
    export type GetListIn = MGetListIn;
    export type GetListOut = MGetListOut;
    export type OpenIn = MOpenIn;
    export type OpenOut = MOpenOut;
  }

  export const TableViewSortKeys: (keyof GrpcVisualizationView)[] = [
    'visualizationUuid',
    'description',
    'filename',
    'userUuid',
  ];

  export type TableViewSortKeys = keyof GrpcVisualizationView;

  export interface Data {
    description: string;
    filename?: string;
    userUuid?: string;
  }

  export interface View {
    visualizationUuid: string;
    data: Data;
  }

  export interface Content {
    body: string;
    systemapis: string;
    componentsTasks: string;
  }
}
