import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { LoginCheckStatus, LoginStatus } from '@/types/models/user';

export interface ILoginLogicProps extends Record<string, unknown> {}

interface ILogin extends Logic {
  readonly props: ILoginLogicProps;
  readonly actions: {
    readonly logIn: (password: string, login: string) => void;
    readonly logOut: () => void;
    readonly setHomeLoaded: () => void;
  };
  readonly values: {
    readonly loginCheckStatus: LoginCheckStatus;
    readonly loginStatus: LoginStatus;
    readonly homeLoaded: boolean;
  };
}

export interface ILoginLogic extends LogicWrapper<ILogin> {}

export namespace ILoginLogic {
  export const $: interfaces.ServiceIdentifier<ILoginLogic> =
    Symbol('ILoginLogic');
}
