import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FunctionComponent, useState } from 'react';

import { ILoginLogic } from '@/logic/interfaces';
import { LoginStatus } from '@/types/models/user';

import './style.scss';

export interface LoginProps {}

export const Login: FunctionComponent<LoginProps> = () => {
  const loginLogic = useInjection(ILoginLogic.$);

  const { logIn } = useActions(loginLogic);
  const { loginStatus } = useValues(loginLogic);

  const [login, setLogin] = useState('');
  const [loginEmptyError, setLoginEmptyError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordEmptyError, setPasswordEmptyError] = useState(false);

  const imageUrl = new URL('../../assets/app.png', import.meta.url);

  const submit = () => {
    let error = false;
    if (login == '') {
      error = true;
      setLoginEmptyError(true);
    } else {
      setLoginEmptyError(false);
    }
    if (password == '') {
      error = true;

      setPasswordEmptyError(true);
    } else {
      setPasswordEmptyError(false);
    }
    if (!error) {
      logIn(login, password);
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <form action="#" onSubmit={() => {}}>
      <div className="login-panel center">
        <img className="login-image" src={imageUrl.toString()} />
        <div className="field">
          <span className="p-input-icon-left">
            <i className="ri ri-user-fill" />
            <InputText
              placeholder="Login"
              id="inputtext"
              onChange={(event) => setLogin(event.target.value)}
              className={loginEmptyError ? 'p-invalid' : undefined}
            />
          </span>
          <small
            id="username2-help"
            className="p-font p-error block select-none"
          >
            {loginEmptyError ? 'Pole nie może być puste.' : <>&nbsp;</>}
          </small>
        </div>

        <div className="field">
          <span className="p-input-icon-left p-input-icon-right">
            <i className="ri ri-lock-fill" />
            <InputText
              placeholder="Hasło"
              id="lefticon"
              onChange={(event) => setPassword(event.target.value)}
              className={passwordEmptyError ? 'p-invalid' : undefined}
              type={!passwordVisible ? 'password' : 'text'}
            />
            <i
              onClick={() => {
                setPasswordVisible(!passwordVisible);
              }}
              className={
                'ri login-password-visible' +
                (!passwordVisible ? ' ri-eye-line' : ' ri-eye-off-line')
              }
            />
          </span>

          <small
            id="username2-help"
            className="p-font p-error block select-none"
          >
            {passwordEmptyError ? 'Pole nie może być puste.' : <>&nbsp;</>}
          </small>
        </div>

        <Button
          icon={
            loginStatus == LoginStatus.LOGGING_IN
              ? 'ri ri-refresh-line pi-spin'
              : undefined
          }
          className={
            'login-button' +
            (loginStatus == LoginStatus.LOGIN_ERROR ? ' p-button-danger' : '')
          }
          onClick={submit}
          disabled={
            loginStatus == LoginStatus.LOGGING_IN ||
            loginStatus == LoginStatus.LOGIN_ERROR
          }
          label={
            loginStatus == LoginStatus.LOGGED_OUT
              ? 'Zaloguj'
              : loginStatus == LoginStatus.LOGIN_ERROR
              ? 'Nie udało się zalogować'
              : undefined
          }
        />
      </div>
    </form>
  );
};
