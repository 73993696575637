import { FC } from 'react';

import { Tag } from '@/components/tag';

interface TagTemplateProps {
  value: {
    icon?: string;
    label: string;
    color: string;
  };
}

export const TagTemplate: FC<TagTemplateProps> = (props) => {
  return (
    <Tag
      icon={props.value.icon}
      label={props.value.label}
      color={props.value.color}
    />
  );
};
