import { useInjection } from 'inversify-react';
import { useActions, useMountedLogic, useValues } from 'kea';
import { FunctionComponent } from 'react';

import { Loader } from '@/app/loader';
import { Login } from '@/app/login';
import LazyLoader from '@/components/lazyLoader';
import { IAppLogic, ILoginLogic } from '@/logic/interfaces';
import { LoginCheckStatus, LoginStatus } from '@/types/models/user';

import './style.scss';

const App: FunctionComponent = () => {
  const loginLogic = useInjection(ILoginLogic.$);
  const appLogic = useInjection(IAppLogic.$);
  useMountedLogic(appLogic);

  const { loginCheckStatus, loginStatus, homeLoaded } = useValues(loginLogic);
  const { setHomeLoaded } = useActions(loginLogic);

  let loginVisible =
    loginStatus == LoginStatus.LOGGED_OUT ||
    loginStatus == LoginStatus.LOGGING_IN ||
    loginStatus == LoginStatus.LOGIN_ERROR;
  let loaderVisible =
    loginStatus == LoginStatus.NOT_CHECKED ||
    loginCheckStatus == LoginCheckStatus.CHECKING ||
    loginCheckStatus == LoginCheckStatus.ERROR ||
    (!homeLoaded && loginStatus == LoginStatus.LOGGED_IN);

  let homeVisible = loginStatus == LoginStatus.LOGGED_IN;

  return (
    <>
      <div className={loginVisible ? 'loader-visible' : 'loader-hidden'}>
        <Login />
      </div>
      <div className={loaderVisible ? 'loader-visible' : 'loader-hidden'}>
        <Loader />
      </div>
      <div className={homeLoaded ? 'loader-visible' : 'loader-hidden'}>
        {homeVisible && (
          <LazyLoader
            promise={async () => await import('./home')}
            loaded={homeLoaded}
            setLoaded={setHomeLoaded}
          />
        )}
      </div>
    </>
  );
};

export default App;
