import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { ITableLogic } from './table';

export interface IPaginationLogicProps extends Record<string, unknown> {}

export interface IPagination extends Logic {
  readonly props: IPaginationLogicProps;
  readonly actions: {
    readonly setCurrentPage: (currentPage: number) => void;
    readonly setRowsPerPage: (rowsPerPage: number) => void;
    readonly refresh: () => void;
    readonly setPaginator?: (value: boolean) => void;
  };
  readonly values: {
    readonly expand: {
      columns: Record<string, string>;
      logic: interfaces.ServiceIdentifier<ITableLogic>;
    };
    readonly currentPage: number;
    readonly rowsPerPage: number;
    readonly amountOfRecords: number;
    readonly isPaginatorActive?: boolean;
    readonly expandProps?: Record<string, any>;
  };
}

export interface IPaginationLogic extends LogicWrapper<IPagination> {}

export const IPaginationLogicSymbol: interfaces.ServiceIdentifier<IPaginationLogic> =
  Symbol('IPaginationLogic');

export namespace IPaginationLogic {
  export const $: interfaces.ServiceIdentifier<IPaginationLogic> =
    IPaginationLogicSymbol;
}
