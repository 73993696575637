import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { BaseFilterType, BoolFilterValue } from '@/types/filter';
import { GetValueIndex } from '@/utils/filterUtils';

import './style.scss';

interface BoolFilterProps {
  filterValue: BoolFilterValue[];
  filterData: BaseFilterType;
  setFilterValue: Dispatch<SetStateAction<BoolFilterValue[]>>;
}

export const BoolFilter: FC<BoolFilterProps> = (props) => {
  const [filterIndex, setIndex] = useState(-1);

  useEffect(() => {
    const index = GetValueIndex(
      props.filterValue,
      props.filterData,
      props.setFilterValue,
      null,
    );

    setIndex(index);
  }, [props]);

  return (
    <div className="ocs-bool-filter">
      <TriStateCheckbox
        value={
          props.filterValue[filterIndex]
            ? props.filterValue[filterIndex]?.filteredValue
            : true
        }
        onChange={(e) => {
          const newFilterValue = [...props.filterValue];
          newFilterValue[filterIndex].filteredValue = e.value;
          props.setFilterValue(newFilterValue);
        }}
      />
    </div>
  );
};
