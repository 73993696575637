import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDevice, MObject } from '@/types/models';

export interface IObjectWindowLogicProps extends Record<string, unknown> {
  initialData: {
    objectUuid: string;
  };
}

export const objectWindowTabs = {
  visualization: {
    icon: 'ri-building-line',
    index: 0,
    visable: (object: MObject.Full, devices: MDevice.TableView[]) =>
      object.settings.visualizationUuid !== undefined,
  },
  detectors: {
    icon: 'ri-pulse-fill',
    index: 1,
    visable: (object: MObject.Full, devices: MDevice.TableView[]) =>
      devices.find(
        (device) => device.listKind == MDevice.Kind.VibroDetector,
      ) !== undefined,
  },
  generic_devices: {
    icon: 'ri-device-fill',
    index: 2,
    visable: (object: MObject.Full, devices: MDevice.TableView[]) =>
      devices.find(
        (device) => device.listKind == MDevice.Kind.GenericDevice,
      ) !== undefined,
  },
  definedAlarmsTable: {
    icon: 'ri-notification-fill',
    index: 3,
    visable: (object: MObject.Full, devices: MDevice.TableView[]) => true,
  },
  notificationTable: {
    icon: 'ri-chat-1-line',
    index: 4,
    visable: (object: MObject.Full, devices: MDevice.TableView[]) => true,
  },
  graph: {
    icon: 'ri-line-chart-line',
    index: 5,
    visable: (object: MObject.Full, devices: MDevice.TableView[]) => true,
  },
};

export type TobjectWindowTabsKeys =
  | keyof typeof objectWindowTabs
  | keyof typeof objectWindowTabs;

export interface TabModel {
  key: string;
  label: string;
  icon: string;
  index: number;
}

interface IObjectWindow extends Logic {
  readonly props: IObjectWindowLogicProps;
  readonly actions: {
    readonly setActiveTab: (tab: TobjectWindowTabsKeys) => void;
  };
  readonly values: {
    readonly visualizationName: string;
    readonly objectUuid: string;
    readonly currentTab: TobjectWindowTabsKeys | null;
    readonly objectLoaded: boolean;
    readonly object: null | MObject.Full;
    readonly devices: MDevice.TableView[];
    readonly visableTabs: TabModel[];
  };
}

export interface IObjectWindowLogic extends LogicWrapper<IObjectWindow> {}

export namespace IObjectWindowLogic {
  export const $: interfaces.ServiceIdentifier<IObjectWindowLogic> =
    Symbol('IObjectWindowLogic');
}
