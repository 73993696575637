import { CompositeBool, Tag } from '@ocs/ocs-components';

import { EventLevel } from './../types/models/event';

export function convertEventLevelToTag(eventLevel: EventLevel): Tag {
  if (eventLevel != undefined) {
    return {
      icon: eventLevel.icon != undefined ? eventLevel.icon : undefined,
      label: `table.${eventLevel.name}`,
      color: eventLevel.color,
    };
  } else {
    return {
      label: '-----',
      color: '#B3B3B3',
    };
  }
}

export function convertActivationBool(boolValue: boolean): CompositeBool {
  return {
    rowValue: boolValue,
    trueColor: '#77dd77',
    falseColor: '#FF5757',
  };
}
