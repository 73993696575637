import { deepEqual } from 'fast-equals';
import { createContext, useContext, useMemo, useRef } from 'react';
import { useTranslation as useTranslate } from 'react-i18next';

export { useGranularEffect } from 'granular-hooks';

export const createGenericContext = <ValueState>() => {
  const genericContext = createContext<ValueState | undefined>(undefined);

  const useGenericContext = (): ValueState => {
    const context = useContext(genericContext);
    if (!context) {
      throw new Error(
        'useGenericContext must by used within a Provider with some value, or default value',
      );
    }
    return context;
  };
  return [useGenericContext, genericContext.Provider] as const;
};

export const useTranslation = () => {
  useMemo;

  const { t } = useTranslate(['dynamic', 'frontend', 'storybook']);
  return t;
};

export function useDeepMemo<TKey, TValue>(
  memoFn: () => TValue,
  key: TKey,
): TValue {
  const ref = useRef<{ key: TKey; value: TValue }>();

  if (!ref.current || !deepEqual(key, ref.current.key)) {
    ref.current = { key, value: memoFn() };
  }

  return ref.current.value;
}
