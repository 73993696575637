{
  "date": "Date",
  "windowTypes": {
    "users": "Użytkownicy",
    "settings": "Ustawienia",
    "test": "Testy",
    "devices": "Devices",
    "objects": "Objects",
    "definedAlarms": "DefinedAlarms",
    "definedAlarmsEdit": "Alarm Editor"
  },
  "desktop": {
    "layout": "Układ",
    "layoutName": "Nazwa układu",
    "fetch": {
      "error": "Błąd ładowania układu",
      "errorMessage": "Wystąpił błąd podczas ładowania układu pulpitu.",
      "empty": "Brak układu pulpitu",
      "emptyMessage": "Podaj nazwę układu pulpitu aby rozpocząć pracę z systemem."
    },
    "create": {
      "shortName": "Nazwa układu musi składać się z co najmniej 5 znaków."
    }
  },
  "actions": {
    "tryAgain": "Spróbuj ponownie",
    "create": "Stwórz"
  },
  "table": {
    "definedAlarms": {
      "eventLevelId": "Event level",
      "listDescription": "Name",
      "listColor": "Color",
      "state": "State",
      "stateDateTime": "Date of state change",
      "icons": {
        "off": "Off",
        "pendingOff": "Awaiting deactivation",
        "pendingOn": "Awaiting activation",
        "on": "On",
        "error": "Error"
      }
    },
    "alarmGroup": {
      "listDescription": "Name",
      "eventLevelId": "Event level",
      "listColor": "Color",
      "state": "State",
      "stateDate": "Date of state change"
    },
    "notifications": {
      "sourceTags": {
        "definedAlarm": "Alarm",
        "definedAlarmGroup": "Grupa alarmów",
        "device": "Urządzenie",
        "object": "Obiekt",
        "user": "User"
      }
    },
    "event_level": {
      "info": "Info",
      "warning": "Ostrzeżenie",
      "danger": "Zagrożenie",
      "critical": "Krytyczny"
    },
    "device": {
      "listName": "Name",
      "statusNoTransmission": "Transmission status",
      "temporaryLastDataDate": "Last date"
    },
    "object": {
      "listRegion": "Region",
      "listTypeId": "Type ID",
      "listCustomer": "Customer",
      "listCity": "City",
      "listAddress": "Address",
      "listName": "Name",
      "temporaryLastDataDate": "Last date",
      "workState": "Work State",
      "status": "Status",
      "icons": {
        "partialNoTransmission": "partialNoTransmission",
        "totalNoTransmission": "totalNoTransmission",
        "definedAlarms": "DefinedAlarms",
        "heatmeter": "heatmeter",
        "preinsulation": "preinsulation",
        "accesscontrol": "accesscontrol",
        "lowBattery": "lowBattery",
        "watermeter": "watermeter",
        "noStatus": "noStatus"
      }
    }
  },
  "editor": {
    "refresh": {
      "errorMessage": "Load failed, try again",
      "button": "Refresh"
    },
    "toast": {
      "success": "Changes successfully saved.",
      "failure": "Failed to save changes."
    },
    "footer": {
      "confirm": "Confirm",
      "reset": "Reset",
      "confirmMessage": "Are you sure you want to proceed?",
      "acceptLabel": "Yes",
      "rejectLabel": "No"
    },
    "object": {
      "primaryData": "Object settings",
      "name": "Name:",
      "workState": "Work State",
      "delay": "Delay:"
    },
    "definedAlarms": {
      "data": "Alarm Informations",
      "group": "Alarm Group",
      "basicSettings": "Basic Settings",
      "advancedSettings": "Advanced Settings",
      "name": "Name:",
      "device": "Device:",
      "color": "Color:",
      "eventLevel": "Event Level:",
      "priority": "Priority:",
      "active": "Active:",
      "sound": "Sound:",
      "col": "Value field:",
      "rule": "Threshold rule:",
      "value": "Threshold value:",
      "math": "Math:",
      "mathCol": "Math Col:",
      "onDelay": "Delay in crossing the threshold:",
      "offDelay": "Delay of not crossing the threshold:",
      "round": "Rounding:"
    },
    "validator": {
      "emptyMessage": "Field can't be empty."
    },
    "enums": {
      "object": {
        "noState": "No work state"
      },
      "groupRule": {
        "and": "'And' rule",
        "or": "'Or' rule"
      },
      "rule": {
        "equal": "Equal",
        "notequal": "Not Equal",
        "less": "Less",
        "lessorequal": "Less or Equal",
        "greater": "Greater",
        "greaterorequal": "Greater or Equal",
        "change": "Change",
        "nochange": "No change"
      },
      "vibrodetector": {
        "result": "Measuring result"
      },
      "event_level": {
        "info": "Info",
        "warning": "Warning",
        "danger": "Danger",
        "critical": "Critical"
      },
      "math": {
        "add": "Add",
        "sub": "Substract",
        "mul": "Multiply",
        "div": "Divide",
        "modulo": "Modulo"
      }
    }
  },
  "forms": {
    "groupAlarms": {
      "name": "Name:",
      "rule": "Group:",
      "eventLevelId": "Event level:",
      "color": "Color:"
    }
  },
  "toolbar": {
    "paginatorDropdownElements": "Amounts of rows:",
    "searchbarPlaceholder": "Search"
  },
  "settings": {
    "visualization": {
      "visualization": "Visualization",
      "appearance": "Appearance",
      "themes": "Themes",
      "font": {
        "options": "Font options",
        "size": "Font size"
      }
    }
  },
  "tooltips": {
    "logout": "Logout",
    "refresh": "Refresh",
    "fold": "Fold",
    "unfold": "Unfold",
    "addGroup": "Add new group",
    "removeGroupButton": "Remove group",
    "chart": {
      "buttons": {
        "reload": "Refresh chart",
        "live": "Run in real time",
        "saveToCSV": "Save to CSV",
        "saveToImage": "Save chart image"
      }
    }
  }
}
