import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { Button } from 'primereact/button';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { useStorybookTranslation } from '@/i18next';
import { ITableLogic } from '@/logic/interfaces/table/table';
import {
  BaseFilterType,
  BoolFilterValue,
  DateFilterValue,
  IconFilterType,
  TagFilterType,
  TextFilterType,
  TextFilterValue,
} from '@/types/filter';
import { ColumnType } from '@/types/table';

import { BoolFilter } from './bool';
import { DateFilter } from './date/date';
import { IconFilter } from './icon';
import './style.scss';
import { TagFilter } from './tag';
import { TextFilter } from './text';

interface FilterTypeProps {
  onApply: MutableRefObject<(() => void) | null>;
  tableLogicIdentifier?: interfaces.ServiceIdentifier<ITableLogic>;
  tableLogic?: ITableLogic;
  filters: (BaseFilterType | IconFilterType | TagFilterType | TextFilterType)[];
}

export const FilterType: FC<FilterTypeProps> = (props) => {
  let tableLogic: ITableLogic | null = null;

  if (props.tableLogicIdentifier !== undefined) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    tableLogic = useInjection(props.tableLogicIdentifier);
  } else if (props.tableLogic !== undefined) {
    tableLogic = props.tableLogic;
  } else {
    throw 'Table needs to have a logic to work properly.';
  }

  const { setFilterValue, clearFilterValue } = useActions(tableLogic);
  const { filtersValues } = useValues(tableLogic);

  const t = useStorybookTranslation();
  const [tempFilterValues, setTempValues] = useState<
    (TextFilterValue | BoolFilterValue | DateFilterValue)[]
  >([]);

  useEffect(() => {
    if (filtersValues != null) setTempValues(filtersValues);
  }, []);

  return props.filters != null ? (
    <div className="ocs-filter-container">
      <div className="ocs-filters">
        {props.filters.map((filter) => {
          switch (filter.type) {
            case ColumnType.TAG:
              return (
                <TagFilter
                  key={filter.key}
                  tagData={filter}
                  filterValue={tempFilterValues as BoolFilterValue[]}
                  setFilterValue={
                    setTempValues as Dispatch<SetStateAction<BoolFilterValue[]>>
                  }
                />
              );

            case ColumnType.STRING:
              return (
                <TextFilter
                  key={filter.key}
                  textData={filter}
                  filterValue={tempFilterValues as TextFilterValue[]}
                  setFilterValue={
                    setTempValues as Dispatch<SetStateAction<TextFilterValue[]>>
                  }
                />
              );

            case ColumnType.NUMBER:
              return (
                <TextFilter
                  key={filter.key}
                  textData={filter}
                  isNumber
                  filterValue={tempFilterValues as TextFilterValue[]}
                  setFilterValue={
                    setTempValues as Dispatch<SetStateAction<TextFilterValue[]>>
                  }
                />
              );

            case ColumnType.ICON:
              return (
                <IconFilter
                  key={filter.key}
                  iconData={filter}
                  filterValue={tempFilterValues as BoolFilterValue[]}
                  setFilterValue={
                    setTempValues as Dispatch<SetStateAction<BoolFilterValue[]>>
                  }
                />
              );

            case ColumnType.BOOL:
              return (
                <BoolFilter
                  key={filter.key}
                  filterData={filter}
                  filterValue={tempFilterValues as BoolFilterValue[]}
                  setFilterValue={
                    setTempValues as Dispatch<SetStateAction<BoolFilterValue[]>>
                  }
                />
              );

            case ColumnType.DATE:
              return (
                <DateFilter
                  key={filter.key}
                  dateData={filter}
                  filterValue={tempFilterValues as DateFilterValue[]}
                  setFilterValue={
                    setTempValues as Dispatch<SetStateAction<DateFilterValue[]>>
                  }
                />
              );

            default:
              return <span key={filter.key}>{JSON.stringify(filter)}</span>;
          }
        })}
      </div>
      <div className="ocs-filter-buttons">
        <Button
          type="button"
          className="ocs-filter-button p-button-outlined p-button-sm"
          label={t('table.filters.clear')}
          onClick={() => {
            for (const value of props.filters) {
              if (clearFilterValue != null) clearFilterValue(value.key);
            }
            props.onApply.current ? props.onApply.current() : undefined;
          }}
        />
        <Button
          type="button"
          className="ocs-filter-button p-button-sm"
          label={t('table.filters.apply')}
          onClick={() => {
            for (const value of tempFilterValues) {
              if (setFilterValue != null)
                setFilterValue(value.key, value.filteredValue);
            }
            props.onApply.current ? props.onApply.current() : undefined;
          }}
        />
      </div>
    </div>
  ) : (
    <span></span>
  );
};
