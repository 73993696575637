import produce from 'immer';
import {
  actions,
  afterMount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { Dependencies } from '@/deps';
import {
  IEventDictContainerLogic,
  IGroupAlarmsTableLogic,
} from '@/logic/interfaces';
import { IDefinedAlarmsTableLogic } from '@/logic/interfaces/definedAlarmsTable';
import { injectDepsToLogic } from '@/logic/utils';
import { IDefinedAlarmsService } from '@/services/interfaces';
import {
  ConfirmStatus,
  EditorTile,
  EditorValue,
  EnumOptions,
  LoadingStatus,
} from '@/types/custom/editorTypes';
import { MDefinedAlarms } from '@/types/models';
import { delay } from '@/utility/delay';
import {
  convertEnumToEditorArray,
  convertEventLevelToEditorArray,
} from '@/utility/editor';
import { validateValues } from '@/validators/core/editor';

import { IDesktopLogic } from '../../../interfaces/desktop';
import { RecordTypes } from './../../../../types/custom/editorTypes';
import { convertDataToGrpc, convertResponseToModel } from './converters';
import type { logicType } from './indexType';

interface AddGroupFormLogicProps {
  initialData: {
    objectUuid: string;
    alarmGroupUuid?: string;
  };
  deps: {
    definedAlarmsService: IDefinedAlarmsService;
    definedAlarmsTableLogic: IDefinedAlarmsTableLogic;
    eventDictContainerLogic: IEventDictContainerLogic;
    groupAlarmsTableLogic: IGroupAlarmsTableLogic;
    desktopLogic: IDesktopLogic;
  };
}

const logic = kea<logicType>([
  path(['groupAlarms', 'addForm']),
  props(
    {} as {
      initialData: {
        objectUuid: string;
        alarmGroupUuid?: string;
      };
      deps: {
        definedAlarmsService: IDefinedAlarmsService;
        definedAlarmsTableLogic: IDefinedAlarmsTableLogic;

        eventDictContainerLogic: IEventDictContainerLogic;
        groupAlarmsTableLogic: IGroupAlarmsTableLogic;

        desktopLogic: IDesktopLogic;
      };
    },
  ),
  key((props) => {
    if (props.initialData.alarmGroupUuid != null) {
      return `alarmGroupEditor/${props.initialData.objectUuid}/${props.initialData.alarmGroupUuid}`;
    } else {
      return `newAlarmGroup/${props.initialData.objectUuid}`;
    }
  }),
  actions({
    updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: EditorValue,
    ) => ({
      tileKey,
      tabKey,
      valueKey,
      value,
    }),

    refresh: true,

    confirm: true,
    setConfirmStatus: (status: ConfirmStatus) => ({ status }),

    remove: true,
    setRemoveStatus: (status: ConfirmStatus) => ({ status }),

    loadAlarm: true,
    setLoadStatus: (status: LoadingStatus) => ({ status }),

    setInitialValue: (
      value: Record<string, Record<string, Record<string, EditorValue>>>,
    ) => ({
      value,
    }),
    resetToInitialValues: true,
  }),
  reducers({
    value: [
      {
        groupData: {
          groupData: {
            description: {
              value: '',
            },
            rule: {
              value: 0,
            },
            eventLevelId: {
              value: 1,
            },
            color: {
              value: undefined,
            },
          },
        },
      } as Record<string, Record<string, Record<string, EditorValue>>>,
      {
        updateValue: (immutableStore, { tileKey, tabKey, valueKey, value }) =>
          produce(immutableStore, (state) => {
            state[tileKey][tabKey][valueKey] = value;
          }),
        setInitialValue: (_, { value }) => value,
      },
    ],
    initialValue: [
      {} as Record<string, Record<string, Record<string, EditorValue>>>,
      {
        setInitialValue: (_, { value }) => value,
      },
    ],
    isSaving: [
      false,
      {
        confirm: () => true,
        setConfirmStatus: () => false,
      },
    ],
    confirmStatus: [
      ConfirmStatus.unknown as ConfirmStatus,
      {
        setConfirmStatus: (_, { status }) => status,
      },
    ],
    removeStatus: [
      ConfirmStatus.unknown as ConfirmStatus,
      {
        setRemoveStatus: (_, { status }) => status,
      },
    ],
    loadStatus: [
      LoadingStatus.isLoading as LoadingStatus,
      {
        setLoadStatus: (_, { status }) => status,
      },
    ],
    translationPrefix: ['alarmGroups', {}],
    tileLayout: [
      {
        groupData: {
          tileLayout: {
            layoutDirection: 'horizontal',
          },
          tabs: {
            groupData: {
              layoutDirection: 'horizontal',
            },
          },
        },
      } as Record<string, EditorTile>,
      {},
    ],
  }),
  selectors({
    eventLevels: [
      () => [
        (_, props: AddGroupFormLogicProps) =>
          props.deps.eventDictContainerLogic.values.eventLevels,
      ],
      (eventLevels) => eventLevels,
    ],
    enumOptionsMap: [
      (selectors) => [selectors.eventLevels],
      (eventLevels) => {
        const enumOptions: EnumOptions = {
          type: RecordTypes.EnumOptions,
          options: {
            eventLevelId: convertEventLevelToEditorArray(eventLevels),
            rule: convertEnumToEditorArray(
              MDefinedAlarms.GroupRule,
              'groupRule',
            ),
          },
        };

        return enumOptions;
      },
    ],
    editorUuid: [
      () => [(_, props: AddGroupFormLogicProps) => props.initialData],
      (initialData) => {
        return `${initialData.objectUuid}/${initialData.alarmGroupUuid}`;
      },
    ],
    isRemoveActive: [
      () => [(_, props: AddGroupFormLogicProps) => props.initialData],
      (initialData) => {
        if (initialData.alarmGroupUuid != null) return true;
        else return false;
      },
    ],
  }),
  listeners(({ props, values, actions }) => ({
    confirm: async () => {
      const response = await props.deps.definedAlarmsService.storeGroup({
        definedAlarmGroupUuid: props.initialData.alarmGroupUuid,
        data: convertDataToGrpc(
          values.value.groupData,
          props.initialData.objectUuid,
        ),
      });

      if (response.success && validateValues(values.value)) {
        actions.setConfirmStatus(ConfirmStatus.success);
        props.deps
          .groupAlarmsTableLogic({
            objectUuid: props.initialData.objectUuid,
          })
          .actions.refresh();

        props.deps
          .definedAlarmsTableLogic({
            objectUuid: props.initialData.objectUuid,
            alarmGroupUuid: props.initialData.alarmGroupUuid,
          })
          .actions.refresh();

        await delay(500);
        if (props.initialData.alarmGroupUuid != null) {
          props.deps.desktopLogic.actions.removeWindow(
            `alarmsGroupAddForm_editGroupForm/${props.initialData.objectUuid}/${props.initialData.alarmGroupUuid}`,
          );
        } else {
          props.deps.desktopLogic.actions.removeWindow(
            `alarmsGroupAddForm_addGroupForm/${props.initialData.objectUuid}`,
          );
        }
      } else {
        actions.setConfirmStatus(ConfirmStatus.failure);
      }
    },
    remove: async () => {
      if (props.initialData.alarmGroupUuid == null) return;

      const response = await props.deps.definedAlarmsService.removeGroup({
        definedAlarmGroupUuid: props.initialData.alarmGroupUuid,
      });

      if (response.success) {
        actions.setRemoveStatus(ConfirmStatus.success);
        props.deps
          .groupAlarmsTableLogic({
            objectUuid: props.initialData.objectUuid,
          })
          .actions.refresh();

        await delay(500);
        props.deps.desktopLogic.actions.removeWindow(
          `alarmsGroupAddForm_editGroupForm/${props.initialData.objectUuid}/${props.initialData.alarmGroupUuid}`,
        );
      } else {
        actions.setRemoveStatus(ConfirmStatus.failure);
      }
    },
    loadAlarm: async () => {
      if (props.initialData.alarmGroupUuid == null) {
        actions.setLoadStatus(LoadingStatus.success);
        return;
      }

      const response = await props.deps.definedAlarmsService.getGroup({
        definedAlarmGroupUuid: props.initialData.alarmGroupUuid,
      });

      if (response.success && response.data.data != null) {
        actions.setInitialValue(convertResponseToModel(response.data.data));
        actions.setLoadStatus(LoadingStatus.success);
      } else {
        actions.setLoadStatus(LoadingStatus.failure);
      }
    },
    resetToInitialValues: () => {
      actions.setInitialValue(values.initialValue);
    },
    refresh: () => {
      actions.setLoadStatus(LoadingStatus.isLoading);
      actions.loadAlarm();
    },
  })),
  afterMount(({ actions }) => {
    actions.loadAlarm();
  }),
]);

export const groupAlarmsAddForm = injectDepsToLogic(logic, () => ({
  definedAlarmsService: Dependencies.get(IDefinedAlarmsService.$),
  definedAlarmsTableLogic: Dependencies.get(IDefinedAlarmsTableLogic.$),
  eventDictContainerLogic: Dependencies.get(IEventDictContainerLogic.$),
  groupAlarmsTableLogic: Dependencies.get(IGroupAlarmsTableLogic.$),
  desktopLogic: Dependencies.get(IDesktopLogic.$),
}));
