import { deepEqual } from 'fast-equals';
import { useValues } from 'kea';
import { BlockUI } from 'primereact/blockui';
import { memo, useState } from 'react';

import { Chart, ChartHandle } from '@/components/chart';
import { IObjectChartLogicNamed } from '@/logic/interfaces';
import { useInjectedLogicWithMergedProps } from '@/logic/utils';

import './style.scss';

export * from './footer';

export const ObjectChartNamed = memo(function VibrodetectorChart() {
  const [chartHandle, setChartHandle] = useState<ChartHandle | null>(null);
  const chartLogic = useInjectedLogicWithMergedProps(IObjectChartLogicNamed.$, {
    chart: chartHandle,
  });

  const { chartId, loadingState, liveState } = useValues(chartLogic);

  return (
    <div className="vibrodetector-module">
      <BlockUI
        template={
          <i
            className="ri-loader-2-line pi-spin"
            style={{ fontSize: '5rem' }}
          />
        }
        blocked={
          loadingState.running ||
          loadingState.error ||
          liveState.loading ||
          liveState.error
        }
      >
        <Chart
          ref={(current) => {
            if (!deepEqual(current, chartHandle)) {
              setChartHandle(current);
            }
          }}
          id={chartId}
        />
      </BlockUI>
    </div>
  );
});
