import { deepEqual } from 'fast-equals';
import {
  actions,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  propsChanged,
  reducers,
  selectors,
} from 'kea';

import { ChartHandle } from '@/components/chart';
import Dependencies from '@/deps';
import { getLanguage } from '@/i18n';
import { IDeviceViewContainerLogic } from '@/logic/interfaces';
import { MakeInitialDataOptional, injectDepsToLogic } from '@/logic/utils';
import { IDeviceService, IVibrodetectorService } from '@/services/interfaces';
import { MDevice, MGenericDevice } from '@/types/models';

import type { logicType } from './indexType';

export interface VibrodetectorLogicProps {
  deps: {
    vibrodetectorService: IVibrodetectorService;
    deviceViewContainerLogic: IDeviceViewContainerLogic;
    deviceService: IDeviceService;
  };
  initialData: {
    deviceUuid: string;
  };
  chart: ChartHandle | null;
}

const logic = kea<logicType>([
  props({} as VibrodetectorLogicProps),
  key((props) => props.initialData.deviceUuid),
  path(['vibrodetectors', 'charts']),
  actions({
    createSeries: true,
    removeSeries: true,
    focusToDateRange: (fitY?: boolean) => ({ fitY }),
    setDateRange: (dateRange: { start: Date; end: Date }) => ({ dateRange }),
    setLoadedDateRange: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    loadCurrentRange: true,
    loadCurrentRangeSuccess: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    loadCurrentRangeFailure: true,
    toggleLive: true,
    startLive: true,
    startLiveListen: true,
    stopLive: true,
    liveError: true,
    saveToImage: true,
    setLivePreloadedRange: (dateRange: { start: Date; end: Date }) => ({
      dateRange,
    }),
    download: true,
  }),
  reducers(() => ({
    dateRange: [
      { start: new Date(Date.now() - 86400000), end: new Date() },
      {
        setDateRange: (_, { dateRange }) => dateRange,
      },
    ],
    loadedDateRange: [
      null as null | { start: Date; end: Date },
      {
        loadCurrentRangeSuccess: (_, { dateRange }) => dateRange,
        setLoadedDateRange: (_, { dateRange }) => dateRange,
      },
    ],
    loadingState: [
      {
        running: false as boolean,
        success: false as boolean,
        error: false as boolean,
      },
      {
        loadCurrentRange: () => ({
          running: true,
          success: false,
          error: false,
        }),
        loadCurrentRangeSuccess: () => ({
          running: false,
          success: true,
          error: false,
        }),
        loadCurrentRangeFailure: () => ({
          running: false,
          success: false,
          error: true,
        }),
      },
    ],
    liveState: [
      {
        loading: false as boolean,
        error: false as boolean,
        running: false as boolean,
      },
      {
        startLive: () => ({ loading: true, error: false, running: false }),
        startLiveListen: () => ({
          loading: false,
          error: false,
          running: true,
        }),
        stopLive: () => ({ loading: false, error: false, running: false }),
      },
    ],
    livePreloadedRange: [
      null as null | {
        start: Date;
        end: Date;
      },
      {
        setLivePreloadedRange: (_, { dateRange }) => dateRange,
      },
    ],
  })),
  selectors({
    deviceUuid: [
      () => [
        (_, props: VibrodetectorLogicProps) => props.initialData.deviceUuid,
      ],
      (deviceUuid) => deviceUuid,
    ],
    chartHandle: [
      () => [(_, props: VibrodetectorLogicProps) => props.chart],
      (chart) => chart,
    ],
  }),
  listeners(({ actions, props, values, cache }) => {
    const onMessage = (message: { value: [Float64Array, Float64Array] }) => {
      values.chartHandle?.addValueArraysToSeries(
        props.initialData.deviceUuid,
        message.value[0],
        message.value[1],
      );
      const end = new Date(message.value[0][message.value[0].length - 1]);

      const start = new Date(
        end.getTime() -
          Math.abs(
            values.dateRange.start.getTime() - values.dateRange.end.getTime(),
          ),
      );
      actions.setLoadedDateRange({
        start: start,
        end: new Date(message.value[0][message.value[0].length - 1]),
      });
    };

    cache.onMessage = onMessage;

    const onDisconnect = async () => {
      if (values.livePreloadedRange != null) {
        props.deps.vibrodetectorService.stream.cancel(onMessage, {
          uuid: props.initialData.deviceUuid,
          start: values.dateRange.start,
        });
        await new Promise((r) => setTimeout(r, 500));

        await props.deps.vibrodetectorService.stream.listen(
          {
            onMessage: onMessage,
            onDisconnect: onDisconnect,
          },
          {
            uuid: props.initialData.deviceUuid,
            start: values.livePreloadedRange.start,
          },
        );
      }
    };

    return {
      download: async () => {
        let lang: MGenericDevice.Messages.Lang;
        const langStr = getLanguage();
        if (langStr == 'pl') {
          lang = MGenericDevice.Messages.Lang.Pl;
        } else if (langStr == 'en') {
          lang = MGenericDevice.Messages.Lang.En;
        } else {
          lang = MGenericDevice.Messages.Lang.En;
        }
        const response = await props.deps.vibrodetectorService.download({
          lang: lang,
          range: {
            device_uuid: props.initialData.deviceUuid,
            start: values.dateRange.start,
            end: values.dateRange.end,
          },
        });
        if (response.success) {
          process.env.NODE_ENV === 'development'
            ? window.open(
                `https://localhost:8433/api/downloads/${response.data.file_uuid}`,
              )
            : window.open(
                `${window.location.origin}/api/downloads/${response.data.file_uuid}`,
              );
        }
      },
      saveToImage: async () => {
        let device: MDevice.View | null = null;
        if (
          props.initialData.deviceUuid in
          props.deps.deviceViewContainerLogic.values.devices
        ) {
          device =
            props.deps.deviceViewContainerLogic.values.devices[
              props.initialData.deviceUuid
            ];
        } else {
          const response = await props.deps.deviceService.get({
            deviceUuid: props.initialData.deviceUuid,
          });
          if (response.success) {
            device = response.data;
            props.deps.deviceViewContainerLogic.actions.addDevices([device]);
          }
        }
        if (device != null) {
          values.dateRange.start.toLocaleString('en-GB').replace(',', '') +
            '.' +
            values.dateRange.start
              .getMilliseconds()
              .toString()
              .padStart(3, '0');
          values.chartHandle?.saveToImage(
            device.base.name +
              '_' +
              values.dateRange.start.toLocaleString('en-GB').replace(',', '') +
              '.' +
              values.dateRange.start
                .getMilliseconds()
                .toString()
                .padStart(3, '0') +
              '_' +
              values.dateRange.end.toLocaleString('en-GB').replace(',', '') +
              '.' +
              values.dateRange.end
                .getMilliseconds()
                .toString()
                .padStart(3, '0'),
          );
        }
      },
      createSeries: () => {
        values.chartHandle?.addSeries(values.deviceUuid);
      },
      removeSeries: () => {
        values.chartHandle?.removeSeries(values.deviceUuid);
      },
      focusToDateRange: ({ fitY }) => {
        values.chartHandle?.setRange(
          values.dateRange.start.getTime(),
          values.dateRange.end.getTime(),
          fitY,
        );
        values.chartHandle?.setRange(
          values.dateRange.start.getTime(),
          values.dateRange.end.getTime(),
          fitY,
        );
      },
      startLiveListen: async () => {
        if (values.livePreloadedRange != null) {
          values.chartHandle?.setDataCleaning(
            props.initialData.deviceUuid,
            true,
          );
          values.chartHandle?.toggleAutoScroll(true);
          values.chartHandle?.toggleMovement(false);

          await props.deps.vibrodetectorService.stream.listen(
            {
              onMessage: onMessage,
              onDisconnect: onDisconnect,
            },
            {
              uuid: props.initialData.deviceUuid,
              start: values.livePreloadedRange.end,
            },
          );
        }
      },
      startLive: async () => {
        const newDateRange = {
          start: new Date(
            new Date().getTime() -
              Math.abs(
                values.dateRange.start.getTime() -
                  values.dateRange.end.getTime(),
              ),
          ),
          end: new Date(),
        };
        const response = await props.deps.vibrodetectorService.getGraphData({
          deviceUuid: props.initialData.deviceUuid,
          daterange: newDateRange,
        });
        if (response.success) {
          actions.setLivePreloadedRange({
            start: new Date(response.data[0][0]),
            end: new Date(response.data[0][response.data[0].length - 1]),
          });
          values.chartHandle?.clearSeries(props.initialData.deviceUuid);
          values.chartHandle?.addValueArraysToSeries(
            props.initialData.deviceUuid,
            response.data[0],
            response.data[1],
          );
          actions.focusToDateRange(false);
          actions.startLiveListen();
        } else {
          actions.liveError();
        }
      },
      stopLive: () => {
        values.chartHandle?.setDataCleaning(
          props.initialData.deviceUuid,
          false,
        );
        values.chartHandle?.toggleAutoScroll(false);
        values.chartHandle?.toggleMovement(true);

        if (values.livePreloadedRange != null) {
          props.deps.vibrodetectorService.stream.cancel(onMessage, {
            uuid: props.initialData.deviceUuid,
            start: values.livePreloadedRange.end,
          });
        }
        if (values.loadedDateRange != null) {
          actions.setDateRange(values.loadedDateRange);
        }
      },
      toggleLive: () => {
        if (!values.liveState.running) {
          actions.startLive();
        } else {
          actions.stopLive();
        }
      },
      loadCurrentRange: async () => {
        const response = await props.deps.vibrodetectorService.getGraphData({
          deviceUuid: props.initialData.deviceUuid,
          daterange: values.dateRange,
        });
        if (response.success) {
          values.chartHandle?.clearSeries(props.initialData.deviceUuid);
          values.chartHandle?.addValueArraysToSeries(
            props.initialData.deviceUuid,
            response.data[0],
            response.data[1],
          );
          actions.loadCurrentRangeSuccess(values.dateRange);
        } else {
          actions.loadCurrentRangeFailure();
        }
        actions.focusToDateRange();
      },
    };
  }),
  propsChanged(({ actions, props, cache }, oldProps) => {
    if (
      (props.chart != null && cache.loaded == null) ||
      cache.loaded == false
    ) {
      actions.createSeries();
      actions.loadCurrentRange();
      cache.loaded = true;
    }
    if (!deepEqual(props.chart, oldProps.chart)) {
      actions.loadCurrentRange();
    }
  }),
  beforeUnmount(({ actions, cache, props, values }) => {
    actions.removeSeries();
    actions.stopLive();
    if (values.livePreloadedRange !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.deps.vibrodetectorService.stream.cancel(cache.onMessage, {
        uuid: props.initialData.deviceUuid,
        start: values.livePreloadedRange.end,
      });
    }
  }),
]);

const vibrodetectorChartLogic = injectDepsToLogic(logic, () => ({
  vibrodetectorService: Dependencies.get(IVibrodetectorService.$),
  deviceViewContainerLogic: Dependencies.get(IDeviceViewContainerLogic.$),
  deviceService: Dependencies.get(IDeviceService.$),
}));

const vibrodetectorChartLogicInitialDataOptional =
  vibrodetectorChartLogic as MakeInitialDataOptional<
    typeof vibrodetectorChartLogic
  >;

export { vibrodetectorChartLogicInitialDataOptional as vibrodetectorChartLogic };
