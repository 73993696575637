import { MDefinedAlarms } from '@/types/models';
import { TableSearchRule } from '@/types/models/table';
import { composeRequestInput } from '@/utility/inputRequest';

interface GroupAlarmsRequest {
  rowsPerPage: number;
  currentPage: number;
  searchInputs?: string;
  props?: {
    objectUuid?: string;
  };
}

export function getRequestInput(groupAlarmsRequest: GroupAlarmsRequest) {
  if (groupAlarmsRequest.props?.objectUuid != null) {
    return composeRequestInput({
      rowsPerPage: groupAlarmsRequest.rowsPerPage,
      currentPage: groupAlarmsRequest.currentPage,
      searchAll: [
        {
          column: 'objectUuid' as MDefinedAlarms.TableGroupViewSortKeys,
          rule: TableSearchRule.Equal,
          value: [groupAlarmsRequest.props.objectUuid],
        },
      ],
    });
  }

  return composeRequestInput({
    rowsPerPage: groupAlarmsRequest.rowsPerPage,
    currentPage: groupAlarmsRequest.currentPage,
  });
}
