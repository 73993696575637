import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { IStream } from '@/services/types/stream';
import {
  ConfirmAlarmEventInput,
  ConfirmAlarmEventMessage,
  ConfirmAllAlarmEvents,
  GetAlarmEventsListInput,
  GetAlarmEventsListMessage,
  GetEventLevelsListInput,
  GetEventLevelsListMessage,
} from '@/types/messages/event';
import { AlarmEventAction } from '@/types/models/event';

export interface IEventService {
  stream: IStream<undefined, AlarmEventAction>;
  getAlarmEventsList: (
    input: GetAlarmEventsListInput,
  ) => Promise<Response<GetAlarmEventsListMessage>>;
  getEventLevelsList: (
    input: GetEventLevelsListInput,
  ) => Promise<Response<GetEventLevelsListMessage>>;
  confirmAlarmEvent: (
    input: ConfirmAlarmEventInput,
  ) => Promise<Response<ConfirmAlarmEventMessage>>;
  confirmAllAlarmEvents: (
    input: ConfirmAllAlarmEvents,
  ) => Promise<Response<void>>;
  removeAllAlarmEvents: () => Promise<Response<void>>;
}

export namespace IEventService {
  export const $: interfaces.ServiceIdentifier<IEventService> =
    Symbol('IEventService');
}
