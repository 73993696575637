import { FC } from 'react';

import { ColumnType } from '@/types/table';

import { BoolTemplate } from '../bool/bool';
import { ColorTemplate } from '../color';
import { DateTemplate } from '../date/date';
import { IconTemplate } from '../icon/icon';
import { TagTemplate } from '../tag';
import './style.scss';

export interface ColumnTemplateProps {
  type?: ColumnType;
  value: any;
}

export const ColumnTemplate: FC<ColumnTemplateProps> = (props) => {
  switch (props.type) {
    case ColumnType.ICON:
      return <IconTemplate value={props.value} />;

    case ColumnType.DATE:
      return <DateTemplate value={props.value} />;

    case ColumnType.TAG:
      return <TagTemplate value={props.value} />;

    case ColumnType.BOOL:
      return <BoolTemplate value={props.value} />;

    case ColumnType.COLOR:
      return <ColorTemplate value={props.value} />;

    case ColumnType.STRING:
      return <span>{props.value}</span>;

    case ColumnType.NUMBER:
      return <span>{props.value}</span>;

    default:
      return (
        <span className="ocs-default-template">
          {JSON.stringify(props.value)}
        </span>
      );
  }
};
