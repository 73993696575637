import { produce } from 'immer';
import { actions, kea, listeners, path, reducers, selectors } from 'kea';

import Dependencies from '@/deps';
import { injectDepsToLogic } from '@/logic/utils';
import { IEventService } from '@/services/interfaces';
import { AlarmEvent } from '@/types/models/event';

import type { logicType } from './indexType';

const logic = kea<logicType>([
  path(['events', 'alarm', 'container']),
  actions({
    startGarbageCollection: true,
    stopGarbageCollection: true,
    addEvents: (events: AlarmEvent[]) => ({ events }),
    useEvents: (eventIds: number[]) => ({ eventIds }),
    releaseEvents: (eventIds: number[]) => ({ eventIds }),
    removeEvents: (eventIds: number[]) => ({ eventIds }),
  }),
  reducers({
    eventsMap: [
      new Map() as Map<number, AlarmEvent>,
      {
        addEvents: (immutableState, { events }) =>
          produce(immutableState, (state) => {
            for (const event of events) {
              state.set(event.alarmEventId, event);
            }
          }),
        removeEvents: (immutableState, { eventIds }) =>
          produce(immutableState, (state) => {
            for (const eventId of eventIds) {
              state.delete(eventId);
            }
          }),
      },
    ],
    usageCounters: [
      {} as Map<
        number,
        {
          counter: number;
          lastUseTime: Date;
        }
      >,
      {},
    ],
    collectingGarbage: [
      false as boolean,
      {
        startGarbageCollection: () => true,
        stopGarbageCollection: () => false,
      },
    ],
  }),
  selectors({
    events: [
      (selectors) => [selectors.eventsMap],
      (eventsMap) => {
        return Object.fromEntries(eventsMap);
      },
    ],
  }),
  listeners(({ values, actions }) => ({
    startGarbageCollection: async (_, breakpoint) => {
      breakpoint();
      while (values.collectingGarbage) {
        const currentTime = new Date();
        const eventIdsToRemove: number[] = [];
        values.usageCounters.forEach((usageCounter, eventKey) => {
          if (
            usageCounter.counter <= 0 &&
            currentTime.getTime() - usageCounter.lastUseTime.getTime() >= 5000
          ) {
            eventIdsToRemove.push(eventKey);
          }
        });

        if (eventIdsToRemove.length > 0) {
          actions.removeEvents(eventIdsToRemove);
        }
        await breakpoint(5000);
      }
    },
  })),
]);

export const eventAlarmContainerLogic = injectDepsToLogic(logic, () => ({
  eventService: Dependencies.get(IEventService.$),
}));
