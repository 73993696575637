import { useInjection } from 'inversify-react';
import { useMountedLogic, useValues } from 'kea';
import { FC, useMemo } from 'react';

import { IVisElementLogic } from '@/logic/interfaces/visualization/visElement';
import { Dynamization, MeasurementType } from '@/types/visualization';
import { GetRgbString, convertRgbToHex, darkenColor } from '@/utils/colorUtils';
import { GetDynamicProps } from '@/utils/visualizationUtils';

import { visualizationContextLogic as visualLogic } from '.';

interface MeasurementProps {
  measurement: MeasurementType;
}

export const MeasurementVisualization: FC<MeasurementProps> = (props) => {
  const visLogic = useMountedLogic(visualLogic);

  const visElementLogic = useInjection(IVisElementLogic.$)({
    sourceUuid: visLogic.props.sourceUuid,
    sourceType: visLogic.props.sourceType,
    visualizationUuid: visLogic.props.visualizationUuid,
    name: visLogic.props.name,
    elementUuid: props.measurement.name,
    tasks: props.measurement.tasks,
  });

  const { values } = useValues(visElementLogic);

  const borderColor = useMemo(() => {
    const hex = convertRgbToHex(
      props.measurement.color.red,
      props.measurement.color.green,
      props.measurement.color.blue,
    );
    return darkenColor(hex, 0.7);
  }, [props.measurement.color]);

  const dynamicProps = useMemo(() => {
    if (values != null && values[0])
      return GetDynamicProps(props.measurement, values[0]) as MeasurementType;
    else return props.measurement;
  }, [props.measurement]);

  if (
    dynamicProps.dynamization === Dynamization.Visibility &&
    values != null &&
    values[0] == 0
  )
    return <></>;

  return (
    <span
      style={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: dynamicProps.alignment,

        border: `2px solid ${borderColor}`,

        top: dynamicProps.top,
        left: dynamicProps.left,
        height: dynamicProps.height,
        width: dynamicProps.width,

        backgroundColor: GetRgbString(
          dynamicProps.color.red,
          dynamicProps.color.green,
          dynamicProps.color.blue,
        ),

        fontFamily: dynamicProps.font,
        color: GetRgbString(
          dynamicProps.fontColor.red,
          dynamicProps.fontColor.green,
          dynamicProps.fontColor.blue,
        ),
        fontWeight: `${dynamicProps.fontStyle.bold ? 'bold' : 'normal'}`,
        fontStyle: `${dynamicProps.fontStyle.italic ? 'italic' : 'normal'}`,
        textDecoration: `${
          dynamicProps.fontStyle.underline ? 'underline' : ''
        } ${dynamicProps.fontStyle.crossedOut ? 'line-through' : ''}`,

        visibility: dynamicProps.hidden ? 'hidden' : 'visible',
      }}
    >
      {values != null && values.length != 0 ? values[0] : '####'}
    </span>
  );
};
