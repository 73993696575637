import { Icon, SideIcon } from '@ocs/ocs-components';

import { MObject } from '@/types/models';

enum StatusNameMap {
  partialNoTransmission = 'partialNoTransmission',
  totalNoTransmission = 'totalNoTransmission',
  definedAlarm = 'definedAlarm',
  heatmeter = 'heatmeter',
  preinsulation = 'preinsulation',
  accesscontrol = 'accesscontrol',
  lowBattery = 'lowBattery',
  watermeter = 'watermeter',
  noStatus = 'noStatus',
}

const icons: Record<
  StatusNameMap,
  { icon: string; name: string; color: string }
> = {
  partialNoTransmission: {
    icon: 'ri-signal-wifi-2-line',
    name: 'table.object.icons.partialNoTransmission',
    color: 'var(--blue-500)',
  },
  totalNoTransmission: {
    icon: 'ri-signal-wifi-line',
    name: 'table.object.icons.totalNoTransmission',
    color: 'var(--blue-500)',
  },
  definedAlarm: {
    icon: 'ri-alert-line',
    name: 'table.object.icons.definedAlarms',
    color: '#e8eb34',
  },
  heatmeter: {
    icon: 'ri-fire-line',
    name: 'table.object.icons.heatmeter',
    color: '#a2eb34',
  },
  preinsulation: {
    icon: 'ri-arrow-left-right-fill',
    name: 'table.object.icons.preinsulation',
    color: '#49eb34',
  },
  accesscontrol: {
    icon: 'ri-checkbox-circle-line',
    name: 'table.object.icons.accesscontrol',
    color: '#34ebc6',
  },
  lowBattery: {
    icon: 'ri-battery-low-line',
    name: 'table.object.icons.lowBattery',
    color: '#34a8eb',
  },
  watermeter: {
    icon: 'ri-drop-line',
    name: 'table.object.icons.watermeter',
    color: '#34a8eb',
  },
  noStatus: {
    icon: 'ri-check-double-line',
    name: 'table.object.icons.noStatus',
    color: 'var(--green-500)',
  },
};

export function getFieldIcon(status: MObject.Status): Icon {
  if (status.partialNoTransmission) {
    return {
      ...icons.partialNoTransmission,
      sideIcons: getSideIcons(StatusNameMap.partialNoTransmission, status),
    };
  }

  if (status.totalNoTransmission) {
    return {
      ...icons.totalNoTransmission,
      sideIcons: getSideIcons(StatusNameMap.totalNoTransmission, status),
    };
  }

  if (status.definedAlarm) {
    return {
      ...icons.definedAlarm,
      sideIcons: getSideIcons(StatusNameMap.definedAlarm, status),
    };
  }

  if (status.heatmeter) {
    return {
      ...icons.heatmeter,
      sideIcons: getSideIcons(StatusNameMap.heatmeter, status),
    };
  }

  if (status.preinsulation) {
    return {
      ...icons.preinsulation,
      sideIcons: getSideIcons(StatusNameMap.preinsulation, status),
    };
  }

  if (status.accesscontrol) {
    return {
      ...icons.accesscontrol,
      sideIcons: getSideIcons(StatusNameMap.accesscontrol, status),
    };
  }

  if (status.lowBattery) {
    return {
      ...icons.lowBattery,
      sideIcons: getSideIcons(StatusNameMap.lowBattery, status),
    };
  }

  if (status.watermeter) {
    return {
      ...icons.watermeter,
      sideIcons: getSideIcons(StatusNameMap.watermeter, status),
    };
  }

  return {
    ...icons.noStatus,
  };
}

function getSideIcons(
  mainIcon: StatusNameMap,
  status: MObject.Status,
): SideIcon[] {
  const sideIcons: Record<string, SideIcon> = {
    partialNoTransmission: {
      ...icons.partialNoTransmission,
      isActive: status.partialNoTransmission,
    },
    totalNoTransmission: {
      ...icons.totalNoTransmission,
      isActive: status.totalNoTransmission,
    },
    definedAlarm: {
      ...icons.definedAlarm,
      isActive: status.definedAlarm,
    },
    heatmeter: {
      ...icons.heatmeter,
      isActive: status.heatmeter,
    },
    preinsulation: {
      ...icons.preinsulation,
      isActive: status.preinsulation,
    },
    accesscontrol: {
      ...icons.accesscontrol,
      isActive: status.accesscontrol,
    },
    lowBattery: {
      ...icons.lowBattery,
      isActive: status.lowBattery,
    },
    watermeter: {
      ...icons.watermeter,
      isActive: status.watermeter,
    },
  };

  delete sideIcons[mainIcon];

  return Object.values(sideIcons);
}
