import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { BuiltLogic, LogicWrapper, useMountedLogic } from 'kea';
import { merge } from 'lodash';

import { useDeepMemo } from '@/hooks';

import { PartialBy } from './../../../old/frontend_old2/src/utility/types';

export type RemoveDepsType<MyLogic extends LogicWrapper> =
  MyLogic extends LogicWrapper<infer LogicType>
    ? LogicWrapper<{
        [Key in keyof LogicType]: 'props' extends Key
          ? Omit<LogicType['props'], 'deps'>
          : LogicType[Key];
      }>
    : never;

export type MakeInitialDataOptional<MyLogic extends LogicWrapper> =
  MyLogic extends LogicWrapper<infer LogicType>
    ? LogicWrapper<{
        [Key in keyof LogicType]: 'props' extends Key
          ? PartialBy<LogicType['props'], 'initialData'>
          : LogicType[Key];
      }>
    : never;

export function injectDepsToLogic<TWrappedLogic extends LogicWrapper>(
  logic: TWrappedLogic,
  depsBuilder: () => TWrappedLogic['props']['deps'],
): RemoveDepsType<TWrappedLogic> {
  const oldBuild = logic.build;
  logic.build = (props: any) => {
    return oldBuild({ ...props, deps: depsBuilder() });
  };

  return logic as RemoveDepsType<TWrappedLogic>;
}

export function useInjectedLogicWithMergedProps<MyLogic extends LogicWrapper>(
  identifier: interfaces.ServiceIdentifier<MyLogic>,
  props: Partial<MyLogic['props']>,
): BuiltLogic<MyLogic> {
  const logic = useInjection(identifier);
  const logicWithoutProps = useMountedLogic(logic);

  const newProps: unknown = useDeepMemo(() => {
    return merge(logicWithoutProps.props, props) as unknown;
  }, [logicWithoutProps.props, props]);

  const logicWithProps = logic(newProps);

  return logicWithProps as BuiltLogic<MyLogic>;
}
