import { Provider } from 'inversify-react';
import ReactDOMClient from 'react-dom/client';
import PrimeReact from 'primereact/api';
PrimeReact.ripple = true;

import App from '@/app';
import { TranslationProvider } from '@/i18n';
import { startKea } from '@/logic';
import type {} from 'react/next'

import { disableReactDevTools } from './scripts';
import './scss/main.scss';
import { Dependencies } from './deps';

(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

if (process.env.NODE_ENV === 'development') {
  console.log('Mode: Development');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any
  const debug: any = require('debug');
  if (process.env.THREAD_JS_DEBUG === 'true') {
    console.log("threads.js debug enabled");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    debug.enable('*');  
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    debug.enable('threads:*');
  } else {
    console.log("threads.js debug disabled");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    debug.disable();
  }

} else {
  disableReactDevTools();
}

startKea();

function Init() {
  return (
    <Provider container={Dependencies}>
      <TranslationProvider>
        <App />
      </TranslationProvider>
    </Provider>
  );
}
// @ts-ignore
ReactDOMClient.createRoot(document.getElementById('root')).render(<Init />);
