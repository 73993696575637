import { MObject } from '@/types/models';
import { TableSearchRule } from '@/types/models/table';

export function GetSearchInputs(value: string) {
  return [
    {
      column: 'listRegion' as MObject.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
    {
      column: 'listCustomer' as MObject.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
    // {
    //   column: 'listCity' as MObject.TableViewSortKeys,
    //   rule: TableSearchRule.Equal,
    //   value: [value],
    // },
    {
      column: 'listAddress' as MObject.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
    {
      column: 'listName' as MObject.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
  ];
}
