import {
  BuiltLogic,
  actions,
  kea,
  key,
  listeners,
  path,
  props,
  selectors,
} from 'kea';

import { IEditorLogic } from '@/logic/interfaces/editor';
import { EditorFieldKey, EditorValue } from '@/types/custom/editorTypes';

import type { editorFieldLogicType } from './indexType';

export const editorFieldLogic = kea<editorFieldLogicType>([
  props(
    {} as {
      editorLogic: BuiltLogic<IEditorLogic>;
      editorKey: EditorFieldKey;
    },
  ),
  key(
    (props) =>
      `${props.editorKey.editorUuid}/${props.editorKey.tileKey}/${props.editorKey.tabKey}/${props.editorKey.valueKey}`,
  ),
  actions({
    updateValue: (value: EditorValue) => ({ value }),
  }),
  selectors(({ props }) => ({
    editorKey: [
      () => [(_, props) => props.editorKey],
      (editorKey) => editorKey,
    ],
    value: [
      (selectors) => [selectors.editorKey, props.editorLogic.selectors.value],
      (
        editorKey: EditorFieldKey,
        value: Record<string, Record<string, Record<string, EditorValue>>>,
      ) => {
        return Object.keys(value).length === 0
          ? undefined
          : value[editorKey.tileKey][editorKey.tabKey][editorKey.valueKey]
              .value;
      },
    ],
  })),
  listeners(({ props }) => ({
    updateValue: ({ value }) => {
      props.editorLogic.actions.updateValue(
        props.editorKey.tileKey,
        props.editorKey.tabKey,
        props.editorKey.valueKey,
        value,
      );
    },
  })),
]);
