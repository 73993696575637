import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabMenu } from 'primereact/tabmenu';
import { memo } from 'react';

import { IObjectWindowLogic, TobjectWindowTabsKeys } from '@/logic/interfaces';
import { INotificationTableLogic } from '@/logic/interfaces/notificationTable';

import './../../datatables/mainStyle.scss';
import { DefinedAlarmsTab } from './definedAlarms';
import { GenericDevicesContainer } from './genericdevices';
import { GraphContainer } from './graph';
import { NotificationTab } from './notifications';
import './style.scss';
import { VibroDetectorsView } from './vibrodetectors';
import { VisualizationView } from './visualization';

export const ObjectWindowHeaderLeft = memo(
  function VibroDetectorChartFooterLeft() {
    const viewLogic = useInjection(IObjectWindowLogic.$);

    const { setActiveTab } = useActions(viewLogic);
    const { currentTab, objectUuid, visableTabs } = useValues(viewLogic);

    const notificationPaginationLogic = useInjection(INotificationTableLogic.$)(
      {
        objectUuid: objectUuid,
      },
    );
    const { setPaginator: setNotificationPaginator } = useActions(
      notificationPaginationLogic,
    );

    return (
      <div>
        <TabMenu
          className="object-view-tab-menu"
          model={visableTabs}
          activeIndex={visableTabs.findIndex((t) => t.key == currentTab)}
          onTabChange={(e) => {
            if (visableTabs[e.index].key === 'notificationTable') {
              if (setNotificationPaginator != null)
                setNotificationPaginator(true);
            } else {
              if (setNotificationPaginator != null) {
                setNotificationPaginator(false);
              }
            }
            setActiveTab(visableTabs[e.index].key as TobjectWindowTabsKeys);
          }}
        />
      </div>
    );
  },
);

export const ObjectWindow = memo(function ObjectView() {
  const viewLogic = useInjection(IObjectWindowLogic.$);
  const { currentTab, objectLoaded } = useValues(viewLogic);

  if (objectLoaded) {
    return (
      <div className="object-view">
        {currentTab == 'visualization' && (
          <VisualizationView></VisualizationView>
        )}
        {currentTab == 'detectors' && <VibroDetectorsView></VibroDetectorsView>}
        {currentTab == 'generic_devices' && (
          <GenericDevicesContainer></GenericDevicesContainer>
        )}
        {currentTab == 'definedAlarmsTable' && <DefinedAlarmsTab />}
        {currentTab == 'notificationTable' && <NotificationTab />}
        {currentTab == 'graph' && <GraphContainer></GraphContainer>}
      </div>
    );
  } else {
    return (
      <div className="object-view-block-ui">
        <BlockUI blocked={true} template={<ProgressSpinner />}></BlockUI>
      </div>
    );
  }
});
