import { IEventService } from '@/services/interfaces';
import { AlarmEvent } from '@/types/models/event';
import { TableOrder } from '@/types/models/table';

export async function loadAlarmEvents(
  service: IEventService,
): Promise<Set<AlarmEvent> | null> {
  const response = await service.getAlarmEventsList({
    sort: {
      order: TableOrder.Desc,
      column: 'startDatetime',
    },
    count: {
      count: 80,
      page: 0,
    },
    searchAll: [],
    searchAny: [],
  });
  const responseEvents = new Set<AlarmEvent>();

  if (response.success) {
    for (const event of response.data.alarmEvents.reverse()) {
      responseEvents.add(event);
    }
    return responseEvents;
  }
  return null;
}
