import { ColumnType, SortOptions } from '@ocs/ocs-components';
import {
  actions,
  afterMount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { Dependencies } from '@/deps';
import { IEventDictContainerLogic } from '@/logic/interfaces';
import { IDefinedAlarmsTableLogic } from '@/logic/interfaces/definedAlarmsTable';
import { injectDepsToLogic } from '@/logic/utils';
import { IDefinedAlarmsService } from '@/services/interfaces';
import { TableRequestInput } from '@/types/messages/table';
import { MDefinedAlarms } from '@/types/models/definedAlarms';
import { convertEventLevelToTag } from '@/utility/table';
import { StringKeys } from '@/utility/types';

import { definedAlarmsIcons } from '../../definedAlarms/table/iconDefinition';
import { IDesktopLogic } from './../../../interfaces/desktop';
import type { logicType } from './indexType';
import { getRequestInput } from './searchRequest';

interface GroupAlarmsTableProps {
  objectUuid: string;
  deps: {
    definedAlarmsTableLogic: IDefinedAlarmsTableLogic;
    definedAlarmsService: IDefinedAlarmsService;

    eventDictContainerLogic: IEventDictContainerLogic;

    desktopLogic: IDesktopLogic;
  };
}
const logic = kea<logicType>([
  props(
    {} as {
      objectUuid: string;
      deps: {
        definedAlarmsTableLogic: IDefinedAlarmsTableLogic;
        definedAlarmsService: IDefinedAlarmsService;

        eventDictContainerLogic: IEventDictContainerLogic;

        desktopLogic: IDesktopLogic;
      };
    },
  ),
  key((props) => props.objectUuid),
  path((key) => ['groupAlarms', 'table', key]),
  actions({
    refresh: true,
    load: true,
    loadSuccess: (alarmGroups: MDefinedAlarms.GroupView[]) => ({ alarmGroups }),
    loadFailure: true,
    setCurrentPage: (page: number) => ({ page }),
    setAmountOfRecords: (amount: number) => ({ amount }),
    setRowsPerPage: (rows: number) => ({ rows }),
    setPaginator: (value: boolean) => ({ value }),
    setSortOptions: (sortOptions: SortOptions) => ({ sortOptions }),
    onRowClicked: (rowKey: string) => ({ rowKey }),
    setExpandedRowKey: (key: string | undefined) => ({ key }),
  }),
  reducers({
    dataKey: ['definedAlarmGroupUuid' as 'definedAlarmGroupUuid'],
    columns: [
      [
        {
          field: 'listDescription',
          header: 'table.alarmGroup.listDescription',
        },
        {
          field: 'eventLevelId',
          header: 'table.alarmGroup.eventLevelId',
        },
        {
          field: 'listColor',
          header: 'table.alarmGroup.listColor',
        },
        {
          field: 'groupsTemporaryState',
          header: 'table.alarmGroup.state',
        },
        {
          field: 'groupsTemporaryStateDateTime',
          header: 'table.alarmGroup.stateDate',
        },
      ] as {
        field: StringKeys<MDefinedAlarms.TableView>;
        header: string;
      }[],
    ],
    columnTypes: [
      {
        listDescription: ColumnType.STRING,
        eventLevelId: ColumnType.TAG,
        listColor: ColumnType.COLOR,
        groupsTemporaryState: ColumnType.ICON,
        groupsTemporaryStateDateTime: ColumnType.DATE,
      } as Record<string, ColumnType>,
    ],
    //sortableColumns: [{}],
    expand: [
      {
        columns: {
          alarmGroupUuid: 'definedAlarmGroupUuid',
          objectUuid: `objectUuid`,
        },
        logic: IDefinedAlarmsTableLogic.$,
      },
    ],
    expandKey: [
      undefined as string | undefined,
      {
        setExpandedRowKey: (_, { key }) => key,
      },
    ],
    loading: [
      false,
      {
        load: () => true,
        loadSuccess: () => false,
        loadFailure: () => false,
      },
    ],
    error: [
      false,
      {
        load: () => false,
        loadSuccess: () => false,
        loadFailure: () => true,
      },
    ],
    currentPage: [
      0,
      {
        setCurrentPage: (_, { page }) => page,
      },
    ],
    rowsPerPage: [
      25,
      {
        setRowsPerPage: (_, { rows }) => rows,
      },
    ],
    amountOfRecords: [
      0,
      {
        setAmountOfRecords: (_, { amount }) => amount,
      },
    ],
    isPaginatorActive: [
      true,
      {
        setPaginator: (_, { value }) => value,
      },
    ],
    sortOptions: [
      undefined as SortOptions | undefined,
      {
        setSortOptions: (_, { sortOptions }) => sortOptions,
      },
    ],
    groupAlarms: [
      [] as MDefinedAlarms.GroupView[],
      {
        loadSuccess: (_, { alarmGroups }) => alarmGroups,
      },
    ],
  }),
  selectors({
    values: [
      (selectors) => [
        selectors.groupAlarms,
        (_, props: GroupAlarmsTableProps) =>
          props.deps.eventDictContainerLogic.values.eventLevels,
      ],
      (groupAlarms, eventLevels) => {
        return groupAlarms.map((group) => {
          return {
            definedAlarmGroupUuid: group.definedAlarmGroupUuid,
            objectUuid: group.objectUuid,
            eventLevelId: convertEventLevelToTag(
              eventLevels[group.eventLevelId],
            ),
            listDescription: group.data.description,
            listColor: group.data.color ? `#${group.data.color}` : '',
            groupsTemporaryState:
              definedAlarmsIcons[group.temporary.temporaryState],
            groupsTemporaryStateDateTime: {
              startDate: group.temporary.temporaryStateDateTime,
            },
          } as MDefinedAlarms.TableGroupView;
        });
      },
    ],
    expandProps: [
      (selectors) => [selectors.expandKey, selectors.values],
      (expandKey, values) => {
        const alarmGroup = values.find(
          (group) => group.definedAlarmGroupUuid === expandKey,
        );

        if (alarmGroup != null) {
          return {
            objectUuid: alarmGroup.objectUuid,
            alarmGroupUuid: alarmGroup.definedAlarmGroupUuid,
          };
        }
      },
    ],
  }),
  listeners(({ actions, props, values }) => ({
    // onRowClicked: ({ rowKey }) => {
    //   props.deps.desktopLogic.actions.openWindow('alarmsGroupAddForm', {
    //     initialData: {
    //       objectUuid: props.objectUuid,
    //       alarmGroupUuid: rowKey,
    //     },
    //     windowKey: `editGroupForm/${props.objectUuid}/${rowKey}`,
    //     title: 'windowTypes.editGroupForm',
    //   });
    // },
    refresh: () => {
      actions.load();
    },
    load: async () => {
      const input = getRequestInput({
        rowsPerPage: values.rowsPerPage,
        currentPage: values.currentPage,
        props: {
          objectUuid: props.objectUuid,
        },
      }) as TableRequestInput<MDefinedAlarms.TableGroupViewSortKeys>;

      const response = await props.deps.definedAlarmsService.getGroupList(
        input,
      );

      if (response.success) {
        actions.setAmountOfRecords(response.data.total);
        actions.loadSuccess(response.data.definedAlarmGroups);
      } else {
        actions.loadFailure();
      }
    },
  })),
  afterMount(({ actions }) => {
    actions.refresh();
  }),
]);

export const groupAlarmsTableLogic = injectDepsToLogic(logic, () => ({
  definedAlarmsTableLogic: Dependencies.get(IDefinedAlarmsTableLogic.$),
  definedAlarmsService: Dependencies.get(IDefinedAlarmsService.$),
  eventDictContainerLogic: Dependencies.get(IEventDictContainerLogic.$),
  desktopLogic: Dependencies.get(IDesktopLogic.$),
}));
