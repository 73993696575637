import { MDevice } from '@/types/models';
import { TableSearchRule } from '@/types/models/table';

export function GetSearchInputs(value: string) {
  return [
    {
      column: 'listName' as MDevice.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
    {
      column: 'temporaryLastDataDate' as MDevice.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
  ];
}
