import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { UserLoginInMessage, UserLoginOutMessage } from '@/types/messages/user';

export interface IUserService {
  login: (input: UserLoginInMessage) => Promise<Response<UserLoginOutMessage>>;
  logout: () => Promise<Response<undefined>>;
  logged: () => Promise<Response<undefined>>;
  keepAlive: () => Promise<Response<undefined>>;
}

export namespace IUserService {
  export const $: interfaces.ServiceIdentifier<IUserService> =
    Symbol('IUserService');
}
