import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { MHubs } from '@/types/models/hubs';

export interface IHubsService {
  get(input: MHubs.Messages.GetIn): Promise<Response<MHubs.Messages.GetOut>>;
  store(
    input: MHubs.Messages.StoreIn,
  ): Promise<Response<MHubs.Messages.StoreOut>>;
}

export namespace IHubsService {
  export const $: interfaces.ServiceIdentifier<IHubsService> =
    Symbol('IHubsService');
}
