"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useGranularHook = (hook, callback, primaryDeps, secondaryDeps) => {
    const ref = (0, react_1.useRef)();
    if (!ref.current ||
        !primaryDeps.every((w, i) => Object.is(w, ref.current[i])))
        ref.current = [...primaryDeps, ...secondaryDeps];
    return hook(callback, ref.current);
};
exports.default = useGranularHook;
