import {
  ColumnType,
  CompositeDate,
  SortOptions,
  Tag,
} from '@ocs/ocs-components';
import {
  actions,
  afterMount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import Dependencies from '@/deps';
import { injectDepsToLogic } from '@/logic/utils';
import { IEventService } from '@/services/interfaces';
import { AlarmEvent, AlarmEventTableViewSortKeys } from '@/types/models/event';
import { TableOrder, TableSearchRule } from '@/types/models/table';
import { convertEventLevelToTag } from '@/utility/table';
import { StringKeys } from '@/utility/types';

import { IEventDictContainerLogic } from './../../../interfaces/eventDictContainer';
import { convertSourceToTag } from './converters';
import type { logicType } from './indexType';

export interface NotificationTableView {
  eventId: number;
  startDatetime: CompositeDate;
  endDatetime?: CompositeDate;
  text: string;
  color?: string;
  source: Tag;
  confirmUserText?: string;
  confirmUserDate?: CompositeDate;
  eventLevelId: Tag;
}

interface NotificationTableProps {
  objectUuid: string;
  deps: {
    eventService: IEventService;
    eventDictContainerLogic: IEventDictContainerLogic;
  };
}

const logic = kea<logicType>([
  props(
    {} as {
      objectUuid: string;
      deps: {
        eventService: IEventService;
        eventDictContainerLogic: IEventDictContainerLogic;
      };
    },
  ),
  key((props) => `${props.objectUuid}/notificationTable`),
  path(['definedAlarms', 'notificationTable']),
  actions({
    refresh: true,
    loadAlarmEvents: true,
    loadSuccess: (alarmEvents: AlarmEvent[]) => ({ alarmEvents }),
    loadFailure: true,
    setCurrentPage: (page: number) => ({ page }),
    setAmountOfRecords: (amount: number) => ({ amount }),
    setRowsPerPage: (rows: number) => ({ rows }),
    setPaginator: (value: boolean) => ({ value }),
    setSortOptions: (sortOptions: SortOptions) => ({ sortOptions }),
    onRowClicked: (rowKey: string) => ({ rowKey }),
    setChosenEvent: (id: number | undefined) => ({ id }),
    setDialogVisibility: (value: boolean) => ({ value }),
  }),
  reducers({
    dataKey: ['eventId' as 'eventId'],
    columns: [
      [
        { field: 'text', header: 'table.notifications.text' },
        { field: 'startDatetime', header: 'table.notifications.startDatetime' },
        {
          field: 'endDatetime',
          header: 'table.notifications.endDatetime',
        },
        { field: 'source', header: 'table.notifications.source' },
        {
          field: 'confirmUserText',
          header: 'table.notifications.confirmUserText',
        },
        {
          field: 'confirmUserDate',
          header: 'table.notifications.confirmUserDate',
        },
        { field: 'eventLevelId', header: 'table.notifications.eventLevelId' },
      ] as {
        field: StringKeys<AlarmEvent>;
        header: string;
      }[],
    ],
    columnTypes: [
      {
        startDatetime: ColumnType.DATE,
        endDatetime: ColumnType.DATE,
        text: ColumnType.STRING,
        source: ColumnType.TAG,
        confirmUserText: ColumnType.STRING,
        confirmUserDate: ColumnType.DATE,
        eventLevelId: ColumnType.TAG,
      } as Record<string, ColumnType>,
    ],
    alarmEvents: [
      [] as AlarmEvent[],
      {
        loadSuccess: (_, { alarmEvents }) => alarmEvents,
      },
    ],
    sortableColumns: ['startDatetime'],
    sortOptions: [
      undefined as SortOptions | undefined,
      {
        setSortOptions: (_, { sortOptions }) => sortOptions,
      },
    ],
    loading: [
      false,
      {
        load: () => true,
        loadSuccess: () => false,
        loadFailure: () => false,
      },
    ],
    error: [
      false,
      {
        load: () => false,
        loadSuccess: () => false,
        loadFailure: () => true,
      },
    ],
    currentPage: [
      0,
      {
        setCurrentPage: (_, { page }) => page,
      },
    ],
    rowsPerPage: [
      25,
      {
        setRowsPerPage: (_, { rows }) => rows,
      },
    ],
    amountOfRecords: [
      0,
      {
        setAmountOfRecords: (_, { amount }) => amount,
      },
    ],
    isPaginatorActive: [false, { setPaginator: (_, { value }) => value }],
    chosenEvent: [
      undefined as number | undefined,
      {
        setChosenEvent: (_, { id }) => id,
      },
    ],
    dialogVisible: [
      false as boolean,
      {
        setDialogVisibility: (_, { value }) => value,
      },
    ],
  }),
  selectors({
    eventLevels: [
      () => [
        (_, props: NotificationTableProps) =>
          props.deps.eventDictContainerLogic.values.eventLevels,
      ],
      (eventLevels) => eventLevels,
    ],
    values: [
      (selectors) => [selectors.alarmEvents, selectors.eventLevels],
      (alarmEvents, eventLevels) => {
        return alarmEvents.map((alarmEvent) => {
          return {
            eventId: alarmEvent.alarmEventId,
            startDatetime: {
              startDate: alarmEvent.startDatetime,
            },
            endDatetime: {
              startDate: alarmEvent.endDatetime,
            },
            text: alarmEvent.text,
            source: convertSourceToTag(alarmEvent.source),
            confirmUserText: alarmEvent.confirmUserText,
            confirmUserDate: {
              startDate: alarmEvent.confirmUserDate,
            },
            eventLevelId: convertEventLevelToTag(
              eventLevels[alarmEvent.eventLevelId],
            ),
          } as NotificationTableView;
        });
      },
    ],
  }),
  listeners(({ props, actions, values }) => ({
    loadAlarmEvents: async () => {
      let input = {
        count: {
          count: values.rowsPerPage,
          page: values.currentPage,
        },
        sort: {
          order: TableOrder.Desc,
          column: 'startDatetime',
        },
        searchAll: [
          {
            column: 'objectUuid' as AlarmEventTableViewSortKeys,
            rule: TableSearchRule.Equal,
            value: [props.objectUuid],
          },
        ],
        searchAny: [],
      };

      if (values.sortOptions != undefined) {
        input = Object.assign(input, {
          sort: {
            order:
              values.sortOptions.order == 1 ? TableOrder.Asc : TableOrder.Desc,
            column: values.sortOptions.field,
          },
        });
      }

      const response = await props.deps.eventService.getAlarmEventsList(input);

      if (response.success) {
        actions.setAmountOfRecords(response.data.total);
        actions.loadSuccess(response.data.alarmEvents);
      } else {
        actions.loadFailure();
      }
    },
    refresh: () => {
      actions.loadAlarmEvents();
    },
    onRowClicked: ({ rowKey }) => {
      const event = values.alarmEvents.find(
        (event) => event.alarmEventId === +rowKey,
      );
      if (event?.confirmUserDate == undefined) {
        actions.setChosenEvent(+rowKey);
        actions.setDialogVisibility(true);
      }
    },
  })),
  afterMount(({ actions }) => {
    actions.loadAlarmEvents();
  }),
]);

export const notificationTable = injectDepsToLogic(logic, () => ({
  eventService: Dependencies.get(IEventService.$),
  eventDictContainerLogic: Dependencies.get(IEventDictContainerLogic.$),
}));
