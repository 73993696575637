import { ColumnType, SortOptions } from '@ocs/ocs-components';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { DeviceConverters } from '@/converters/device';
import Dependencies from '@/deps';
import { IDesktopLogic, IDeviceViewContainerLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IDeviceService } from '@/services/interfaces';
import { MDevice } from '@/types/models';
import { TableOrder, TableSearchRule } from '@/types/models/table';
import { StringKeys } from '@/utility/types';

import { desktopLogic } from './../../desktop/index';
import type { logicType } from './indexType';
import { GetSearchInputs } from './searchRequests';

const logic = kea<logicType>([
  props({ objectUuid: null } as {
    objectUuid: string | null;
    deps: {
      desktopLogic: IDesktopLogic;
      deviceViewContainerLogic: IDeviceViewContainerLogic;
      deviceService: IDeviceService;
      deviceConverters: DeviceConverters;
    };
  }),
  key((props) => (props.objectUuid === null ? 'all' : props.objectUuid)),
  path(['devices', 'table']),
  actions({
    refresh: true,
    load: true,
    loadSuccess: (devices: MDevice.View[]) => ({ devices }),
    loadFailure: true,
    setSortOptions: (sortOptions: SortOptions) => ({ sortOptions }),
    setCurrentDeviceIds: (deviceIds: string[]) => ({ deviceIds }),
    onRowClicked: (rowKey: string) => ({ rowKey }),
    setCurrentPage: (page: number) => ({ page }),
    setRowsPerPage: (rows: number) => ({ rows }),
    setAmountOfRecords: (amount: number) => ({ amount }),
    setPaginator: (value: boolean) => ({ value }),
    setSearchInput: (value: string) => ({ value }),
  }),
  reducers({
    dataKey: ['deviceUuid' as 'deviceUuid'],
    columns: [
      [
        { field: 'listName', header: 'table.device.listName' },
        {
          field: 'statusNoTransmission',
          header: 'table.device.statusNoTransmission',
        },
        {
          field: 'temporaryLastDataDate',
          header: 'table.device.temporaryLastDataDate',
        },
      ] as {
        field: StringKeys<MDevice.TableView>;
        header: string;
      }[],
    ],
    columnTypes: [
      {
        listName: ColumnType.STRING,
        statusNoTransmission: ColumnType.STRING,
        temporaryLastDataDate: ColumnType.DATE,
      } as Record<string, ColumnType>,
    ],
    sortableColumns: ['listName'],
    valueLists: [{ status: 'test' }],
    loading: [
      false,
      {
        load: () => true,
        loadSuccess: () => false,
        loadFailure: () => false,
      },
    ],
    error: [
      false,
      {
        load: () => false,
        loadSuccess: () => false,
        loadFailure: () => true,
      },
    ],
    deviceIds: [
      [] as string[],
      {
        setCurrentDeviceIds: (_, { deviceIds }) => deviceIds,
      },
    ],
    objectIds: [
      [] as string[],
      {
        setCurrentObjectIds: (_, { objectIds }) => objectIds,
      },
    ],
    currentPage: [
      0,
      {
        setCurrentPage: (_, { page }) => page as number,
      },
    ],
    rowsPerPage: [
      25,
      {
        setRowsPerPage: (_, { rows }) => rows as number,
      },
    ],
    amountOfRecords: [
      0,
      {
        setAmountOfRecords: (_, { amount }) => amount as number,
      },
    ],
    sortOptions: [
      undefined as SortOptions | undefined,
      {
        setSortOptions: (_, { sortOptions }) => sortOptions,
      },
    ],
    isPaginatorActive: [
      true,
      {
        setPaginator: (_, { value }) => value,
      },
    ],
    headerSearchValue: [
      '',
      {
        setSearchInput: (_, { value }) => value,
      },
    ],
  }),
  selectors(({ props }) => ({
    deviceViews: [
      (selectors) => [
        selectors.deviceIds,
        props.deps.deviceViewContainerLogic.selectors.devices,
      ],
      (deviceIds, deviceViews) => {
        return Object.values(deviceViews).filter((deviceView) => {
          return deviceIds.includes(deviceView.deviceUuid);
        });
      },
    ],
    values: [
      (selectors) => [selectors.deviceViews],
      (deviceViews) => {
        return deviceViews.map((deviceView) => {
          return props.deps.deviceConverters.viewToTableView(deviceView);
        });
      },
    ],
  })),
  listeners(({ props, actions, values }) => ({
    onRowClicked: ({ rowKey }) => {
      const device = props.deps.deviceViewContainerLogic.values.devices[rowKey];
      if (device.base.kind == MDevice.Kind.VibroDetector) {
        desktopLogic.actions.openWindow('vibrodetectorChart', {
          initialData: { deviceUuid: rowKey },
          windowKey: rowKey,
          title: device.base.name,
        });
      } else if (device.base.kind == MDevice.Kind.GenericDevice) {
        desktopLogic.actions.openWindow('genericDeviceChart', {
          initialData: { deviceUuid: rowKey },
          windowKey: rowKey,
          title: device.base.name,
        });
      }
      // else if (device.base.kind == MDevice.Kind.Hub) {
      //   desktopLogic.actions.openWindow('hubsEditor', {
      //     initialData: { deviceUuid: rowKey },
      //     windowKey: rowKey,
      //     title: device.base.name,
      //   });
      // }
    },
    refresh: () => {
      actions.load();
    },
    load: async () => {
      let input = {
        searchAll: [],
        searchAny: [],
      };

      if (props.objectUuid != null) {
        input = Object.assign(input, {
          searchAll: [
            {
              column: 'objectUuid',
              value: [props.objectUuid],
              rule: TableSearchRule.Equal,
            },
          ],
        });
      } else {
        input = Object.assign(input, {
          count: {
            count: values.rowsPerPage,
            page: values.currentPage,
          },
          searchAny: GetSearchInputs(values.headerSearchValue),
        });
      }

      if (values.sortOptions != undefined) {
        input = Object.assign(input, {
          sort: {
            order:
              values.sortOptions.order == 1 ? TableOrder.Asc : TableOrder.Desc,
            column: values.sortOptions.field,
          },
        });
      }

      const response = await props.deps.deviceService.getList(input);

      if (response.success && response.data) {
        actions.setAmountOfRecords(response.data.total);
        actions.loadSuccess(response.data.devices);
      } else {
        actions.loadFailure();
      }
    },
    loadSuccess: ({ devices }) => {
      const deviceIds = devices.map((device) => device.deviceUuid);
      props.deps.deviceViewContainerLogic.actions.releaseDevices(
        values.deviceIds,
      );
      props.deps.deviceViewContainerLogic.actions.addDevices(devices);
      props.deps.deviceViewContainerLogic.actions.useDevices(deviceIds);
      actions.setCurrentDeviceIds(deviceIds);
    },
  })),
  afterMount(({ actions }) => {
    actions.refresh();
  }),
  beforeUnmount(({ values, props }) => {
    props.deps.deviceViewContainerLogic.actions.releaseDevices(
      values.deviceIds,
    );
  }),
]);

export const deviceTableLogic = injectDepsToLogic(logic, () => ({
  desktopLogic: Dependencies.get(IDesktopLogic.$),
  deviceViewContainerLogic: Dependencies.get(IDeviceViewContainerLogic.$),
  deviceService: Dependencies.get(IDeviceService.$),
  deviceConverters: new DeviceConverters(),
}));
