import { Dispatch, SetStateAction } from 'react';

import {
  BaseFilterType,
  BoolFilterValue,
  CompositeDate,
  DateFilterValue,
  IconFilterType,
  TagFilterType,
  TextFilterType,
  TextFilterValue,
} from '@/types/filter';

//Gets index of filter value,
//if it does not exist creates values for this index
export function GetValueIndex(
  filterValue: (TextFilterValue | BoolFilterValue | DateFilterValue)[],
  filterData: BaseFilterType | TextFilterType | IconFilterType | TagFilterType,
  setFilterValue: any,
  filteredValue: string | number | boolean | CompositeDate | null | undefined,
) {
  const newFilterValue = [...filterValue];
  const index = filterValue.findIndex((e) => e.key === filterData.key);
  if (index === -1) {
    newFilterValue.push({ key: filterData.key, filteredValue: filteredValue });
    setFilterValue(newFilterValue);
    return newFilterValue.length;
  } else {
    return index;
  }
}
