import { EventLink } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { memo } from 'react';

import {
  IDesktopLogic,
  IGenericDeviceViewLogic,
  IGenericDevicesContainerLogic,
  IObjectWindowLogic,
} from '@/logic/interfaces';

export const GenericDevicesContainer = memo(function GenericDevicesContainer() {
  const viewLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(viewLogic);

  const genericDevicesContainerLogic = useInjection(
    IGenericDevicesContainerLogic.$,
  )({
    objectUuid,
  });
  const { devices } = useValues(genericDevicesContainerLogic);

  return (
    <div className="grid object-grid">
      {devices?.map((genericDevice) => (
        <GenericDeviceView
          key={genericDevice.deviceUuid}
          deviceUuid={genericDevice.deviceUuid}
        ></GenericDeviceView>
      ))}
    </div>
  );
});

interface GenericDeviceViewProps {
  deviceUuid: string;
}

export const GenericDeviceView = memo(function GenericDeviceView(
  props: GenericDeviceViewProps,
) {
  const viewLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(viewLogic);

  const deviceLogic = useInjection(IGenericDeviceViewLogic.$)({
    objectUuid,
    deviceUuid: props.deviceUuid,
  });

  const { now, device, modelColumns, units, connected } =
    useValues(deviceLogic);

  const { connectLiveStream, disconnectLiveStream } = useActions(deviceLogic);

  const desktopLogic = useInjection(IDesktopLogic.$);

  const { openWindow } = useActions(desktopLogic);

  if (device !== null) {
    const subTitle = (
      <div className="object-view-card-title">
        <EventLink
          details={device.listName}
          detailsOnClick={() => {
            openWindow('genericDeviceChart', {
              initialData: { deviceUuid: device.deviceUuid },
              windowKey: device.deviceUuid,
              title: device.listName,
            });
          }}
        ></EventLink>
        <Button
          className={`p-button-text p-button-sm vibrodetector-chart-toggle-live-button ${
            connected
              ? 'live-active p-button-danger'
              : 'live-disabled p-button-success'
          }`}
          onClick={() => {
            if (!connected) {
              connectLiveStream();
            } else {
              disconnectLiveStream();
            }
          }}
          icon={connected ? 'ri-stop-fill' : 'ri-play-fill'}
        />
      </div>
    );

    return (
      <div key={device.deviceUuid} className="col-2">
        <Card subTitle={subTitle}>
          <span className="object-view-card-input p-float-label p-input-icon-right">
            <InputText
              className="p-inputtext-sm block mb-2"
              id={device.deviceUuid + 'date'}
              value={
                now !== null
                  ? now.datetime.toLocaleString('en-GB').replace(',', '') +
                    '.' +
                    now.datetime.getMilliseconds().toString().padStart(3, '0')
                  : '----'
              }
              readOnly={true}
            />
            <label htmlFor={device.deviceUuid + 'date'}>Data</label>
          </span>
          {now !== null && modelColumns != null
            ? Object.entries(now.data).map((value, index) => (
                <div key={index}>
                  <br />
                  <span className="object-view-card-input p-float-label p-input-icon-right">
                    <span className="p-input-suffix">
                      {'[' +
                        units[modelColumns[parseInt(value[0])].unitId]
                          .shortDescription +
                        ']'}
                    </span>
                    <InputText
                      className="p-inputtext-sm block mb-2"
                      value={value[1] !== null ? (value[1] as any) : '----'}
                      readOnly={true}
                    />
                    <label htmlFor={device.deviceUuid + 'value'}>
                      {modelColumns[parseInt(value[0])].name}
                    </label>
                  </span>
                </div>
              ))
            : null}
        </Card>
      </div>
    );
  } else {
    return <div></div>;
  }
});
