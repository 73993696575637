import { Tag } from '@ocs/ocs-components';

import { AlarmEventSource } from '@/types/models/event';

const sourceData: Record<number, { label: string; color: string }> = {
  0: {
    label: 'definedAlarm',
    color: '#b7ded2',
  },
  1: {
    label: 'definedAlarmGroup',
    color: '#f6a6b2',
  },
  2: {
    label: 'device',
    color: '#f7c297',
  },
  3: {
    label: 'object',
    color: '#ffecb8',
  },
  4: {
    label: 'user',
    color: '#90d2d8',
  },
};

export function convertSourceToTag(source: AlarmEventSource) {
  return {
    label: `table.notifications.sourceTags.${sourceData[source].label}`,
    color: sourceData[source].color,
  } as Tag;
}
