import { FC, useMemo } from 'react';

import { ResponsiveTileComponent } from '@/types/custom/responsiveBar';

interface StaticTileProps {
  elements?: (ResponsiveTileComponent | JSX.Element)[];
  elementKey: string;
}

export const StaticTile: FC<StaticTileProps> = (props) => {
  const cachedElements = useMemo(() => {
    return props.elements?.map((element) => {
      return 'component' in element ? element.component : element;
    });
  }, [props.elements]);

  return (
    <>
      {cachedElements?.map((element, index) => {
        return <div key={`${props.elementKey}${index}`}>{element}</div>;
      })}
    </>
  );
};
