import { FC } from 'react';

import './style.scss';

interface BoolTemplateProps {
  value: {
    rowValue: boolean;
    trueColor?: string;
    falseColor?: string;
  };
}

export const BoolTemplate: FC<BoolTemplateProps> = (props) => {
  return (
    <div className="ocs-bool-template-container">
      {props.value.rowValue ? (
        <i
          className="ri-checkbox-circle-line"
          style={{ color: props.value.trueColor }}
        />
      ) : (
        <i
          className="ri-close-circle-line"
          style={{ color: props.value.falseColor }}
        />
      )}
    </div>
  );
};
