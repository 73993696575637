/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IDeviceService } from '@/services/interfaces/device';
import { Response } from '@/services/types/response';
import { MDevice } from '@/types/models';

import { BaseService } from './base';

@injectable()
export class DeviceService extends BaseService implements IDeviceService {
  public async get(
    input: MDevice.Messages.GetIn,
  ): Promise<Response<MDevice.Messages.GetOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.deviceGet(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getByVisalizationAlias(
    input: MDevice.Messages.GetByVisualizationAliasIn,
  ): Promise<Response<MDevice.Messages.GetByVisualizationAliasOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.deviceGetByVisualizationAlias(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getList(
    input: MDevice.Messages.GetListIn,
  ): Promise<Response<MDevice.Messages.GetListOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.deviceGetList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getSlots(
    input: MDevice.Messages.GetSlotsIn,
  ): Promise<Response<MDevice.Messages.GetSlotsOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.deviceGetSlots(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async assignSlot(
    input: MDevice.Messages.AssignSlotIn,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.deviceAssignSlot(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async updateVisualizationAlias(
    input: MDevice.Messages.UpdateVisualizationAliasIn,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.deviceUpdateVisualizationAlias(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
