// @generated by protobuf-ts 2.8.0
// @generated from protobuf file "live.proto" (package "live", syntax proto2)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * @generated from protobuf message live.MessageLive
 */
export interface MessageLive {
    /**
     * @generated from protobuf field: string uuid = 1;
     */
    uuid: string;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp start = 2;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: optional int32 interval = 3;
     */
    interval?: number;
    /**
     * @generated from protobuf field: repeated int32 work_state_id = 4;
     */
    workStateId: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class MessageLive$Type extends MessageType<MessageLive> {
    constructor() {
        super("live.MessageLive", [
            { no: 1, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "interval", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "work_state_id", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MessageLive>): MessageLive {
        const message = { uuid: "", workStateId: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageLive>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageLive): MessageLive {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uuid */ 1:
                    message.uuid = reader.string();
                    break;
                case /* optional google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* optional int32 interval */ 3:
                    message.interval = reader.int32();
                    break;
                case /* repeated int32 work_state_id */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.workStateId.push(reader.int32());
                    else
                        message.workStateId.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageLive, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uuid = 1; */
        if (message.uuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uuid);
        /* optional google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional int32 interval = 3; */
        if (message.interval !== undefined)
            writer.tag(3, WireType.Varint).int32(message.interval);
        /* repeated int32 work_state_id = 4; */
        for (let i = 0; i < message.workStateId.length; i++)
            writer.tag(4, WireType.Varint).int32(message.workStateId[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message live.MessageLive
 */
export const MessageLive = new MessageLive$Type();
