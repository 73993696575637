//Translations
import storybookEn from '@/i18n/en.json';
import storybookPl from '@/i18n/pl.json';

export const translations = { storybookEn, storybookPl };

//Events
export { EventViewContainer } from './components/events/container';
export { EventViewContent } from '@/components/events/content';

export { darkenColor, isColorDark } from '@/utils/colorUtils';

export { EventList } from '@/components/events/list';
export type { EventView } from '@/types/eventView';
export {
  IAlarmEventsLogic,
  IAlarmsLogicSymbol,
} from '@/logic/interfaces/event/alarmEvents';

export { ConfirmDialog } from '@/components/confirmDialog';
export { EventConfirmDialog } from '@/components/events/dialog';

export { EventLink } from '@/components/events/link';

export { IAppLogic, IAppLogicSymbol } from '@/logic/interfaces/app';

//Generic table
export { Table } from '@/components/table';
export type { ColumnDefinition, TableValue, SortOptions } from '@/types/table';
export { ColumnType } from '@/types/table';
export { ITableLogic, ITableLogicSymbol } from '@/logic/interfaces/table/table';
export {
  ITableColumnLogic,
  ITableColumnLogicSymbol,
} from '@/logic/interfaces/table/tableColumn';
export {
  IPaginationLogic,
  IPaginationLogicSymbol,
} from '@/logic/interfaces/table/pagination';
export {
  ISearchbarLogic,
  ISearchbarLogicSymbol,
} from '@/logic/interfaces/table/searchbar';
export { IEventLogic, IEventLogicSymbol } from '@/logic/interfaces/event/event';

//BadgeIcons
export { BadgeIcon } from '@/components/badgeIcon';

//Visualizations
export { Visualization } from '@/components/visualizations';
export {
  ConvertToVisualizationType,
  ConvertToSystemAPI,
} from '@/utils/visualizationUtils';
export * from '@/types/visualization';
export {
  IVisualizationLogic,
  IVisualizationLogicSymbol,
} from '@/logic/interfaces/visualization/visualization';
export {
  IVisElementLogic,
  IVisElementLogicSymbol,
} from '@/logic/interfaces/visualization/visElement';
