import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { EventLevel } from '@/types/models/event';

export interface IEventDictContainerLogicProps
  extends Record<string, unknown> {}

interface IEventDictContainer extends Logic {
  readonly props: IEventDictContainerLogicProps;
  readonly values: { eventLevels: Record<string, EventLevel> };
  readonly selectors: {
    readonly eventLevels: (state: any) => Record<string, EventLevel>;
  };
}

export interface IEventDictContainerLogic
  extends LogicWrapper<IEventDictContainer> {}

export namespace IEventDictContainerLogic {
  export const $: interfaces.ServiceIdentifier<IEventDictContainerLogic> =
    Symbol('IEventDictContainerLogic');
}
