export enum EditorTypes {
  string,
  number,
  float,
  inputSwitch,
  checkbox,
  button,
  color,
  enum,
  divider,
  unknown,
}

export enum ConfirmStatus {
  success,
  failure,
  unknown,
}

export enum LoadingStatus {
  success,
  failure,
  isLoading,
}

export enum RecordTypes {
  EnumOptions,
  EnumExcludeOptions,
}

export interface EnumOptions {
  type: RecordTypes.EnumOptions;
  options: Record<string, EnumContent[]>;
  disabled?: Record<string, boolean>;
}

export interface EnumExcludeOptions {
  type: RecordTypes.EnumExcludeOptions;
  options: Record<string, Record<string, EnumContent[]>>;
  disabled?: Record<string, boolean>;
}

export interface EnumContent {
  name: string;
  key: number | string;
}

export interface ButtonContent {
  icon: string;
  key: string;
}

export interface EditorModel {
  name: string;
  leftIcon?: string;
  rightIcon?: string;
  valueUnit?: string;
  type: EditorTypes;
  enumOptionsName?: string;
  disabled?: boolean;
  inputValidator?: (value: string | number) => boolean;
  errorMessage?: string;
}

export interface UpdateModel {
  tileKey: string;
  tabKey: string;
  valueKey: string;
  value: EditorValue;
}

export type EditorForm = Record<
  string,
  Record<string, Record<string, EditorModel>>
>;

export interface EditorValue {
  value: any;
  error?: string;
}

export interface EditorTile {
  tileLayout: EditorTileLayout;
  tabs: Record<string, EditorTabsLayout>;
}
export interface EditorTileLayout {
  layoutDirection: 'horizontal' | 'vertical';
  gridLayout?: string;
  width?: string;
}

export interface EditorTabsLayout {
  layoutDirection: 'horizontal' | 'vertical';
  gridLayout?: string;
  width?: string;
}

export interface EditorFieldKey {
  editorUuid: string;
  tileKey: string;
  tabKey: string;
  valueKey: string;
}
