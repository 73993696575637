import { Button } from 'primereact/button';
import { Dialog as PRDialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FC, useEffect, useState } from 'react';

import './style.scss';

export type DialogButton = {
  icon?: string;
  className?: string;
  label?: string;
  onClick: () => void;
};

export interface DialogProps {
  onConfirm?: (value: string) => void;
  onHide: () => void;
  onCancel?: () => void;
  validator?: (text: string) => boolean;
  className?: string;
  containerClassName?: string;
  children?: JSX.Element;
  onValidatorErrorMsg?: string;
  closable?: boolean;
  sendError?: boolean;
  visible?: boolean;
  width?: number;
  dialogTitle?: string;
  title?: string;
  details?: string;
  footer?: DialogButton[];
  inputPlaceholder?: string;
  confirmLabel?: string;
  errorLabel?: string;
}

export const ConfirmDialog: FC<DialogProps> = (props) => {
  const [input, setInput] = useState('');
  const [validatorError, setError] = useState(false);

  useEffect(() => {
    if (
      (props.details == null && props.children == null) ||
      (props.details != null && props.children != null)
    )
      throw new Error('Only details or children should be given');
  }, []);

  return (
    <PRDialog
      headerClassName="ocs-dialog-header"
      contentClassName="ocs-dialog-content"
      className={`${props.className ? props.className : ''} ocs-dialog`}
      visible={props.visible}
      header={props.dialogTitle}
      onHide={() => {
        if (!props.closable) {
          if (props.onCancel != null) props.onCancel();
          props.onHide();
        }
      }}
      footer={
        <>
          {props.footer?.map((currentElement, index) => {
            return (
              <Button
                key={`footerButtons${index}`}
                icon={currentElement.icon}
                className={currentElement.className}
                label={currentElement.label}
                onClick={currentElement.onClick}
              />
            );
          })}
          <Button
            icon={
              props.closable
                ? 'pi pi-spin pi-spinner'
                : props.sendError
                ? 'pi pi-exclamation-triangle'
                : 'pi pi-check'
            }
            className={props.sendError ? 'p-button-danger' : ''}
            label={props.sendError ? props.errorLabel : props.confirmLabel}
            onClick={() => {
              if (props.onConfirm != null) {
                if (props.validator != null) {
                  if (props.validator(input) === true) {
                    setError(false);
                    props.onConfirm(input);
                  } else setError(true);
                } else props.onConfirm(input);
              }
            }}
            disabled={props.closable ? true : false}
          />
        </>
      }
      style={{ width: `${props.width}rem` }}
    >
      <div
        className={`ocs-dialog-container ${
          props.containerClassName ? props.containerClassName : ''
        }`}
      >
        {props.children != null ? (
          props.children
        ) : (
          <>
            <span className="ocs-dialog-title">
              {props.title != null ? props.title : ''}
            </span>
            <span className="ocs-dialog-details-text">
              {props.details != null ? props.details : ''}
            </span>
          </>
        )}{' '}
        <>
          <InputText
            className="ocs-dialog-inputText"
            value={input}
            placeholder={props.inputPlaceholder}
            aria-describedby="inputText-error"
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
          <small id="inputText-error" className="p-error block">
            {validatorError === true ? props?.onValidatorErrorMsg : ''}
          </small>
        </>
      </div>
    </PRDialog>
  );
};
