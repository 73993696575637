export function darkenColor(hex: string, ratio: number) {
  const rgb = convertHexToRgb(hex);
  if (rgb == null) return '#ff2ef8';

  let hsl = convertRgbToHsl(rgb.r, rgb.g, rgb.b);
  hsl.l *= ratio;
  hsl.l = hsl.l > 1 ? 1 : hsl.l < 0 ? 0 : hsl.l;

  const newRgb = convertHslToRgb(hsl.h, hsl.s, hsl.l);

  return convertRgbToHex(newRgb.r, newRgb.g, newRgb.b);
}

export function isColorDark(hex: string) {
  const rgb = convertHexToRgb(hex);
  if (rgb == null) return false;

  const hsl = convertRgbToHsl(rgb.r, rgb.g, rgb.b);
  if (hsl.l <= 0.45) return true;
  else return false;
}

export function convertHexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function convertRgbToHsl(r: number, g: number, b: number) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let v = (max + min) / 2;
  let h = v;
  let s = v;
  let l = v;

  if (max == min) h = s = 0;
  else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return { h: h, s: s, l: l };
}

export function convertHslToRgb(h: number, s: number, l: number) {
  let r, g, b;

  if (s == 0) {
    r = g = b = l;
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = convertHueToRgb(p, q, h + 0.33);
    g = convertHueToRgb(p, q, h);
    b = convertHueToRgb(p, q, h - 0.33);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

export function convertHueToRgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

export function convertRgbToHex(r: number, g: number, b: number) {
  return '#' + numberToHex(r) + numberToHex(g) + numberToHex(b);
}

export function numberToHex(value: number) {
  const hex = value.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

export function GetRgbString(r?: number, g?: number, b?: number) {
  if (r != null && g != null && b != null) return `rgb(${r},${g},${b})`;
  else {
    console.log("RGB values can't be null, pink has been returned ");
    return 'pink';
  }
}
