import { Button } from 'primereact/button';
import { FC } from 'react';

import './style.scss';

interface FooterButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'disabled' | 'ref'
  > {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: string;
}

export const FooterButton: FC<FooterButtonProps> = (props) => {
  return (
    <Button
      style={props.style}
      className="OCS-footer-text-button p-button-outlined"
      icon={props.icon}
      label={props.label}
      onClick={() => {
        props.onClick();
      }}
      disabled={props.disabled}
    />
  );
};
