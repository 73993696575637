import { ColumnType, SortOptions } from '@ocs/ocs-components';
import { shallowEqual } from 'fast-equals';
import {
  actions,
  afterMount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { Dependencies } from '@/deps';
import { IDefinedAlarmsViewContainerLogic } from '@/logic/interfaces/definedAlarmsViewContainer';
import { IDesktopLogic } from '@/logic/interfaces/desktop';
import { injectDepsToLogic } from '@/logic/utils';
import { IDefinedAlarmsService } from '@/services/interfaces/definedAlarms';
import { TableRequestInput } from '@/types/messages/table';
import { MDefinedAlarms } from '@/types/models/definedAlarms';
import { convertActivationBool, convertEventLevelToTag } from '@/utility/table';
import { StringKeys } from '@/utility/types';

import { desktopLogic } from '../../desktop';
import { IEventDictContainerLogic } from './../../../interfaces/eventDictContainer';
import { definedAlarmsIcons } from './iconDefinition';
import type { logicType } from './indexType';
import { getRequestInput } from './searchRequests';

interface DefinedAlarmsTable {
  objectUuid: string | undefined;
  alarmGroupUuid: string | undefined;
  deps: {
    desktopLogic: IDesktopLogic;
    definedAlarmsViewContainerLogic: IDefinedAlarmsViewContainerLogic;
    definedAlarmsService: IDefinedAlarmsService;
    eventDictContainerLogic: IEventDictContainerLogic;
  };
}

const logic = kea<logicType>([
  props(
    {} as {
      objectUuid: string | undefined;
      alarmGroupUuid: string | undefined;
      deps: {
        desktopLogic: IDesktopLogic;
        definedAlarmsViewContainerLogic: IDefinedAlarmsViewContainerLogic;
        definedAlarmsService: IDefinedAlarmsService;
        eventDictContainerLogic: IEventDictContainerLogic;
      };
    },
  ),
  key((props) => {
    if (props.objectUuid != null && props.alarmGroupUuid != null) {
      return `${props.objectUuid}/${props.alarmGroupUuid}`;
    }

    if (props.objectUuid != null) return props.objectUuid;

    return 'all';
  }),
  path(['definedAlarms', 'table']),
  actions({
    refresh: true,
    load: true,
    loadSuccess: (definedAlarms: MDefinedAlarms.View[]) => ({ definedAlarms }),
    loadFailure: true,
    setSortOptions: (sortOptions: SortOptions) => ({ sortOptions }),
    setCurrentDefinedAlarmsIds: (alarmIds: string[]) => ({ alarmIds }),
    setCurrentPage: (page: number) => ({ page }),
    setRowsPerPage: (rows: number) => ({ rows }),
    setAmountOfRecords: (amount: number) => ({ amount }),
    setPaginator: (value: boolean) => ({ value }),
    onRowClicked: (rowKey: string) => ({ rowKey }),
    setSearchInput: (value: string) => ({ value }),
  }),
  reducers({
    dataKey: ['definedAlarmUuid' as 'definedAlarmUuid'],
    columns: [
      [
        {
          field: 'listDescription',
          header: 'table.definedAlarms.listDescription',
        },
        { field: 'eventLevelId', header: 'table.definedAlarms.eventLevelId' },
        { field: 'listColor', header: 'table.definedAlarms.listColor' },
        { field: 'temporaryState', header: 'table.definedAlarms.state' },
        {
          field: 'temporaryStateDateTime',
          header: 'table.definedAlarms.stateDateTime',
        },
        {
          field: 'isActive',
          header: 'table.definedAlarms.isActive',
        },
      ] as {
        field: StringKeys<MDefinedAlarms.TableView>;
        header: string;
      }[],
    ],
    columnTypes: [
      {
        listDescription: ColumnType.STRING,
        eventLevelId: ColumnType.TAG,
        listColor: ColumnType.COLOR,
        temporaryState: ColumnType.ICON,
        temporaryStateDateTime: ColumnType.DATE,
        isActive: ColumnType.BOOL,
      } as Record<string, ColumnType>,
    ],
    sortableColumns: ['listDescription'],
    loading: [
      false,
      {
        load: () => true,
        loadSuccess: () => false,
        loadFailure: () => false,
      },
    ],
    error: [
      false,
      {
        load: () => false,

        loadSuccess: () => false,
        loadFailure: () => true,
      },
    ],
    sortOptions: [
      undefined as SortOptions | undefined,
      {
        setSortOptions: (_, { sortOptions }) => sortOptions,
      },
    ],
    definedAlarmsIds: [
      [] as string[],
      {
        setCurrentDefinedAlarmsIds: (_, { alarmIds }) => alarmIds,
      },
    ],
    currentPage: [
      0,
      {
        setCurrentPage: (_, { page }) => page,
      },
    ],
    rowsPerPage: [
      25,
      {
        setRowsPerPage: (_, { rows }) => rows,
      },
    ],
    amountOfRecords: [
      0,
      {
        setAmountOfRecords: (_, { amount }) => amount,
      },
    ],
    isPaginatorActive: [true, { setPaginator: (_, { value }) => value }],
    headerSearchValue: [
      '',
      {
        setSearchInput: (_, { value }) => value,
      },
    ],
  }),
  selectors(({ props }) => ({
    definedAlarmsViews: [
      (selectors) => [
        selectors.definedAlarmsIds,
        props.deps.definedAlarmsViewContainerLogic.selectors.definedAlarms,
      ],
      (definedAlarmsIds, definedAlarms) => {
        return Object.values(definedAlarms).filter((definedAlarmView) => {
          return definedAlarmsIds.includes(definedAlarmView.definedAlarmUuid);
        });
      },
      { resultEqualityCheck: shallowEqual },
    ],
    eventLevels: [
      () => [
        (_, props: DefinedAlarmsTable) =>
          props.deps.eventDictContainerLogic.values.eventLevels,
      ],
      (eventLevels) => eventLevels,
    ],
    values: [
      (selectors) => [selectors.definedAlarmsViews, selectors.eventLevels],
      (definedAlarmsViews, eventLevels) => {
        return definedAlarmsViews.map((alarmView) => {
          return {
            definedAlarmUuid: alarmView.definedAlarmUuid,
            deviceUuid: alarmView.identifier.deviceUuid,
            objectUuid: alarmView.identifier.objectUuid,
            definedAlarmGroupUuid: alarmView.identifier.definedAlarmGroupUuid,
            eventLevelId: convertEventLevelToTag(
              eventLevels[alarmView.data.eventLevelId],
            ),
            listDescription: alarmView.data.description,
            listColor: alarmView.data.color ? `#${alarmView.data.color}` : '',
            temporaryState: definedAlarmsIcons[alarmView.temporary.state],
            temporaryStateDateTime: {
              startDate: alarmView.temporary.stateDateTime,
            },
            isActive: convertActivationBool(alarmView.isActive),
          };
        });
      },
    ],
  })),
  listeners(({ props, values, actions }) => ({
    onRowClicked: ({ rowKey }) => {
      if (props.objectUuid != null) {
        desktopLogic.actions.openWindow('definedAlarmsEdit', {
          initialData: {
            definedAlarmsUuid: rowKey,
            editorUuid: rowKey,
            objectUuid: props.objectUuid,
          },
          windowKey: rowKey,
          title: 'windowTypes.definedAlarmsEdit',
        });
      }
    },
    refresh: async () => {
      actions.load();
    },
    load: async () => {
      const input = getRequestInput({
        rowsPerPage: values.rowsPerPage,
        currentPage: values.currentPage,
        searchInputs: values.headerSearchValue,
        props: {
          objectUuid: props.objectUuid,
          groupUuid: props.alarmGroupUuid,
        },
      }) as TableRequestInput<MDefinedAlarms.TableViewSortKeys>;

      const alarmsResponse = await props.deps.definedAlarmsService.getList(
        input,
      );

      if (alarmsResponse.success) {
        actions.setAmountOfRecords(alarmsResponse.data.total);
        actions.loadSuccess(alarmsResponse.data.definedAlarms);
      } else {
        actions.loadFailure();
      }
    },
    loadSuccess: async ({ definedAlarms }) => {
      const alarmsIds = definedAlarms.map((alarm) => alarm.definedAlarmUuid);
      props.deps.definedAlarmsViewContainerLogic.actions.releaseDefinedAlarms(
        values.definedAlarmsIds,
      );
      props.deps.definedAlarmsViewContainerLogic.actions.addDefinedAlarms(
        definedAlarms,
      );
      props.deps.definedAlarmsViewContainerLogic.actions.useDefinedAlarms(
        alarmsIds,
      );
      actions.setCurrentDefinedAlarmsIds(alarmsIds);
    },
  })),
  afterMount(({ actions }) => {
    actions.refresh();
  }),
]);

export const DefinedAlarmsTableLogic = injectDepsToLogic(logic, () => ({
  desktopLogic: Dependencies.get(IDesktopLogic.$),
  definedAlarmsViewContainerLogic: Dependencies.get(
    IDefinedAlarmsViewContainerLogic.$,
  ),
  definedAlarmsService: Dependencies.get(IDefinedAlarmsService.$),
  eventDictContainerLogic: Dependencies.get(IEventDictContainerLogic.$),
}));
