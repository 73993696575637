import { ColumnType, SortOptions } from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MObject } from '@/types/models';
import { StringKeys } from '@/utility/types';

import { IDeviceTableLogic } from './deviceTable';

export interface IObjectTableLogicProps extends Record<string, unknown> {}

interface IObjectTable extends Logic {
  readonly props: IObjectTableLogicProps;
  readonly actions: {
    readonly refresh: () => void;
    readonly setCurrentPage: (currentPage: number) => void;
    readonly setRowsPerPage: (rowsPerPage: number) => void;
    readonly setPaginator?: (value: boolean) => void;
  };
  readonly values: {
    readonly dataKey: 'objectUuid';
    readonly columns: {
      field: StringKeys<MObject.TableView>;
      header: string;
    }[];
    readonly columnTypes: { [key: string]: ColumnType };
    readonly values: MObject.TableView[] | null;
    readonly expand: {
      columns: Record<string, string>;
      logic: interfaces.ServiceIdentifier<IDeviceTableLogic>;
    };
    readonly loading: boolean;
    readonly error: boolean;
    readonly currentPage: number;
    readonly rowsPerPage: number;
    readonly amountOfRecords: number;
    readonly isPaginatorActive?: boolean;
    readonly sortOptions?: SortOptions;
  };
}

export interface IObjectTableLogic extends LogicWrapper<IObjectTable> {}

export namespace IObjectTableLogic {
  export const $: interfaces.ServiceIdentifier<IObjectTableLogic> =
    Symbol('IObjectTableLogic');
}
