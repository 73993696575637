import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { InputText } from 'primereact/inputtext';
import { FC } from 'react';

import { useTranslation } from '@/hooks';
import { IHeaderSearchLogic } from '@/logic/interfaces';

import './style.scss';

interface HeaderSearchbarProps {
  logicIdentifier: interfaces.ServiceIdentifier<IHeaderSearchLogic>;
}

export const HeaderSearchbar: FC<HeaderSearchbarProps> = (props) => {
  const t = useTranslation();

  const searchLogic = useInjection(props.logicIdentifier);

  const { setSearchInput, refresh } = useActions(searchLogic);
  const { headerSearchValue } = useValues(searchLogic);

  return (
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        className="searchbar-inputtext"
        placeholder={t('toolbar.searchbarPlaceholder')}
        value={headerSearchValue}
        onChange={(e) => {
          setSearchInput(e.target.value);
        }}
        onBlur={() => {
          refresh();
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            refresh();
          }
        }}
      />
    </span>
  );
};
