import {
  IAlarmEventsLogic as IOCSAlarmEventsLogic,
  IAppLogic as IOCSAppLogic,
  IEventLogic as IOCSEventLogic,
} from '@ocs/ocs-components';
import { IVisElementLogic as IOCSIVisElementLogic } from '@ocs/ocs-components';
import { Container } from 'inversify';
import 'reflect-metadata';

import {
  appLogic,
  definedAlarmsViewContainerLogic,
  desktopLogic,
  deviceDetailContainerLogic,
  deviceTableLogic,
  deviceViewContainerLogic,
  eventAlarmContainerLogic,
  eventDictContainerLogic,
  eventListLogic,
  eventViewLogic,
  genericDeviceChartLogic,
  genericDeviceEditorLogic,
  genericDeviceViewLogic,
  genericDevicesContainerLogic,
  genericDevicesDictsLogic,
  loginLogic,
  moduleLogic,
  objectChartLogic,
  objectDetailContainerLogic,
  objectEditorLogic,
  objectTableLogic,
  objectViewContainerLogic,
  objectWindowLogic,
  unitsLogic,
  vibroDetectorsViewLogic,
  vibrodetectorChartLogic,
  visElementLogic,
  visSystemApiLogic,
  visualizationLogic,
  windowLogic,
} from '@/logic/core';
import {
  IAppLogic,
  IDefinedAlarmsViewContainerLogic,
  IDesktopLogic,
  IDeviceDetailContainerLogic,
  IDeviceTableLogic,
  IDeviceViewContainerLogic,
  IEventAlarmContainerLogic,
  IEventDictContainerLogic,
  IEventListLogic,
  IEventViewLogic,
  IGenericDeviceEditorLogic,
  IGenericDeviceViewLogic,
  IGenericDevicesContainerLogic,
  IGenericDevicesDictsLogic,
  ILoginLogic,
  IModuleLogic,
  IObjectChartLogic,
  IObjectChartLogicNamed,
  IObjectDetailContainerLogic,
  IObjectEditorLogic,
  IObjectTableLogic,
  IObjectViewContainerLogic,
  IObjectWindowLogic,
  IUnitsLogic,
  IVibroDetectorsViewLogic,
  IVibrodetectorChartLogic,
  IVisSystemApiLogic,
  IVisualizationElementLogic,
  IVisualizationLogic,
  IWindowLogic,
} from '@/logic/interfaces';
import { IEditorFieldLogic } from '@/logic/interfaces/editorField';
import { ITabMenuLogic } from '@/logic/interfaces/tabMenu';
import { IModules, modules } from '@/modules';
import {
  DefinedAlarmsService,
  DesktopService,
  DeviceService,
  EventService,
  GenericDeviceService,
  GlobalService,
  HubsService,
  ObjectService,
  UserService,
  VibrodetectorService,
  VisualizationService,
} from '@/services/core';
import {
  IDefinedAlarmsService,
  IDesktopService,
  IDeviceService,
  IEventService,
  IGenericDeviceService,
  IGlobalService,
  IHubsService,
  IObjectService,
  IUserService,
  IVibrodetectorService,
  IVisualizationService,
} from '@/services/interfaces';
import { DesktopValidators } from '@/validators/core';
import { IDesktopValidators } from '@/validators/interfaces';

import { definedAlarmEditorLogic } from './logic/core/definedAlarms/editor';
import { notificationTable } from './logic/core/definedAlarms/notificationTable';
import { DefinedAlarmsTableLogic } from './logic/core/definedAlarms/table';
import { editorFieldLogic } from './logic/core/editorField';
import { groupAlarmsAddForm } from './logic/core/groupAlarms/editor/index';
import { groupAlarmsTableLogic } from './logic/core/groupAlarms/table';
import { hubsEditorLogic } from './logic/core/hubs/editor/index';
import { objectChartNamedLogic } from './logic/core/object/chartNamed';
import { tabMenuLogic } from './logic/core/tabMenu';
import { vibrodetectorEditorLogic } from './logic/core/vibrodetector/editor';
import { IDefinedAlarmsEditorLogic } from './logic/interfaces/definedAlarmsEditor';
import { IDefinedAlarmsTableLogic } from './logic/interfaces/definedAlarmsTable';
import { IGenericDeviceChartLogic } from './logic/interfaces/genericDeviceChart';
import { IGroupAlarmsAddFormLogic } from './logic/interfaces/groupAlarmsAddForm';
import { IGroupAlarmsTableLogic } from './logic/interfaces/groupAlarmsTable';
import { IHubsEditorLogic } from './logic/interfaces/hubsEditor';
import { INotificationTableLogic } from './logic/interfaces/notificationTable';
import { IVibrodetectorEditorLogic } from './logic/interfaces/vibrodetectorEditor';

const Dependencies = new Container();

Dependencies.bind(IModules.$).toConstantValue(modules);

Dependencies.bind(IObjectChartLogicNamed.$).toConstantValue(
  objectChartNamedLogic,
);

Dependencies.bind(IUserService.$).to(UserService).inSingletonScope();
Dependencies.bind(IDesktopService.$).to(DesktopService).inSingletonScope();
Dependencies.bind(IEventService.$).to(EventService).inSingletonScope();
Dependencies.bind(IObjectService.$).to(ObjectService).inSingletonScope();
Dependencies.bind(IDeviceService.$).to(DeviceService).inSingletonScope();
Dependencies.bind(IDefinedAlarmsService.$)
  .to(DefinedAlarmsService)
  .inSingletonScope();
Dependencies.bind(IVibrodetectorService.$)
  .to(VibrodetectorService)
  .inSingletonScope();
Dependencies.bind(IVisualizationService.$)
  .to(VisualizationService)
  .inSingletonScope();
Dependencies.bind(IGenericDeviceService.$)
  .to(GenericDeviceService)
  .inSingletonScope();
Dependencies.bind(IGlobalService.$).to(GlobalService).inSingletonScope();
Dependencies.bind(IHubsService.$).to(HubsService).inSingletonScope();

Dependencies.bind(IAppLogic.$).toConstantValue(appLogic);
Dependencies.bind(IDesktopLogic.$).toConstantValue(desktopLogic);
Dependencies.bind(IWindowLogic.$).toConstantValue(windowLogic);
Dependencies.bind(IModuleLogic.$).toConstantValue(moduleLogic);
Dependencies.bind(ILoginLogic.$).toConstantValue(loginLogic);

Dependencies.bind(IEventViewLogic.$).toConstantValue(eventViewLogic);
Dependencies.bind(IEventListLogic.$).toConstantValue(eventListLogic);
Dependencies.bind(IEventAlarmContainerLogic.$).toConstantValue(
  eventAlarmContainerLogic,
);
Dependencies.bind(IEventDictContainerLogic.$).toConstantValue(
  eventDictContainerLogic,
);

Dependencies.bind(IObjectDetailContainerLogic.$).toConstantValue(
  objectDetailContainerLogic,
);
Dependencies.bind(IObjectViewContainerLogic.$).toConstantValue(
  objectViewContainerLogic,
);
Dependencies.bind(IObjectTableLogic.$).toConstantValue(objectTableLogic);
Dependencies.bind(IDeviceTableLogic.$).toConstantValue(deviceTableLogic);
Dependencies.bind(IDefinedAlarmsTableLogic.$).toConstantValue(
  DefinedAlarmsTableLogic,
);

Dependencies.bind(IDeviceViewContainerLogic.$).toConstantValue(
  deviceViewContainerLogic,
);
Dependencies.bind(IDeviceDetailContainerLogic.$).toConstantValue(
  deviceDetailContainerLogic,
);

Dependencies.bind(IDefinedAlarmsViewContainerLogic.$).toConstantValue(
  definedAlarmsViewContainerLogic,
);

Dependencies.bind(IDefinedAlarmsEditorLogic.$).toConstantValue(
  definedAlarmEditorLogic,
);
Dependencies.bind(IEditorFieldLogic.$).toConstantValue(editorFieldLogic);

Dependencies.bind(IOCSAlarmEventsLogic.$).toConstantValue(eventListLogic);
Dependencies.bind(IOCSAppLogic.$).toConstantValue(appLogic);
Dependencies.bind(IOCSEventLogic.$).toConstantValue(eventViewLogic);

Dependencies.bind(IVibrodetectorChartLogic.$).toConstantValue(
  vibrodetectorChartLogic,
);

Dependencies.bind(IDesktopValidators.$)
  .to(DesktopValidators)
  .inSingletonScope();
Dependencies.bind(IObjectWindowLogic.$).toConstantValue(objectWindowLogic);

Dependencies.bind(IVisualizationLogic.$).toConstantValue(visualizationLogic);
Dependencies.bind(IOCSIVisElementLogic.$).toConstantValue(visElementLogic);
Dependencies.bind(IVisualizationElementLogic.$).toConstantValue(
  visElementLogic,
);

Dependencies.bind(IVibroDetectorsViewLogic.$).toConstantValue(
  vibroDetectorsViewLogic,
);

Dependencies.bind(IGenericDevicesContainerLogic.$).toConstantValue(
  genericDevicesContainerLogic,
);

Dependencies.bind(IGenericDeviceViewLogic.$).toConstantValue(
  genericDeviceViewLogic,
);

Dependencies.bind(IGenericDevicesDictsLogic.$).toConstantValue(
  genericDevicesDictsLogic,
);
Dependencies.bind(IGenericDeviceChartLogic.$).toConstantValue(
  genericDeviceChartLogic,
);

Dependencies.bind(IVisSystemApiLogic.$).toConstantValue(visSystemApiLogic);

Dependencies.bind(IUnitsLogic.$).toConstantValue(unitsLogic);
Dependencies.bind(IObjectChartLogic.$).toConstantValue(objectChartLogic);

Dependencies.bind(ITabMenuLogic.$).toConstantValue(tabMenuLogic);

Dependencies.bind(INotificationTableLogic.$).toConstantValue(notificationTable);

Dependencies.bind(IGroupAlarmsTableLogic.$).toConstantValue(
  groupAlarmsTableLogic,
);

Dependencies.bind(IGroupAlarmsAddFormLogic.$).toConstantValue(
  groupAlarmsAddForm,
);

Dependencies.bind(IVibrodetectorEditorLogic.$).toConstantValue(
  vibrodetectorEditorLogic,
);

Dependencies.bind(IGenericDeviceEditorLogic.$).toConstantValue(
  genericDeviceEditorLogic,
);

Dependencies.bind(IHubsEditorLogic.$).toConstantValue(hubsEditorLogic);

Dependencies.bind(IObjectEditorLogic.$).toConstantValue(objectEditorLogic);

export { Dependencies };

export default Dependencies;
