import React, { FC, useState } from 'react';

import { Sidebar } from '@/components/sidebar';
import { createGenericContext } from '@/hooks';

import { Views } from './model';
import './style.scss';
import { Appearance } from './theme';

const JSXmap: { [key in Views]?: JSX.Element } = {
  [Views.Appearances]: <Appearance />,
};

export const [useSettingsContext, SettingsProvider] = createGenericContext<{
  view: Views;
  setView: React.Dispatch<React.SetStateAction<Views>>;
}>();

export const Settings: FC = () => {
  const [currentView, setViewState] = useState(Views.Appearances);

  return (
    <SettingsProvider value={{ view: currentView, setView: setViewState }}>
      <div className="settings-container">
        <div className="settings-sidebar">
          <Sidebar />
        </div>
        <div className="settings-content">{JSXmap[currentView]}</div>
      </div>
    </SettingsProvider>
  );
};
