/* eslint-disable @typescript-eslint/require-await */
import { inject, injectable } from 'inversify';

import {
  CreateLayoutMessage,
  RemoveLayoutInput,
  SetCurrentLayoutInput,
  UpdateLayoutInput,
} from '@/types/messages/desktop';
import { CreateLayoutInput } from '@/types/messages/desktop';
import { Desktop, Layout, Window } from '@/types/models/desktop';
import { IDesktopValidators } from '@/validators/interfaces';

import { IDesktopService } from '../interfaces/desktop';
import { Response } from '../types/response';
import { BaseService } from './base';

@injectable()
export class DesktopService extends BaseService implements IDesktopService {
  desktopValidators: IDesktopValidators;

  constructor(
    @inject(IDesktopValidators.$) desktopValidators: IDesktopValidators,
  ) {
    super();
    this.desktopValidators = desktopValidators;
  }

  public async getDesktop(): Promise<Response<Desktop>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.desktopGetDesktop();
      });

      const unvalidatedDesktop = await task;
      const newDesktop: Desktop = {
        ...unvalidatedDesktop,
        lastFocus: null,
        layouts: {},
      };

      for (const layoutKey in unvalidatedDesktop.layouts) {
        const unvalidatedLayout = unvalidatedDesktop.layouts[layoutKey];
        const newLayout: Layout = { ...unvalidatedLayout, windows: {} };
        const newWindows: Record<string, Window> = {};

        for (const windowKey in unvalidatedLayout.windows) {
          const window = unvalidatedLayout.windows[windowKey];
          if (this.desktopValidators.validateWindow(window)) {
            newWindows[windowKey] = window;
          }
        }

        newLayout.windows = newWindows;

        newDesktop.layouts[layoutKey] = newLayout;
      }

      return {
        success: true,
        data: newDesktop,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async setCurrentLayout(
    input: SetCurrentLayoutInput,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.desktopSetCurrentLayout(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async updateLayout(input: UpdateLayoutInput): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.desktopUpdateLayout(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async createLayout(
    input: CreateLayoutInput,
  ): Promise<Response<CreateLayoutMessage>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.desktopCreateLayout(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async removeLayout(input: RemoveLayoutInput): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.desktopRemoveLayout(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
