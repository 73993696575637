import { shallowEqual } from 'fast-equals';
import { events, kea, key, path, props, selectors } from 'kea';

import Dependencies from '@/deps';
import {
  IDeviceTableLogic,
  IGenericDevicesContainerLogicProps,
} from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IGenericDeviceService } from '@/services/interfaces';
import { MDevice } from '@/types/models';

import type { logicType } from './indexType';

export interface GenericDevicesContainerLogicProps
  extends IGenericDevicesContainerLogicProps {
  deps: {
    deviceTableLogic: IDeviceTableLogic;
    genericDeviceService: IGenericDeviceService;
  };
}

const logic = kea<logicType>([
  props({} as GenericDevicesContainerLogicProps),
  key((props) => props.objectUuid),
  path((key) => ['genericDevicesContainer', key]),
  selectors(({ props }) => ({
    devices: [
      () => [
        props.deps.deviceTableLogic({ objectUuid: props.objectUuid }).selectors
          .values,
      ],
      (values) => {
        if (values != null) {
          return values.filter(
            (d: MDevice.TableView) => d.listKind == MDevice.Kind.GenericDevice,
          );
        } else {
          return null;
        }
      },
      { resultEqualityCheck: shallowEqual },
    ],
  })),
  //TODO: this should use kea connect, but kea-typegen can not get over it
  events(({ props }) => ({
    beforeMount: () => {
      props.deps.deviceTableLogic({ objectUuid: props.objectUuid }).mount();
    },
    afterUnmount: () => {
      props.deps.deviceTableLogic({ objectUuid: props.objectUuid }).unmount();
    },
  })),
]);

export const genericDevicesContainerLogic = injectDepsToLogic(logic, () => ({
  deviceTableLogic: Dependencies.get(IDeviceTableLogic.$),
  genericDeviceService: Dependencies.get(IGenericDeviceService.$),
}));
