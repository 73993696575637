import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useMountedLogic, useValues } from 'kea';
import { FC } from 'react';

import { Colorpicker } from '@/components/colorpicker';
import { IEditorLogic } from '@/logic/interfaces/editor';
import { IEditorFieldLogic } from '@/logic/interfaces/editorField';
import { EditorFieldKey, EditorValue } from '@/types/custom/editorTypes';

import './../style.scss';

interface ColorEditorProps {
  name: string;
  editorLogic: IEditorLogic;
  editorKey: EditorFieldKey;
}

export const ColorEditor: FC<ColorEditorProps> = (props) => {
  const editorLogic = useMountedLogic(props.editorLogic);

  const fieldLogic = useInjection(IEditorFieldLogic.$)({
    editorLogic: editorLogic as BuiltLogic<IEditorLogic>,
    editorKey: props.editorKey,
  });

  const { updateValue } = useActions(fieldLogic);
  const { value } = useValues(fieldLogic);

  return (
    <div className="OCS-color-editor">
      <div className="OCS-editor-value">{props.name}</div>
      <div className="OCS-editor-input">
        <Colorpicker
          color={value != null ? value : ''}
          setColor={(color) => {
            updateValue({
              value: color,
            });
          }}
        />
      </div>
    </div>
  );
};
