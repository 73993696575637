{
  "eventList": {
    "notifications": "Powiadomienia",
    "remove": "Napewno chcesz usunąć wszystkie powiadomienia?",
    "removeAccept": "Tak",
    "removeReject": "Nie",
    "eventDialog": {
      "alarmEvent": "Powiadomienie alarmu",
      "alarmInput": "Komentarz",
      "alarmAccept": "Zatwierdź",
      "alarmError": "Ponów"
    },
    "confirmAll": {
      "dialogTitle": "Potwierdzenie",
      "details": "Potwierdź wszystkie powiadomienia alarmów",
      "inputPlaceholder": "Komentarz",
      "confirmLabel": "Zatwierdź"
    }
  },
  "table": {
    "template": {
      "icon": {
        "tooltip": "Wszystkie alarmy:"
      }
    },
    "reload": {
      "error": "Wystąpił błąd poczas wczytywania tabeli",
      "button": "Spróbuj ponownie"
    },
    "filters": {
      "text": "Wyszukaj po nazwie",
      "number": "Wyszukaj po wartości",
      "tag": "Wyszukaj po tagu",
      "beginDate": "Wybierz startową datę",
      "endDate": "Wybierz końcową datę",
      "apply": "Potwierdź",
      "clear": "Wyczyść"
    }
  },
  "tooltips": {
    "alarmEvent": "Powiadomienia"
  }
}
