import { Table } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { useActions } from 'kea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FC } from 'react';

import { IDesktopLogic, IDeviceTableLogic } from '@/logic/interfaces';
import { MDevice } from '@/types/models';

import './../mainStyle.scss';

export const DeviceDatatable: FC = () => {
  return (
    <div className="OCSdatatable">
      <Card className="OCSdatatable-card">
        <Table
          tableLogicIdentifier={IDeviceTableLogic.$}
          additionalColumns={{ editor: DeviceEditorButton }}
        />
      </Card>
    </div>
  );
};

interface DeviceEditorButtonProps {
  rowData: any;
}

const DeviceEditorButton: FC<DeviceEditorButtonProps> = (props) => {
  const desktopLogic = useInjection(IDesktopLogic.$);

  const { openWindow } = useActions(desktopLogic);

  return (
    <Button
      className="p-button-outlined"
      icon={'ri ri-edit-2-fill'}
      disabled={!editorExist(props.rowData.listKind)}
      onClick={() => {
        const rowData: MDevice.TableView = props.rowData;

        if (rowData.listKind == MDevice.Kind.VibroDetector) {
          openWindow('vibrodetectorEditor', {
            initialData: { deviceUuid: rowData.deviceUuid },
            windowKey: rowData.deviceUuid,
            title: rowData.listName,
          });
        } else if (rowData.listKind == MDevice.Kind.GenericDevice) {
          openWindow('genericDeviceEditor', {
            initialData: { deviceUuid: rowData.deviceUuid },
            windowKey: rowData.deviceUuid,
            title: rowData.listName,
          });
        } else if (rowData.listKind == MDevice.Kind.Hub) {
          openWindow('hubsEditor', {
            initialData: { deviceUuid: rowData.deviceUuid },
            windowKey: rowData.deviceUuid,
            title: rowData.listName,
          });
        }
      }}
    />
  );
};

function editorExist(kind: MDevice.Kind): boolean {
  const available = [
    MDevice.Kind.Hub,
    MDevice.Kind.GenericDevice,
    MDevice.Kind.VibroDetector,
  ];

  return available.includes(kind);
}
