import { InputText } from 'primereact/inputtext';
import {
  ChangeEvent,
  Dispatch,
  HTMLInputTypeAttribute,
  ReactNode,
  SetStateAction,
  memo,
  useCallback,
  useState,
} from 'react';
import { Trans } from 'react-i18next';

import './style.scss';

export interface TextFieldProps {
  id?: string;
  className?: string;
  inputId?: string;
  inputClassName?: string;
  value: string;
  onChange: (value: string) => void;
  label?: string;
  type?: HTMLInputTypeAttribute;
  min?: string | number;
  errorMessage?: string;
  floorLeftContent?: ReactNode;
  floorRightContent?: ReactNode;
  leftIcon?: string;
  rightIcon?: string;
  onLeftIconClick?: () => void;
  onRightIconClick?: () => void;
  inputValidator?: (value: string | number) => boolean;
  validatorSetter?: Dispatch<SetStateAction<boolean>>;
  autoComplete?: string;
  disabled?: boolean;
  valueUnit?: string;
  step?: string;
  pattern?: string;
  maxLength?: number;
}

export const TextField = memo(function TextField(props: TextFieldProps) {
  const {
    id,
    className,
    inputId,
    inputClassName,
    value,
    onChange,
    label,
    type,
    errorMessage,
    onLeftIconClick,
    onRightIconClick,
    inputValidator,
    autoComplete,
    disabled,
    step,
    min,
    pattern,
    maxLength,
  } = props;

  const [isError, setError] = useState(false);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      return onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div
      id={id}
      className={`field text-field ${className != null ? className : ''}`}
    >
      {/*   TextField label   */}
      {label != null ? (
        <label htmlFor={inputId != null ? inputId : undefined}>
          <Trans>{label}</Trans>
        </label>
      ) : null}

      {/*   TextField body   */}
      <span
        className={`text-field-body 
        ${props.leftIcon != null ? 'p-input-icon-left' : ''} 
        ${
          props.rightIcon != null || props.valueUnit != null
            ? 'p-input-icon-right'
            : ''
        }`}
      >
        {/*   TextField left icon   */}
        {props.leftIcon != null ? (
          <i
            className={`${props.leftIcon} ${
              onLeftIconClick != null ? 'clickable' : ''
            }`}
            onClick={onLeftIconClick}
          />
        ) : (
          <></>
        )}

        {/*   TextField input   */}
        <InputText
          id={inputId}
          className={`text-field-input ${
            inputClassName ? inputClassName : ''
          } ${errorMessage != null && isError ? 'p-invalid' : ''}`}
          value={value}
          onChange={onInputChange}
          onBlur={(e) => {
            if (inputValidator != null) {
              const validatorOutput = inputValidator(value);
              setError(!validatorOutput);
              //   if (props.validatorSetter != undefined) {
              //     props.validatorSetter(validatorOutput);
              //     onInputChange(e);
              //   }
              // }
            }
          }}
          type={type}
          min={min}
          autoComplete={autoComplete}
          disabled={disabled}
          step={step}
          pattern={pattern}
          maxLength={maxLength}
        />

        {/*   TextField right icon   */}
        {props.rightIcon != null ? (
          <i
            className={`${props.rightIcon} ${
              onRightIconClick != null ? 'clickable' : ''
            }`}
            onClick={onRightIconClick}
          />
        ) : props.valueUnit != null ? (
          <span className="p-input-suffix">{props.valueUnit}</span>
        ) : (
          <></>
        )}
      </span>

      {/*   TextField errorMessage   */}

      <div className="text-field-floor">
        {props.errorMessage != null && isError ? (
          <span className="p-error text-field-error-message">
            <Trans>{props.errorMessage}</Trans>
          </span>
        ) : props.floorLeftContent != null ? (
          props.floorLeftContent
        ) : (
          <div />
        )}
        {props.floorRightContent}
      </div>
    </div>
  );
});
