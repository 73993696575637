import { SystemAPIDataset as OCSSystemAPIDataset } from '@ocs/ocs-components';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import Dependencies from '@/deps';
import {
  IVisSystemApiLogicProps,
  IVisualizationLogic,
} from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import {
  IDefinedAlarmsService,
  IDeviceService,
  IObjectService,
} from '@/services/interfaces';
import { MDevice } from '@/types/models';
import { MObject } from '@/types/models';

import { getDefinedAlarmStream } from './dataSources/definedAlarm';
import { getDeviceStream } from './dataSources/device';
import type { logicType } from './indexType';
import { objectStream } from './streams/object';
import { objectDescriptionStream } from './streams/objectDescription';

export interface DeviceAlias {
  sourceUuid: string;
  deviceKind: MDevice.Kind;
  interval: number | undefined;
}

export interface AlarmAlias {
  sourceUuid: string;
  interval: number | undefined;
}

export interface visSystemApiLogic extends IVisSystemApiLogicProps {
  deps: {
    visualizationLogic: IVisualizationLogic;
    deviceService: IDeviceService;
    objectService: IObjectService;
    definedAlarmService: IDefinedAlarmsService;
  };
}

export type SystemAPIDataset = OCSSystemAPIDataset;

export interface IvisSystemApiStream {
  cancel(): void;
  callback(callback: (data: Record<string, unknown>) => void): boolean;
}

const logic = kea<logicType>([
  props({} as visSystemApiLogic),
  key(
    (props) =>
      `${props.sourceType}/${props.sourceUuid}/${props.visualizationUuid}/${props.name}/${props.systemapi_name}`,
  ),
  path((key) => ['vissystemapi', key]),
  actions({
    loadAlias: true,
    start: true,
    stop: true,

    setDeviceAlias: (alias: DeviceAlias) => ({ alias }),
    setAlarmAlias: (alias: AlarmAlias) => ({ alias }),
  }),
  reducers({
    deviceAlias: [
      {} as DeviceAlias,
      {
        setDeviceAlias: (_, { alias }) => alias,
      },
    ],
    alarmAlias: [
      {} as AlarmAlias,
      {
        setAlarmAlias: (_, { alias }) => alias,
      },
    ],
  }),
  selectors(({ props }) => ({
    systemapi: [
      () => [props.deps.visualizationLogic(props).selectors.systemapis],
      (systemapis) => systemapis[props.systemapi_name] as SystemAPIDataset,
    ],
    workStates: [
      () => [props.deps.visualizationLogic(props).selectors.workStates],
      (workStates: Record<string, MObject.WorkState>) => workStates,
    ],
  })),
  listeners(({ props, actions, cache, values }) => {
    return {
      loadAlias: async () => {
        if (props.sourceType === 'Object') {
          if (values.systemapi.dataSource === 'Device') {
            const response =
              await props.deps.deviceService.getByVisalizationAlias({
                objectUuid: props.sourceUuid,
                visualizationAlias: values.systemapi.visualizationAlias,
              });

            if (response.success) {
              const deviceKindResponse = await props.deps.deviceService.get({
                deviceUuid: response.data.deviceUuid,
              });

              if (deviceKindResponse.success) {
                actions.setDeviceAlias({
                  sourceUuid: response.data.deviceUuid,
                  deviceKind: deviceKindResponse.data.base.kind,
                  interval: values.systemapi.interval,
                });
              }
            }
          } else if (values.systemapi.dataSource === 'DefinedAlarm') {
            const response =
              await props.deps.definedAlarmService.getByVisalizationAlias({
                objectUuid: props.sourceUuid,
                visualizationAlias: values.systemapi.visualizationAlias,
              });

            if (response.success) {
              actions.setAlarmAlias({
                sourceUuid: response.data.definedAlarmUuid,
                interval: values.systemapi.interval,
              });
            }
          }
        }

        actions.start();
      },
      start: async () => {
        let stream: null | IvisSystemApiStream = null;
        do {
          if (props.sourceType === 'Object') {
            if (values.systemapi.dataSource === 'Device') {
              stream = await getDeviceStream(values.deviceAlias, props);
            } else if (values.systemapi.dataSource === 'DefinedAlarm') {
              stream = await getDefinedAlarmStream(values.alarmAlias, props);
            } else if (values.systemapi.dataSource === 'ObjectDescription') {
              stream = await objectDescriptionStream(
                props.sourceUuid,
                values.systemapi.interval,
              );
            } else if (values.systemapi.dataSource === 'Object') {
              stream = await objectStream(
                props.sourceUuid,
                values.workStates,
                props,
                values.systemapi.interval,
              );
            } else {
              console.error(
                'vis dataSource is not implemented yet',
                values.systemapi.dataSource,
              );
            }
          } else {
            console.error('vis sourceType Device is not implemented yet');
          }

          if (!logic.isMounted(props)) return;
        } while (stream == null);

        stream.callback((values) => {
          const visLogic = props.deps.visualizationLogic({
            sourceType: props.sourceType,
            sourceUuid: props.sourceUuid,
            visualizationUuid: props.visualizationUuid,
            name: props.name,
          });
          if (visLogic.isMounted()) {
            visLogic.actions.updateSystemApiValue(props.systemapi_name, values);
          } else {
            console.warn(
              'running cancel from callback itself on: ',
              stream,
              props,
            );
            stream?.cancel();
            visSystemApiLogic(props).unmount();
          }
        });
        cache.stream = stream;
      },
      stop: () => {
        const stream: IvisSystemApiStream | null = cache.stream;
        stream?.cancel();
      },
    };
  }),
  afterMount(({ actions }) => {
    actions.loadAlias();
  }),
  beforeUnmount(({ props, cache }) => {
    if (cache.stream != null) {
      const stream = cache.stream as IvisSystemApiStream;
      stream.cancel();
    } else {
      console.warn('stram not present in cache!', props);
    }
  }),
]);

export const visSystemApiLogic = injectDepsToLogic(logic, () => ({
  visualizationLogic: Dependencies.get(IVisualizationLogic.$),
  deviceService: Dependencies.get(IDeviceService.$),
  objectService: Dependencies.get(IObjectService.$),
  definedAlarmService: Dependencies.get(IDefinedAlarmsService.$),
}));
