import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import {
  BaseFilterType,
  BoolFilterValue,
  DateFilterValue,
  IconFilterType,
  TagFilterType,
  TextFilterValue,
} from '@/types/filter';
import { TextFilterType } from '@/types/filter';
import { ColumnDefinition, ColumnType, TableValue } from '@/types/table';

import { SortOptions } from './../../../types/table';

export interface ITableLogicProps extends Record<string, unknown> {}

export interface ITable extends Logic {
  readonly props: ITableLogicProps;
  readonly actions: {
    readonly addFilter?: (key: string, value: string) => void;
    readonly removeFilter?: (key: string) => void;
    readonly refresh: () => void;
    readonly setFilterValue?: (key: string, filteredValue: any) => void;
    readonly clearFilterValue?: (key: string) => void;
    readonly onRowClicked?: (rowKey: string) => void;
    readonly setSortOptions?: (sortOptions: SortOptions) => void;
    readonly setExpandedRowKey?: (key: string | undefined) => void;
  };
  readonly values: {
    readonly dataKey: string;
    readonly columns?: ColumnDefinition[] | null;
    readonly columnTypes: { [key: string]: ColumnType } | null;
    readonly columnWidths?: { [key: string]: string } | null;
    readonly sortableColumns?: string[] | null;
    readonly filterableColumns?: string[] | null;
    readonly values: TableValue[] | null;
    readonly sortOptions?: SortOptions | undefined;
    readonly filters?: {
      [key: string]: (
        | BaseFilterType
        | IconFilterType
        | TagFilterType
        | TextFilterType
      )[];
    };
    readonly filtersValues?: (
      | TextFilterValue
      | BoolFilterValue
      | DateFilterValue
    )[];
    readonly sort?: string | null;
    readonly expand?: {
      columns: Record<string, string>;
      logic: interfaces.ServiceIdentifier<ITableLogic>;
    } | null;
    readonly expandProps?: { [key: string]: any };
    readonly loading: boolean;
    readonly error: boolean;
  };
}

export interface ITableLogic extends LogicWrapper<ITable> {}

export const ITableLogicSymbol: interfaces.ServiceIdentifier<ITableLogic> =
  Symbol('ITableLogic');

export namespace ITableLogic {
  export const $: interfaces.ServiceIdentifier<ITableLogic> = ITableLogicSymbol;
}
