import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MGenericDevice } from '@/types/models';

interface IGenericDictsDevices extends Logic {
  readonly actions: {};
  readonly values: {
    readonly brands: Record<number, MGenericDevice.Brand>;
    readonly brandModbusTasks: Record<number, MGenericDevice.BrandModbusTask[]>;
    readonly models: Record<number, MGenericDevice.Model>;
    readonly modelColumns: Record<number, MGenericDevice.ModelColumn[]>;
  };
  readonly selectors: {
    readonly brands: (
      state: any,
      props?: any,
    ) => Record<number, MGenericDevice.Brand>;
    readonly modelColumns: (
      state: any,
      props?: any,
    ) => Record<number, MGenericDevice.ModelColumn[]>;
  };
}

export interface IGenericDevicesDictsLogic
  extends LogicWrapper<IGenericDictsDevices> {}

export namespace IGenericDevicesDictsLogic {
  export const $: interfaces.ServiceIdentifier<IGenericDevicesDictsLogic> =
    Symbol('IGenericDevices');
}
