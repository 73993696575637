export type TaskKind = 'Controller' | 'Database' | 'Variable' | 'SystemAPI';

export type RegisterKind =
  | 'B1'
  | 'B8'
  | 'B16'
  | 'B32'
  | 'BI1'
  | 'BI16'
  | 'BANK16'
  | 'BANK32';

export type Format = 'Decimally' | 'DecimallySign' | 'Hex' | 'Text' | 'F32';

export interface RGBColor {
  red: number;
  green: number;
  blue: number;
}

export interface FontStyle {
  bold: boolean;
  italic: boolean;
  underline: boolean;
  crossedOut: boolean;
}

export enum NodeType {
  Image = 'Image',
  Desc = 'Desc',
  Group = 'Group',
  Measurement = 'Measurement',
  Line = 'Line',
}

export enum LineStyle {
  Continuous = 'Continuous',
  Hatched = 'Hatched',
  DotDash = 'DotDash',
  DotDotDash = 'DotDotDash',
}

export enum LineDirection {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
  DiagonalUpLeftDownRight = 'DiagonalUpLeftDownRight',
  DiagonalUpRightDownLeft = 'DiagonalUpRightDownLeft',
  NotSpecified = 'NotSpecified',
}

export enum Dynamization {
  None = 'None',
  Visibility = 'Visibility',
  Height = 'Height',
  Width = 'Width',
  PositionTop = 'PositionTop',
  PositionLeft = 'PositionLeft',
}

export interface VisLogicProps {
  sourceUuid: string;
  sourceType: 'Object' | 'Device';
  visualizationUuid: string;
  name: string;
}

export interface VisElementProps extends VisLogicProps {
  elementUuid: string;
  tasks?: Task[];
}

export interface SystemAPIDataset {
  name: string;
  active: boolean;
  visualizationAlias: string;
  interval?: number;
  dataSource:
    | 'Object'
    | 'Device'
    | 'System'
    | 'DeviceDescription'
    | 'ObjectDescription'
    | 'User'
    | 'DefinedAlarm';
}

export interface Task {
  kind: TaskKind;
  systemApiDataset?: string | null;
  taskGroup?: string | null;
  variable?: string | null;
  controller?: string | null;
  dataset?: string | null;
  registerKind: RegisterKind;
  registerAddress: number;
  bankNumber: number;
  registerCount?: number | null;
  datasetField?: string | null;
  mask: number;
  scale?: boolean | null;
  multiplier: number;
  divider: number;
  offset: number;
  functionCalc: Function | null;
  limit: boolean | null;
  precision: number | null;
  thousands: boolean | null;
}

export interface VisualizationType {
  name: string;
  width: number;
  height: number;
  workspaceWidth: number;
  workspaceHeight: number;
  description: string;
  frame: boolean;
  frameRight: number;
  frameLeft: number;
  color: RGBColor;
  font: string;
  fontColor: RGBColor;
  fontStyle: FontStyle;
  fontSize: number;
  OnStart?: string;
  OnClose?: string;
  modbusTasks?: any[];
  childs: (
    | ImageType
    | MeasurementType
    | GroupType
    | LineType
    | DescriptionType
  )[];
}

export interface ImageType {
  name: string;
  type: NodeType.Image;
  top: number;
  left: number;
  height: number;
  width: number;
  tooltip: string;
  OnStart?: string;
  OnMouseMove?: string;
  isMovable: boolean;
  isTransparent: boolean;
  isResizable: boolean;
  variableSize: boolean;
  dynamization: string;
  flash: boolean;
  source: string;
  tasks?: Task[];
}

export interface MeasurementType {
  name: string;
  type: NodeType.Measurement;
  top: number;
  left: number;
  width: number;
  height: number;
  tooltip: string;
  text: string;
  frame: boolean;
  readonly: boolean;
  color: RGBColor;
  alignment: string;
  font: string;
  fontColor: RGBColor;
  fontStyle: FontStyle;
  fontSize: number;
  hidden: boolean;
  dynamization: string;
  tasks?: Task[];
}

export interface GroupType {
  name: string;
  type: NodeType.Group;
  width: number;
  height: number;
  top: number;
  left: number;
  description: string;
  color: RGBColor;
  frame: boolean;
  hidden: boolean;
  transparent: boolean;
  dynamization: string;
  tasks?: Task[];
  childs: (
    | MeasurementType
    | ImageType
    | DescriptionType
    | LineType
    | GroupType
  )[];
}

export interface LineType {
  name: string;
  type: NodeType.Line;
  top: number;
  left: number;
  height: number;
  width: number;
  color: RGBColor;
  lineWidth: number;
  hidden: boolean;
  style: LineStyle;
  dynamization: string;
  direction: LineDirection;
  tasks?: Task[];
}

export interface DescriptionType {
  name: string;
  type: NodeType.Desc;
  top: number;
  left: number;
  height: number;
  width: number;
  tooltip: string;
  variableWidth: boolean;
  description: string;
  dynamization: string;
  wordwrap: boolean;
  color: RGBColor;
  transparent: boolean;
  alignment: string;
  font: string;
  fontColor: RGBColor;
  fontStyle: FontStyle;
  fontSize: number;
  hidden: boolean;
  tasks?: Task[];
}
