/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IUserService } from '@/services/interfaces/user';
import { Response } from '@/services/types/response';
import { UserLoginInMessage, UserLoginOutMessage } from '@/types/messages/user';

import { BaseService } from './base';

@injectable()
export class UserService extends BaseService implements IUserService {
  public async login(
    input: UserLoginInMessage,
  ): Promise<Response<UserLoginOutMessage>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.userLogin(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async logout(): Promise<Response<undefined>> {
    try {
      await BaseService.apiPool.queue(async (worker) => {
        return worker.userLogout();
      });

      return {
        success: true,
        data: undefined,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async logged(): Promise<Response<undefined>> {
    try {
      await BaseService.apiPool.queue(async (worker) => {
        return worker.userLogged();
      });

      return {
        success: true,
        data: undefined,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
  public async keepAlive(): Promise<Response<undefined>> {
    try {
      await BaseService.apiPool.queue(async (worker) => {
        return worker.userKeepAlive();
      });

      return {
        success: true,
        data: undefined,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
