import { shallowEqual } from 'fast-equals';
import { current, produce } from 'immer';
import {
  LogicWrapper,
  LogicWrapperAdditions,
  actions,
  kea,
  listeners,
  props,
  reducers,
  selectors,
} from 'kea';
import { merge } from 'lodash';
import { actionChannel, call, delay, race, take } from 'typed-redux-saga';

import { config } from '@/config';
import Dependencies from '@/deps';
import { SyncStatus } from '@/enums';
import { startSagas, takeEvery } from '@/logic/saga';
import { injectDepsToLogic } from '@/logic/utils';
import { IModules, IModulesKeys } from '@/modules';
import { IDesktopService } from '@/services/interfaces';
import type {
  CreateWindowData,
  DefaultWindowData,
  Desktop,
  Window,
  WindowGeometry,
} from '@/types/models/desktop';
import { ChangeTypeOfSubKeys, RecursivePartial } from '@/utility/types';

import type { logicType } from './indexType';
import {
  createLayout,
  createWindow,
  focusWindow,
  removeWindow,
  updateMultipleGeometries,
  updateWindowGeometry,
} from './reducers';

export const defaultWindowGeometry: WindowGeometry = {
  position: { x: 100, y: 100 },
  size: { width: 1200, height: 500 },
  zIndex: 100,
  minWidth: 600,
  minHeight: 150,
  minimized: false,
  maximized: false,
  pinned: false,
  onTop: false,
};

export const defaultWindowData: DefaultWindowData = {
  geometry: defaultWindowGeometry,
};

const logic = kea<logicType>([
  props(
    {} as {
      deps: {
        desktopService: IDesktopService;
        modules: IModules;
      };
    },
  ),
  actions(({ props }) => ({
    fetch: true,
    fetchFailed: true,
    fetchSuccess: (desktop: Desktop) => ({ desktop }),
    sync: true,
    syncFailed: true,
    syncSuccess: true,
    setCurrentLayout: (id: number) => ({ id }),
    addLayout: (name: string) => ({ name }),
    openWindow: (
      type: IModulesKeys,
      createWindowData: CreateWindowData<IModulesKeys>,
    ) => {
      const moduleDefaultData = props.deps.modules[type].defaultWindowData;

      const { windowKey, ...windowData } = merge(
        createWindowData,
        defaultWindowData,
        moduleDefaultData,

        { type: type },
      );

      return {
        windowKey: windowKey,
        windowData: windowData,
      };
    },
    createWindow: (windowKey: string, windowData: Window) => ({
      windowKey,
      windowData,
    }),
    createLayout: (name: string, id: number) => ({ name, id }),
    focusWindow: (windowKey: string) => ({ windowKey }),
    removeWindow: (windowKey: string) => ({ windowKey }),
    updateWindowGeometry: (
      windowKey: string,
      geometry: RecursivePartial<WindowGeometry>,
    ) => ({
      windowKey,
      geometry,
    }),
    updateMultipleGeometries: (
      geometries: Record<string, RecursivePartial<WindowGeometry>>,
    ) => ({ geometries }),
  })),
  reducers({
    status: [
      SyncStatus.NOT_LOADED as SyncStatus,
      {
        fetch: () => SyncStatus.LOADING,
        fetchFailed: () => SyncStatus.LOADING_ERROR,
        fetchSuccess: () => SyncStatus.IDLE,
      },
    ],
    desktop: [
      {
        currentLayoutId: undefined,
        layouts: {},
      } as Desktop,
      {
        fetchSuccess: (_, { desktop }) => desktop,
        createLayout: (immutableState, { name, id }) => {
          return produce(immutableState, (state) => {
            createLayout(state, name, id);
          });
        },
        setCurrentLayout: (immutableState, { id }) => {
          return produce(immutableState, (state) => {
            state.currentLayoutId = id;
          });
        },
        createWindow: (immutableState, { windowKey, windowData }) => {
          return produce(immutableState, (state) => {
            createWindow(state, windowKey, windowData);
            focusWindow(state, windowData.type + '_' + windowKey);
          });
        },
        removeWindow: (immutableState, { windowKey }) => {
          return produce(immutableState, (state) =>
            removeWindow(state, windowKey),
          );
        },
        updateWindowGeometry: (immutableState, { windowKey, geometry }) => {
          return produce(immutableState, (state) => {
            updateWindowGeometry(state, windowKey, geometry);
            if (!geometry.minimized) focusWindow(state, windowKey);
          });
        },
        updateMultipleGeometries: (immutableState, { geometries }) => {
          return produce(immutableState, (state) =>
            updateMultipleGeometries(state, geometries),
          );
        },
        focusWindow: (immutableState, { windowKey }) => {
          return produce(immutableState, (state) => {
            focusWindow(state, windowKey);
          });
        },
      },
    ],
  }),
  selectors({
    currentLayoutId: [
      (selectors) => [selectors.desktop],
      (desktop) => desktop.currentLayoutId,
    ],
    layouts: [(selectors) => [selectors.desktop], (desktop) => desktop.layouts],
    layoutIds: [
      (selectors) => [selectors.layouts],
      (layouts) =>
        Object.keys(layouts).map((strLayoutId) => {
          return parseInt(strLayoutId);
        }),
    ],
    currentLayout: [
      (selectors) => [selectors.layouts, selectors.currentLayoutId],
      (layouts, currentLayoutId) => {
        if (currentLayoutId !== undefined && currentLayoutId in layouts) {
          return layouts[currentLayoutId];
        }
        return null;
      },
    ],
    currentLayoutName: [
      (selectors) => [selectors.currentLayout],
      (currentLayout) => currentLayout?.name,
    ],
    windows: [
      (selectors) => [selectors.currentLayout],
      (currentLayout) => currentLayout?.windows,
    ],
    windowKeys: [
      (selectors) => [selectors.currentLayout],
      (currentLayout) => {
        if (currentLayout !== null) {
          return Object.keys(currentLayout.windows);
        }
        return [];
      },
      { resultEqualityCheck: shallowEqual },
    ],
    currentWindowTypes: [
      (selectors) => [selectors.currentLayout],
      (currentLayout) => {
        const types: IModulesKeys[] = [];
        for (const key in currentLayout?.windows) {
          const window = currentLayout?.windows[key];
          if (window && !types.includes(window.type)) {
            types.push(window.type);
          }
        }
        return types;
      },
      { resultEqualityCheck: shallowEqual },
    ],
  }),
  listeners(({ values, actions, props }) => ({
    sync: async () => {
      if (
        values.currentLayoutId !== undefined &&
        values.currentLayoutName != undefined &&
        values.windows != undefined
      ) {
        const response = await props.deps.desktopService.updateLayout({
          id: values.currentLayoutId,
          layout: { name: values.currentLayoutName, windows: values.windows },
        });
        if (response.success) {
          actions.syncSuccess();
        } else {
          actions.syncFailed();
        }
      }
    },
    addLayout: async ({ name }) => {
      const response = await props.deps.desktopService.createLayout({ name });
      if (response.success) {
        actions.createLayout(name, response.data.id);
        if (values.currentLayoutId === undefined) {
          actions.setCurrentLayout(response.data.id);
        }
      }
    },
    openWindow: ({ windowKey, windowData }) => {
      actions.createWindow(windowKey, windowData);
    },
    fetch: async () => {
      const response = await props.deps.desktopService.getDesktop();
      if (response.success) {
        await new Promise((r) => setTimeout(r, 500));
        actions.fetchSuccess(response.data);

        actions.fetchSuccess(values.desktop);
      } else {
        actions.fetchFailed();
      }
    },
  })),
  takeEvery(({ values, props }) => {
    return {
      setCurrentLayout: function* syncCurrentLayoutId() {
        if (values.currentLayoutId != null) {
          yield* call(
            [
              props.deps.desktopService,
              props.deps.desktopService.setCurrentLayout,
            ],
            { id: values.currentLayoutId },
          );
        }
      },
    };
  }),
  startSagas(({ actions, actionTypes }) => [
    function* () {
      const requestChan = yield* actionChannel([
        actionTypes.createWindow,
        actionTypes.updateMultipleGeometries,
        actionTypes.updateWindowGeometry,
      ]);
      let sendFlag = false;
      while (true) {
        const { action, timeout } = yield* race({
          action: take(requestChan),
          timeout: delay(config.desktop.syncDelay, true),
        });
        if (timeout && sendFlag) {
          sendFlag = false;
          actions.sync();
        } else if (action) {
          sendFlag = true;
        }
      }
    },
    function* () {
      actions.fetch();
    },
  ]),
]);

type myLogicType = ChangeTypeOfSubKeys<
  logicType,
  'actions',
  'openWindow',
  <Type extends IModulesKeys>(
    type: Type,
    windowData: CreateWindowData<Type>,
  ) => void
> &
  LogicWrapperAdditions<logicType>;

export const desktopLogic = injectDepsToLogic(
  logic as LogicWrapper<myLogicType>,
  () => ({
    desktopService: Dependencies.get(IDesktopService.$),
    modules: Dependencies.get(IModules.$),
  }),
);
