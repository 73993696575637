/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IVibrodetectorService } from '@/services/interfaces';
import { DownloadIn, DownloadOut } from '@/types/messages/genericdevice';
import {
  VibrodetectorGetIn,
  VibrodetectorGetOut,
  VibrodetectorGraphInput,
  VibrodetectorGraphLiveInput,
  VibrodetectorStoreIn,
  VibrodetectorStoreOut,
} from '@/types/messages/vibrodetector';
import { MDevice } from '@/types/models';
import { VibrodetectorData } from '@/types/models/vibrodetector';

import { ServerStream } from '../stream';
import { Response } from '../types/response';
import { BaseService } from './base';

@injectable()
export class VibrodetectorService
  extends BaseService
  implements IVibrodetectorService
{
  public async getGraphData(
    input: VibrodetectorGraphInput,
  ): Promise<Response<[Float64Array, Float64Array]>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.vibrodetectorGetGraphData(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public stream = new ServerStream((input: VibrodetectorGraphLiveInput) =>
    BaseService.apiPool.queue(async (worker) => {
      return { observer: worker.vibrodetectorLive(input) };
    }),
  );

  public async getNow(
    input: MDevice.Messages.GetIn,
  ): Promise<Response<VibrodetectorData>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.vibrodetectorGetNow(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async get(
    input: VibrodetectorGetIn,
  ): Promise<Response<VibrodetectorGetOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.vibrodetectorGet(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async store(
    input: VibrodetectorStoreIn,
  ): Promise<Response<VibrodetectorStoreOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.vibrodetectorStore(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async download(input: DownloadIn): Promise<Response<DownloadOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.vibrodetectorDownload(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
