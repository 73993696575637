import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDevice } from '@/types/models';

export interface IDeviceViewContainerLogicProps
  extends Record<string, unknown> {}

interface IDeviceViewContainer extends Logic {
  readonly props: IDeviceViewContainerLogicProps;
  readonly actions: {
    readonly addDevices: (devices: MDevice.View[]) => void;
    readonly useDevices: (deviceIds: string[]) => void;
    readonly releaseDevices: (deviceIds: string[]) => void;
  };
  readonly values: { devices: Record<string, MDevice.View> };
  readonly selectors: {
    readonly devices: (state: any) => Record<string, MDevice.View>;
  };
}

export interface IDeviceViewContainerLogic
  extends LogicWrapper<IDeviceViewContainer> {}

export namespace IDeviceViewContainerLogic {
  export const $: interfaces.ServiceIdentifier<IDeviceViewContainerLogic> =
    Symbol('IDeviceViewContainerLogic');
}
