import {
  DescriptionType,
  Dynamization,
  LineType,
  MeasurementType,
  NodeType,
  SystemAPIDataset,
} from '@/types/visualization';
import { Task } from '@/types/visualization';

import {
  GroupType,
  ImageType,
  VisualizationType,
} from './../types/visualization';

export function ConvertPixelsToREM(pixels: number) {
  return (pixels / 16).toFixed(2);
}

export function ConvertToSystemAPI(json: any) {
  let systemAPIDataset: SystemAPIDataset[] = [];
  for (const element of json) {
    systemAPIDataset.push({
      name: element.name,
      active: element.active,
      visualizationAlias: element.visualization_alias,
      interval: element.interval,
      dataSource: element.data_source,
    });
  }
  return systemAPIDataset;
}

export function ConvertToVisualizationType(json: any) {
  const VisType: VisualizationType = {
    name: json.name,
    width: json.width,
    height: json.height,
    workspaceHeight: json.workspace_height,
    workspaceWidth: json.workspace_width,
    description: json.desc,
    frame: json.frame,
    frameRight: json.frame_right,
    frameLeft: json.frame_left,
    color: {
      red: json.color.red,
      green: json.color.green,
      blue: json.color.blue,
    },
    font: json.font,
    fontColor: {
      red: json.font_color.red,
      green: json.font_color.green,
      blue: json.font_color.blue,
    },
    fontStyle: {
      bold: json.font_style.bold,
      italic: json.font_style.italic,
      underline: json.font_style.underline,
      crossedOut: json.font_style.crossedout,
    },
    fontSize: json.font_size,
    OnStart: json.macro_at_start,
    OnClose: json.macro_at_end,
    modbusTasks: json.modbus_tasks,
    childs: ConvertChilds(json.childs),
  };

  return VisType;
}

export function ConvertToVisualizationImageType(json: any) {
  const visImageType: ImageType = {
    name: json.Image.name,
    type: NodeType.Image,
    top: json.Image.top,
    left: json.Image.left,
    height: json.Image.height,
    width: json.Image.width,
    tooltip: json.Image.tooltip,
    OnStart: json.Image.macro_at_start,
    OnMouseMove: json.Image.macro_at_mouse_move,
    isMovable: json.Image.allow_move,
    isTransparent: json.Image.transparent,
    isResizable: json.Image.resize,
    variableSize: json.Image.variable_size,
    dynamization: json.Image.dynamization,
    flash: json.Image.flash,
    source: json.Image.image,
    tasks: ConvertToTaskType(json.Image.tasks),
  };

  return visImageType;
}

export function ConvertToVisualizationMeasurementType(json: any) {
  const visMeasurementType: MeasurementType = {
    name: json.Measurement.name,
    type: NodeType.Measurement,
    top: json.Measurement.top,
    left: json.Measurement.left,
    width: json.Measurement.width,
    height: json.Measurement.height,
    tooltip: json.Measurement.tooltip,
    text: json.Measurement.text,
    frame: json.Measurement.frame,
    readonly: json.Measurement.readonly,
    color: {
      red: json.Measurement.color.red,
      green: json.Measurement.color.green,
      blue: json.Measurement.color.blue,
    },
    alignment: json.Measurement.alignment,
    font: json.Measurement.font,
    fontColor: {
      red: json.Measurement.font_color.red,
      green: json.Measurement.font_color.green,
      blue: json.Measurement.font_color.blue,
    },
    fontStyle: {
      bold: json.Measurement.font_style.bold,
      italic: json.Measurement.font_style.italic,
      underline: json.Measurement.font_style.underline,
      crossedOut: json.Measurement.font_style.crossedout,
    },
    fontSize: json.Measurement.font_size,
    hidden: json.Measurement.hidden,
    dynamization: json.Measurement.dynamization,
    tasks: ConvertToTaskType(json.Measurement.tasks),
  };

  return visMeasurementType;
}

export function ConvertToVisualizationGroupType(json: any) {
  const groupVis: GroupType = {
    name: json.Group.name,
    type: NodeType.Group,
    width: json.Group.width,
    height: json.Group.height,
    top: json.Group.top,
    left: json.Group.left,
    description: json.Group.description,
    color: {
      red: json.Group.color.red,
      green: json.Group.color.green,
      blue: json.Group.color.blue,
    },
    frame: json.Group.frame,
    hidden: json.Group.hidden,
    transparent: json.Group.transparent,
    dynamization: json.Group.dynamization,
    childs: ConvertChilds(json.Group.childs),
    tasks: ConvertToTaskType(json.Group.tasks),
  };

  return groupVis;
}

export function ConvertToVisualizationLineType(json: any) {
  const LineVis: LineType = {
    name: json.Line.name,
    type: NodeType.Line,
    top: json.Line.top,
    left: json.Line.left,
    height: json.Line.height,
    width: json.Line.width,
    color: {
      red: json.Line.color.red,
      green: json.Line.color.green,
      blue: json.Line.color.blue,
    },
    lineWidth: json.Line.line_width,
    hidden: json.Line.hidden,
    style: json.Line.style,
    direction: json.Line.direction,
    dynamization: json.Line.dynamization,
    tasks: ConvertToTaskType(json.Line.tasks),
  };

  return LineVis;
}

export function ConvertToVisualDescType(json: any) {
  const DescVis: DescriptionType = {
    name: json.Desc.name,
    type: NodeType.Desc,
    top: json.Desc.top,
    left: json.Desc.left,
    height: json.Desc.height,
    width: json.Desc.width,
    tooltip: json.Desc.tooltip,
    variableWidth: json.Desc.variable_width,
    description: json.Desc.desc,
    wordwrap: json.Desc.wordwrap,
    color: {
      red: json.Desc.color.red,
      green: json.Desc.color.green,
      blue: json.Desc.color.blue,
    },
    transparent: json.Desc.transparent,
    alignment: json.Desc.alignment,
    font: json.Desc.font,
    fontColor: {
      red: json.Desc.font_color.red,
      green: json.Desc.font_color.green,
      blue: json.Desc.font_color.blue,
    },
    fontStyle: {
      bold: json.Desc.font_style.bold,
      italic: json.Desc.font_style.italic,
      underline: json.Desc.font_style.underline,
      crossedOut: json.Desc.font_style.crossedout,
    },
    fontSize: json.Desc.font_size,
    hidden: json.Desc.hidden,
    dynamization: json.Desc.dynamization,
    tasks: ConvertToTaskType(json.Desc.tasks),
  };

  return DescVis;
}

export function ConvertChilds(childs: any[]) {
  let convertedChilds: (
    | ImageType
    | DescriptionType
    | LineType
    | GroupType
    | MeasurementType
  )[] = [];

  for (const child of childs) {
    switch (Object.keys(child).toString()) {
      case 'Image':
        convertedChilds.push(ConvertToVisualizationImageType(child));
        break;

      case 'Group':
        convertedChilds.push(ConvertToVisualizationGroupType(child));
        break;

      case 'Line':
        convertedChilds.push(ConvertToVisualizationLineType(child));
        break;

      case 'Desc':
        convertedChilds.push(ConvertToVisualDescType(child));
        break;

      case 'Measurement':
        convertedChilds.push(ConvertToVisualizationMeasurementType(child));
        break;
    }
  }

  return convertedChilds;
}

export function ConvertToTaskType(tasks: any) {
  const task: Task[] = [];

  for (let wrapTask of tasks) {
    task.push({
      kind: wrapTask.Task.kind,
      systemApiDataset: wrapTask.Task.systemapi_dataset,
      taskGroup: wrapTask.Task.taskgroup,
      variable: wrapTask.Task.variable,
      controller: wrapTask.Task.controller,
      dataset: wrapTask.Task.dataset,
      registerKind: wrapTask.Task.register_kind,
      registerAddress: wrapTask.Task.register_address,
      bankNumber: wrapTask.Task.bankNumber,
      registerCount: wrapTask.Task.register_count,
      datasetField: wrapTask.Task.field,
      mask: wrapTask.Task.mask,
      scale: wrapTask.Task.scale,
      multiplier: wrapTask.Task.multiplier,
      divider: wrapTask.Task.divider,
      offset: wrapTask.Task.offset,
      functionCalc: wrapTask.Task.function_calc,
      limit: wrapTask.Task.limit,
      precision: wrapTask.Task.precision,
      thousands: wrapTask.Task.thousands,
    });
  }

  return task;
}

export function GetDynamicProps(
  props: ImageType | MeasurementType | GroupType | LineType | DescriptionType,
  taskValue: number | string,
): ImageType | MeasurementType | GroupType | LineType | DescriptionType {
  if (taskValue == null) return props;

  switch (props.dynamization) {
    case Dynamization.None:
      return props;

    case Dynamization.Visibility:
      return props;

    case Dynamization.Height:
      return { ...props, height: +taskValue };

    case Dynamization.Width:
      return { ...props, width: +taskValue };

    case Dynamization.PositionLeft:
      return { ...props, left: +taskValue };

    case Dynamization.PositionTop:
      return { ...props, top: +taskValue };

    default:
      return props;
  }
}
