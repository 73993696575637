import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { MGlobal } from '@/types/models';

export interface IGlobalService {
  getUnitsList: (
    input: MGlobal.Messages.GetUnitsListIn,
  ) => Promise<Response<MGlobal.Messages.GetUnitsListOut>>;

  getLightningChartLicense: () => Promise<
    Response<MGlobal.Messages.GetLightningChartLicenseOut>
  >;
}

export namespace IGlobalService {
  export const $: interfaces.ServiceIdentifier<IGlobalService> =
    Symbol('IGlobalService');
}
