export enum TableOrder {
  Asc = 0,
  Desc = 1,
}

export enum TableSearchRule {
  Equal = 0,
  NotEqual = 1,
  BeginsWith = 2,
  Greater = 3,
  GreaterOrEqual = 4,
  Less = 5,
  LessOrEqual = 6,
}

export interface TableSort<Column = string> {
  order: TableOrder;
  column: Column;
}

export interface TableSearch<Column = string> {
  column: Column;
  rule: TableSearchRule;
  value: unknown[];
}

export interface TableCount {
  count: number;
  page: number;
}
