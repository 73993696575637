import { EnumContent } from '@/types/custom/editorTypes';
import { MDevice, MGenericDevice } from '@/types/models';

export function getColumns(
  deviceKind: MDevice.Kind,
  e: Record<number, MGenericDevice.ModelColumn[]>,
  modelId: number,
): EnumContent[] {
  switch (deviceKind) {
    case MDevice.Kind.GenericDevice:
      const array: EnumContent[] = [];

      Object.values(e).forEach((tempRecord) => {
        tempRecord.forEach((option) => {
          if (option.modelId === modelId) {
            array.push({
              name: option.name,
              key: option.modelColumnId,
            });
          }
        });
      });

      return array;

    case MDevice.Kind.VibroDetector:
      return [
        {
          name: 'editor.enums.vibrodetector.result',
          key: 'measuring_result',
        },
      ];

    default:
      return [];
  }
}
