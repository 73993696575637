import { EditorModel, EditorTypes } from '@/types/custom/editorTypes';

export const groupAlarmsAddFormModel: Record<
  string,
  Record<string, Record<string, EditorModel>>
> = {
  groupData: {
    groupData: {
      description: {
        name: 'editor.alarmGroups.name',
        type: EditorTypes.string,
        inputValidator: (value) => {
          if (value === '') return false;
          else return true;
        },
        errorMessage: 'editor.validator.emptyFieldMessage',
      },
      rule: {
        name: 'editor.alarmGroups.rule',
        type: EditorTypes.enum,
      },
      eventLevelId: {
        name: 'editor.alarmGroups.eventLevelId',
        type: EditorTypes.enum,
      },
      color: {
        name: 'editor.alarmGroups.color',
        type: EditorTypes.color,
      },
    },
  },
};
