{
  "date": "Data",
  "windowTypes": {
    "users": "Użytkownicy",
    "settings": "Ustawienia",
    "test": "Testy",
    "devices": "Lista urządzeń",
    "objects": "Lista obiektów",
    "objectView": "Obiekty",
    "definedAlarms": "Zdefiniowane alarmy",
    "definedAlarmsEdit": "Edycja alarmu",
    "vibrodetector": "Detektory",
    "visualizationsView": "Wizualizacje",
    "addGroupForm": "Nowa grupa alarmów",
    "editGroupForm": "Edycja grupy alarmów",
    "vibrodetectorEditor": "Edycja detektorów",
    "genericDeviceEditor": "Edycja innych urządzeń",
    "hubsEditor": "Edycja koncentratorów"
  },
  "desktop": {
    "layout": "Układ",
    "layoutName": "Nazwa układu",
    "fetch": {
      "error": "Błąd ładowania układu",
      "errorMessage": "Wystąpił błąd podczas ładowania układu pulpitu.",
      "empty": "Brak układu pulpitu",
      "emptyMessage": "Podaj nazwę układu pulpitu aby rozpocząć pracę z systemem."
    },
    "create": {
      "shortName": "Nazwa układu musi składać się z co najmniej 5 znaków."
    }
  },
  "actions": {
    "tryAgain": "Spróbuj ponownie",
    "create": "Stwórz"
  },
  "object": {
    "workState": "Tryb pracy",
    "view": {
      "visualization": "Wizualizacja",
      "detectors": "Detektory",
      "generic_devices": "Inne urzadzenia",
      "definedAlarmsTable": "Alarmy",
      "notificationTable": "Powiadomienia",
      "graph": "Wykres"
    }
  },
  "window": {
    "error": {
      "message": "Błąd podczas ładowania zawartości okna:",
      "retry": "Ponów"
    }
  },
  "table": {
    "definedAlarms": {
      "eventLevelId": "Poziom zdarzenia",
      "listDescription": "Nazwa",
      "listColor": "Kolor",
      "state": "Stan",
      "stateDateTime": "Data zmiany stanu",
      "isActive": "Włączony",
      "icons": {
        "off": "Nieaktywne",
        "pendingOff": "Oczekujący na dezaktywowanie",
        "pendingOn": "Oczekujący na aktywność",
        "on": "Aktywne",
        "error": "Błąd"
      }
    },
    "alarmGroup": {
      "listDescription": "Nazwa",
      "eventLevelId": "Poziom zdarzenia",
      "listColor": "Kolor",
      "state": "Stan",
      "stateDate": "Data zmiany stanu"
    },
    "notifications": {
      "startDatetime": "Data początkowa",
      "endDatetime": "Data zakończenia",
      "text": "Treść alarmu",
      "source": "Źródło",
      "confirmUserText": "Wiadomość potwierdzająca",
      "confirmUserDate": "Data potwierdzenia",
      "eventLevelId": "Poziom zdarzenia",
      "sourceTags": {
        "definedAlarm": "Alarm",
        "definedAlarmGroup": "Grupa alarmów",
        "device": "Urządzenie",
        "object": "Obiekt",
        "user": "User"
      }
    },
    "event_level": {
      "info": "Info",
      "warning": "Ostrzeżenie",
      "danger": "Zagrożenie",
      "critical": "Krytyczny"
    },
    "device": {
      "listName": "Nazwa",
      "statusNoTransmission": "Status transmisji",
      "temporaryLastDataDate": "Data aktualizacji"
    },
    "object": {
      "listRegion": "Region",
      "listTypeId": "Identyfikator typu",
      "listCustomer": "Klient",
      "listCity": "Miasto",
      "listAddress": "Adres",
      "listName": "Nazwa",
      "temporaryLastDataDate": "Data aktualizacji",
      "workState": "Tryb pracy",
      "status": "Status",
      "icons": {
        "partialNoTransmission": "Częsciowy brak transmisji",
        "totalNoTransmission": "Całkowity brak transmisji",
        "definedAlarms": "DefinedAlarms",
        "heatmeter": "heatmeter",
        "preinsulation": "preinsulation",
        "accesscontrol": "accesscontrol",
        "lowBattery": "lowBattery",
        "watermeter": "watermeter",
        "noStatus": "Brak błędów"
      }
    }
  },
  "editor": {
    "refresh": {
      "errorMessage": "Wczytywanie nie powiodło się, spróbuj ponownie",
      "button": "Odśwież"
    },
    "toast": {
      "success": "Pomyślnie zapisano zmiany.",
      "failure": "Nie udało się zapisać zmian."
    },
    "footer": {
      "confirm": "Zatwierdź",
      "reset": "Zresetuj",
      "confirmMessage": "Czy chcesz zapisać zmiany?",
      "removeMessage": "Czy chcesz usunąć grupę alarmów?",
      "acceptLabel": "Tak",
      "rejectLabel": "Nie"
    },
    "definedAlarms": {
      "data": "Informacje Podstawowe",
      "group": "Grupa alarmów",
      "basicSettings": "Ustawienia podstawowe",
      "advancedSettings": "Ustawienia zaawansowane",
      "name": "Nazwa:",
      "device": "Urządzenie:",
      "color": "Kolor:",
      "eventLevel": "Poziom zdarzenia:",
      "priority": "Priorytet:",
      "active": "Aktywny:",
      "sound": "Dźwięk:",
      "col": "Pole wartości:",
      "rule": "Reguła progu:",
      "value": "Wartość progu:",
      "math": "Math:",
      "mathCol": "Math Col:",
      "onDelay": "Opóźnienie przekroczenia progu:",
      "offDelay": "Opóźnienie braku przekroczenia progu:",
      "round": "Zaokrąglenie wartości:"
    },
    "validator": {
      "emptyMessage": "Pole musi mieć wartość dodatnią",
      "emptyFieldMessage": "Pole nie może być puste"
    },
    "enums": {
      "object": {
        "noState": "Brak stanu pracy"
      },
      "group": {
        "noGroup": "Brak grupy"
      },
      "groupRule": {
        "and": "Reguła 'oraz'",
        "or": "Reguła 'lub'"
      },
      "rule": {
        "equal": "Równe",
        "notequal": "Nie równe",
        "less": "Mniejsze",
        "lessorequal": "Mniejsze lub równe",
        "greater": "Większe",
        "greaterorequal": "Większe lub równe",
        "change": "Zmień",
        "nochange": "Bez zmian"
      },
      "vibrodetector": {
        "result": "Rezultat pomiaru"
      },
      "event_level": {
        "info": "Info",
        "warning": "Ostrzeżenie",
        "danger": "Zagrożenie",
        "critical": "Krytyczny"
      },
      "math": {
        "add": "Dodawanie",
        "sub": "Odejmowanie",
        "mul": "Mnożenie",
        "div": "Dzielenie",
        "modulo": "Modulo"
      },
      "hysteresisMode": {
        "percentage": "Wartość procentowa",
        "absolute": "Wartość bezwzględna"
      }
    },
    "alarmGroups": {
      "groupData": "Dane grupy alarmów",
      "name": "Nazwa:",
      "rule": "Grupa:",
      "eventLevelId": "Poziom zdarzenia",
      "color": "Kolor:"
    },
    "vibrodetector": {
      "primaryData": "Ustawienia detektora",
      "name": "Nazwa:",
      "hysteresis": "Wartość histerezy:",
      "hysteresisMode": "Tryb pracy histerezy:",
      "timeoutHysteresis": "Czas stabilizacji pomiaru"
    },
    "genericDevice": {
      "primaryData": "Ustawienia urządzenia",
      "name": "Nazwa:",
      "hysteresis": "Wartość histerezy:",
      "multipleValueUnits": "[Dotyczy wszystkich wartości odczytowych]",
      "hysteresisMode": "Tryb pracy histerezy:",
      "timeoutHysteresis": "Czas stabilizacji pomiaru"
    },
    "object": {
      "primaryData": "Ustawienia obiektu",
      "name": "Nazwa:",
      "workState": "Stan pracy:",
      "delay": "Opóźnienie:"
    },
    "hubs": {
      "primaryData": "Ustawienia koncentratora",
      "name": "Nazwa:",
      "readTime": "Częstotliwość odczytu:"
    }
  },
  "toolbar": {
    "paginatorDropdownElements": "Liczba wierszy:",
    "searchbarPlaceholder": "Wyszukiwanie"
  },
  "settings": {
    "visualization": {
      "visualization": "Globalny wygląd",
      "appearance": "Wygląd",
      "themes": "Motywy",
      "font": {
        "options": "Czcionka",
        "size": "Rozmiar czcionki"
      }
    }
  },
  "chart": {
    "csv": {
      "label": "Czas uśrednienia",
      "noTime": "Brak",
      "confirm": "Zatwierdź",
      "cancel": "Anuluj"
    }
  },
  "tooltips": {
    "logout": "Wyloguj",
    "refresh": "Odśwież",
    "fold": "Zwiń",
    "unfold": "Rozwiń",
    "addGroup": "Dodaj grupę",
    "removeGroupButton": "Usuń grupę",
    "chart": {
      "buttons": {
        "reload": "Odśwież wykres",
        "live": "Uruchom w czasie rzeczywistym",
        "saveToCSV": "Zapisz do CSV",
        "saveToImage": "Zapisz zdjęcie wykresu"
      }
    }
  }
}
