import { produce } from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  listeners,
  path,
  reducers,
} from 'kea';

import { injectDepsToLogic } from '@/logic/utils';
import { MDevice } from '@/types/models';

import type { logicType } from './indexType';

const logic = kea<logicType>([
  path(['devices', 'view']),
  actions({
    startGarbageCollection: true,
    stopGarbageCollection: true,
    addDevices: (devices: MDevice.View[]) => ({
      devices,
    }),
    useDevices: (deviceIds: string[]) => ({ deviceIds }),
    releaseDevices: (deviceIds: string[]) => ({ deviceIds }),
    removeDevices: (deviceIds: string[]) => ({ deviceIds }),
  }),
  reducers({
    devices: [
      {} as { [key: string]: MDevice.View },
      {
        addDevices: (immutableState, { devices }) =>
          produce(immutableState, (state) => {
            for (const device of devices) {
              state[device.deviceUuid] = device;
            }
          }),
        removeDevices: (immutableState, { deviceIds }) =>
          produce(immutableState, (state) => {
            for (const deviceId of deviceIds) {
              if (deviceId in state) {
                delete state[deviceId];
              }
            }
          }),
      },
    ],
    usageCounters: [
      {} as {
        [key: string]: {
          counter: number;
          lastUseTime: Date;
        };
      },
      {
        addDevices: (immutableState, { devices }) =>
          produce(immutableState, (state) => {
            for (const device of devices) {
              if (!(device.deviceUuid in state)) {
                state[device.deviceUuid] = {
                  counter: 0,
                  lastUseTime: new Date(),
                };
              }
            }
          }),
        useDevices: (immutableState, { deviceIds }) =>
          produce(immutableState, (state) => {
            for (const id of deviceIds) {
              if (id in state) {
                state[id].counter += 1;
                state[id].lastUseTime = new Date();
              } else {
                throw Error('Using an device not present in container');
              }
            }
          }),
        releaseDevices: (immutableState, { deviceIds }) =>
          produce(immutableState, (state) => {
            for (const id of deviceIds) {
              if (id in state) {
                if (state[id].counter > 0) {
                  state[id].counter -= 1;
                  state[id].lastUseTime = new Date();
                }
              } else {
                throw Error('Releasing an device not present in container');
              }
            }
          }),
        removeDevices: (immutableState, { deviceIds }) =>
          produce(immutableState, (state) => {
            for (const id of deviceIds) {
              if (id in state) {
                delete state[id];
              }
            }
          }),
      },
    ],
    collectingGarbage: [
      false as boolean,
      {
        startGarbageCollection: (_) => true,
        stopGarbageCollection: (_) => false,
      },
    ],
  }),
  listeners(({ values, actions }) => ({
    startGarbageCollection: async (_, breakpoint) => {
      breakpoint();
      while (values.collectingGarbage) {
        const currentTime = new Date();
        const deviceIdsToRemove: string[] = [];
        for (const deviceKey in values.usageCounters) {
          const usageCounter = values.usageCounters[deviceKey];
          if (
            usageCounter.counter <= 0 &&
            currentTime.getTime() - usageCounter.lastUseTime.getTime() >= 5000
          ) {
            deviceIdsToRemove.push(deviceKey);
          }
        }
        if (deviceIdsToRemove.length > 0) {
          actions.removeDevices(deviceIdsToRemove);
        }
        await breakpoint(5000);
      }
    },
  })),
  afterMount(({ actions }) => {
    actions.startGarbageCollection();
  }),
  beforeUnmount(({ actions }) => {
    actions.stopGarbageCollection();
  }),
]);

export const deviceViewContainerLogic = injectDepsToLogic(logic, () => ({}));
