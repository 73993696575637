import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDevice, MGenericDevice, MGlobal } from '@/types/models';

// begin joined generic devices view logic
export interface IGenericDevicesContainerLogicProps {
  objectUuid: string;
}

interface IGenericDevicesContainer extends Logic {
  readonly props: IGenericDevicesContainerLogicProps;
  readonly actions: {};
  readonly values: {
    readonly devices: MDevice.TableView[] | null;
  };
  readonly selectors: {
    readonly devices: (
      deviceViews: MDevice.View[],
    ) => MDevice.TableView[] | null;
  };
}

export interface IGenericDevicesContainerLogic
  extends LogicWrapper<IGenericDevicesContainer> {}

export namespace IGenericDevicesContainerLogic {
  export const $: interfaces.ServiceIdentifier<IGenericDevicesContainerLogic> =
    Symbol('IGenericDevicesContainer');
}

// end joined generic devices view logic

// begin single generic devices view logic

export interface IGenericDeviceViewLogicProps {
  objectUuid: string;
  deviceUuid: string;
}

interface IGenericDeviceView extends Logic {
  readonly props: IGenericDeviceViewLogicProps;
  readonly actions: {
    connectLiveStream: () => void;
    disconnectLiveStream: () => void;
  };
  readonly values: {
    readonly device: MDevice.TableView | null;
    readonly now: MGenericDevice.Data | null;
    readonly modelColumns: Record<number, MGenericDevice.ModelColumn> | null;
    readonly units: Record<number, MGlobal.Unit>;
    readonly connected: boolean;
  };
}

export interface IGenericDeviceViewLogic
  extends LogicWrapper<IGenericDeviceView> {}

export namespace IGenericDeviceViewLogic {
  export const $: interfaces.ServiceIdentifier<IGenericDeviceViewLogic> =
    Symbol('IGenericDeviceView');
}

// end single generic devices view logic
