import { actions, kea, key, props, reducers } from 'kea';

import type { tabMenuLogicType } from './indexType';

export interface TabMenuLogicProps {
  tabMenuKey: string;
}

export const tabMenuLogic = kea<tabMenuLogicType>([
  props({} as TabMenuLogicProps),
  key((props: TabMenuLogicProps) => props.tabMenuKey),
  actions({
    setActiveIndex: (index: number) => ({ index }),
  }),
  reducers({
    activeIndex: [
      0 as number,
      {
        setActiveIndex: (_, { index }) => index as number,
      },
    ],
  }),
]);
