import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { FC, useEffect, useState } from 'react';

import { ConfirmDialog, DialogProps } from '@/components/confirmDialog';
import { IEventLogic } from '@/logic/interfaces/event/event';

import { EventViewContainer } from '../container';
import './style.scss';

interface EventDialogProps extends DialogProps {
  id: number;
}

export const EventConfirmDialog: FC<EventDialogProps> = (props) => {
  const eventLogic = useInjection(IEventLogic.$)({
    eventId: props.id.toString(),
  });

  const { confirm } = useActions(eventLogic);

  const { confirmSending, eventView, confirmSuccess, confirmError } =
    useValues(eventLogic);

  const [confirmPressed, setConfirm] = useState(false);

  useEffect(() => {
    if (confirmPressed)
      if (confirmSending === false && confirmSuccess === true) props.onHide();
  }, [confirmSending, confirmPressed]);

  useEffect(() => {
    if (eventView == null) props.onHide();
  }, [eventView]);

  if (eventView === null) return <div></div>;

  return (
    <>
      <ConfirmDialog
        {...props}
        className={'ocs-eventdialog'}
        containerClassName={'ocs-eventdialog-container'}
        closable={confirmSending}
        sendError={confirmError}
        onConfirm={(value) => {
          confirm(value);
          setConfirm(true);
        }}
        footer={[
          {
            icon: 'pi pi-link',
            onClick:
              eventView?.detailsOnClick != null
                ? eventView?.detailsOnClick
                : () => {},
            className:
              'p-button-rounded p-button-text p-button-plain ocs-eventdialog-link',
          },
        ]}
      >
        <EventViewContainer
          event={{
            id: eventView?.id,
            icon: eventView?.icon,
            color: eventView?.color,
            title: eventView?.title,
            startDatetime: eventView?.startDatetime,
            endDatetime: eventView?.endDatetime,
            details: eventView?.details,
          }}
          ripple={false}
          disableSelection={false}
          closable={false}
        />
      </ConfirmDialog>
    </>
  );
};
