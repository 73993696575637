/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IGlobalService } from '@/services/interfaces';
import { MGlobal } from '@/types/models';

import { Response } from '../types/response';
import { BaseService } from './base';

@injectable()
export class GlobalService extends BaseService implements IGlobalService {
  public async getUnitsList(
    input: MGlobal.Messages.GetUnitsListIn,
  ): Promise<Response<MGlobal.Messages.GetUnitsListOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.globalGetUnitsList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getLightningChartLicense(): Promise<
    Response<MGlobal.Messages.GetLightningChartLicenseOut>
  > {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.getLightningChartLicense();
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
