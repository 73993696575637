import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { BindLogic, useMountedLogic, useValues } from 'kea';
import { FC, useMemo } from 'react';

import { IVisualizationLogic } from '@/logic/interfaces/visualization/visualization';
import { VisLogicProps } from '@/types/visualization';
import { GetRgbString, convertRgbToHex, darkenColor } from '@/utils/colorUtils';
import { ConvertPixelsToREM } from '@/utils/visualizationUtils';

import { VisualizationRenderer } from './visRenderer';

export let visualizationContextLogic: IVisualizationLogic;
export interface VisualizationProps {
  visualizationLogicIdentifier: interfaces.ServiceIdentifier<IVisualizationLogic>;
  properties: VisLogicProps;
}

export const Visualization: FC<VisualizationProps> = (props) => {
  visualizationContextLogic = useInjection(props.visualizationLogicIdentifier);

  return (
    <BindLogic
      logic={visualizationContextLogic}
      props={{
        sourceUuid: props.properties.sourceUuid,
        sourceType: props.properties.sourceType,
        visualizationUuid: props.properties.visualizationUuid,
        name: props.properties.name,
      }}
    >
      <BindedVisualization />
    </BindLogic>
  );
};

export const BindedVisualization: FC = () => {
  const visLogic = useMountedLogic(visualizationContextLogic);

  const { visualization, loadSuccess } = useValues(visLogic);

  const borderColor = useMemo(() => {
    if (loadSuccess === true) {
      const hex = convertRgbToHex(
        visualization.color.red,
        visualization.color.green,
        visualization.color.blue,
      );
      return darkenColor(hex, 0.7);
    }
  }, [visualization.color]);

  if (loadSuccess === false) {
    return <></>;
  }

  return (
    <div
      style={{
        position: 'relative',
        width: visualization.workspaceWidth,
        height: visualization.workspaceHeight,

        border: visualization.frame ? `2px solid ${borderColor}` : '',
        borderLeft: `${visualization.frameLeft} solid ${borderColor}`,
        borderRight: `${visualization.frameRight} solid ${borderColor}`,
        backgroundColor: GetRgbString(
          visualization.color.red,
          visualization.color.green,
          visualization.color.blue,
        ),

        fontFamily: visualization.font,
        color: GetRgbString(
          visualization.fontColor.red,
          visualization.fontColor.green,
          visualization.fontColor.blue,
        ),
        fontWeight: `${visualization.fontStyle.bold ? 'bold' : 'normal'}`,
        fontStyle: `${visualization.fontStyle.italic ? 'italic' : 'normal'}`,
        textDecoration: `${
          visualization.fontStyle.underline ? 'underline' : ''
        } ${visualization.fontStyle.crossedOut ? 'line-through' : ''}`,
        fontSize: `${
          visualization.fontSize
            ? ConvertPixelsToREM(visualization.fontSize)
            : ''
        }`,
      }}
    >
      <VisualizationRenderer childs={visualization.childs} />
    </div>
  );
};
