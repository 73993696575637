import {
  actions,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { config } from '@/config';
import Dependencies from '@/deps';
import { IDesktopLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';

import type { logicType } from './indexType';

const logic = kea<logicType>([
  key((props) => props.key),
  props(
    {} as {
      key: string;
      deps: {
        desktopLogic: IDesktopLogic;
      };
    },
  ),
  path((key) => ['windows', key]),
  actions({
    setPosition: (x: number, y: number) => ({ x, y }),
    setSize: (width: number, height: number) => ({ width, height }),
    setMoving: (moving: boolean) => ({ moving }),
    setResizing: (resizing: boolean) => ({ resizing }),
    toggle: true,
    minimize: true,
    maximize: true,
    focus: true,
    close: true,
  }),
  reducers({
    moving: [false, { setMoving: (_, { moving }) => moving }],
    resizing: [false, { setResizing: (_, { resizing }) => resizing }],
  }),
  listeners(({ props, values }) => ({
    setPosition: (position) =>
      props.deps.desktopLogic.actions.updateWindowGeometry(props.key, {
        position: position,
      }),
    setSize: (size) =>
      props.deps.desktopLogic.actions.updateWindowGeometry(props.key, {
        size,
      }),
    minimize: () =>
      props.deps.desktopLogic.actions.updateWindowGeometry(props.key, {
        minimized: !values.geometry.minimized,
      }),
    maximize: () =>
      props.deps.desktopLogic.actions.updateWindowGeometry(props.key, {
        maximized: !values.geometry.maximized,
      }),
    focus: () => props.deps.desktopLogic.actions.focusWindow(props.key),
    close: () => props.deps.desktopLogic.actions.removeWindow(props.key),
  })),
  selectors(({ props }) => ({
    windowKey: [
      (_) => [(_, props) => props.key],
      (windowKey) => windowKey as string,
    ],
    data: [
      (selectors) => [
        props.deps.desktopLogic.selectors.currentLayout,
        selectors.windowKey,
      ],
      (currentLayout, windowKey) => {
        if (currentLayout != null) {
          if (windowKey in currentLayout.windows) {
            return currentLayout.windows[windowKey];
          }
          throw 'Tried to render a window with a key not present in the current layout.';
        }
        throw 'Tried to render a window when no layout is selected.';
      },
    ],
    title: [(selectors) => [selectors.data], (data) => data.title],
    type: [(selectors) => [selectors.data], (data) => data.type],
    initialData: [(selectors) => [selectors.data], (data) => data.initialData],
    icon: [(selectors) => [selectors.data], (data) => data.icon],
    geometry: [(selectors) => [selectors.data], (data) => data.geometry],
    position: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.position,
    ],
    size: [(selectors) => [selectors.geometry], (geometry) => geometry.size],
    zIndex: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.zIndex,
    ],
    minWidth: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.minWidth,
    ],
    minHeight: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.minHeight,
    ],
    focused: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.zIndex == config.desktop.maxZIndex,
    ],
    minimized: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.minimized,
    ],
    maximized: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.maximized,
    ],
    pinned: [
      (selectors) => [selectors.geometry],
      (geometry) => geometry.pinned,
    ],
    onTop: [(selectors) => [selectors.geometry], (geometry) => geometry.onTop],
  })),
]);

export const windowLogic = injectDepsToLogic(logic, () => ({
  desktopLogic: Dependencies.get(IDesktopLogic.$),
}));
