import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDefinedAlarms } from '@/types/models/definedAlarms';

export interface IDefinedAlarmsViewContainerLogicProps
  extends Record<string, unknown> {}

interface IDefinedAlarmsViewContainer extends Logic {
  readonly props: IDefinedAlarmsViewContainerLogicProps;
  readonly actions: {
    readonly addDefinedAlarms: (definedAlarms: MDefinedAlarms.View[]) => void;
    readonly useDefinedAlarms: (definedAlarmsIds: string[]) => void;
    readonly releaseDefinedAlarms: (definedAlarmsIds: string[]) => void;
  };
  readonly values: {
    definedAlarms: Record<string, MDefinedAlarms.View>;
  };
  readonly selectors: {
    readonly definedAlarms: (
      state: unknown,
    ) => Record<string, MDefinedAlarms.View>;
  };
}

export interface IDefinedAlarmsViewContainerLogic
  extends LogicWrapper<IDefinedAlarmsViewContainer> {}

export namespace IDefinedAlarmsViewContainerLogic {
  export const $: interfaces.ServiceIdentifier<IDefinedAlarmsViewContainerLogic> =
    Symbol('IDefinedAlarmsViewContainerLogic');
}
