import { shallowEqual } from 'fast-equals';
import produce from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import { config } from '@/config';
import Dependencies from '@/deps';
import {
  IEventAlarmContainerLogic,
  IEventDictContainerLogic,
  IEventViewLogic,
} from '@/logic/interfaces';
import { IEventViewLogicBuilt } from '@/logic/interfaces/eventView';
import { injectDepsToLogic } from '@/logic/utils';
import { IEventService } from '@/services/interfaces';
import { AlarmEvent, AlarmEventAction, EventView } from '@/types/models/event';

import type { logicType } from './indexType';
import { loadAlarmEvents } from './loaders';

const logic = kea<logicType>([
  path(['events', 'list']),
  props(
    {} as {
      deps: {
        eventService: IEventService;
        eventAlarmContainerLogic: IEventAlarmContainerLogic;
        eventDictContainerLogic: IEventDictContainerLogic;
        eventViewLogic: IEventViewLogic;
      };
    },
  ),
  actions({
    setMuted: (value: boolean) => ({ value }),
    setDisplayed: (alarmEventsIds: string[]) => ({ alarmEventsIds }),
    startListen: true,
    stopListen: true,
    loadAlarmEvents: true,
    loadAlarmEventsSuccess: (alarmEvents: Set<AlarmEvent>) => ({
      alarmEvents,
    }),
    setNewAlarmEventIds: (alarmEventIds: Set<string>) => ({ alarmEventIds }),
    addNewAlarmEventId: (alarmEventId: string) => ({ alarmEventId }),
    removeAlarmEventId: (alarmEventId: string) => ({ alarmEventId }),
    loadAlarmEventsFailure: true,
    addNewAlarmEvent: (alarmEvent: AlarmEvent) => ({ alarmEvent }),
    removeAlarmEvent: (alarmEventId: string) => ({ alarmEventId }),

    setSending: (value: boolean) => ({ value }),

    confirmAll: (confirmMessage: string) => ({ confirmMessage }),
    removeAll: true,
  }),
  reducers({
    alarmEventIds: [
      new Set() as Set<string>,
      {
        setNewAlarmEventIds: (_, { alarmEventIds }) => alarmEventIds,
        addNewAlarmEventId: (immutableState, { alarmEventId }) =>
          new Set([...immutableState, alarmEventId]),
        removeAlarmEventId: (immutableState, { alarmEventId }) =>
          produce(immutableState, (state) => {
            state.delete(alarmEventId);
          }),
      },
    ],
    displayedAlarmEventIds: [
      new Set() as Set<string>,
      {
        setDisplayed: (immutableState, { alarmEventsIds }) =>
          produce(immutableState, (state) => {
            for (const alarmEventsId of alarmEventsIds) {
              state.add(alarmEventsId);
            }
          }),
      },
    ],
    isMuted: [
      false,
      {
        setMuted: (_, { value }) => value,
      },
    ],
    isSending: [
      false,
      {
        setSending: (_, { value }) => value,
      },
    ],
  }),
  selectors(({ props }) => ({
    alarmEventLogics: [
      (selectors) => [selectors.alarmEventIds],
      (alarmEventIds) => {
        const alarmEventLogics: IEventViewLogicBuilt[] = [];
        for (const alarmEventId of alarmEventIds) {
          const eventViewLogic = props.deps.eventViewLogic({
            eventId: alarmEventId,
          });
          if (eventViewLogic.isMounted()) {
            alarmEventLogics.push(eventViewLogic);
          }
        }
        return alarmEventLogics;
      },
    ],
    alarmEventViews: [
      (selectors) => [
        (state) => {
          const eventViews: EventView[] = [];
          const alarmEventLogics = selectors.alarmEventLogics(state);
          for (const alarmEventLogic of alarmEventLogics) {
            if (alarmEventLogic.values.eventView != null) {
              eventViews.push(alarmEventLogic.values.eventView);
            }
          }
          return eventViews;
        },
      ],
      (eventViews) => {
        return eventViews;
      },
      { resultEqualityCheck: shallowEqual },
    ],
    alarmEvents: [
      (selectors) => [selectors.alarmEventViews],
      (alarmEventViews) => {
        return alarmEventViews.reverse();
      },
    ],
    alarmEventsCount: [
      (selectors) => [selectors.alarmEventViews],
      (alarmEventViews) => {
        return alarmEventViews.length;
      },
    ],
    alarmEventsToDisplay: [
      (selectors) => [selectors.displayedAlarmEventIds, selectors.alarmEvents],
      (displayedAlarmEventIds, alarmEvents) => {
        return alarmEvents.filter((alarmEvent) => {
          return !displayedAlarmEventIds.has(alarmEvent.id.toString());
        });
      },
    ],
  })),
  listeners(({ props, actions, values }) => {
    const onNewNotification = (event: { value: AlarmEventAction }) => {
      if (event.value.action === 'new') {
        actions.addNewAlarmEvent(event.value.data);
      } else if (event.value.action === 'updated') {
        //TODO: this should update only
        actions.addNewAlarmEvent(event.value.data);
      } else {
        actions.removeAlarmEvent(event.value.data.toString());
      }
    };

    const onDisconnect = async () => {
      actions.stopListen();
      if (process.env.NODE_ENV === 'development') {
        await new Promise((r) => setTimeout(r, 60000));
      } else {
        await new Promise((r) => setTimeout(r, 1000));
      }
      actions.startListen();
    };

    return {
      startListen: async () => {
        await props.deps.eventService.stream.listen(
          {
            onMessage: onNewNotification,
            onDisconnect: onDisconnect,
          },
          undefined,
        );
      },
      stopListen: () => {
        props.deps.eventService.stream.cancel(onNewNotification, undefined);
      },
      loadAlarmEvents: async () => {
        const alarmEvents = await loadAlarmEvents(props.deps.eventService);
        if (alarmEvents !== null) {
          actions.loadAlarmEventsSuccess(alarmEvents);
        } else {
          actions.loadAlarmEventsFailure();
        }
      },
      loadAlarmEventsSuccess: async ({ alarmEvents }) => {
        const alarmEventIds = new Set<string>();

        alarmEvents.forEach((alarmEvent) => {
          if (alarmEvent.confirmUserDate == null)
            alarmEventIds.add(alarmEvent.alarmEventId.toString());
        });

        const alarmEventIdsNumber = new Set(
          [...alarmEvents].map((alarmEvent) => alarmEvent.alarmEventId),
        );
        const newAlarmEventIds: number[] = [];

        values.alarmEventIds.forEach((alarmEventId) => {
          newAlarmEventIds.push(parseInt(alarmEventId));
        });

        props.deps.eventAlarmContainerLogic.actions.releaseEvents(
          newAlarmEventIds,
        );
        props.deps.eventAlarmContainerLogic.actions.addEvents([...alarmEvents]);
        props.deps.eventAlarmContainerLogic.actions.useEvents([
          ...alarmEventIdsNumber,
        ]);
        actions.setNewAlarmEventIds(alarmEventIds);
        actions.setDisplayed(Array.from(alarmEventIds.keys()));

        for (const alarmEvent of alarmEvents) {
          const eventViewLogic = props.deps.eventViewLogic({
            eventId: alarmEvent.alarmEventId.toString(),
          });
          eventViewLogic.mount();
          while (eventViewLogic.values.eventView == null) {
            await new Promise((r) => setTimeout(r, 1));
          }
        }
      },
      loadAlarmEventsFailure: async () => {
        await new Promise((r) => setTimeout(r, 2500));
        actions.loadAlarmEvents();
      },
      addNewAlarmEvent: ({ alarmEvent }) => {
        if (alarmEvent.confirmUserDate != null) {
          actions.removeAlarmEvent(alarmEvent.alarmEventId.toString());
          return;
        }

        if (values.alarmEventIds.size > config.events.maxDisplayedEvents) {
          const alarmEventIds = values.alarmEventIds.values();
          for (
            let index = 0;
            index <
            values.alarmEventIds.size - config.events.maxDisplayedEvents;
            index++
          ) {
            const firstAlarmEventId = alarmEventIds.next().value as string;
            actions.removeAlarmEvent(firstAlarmEventId);
          }
        }
        props.deps.eventAlarmContainerLogic.actions.addEvents([alarmEvent]);
        props.deps.eventAlarmContainerLogic.actions.useEvents([
          alarmEvent.alarmEventId,
        ]);
        props.deps
          .eventViewLogic({ eventId: alarmEvent.alarmEventId.toString() })
          .mount();
        actions.addNewAlarmEventId(alarmEvent.alarmEventId.toString());
      },
      removeAlarmEvent: ({ alarmEventId }) => {
        actions.removeAlarmEventId(alarmEventId);
        props.deps
          .eventViewLogic({
            eventId: alarmEventId.toString(),
          })
          .unmount();
      },
      confirmAll: async ({ confirmMessage }) => {
        actions.setSending(true);

        const response = await props.deps.eventService.confirmAllAlarmEvents({
          confirmUserText: confirmMessage,
        });

        if (response.success) {
          values.alarmEventIds.forEach((id) => {
            actions.removeAlarmEvent(id);
          });
        }
        actions.setSending(false);
      },
      removeAll: async () => {
        const response = await props.deps.eventService.removeAllAlarmEvents();

        if (response.success) {
          values.alarmEventIds.forEach((id) => {
            actions.removeAlarmEvent(id);
          });
        }
      },
    };
  }),
  selectors(() => ({})),
  afterMount(({ actions }) => {
    actions.startListen();
    actions.loadAlarmEvents();
  }),
  beforeUnmount(({ actions }) => {
    actions.stopListen();
  }),
]);

export const eventListLogic = injectDepsToLogic(logic, () => ({
  eventService: Dependencies.get(IEventService.$),
  eventAlarmContainerLogic: Dependencies.get(IEventAlarmContainerLogic.$),
  eventDictContainerLogic: Dependencies.get(IEventDictContainerLogic.$),
  eventViewLogic: Dependencies.get(IEventViewLogic.$),
}));
