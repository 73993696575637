/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable react-hooks/rules-of-hooks */
import { IPaginationLogic } from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useValues } from 'kea';
import { Button } from 'primereact/button';
import { FC } from 'react';

import { config } from '@/config';
import { useTranslation } from '@/hooks';

import './../style.scss';

interface RefreshButtonProps {
  logicIdentifier?: interfaces.ServiceIdentifier<IPaginationLogic>;
  logic?: IPaginationLogic | BuiltLogic<IPaginationLogic>;
  isDefinedAlarms?: boolean;
}

export const TableRefreshButton: FC<RefreshButtonProps> = (props) => {
  const t = useTranslation();

  let paginationLogic: IPaginationLogic | null = null;
  if (props.logicIdentifier !== undefined) {
    paginationLogic = useInjection(props.logicIdentifier);
  } else if (props.logic !== undefined) {
    paginationLogic = props.logic;
  } else {
    throw 'Verify if table logic has been passed correctly';
  }

  const { refresh: paginationRefresh } = useActions(paginationLogic);
  const { isPaginatorActive, expand, expandProps } = useValues(paginationLogic);

  let expandedTableRefresh = () => {};

  if (expand != null) {
    const props: { [k: string]: any } = {};

    for (const key in expandProps) {
      props[key] = expandProps[key];
    }

    const expandedTableLogic = useInjection(expand.logic)(props);
    const { refresh } = useActions(expandedTableLogic);

    expandedTableRefresh = refresh;
  }

  if (props.isDefinedAlarms == undefined && !isPaginatorActive) return null;

  return (
    <Button
      icon={'pi pi-refresh'}
      className="p-button-text p-button-plain OCS-footer-button"
      onClick={() => {
        expandedTableRefresh();
        paginationRefresh();
      }}
      tooltip={t('tooltips.refresh')}
      tooltipOptions={{
        position: 'top',
        showDelay: config.tooltip.showDelay,
      }}
    />
  );
};
