// @generated by protobuf-ts 2.8.0
// @generated from protobuf file "table.proto" (package "table", syntax proto2)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Value } from "./google/protobuf/struct";
/**
 * *
 * Sortowanie tabeli
 *
 * @generated from protobuf message table.TableSort
 */
export interface TableSort {
    /**
     * @generated from protobuf field: table.Order order = 1;
     */
    order: Order; // / Kierunek sortowania
    /**
     * @generated from protobuf field: string column = 2;
     */
    column: string; // / Kolumna sortowania (snake_case)
}
/**
 * *
 * Wyszukiwanie w tabeli
 *
 * @generated from protobuf message table.TableSearch
 */
export interface TableSearch {
    /**
     * @generated from protobuf field: string column = 1;
     */
    column: string; // / Kolumna wyszukiwania (snake_case)
    /**
     * @generated from protobuf field: table.SearchRule rule = 2;
     */
    rule: SearchRule; // / Reguła wyszukiwania
    /**
     * @generated from protobuf field: repeated google.protobuf.Value value = 3;
     */
    value: Value[]; // / Wartość do wyszukania
}
/**
 * *
 * Paginacja tabeli
 *
 * @generated from protobuf message table.TableCount
 */
export interface TableCount {
    /**
     * @generated from protobuf field: int32 count = 1;
     */
    count: number; // / Ilość wierszy na stronę
    /**
     * @generated from protobuf field: int32 page = 2;
     */
    page: number; // / Strona danych
}
/**
 * *
 * Zapytanie o dane tabeli
 *
 * @generated from protobuf message table.MessageTableRequest
 */
export interface MessageTableRequest {
    /**
     * @generated from protobuf field: optional table.TableCount count = 1;
     */
    count?: TableCount; // / Paginacja tabeli, brak zwraca wszystkie wiersze danej tabeli
    /**
     * @generated from protobuf field: optional table.TableSort sort = 2;
     */
    sort?: TableSort; // / Sortowanie tabeli, brak przyjmuje domyślne sortowanie tabeli wewnątrz bazy danych
    /**
     * @generated from protobuf field: repeated table.TableSearch search_all = 3;
     */
    searchAll: TableSearch[]; // / Tablica wyszukań tabeli, wiersz musi spełniać wszystkie podane wyszukiwania
    /**
     * @generated from protobuf field: repeated table.TableSearch search_any = 4;
     */
    searchAny: TableSearch[]; // / Tablica wyszukań tabeli, wiersz musi którykolwiek z podanych wyszukiwań + wszystkie z search_all
}
/**
 * *
 * Kierunek sortowania
 *
 * @generated from protobuf enum table.Order
 */
export enum Order {
    /**
     * / Rosnąco
     *
     * @generated from protobuf enum value: Asc = 0;
     */
    Asc = 0,
    /**
     * / Malejąco
     *
     * @generated from protobuf enum value: Desc = 1;
     */
    Desc = 1
}
/**
 * *
 * Reguła wyszukowania
 *
 * @generated from protobuf enum table.SearchRule
 */
export enum SearchRule {
    /**
     * / Równe
     *
     * @generated from protobuf enum value: Equal = 0;
     */
    Equal = 0,
    /**
     * / Nierówne
     *
     * @generated from protobuf enum value: NotEqual = 1;
     */
    NotEqual = 1,
    /**
     * / Zaczyna się od (tekst)
     *
     * @generated from protobuf enum value: BeginsWith = 2;
     */
    BeginsWith = 2,
    /**
     * / Większe (data)
     *
     * @generated from protobuf enum value: Greater = 3;
     */
    Greater = 3,
    /**
     * / Większe lub równe (data)
     *
     * @generated from protobuf enum value: GreaterOrEqual = 4;
     */
    GreaterOrEqual = 4,
    /**
     * / Mniejsze (data)
     *
     * @generated from protobuf enum value: Less = 5;
     */
    Less = 5,
    /**
     * / Mniejsze lub równe (data)
     *
     * @generated from protobuf enum value: LessOrEqual = 6;
     */
    LessOrEqual = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class TableSort$Type extends MessageType<TableSort> {
    constructor() {
        super("table.TableSort", [
            { no: 1, name: "order", kind: "enum", T: () => ["table.Order", Order] },
            { no: 2, name: "column", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TableSort>): TableSort {
        const message = { order: 0, column: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TableSort>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableSort): TableSort {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* table.Order order */ 1:
                    message.order = reader.int32();
                    break;
                case /* string column */ 2:
                    message.column = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableSort, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* table.Order order = 1; */
        if (message.order !== 0)
            writer.tag(1, WireType.Varint).int32(message.order);
        /* string column = 2; */
        if (message.column !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.column);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message table.TableSort
 */
export const TableSort = new TableSort$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableSearch$Type extends MessageType<TableSearch> {
    constructor() {
        super("table.TableSearch", [
            { no: 1, name: "column", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rule", kind: "enum", T: () => ["table.SearchRule", SearchRule] },
            { no: 3, name: "value", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => Value }
        ]);
    }
    create(value?: PartialMessage<TableSearch>): TableSearch {
        const message = { column: "", rule: 0, value: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TableSearch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableSearch): TableSearch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string column */ 1:
                    message.column = reader.string();
                    break;
                case /* table.SearchRule rule */ 2:
                    message.rule = reader.int32();
                    break;
                case /* repeated google.protobuf.Value value */ 3:
                    message.value.push(Value.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableSearch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string column = 1; */
        if (message.column !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.column);
        /* table.SearchRule rule = 2; */
        if (message.rule !== 0)
            writer.tag(2, WireType.Varint).int32(message.rule);
        /* repeated google.protobuf.Value value = 3; */
        for (let i = 0; i < message.value.length; i++)
            Value.internalBinaryWrite(message.value[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message table.TableSearch
 */
export const TableSearch = new TableSearch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableCount$Type extends MessageType<TableCount> {
    constructor() {
        super("table.TableCount", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TableCount>): TableCount {
        const message = { count: 0, page: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TableCount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableCount): TableCount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableCount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message table.TableCount
 */
export const TableCount = new TableCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageTableRequest$Type extends MessageType<MessageTableRequest> {
    constructor() {
        super("table.MessageTableRequest", [
            { no: 1, name: "count", kind: "message", T: () => TableCount },
            { no: 2, name: "sort", kind: "message", T: () => TableSort },
            { no: 3, name: "search_all", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => TableSearch },
            { no: 4, name: "search_any", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => TableSearch }
        ]);
    }
    create(value?: PartialMessage<MessageTableRequest>): MessageTableRequest {
        const message = { searchAll: [], searchAny: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MessageTableRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageTableRequest): MessageTableRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional table.TableCount count */ 1:
                    message.count = TableCount.internalBinaryRead(reader, reader.uint32(), options, message.count);
                    break;
                case /* optional table.TableSort sort */ 2:
                    message.sort = TableSort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* repeated table.TableSearch search_all */ 3:
                    message.searchAll.push(TableSearch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated table.TableSearch search_any */ 4:
                    message.searchAny.push(TableSearch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageTableRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional table.TableCount count = 1; */
        if (message.count)
            TableCount.internalBinaryWrite(message.count, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional table.TableSort sort = 2; */
        if (message.sort)
            TableSort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated table.TableSearch search_all = 3; */
        for (let i = 0; i < message.searchAll.length; i++)
            TableSearch.internalBinaryWrite(message.searchAll[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated table.TableSearch search_any = 4; */
        for (let i = 0; i < message.searchAny.length; i++)
            TableSearch.internalBinaryWrite(message.searchAny[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message table.MessageTableRequest
 */
export const MessageTableRequest = new MessageTableRequest$Type();
