import { EventConfirmDialog, ITableLogic, Table } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useValues } from 'kea';
import { Card } from 'primereact/card';
import { FC } from 'react';

import { useTranslation } from '@/hooks';
import { IObjectWindowLogic } from '@/logic/interfaces';
import { INotificationTableLogic } from '@/logic/interfaces/notificationTable';

import './style.scss';

export const NotificationTab: FC = () => {
  const t = useTranslation();

  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(objectWindowLogic);

  const notificationLogic = useInjection(INotificationTableLogic.$)({
    objectUuid: objectUuid,
  });

  const { setDialogVisibility, refresh } = useActions(notificationLogic);
  const { chosenEvent, dialogVisible } = useValues(notificationLogic);

  return (
    <>
      <div className="OCSdatatable">
        <Card className="OCSdatatable-card">
          <Table
            tableLogic={notificationLogic as unknown as BuiltLogic<ITableLogic>}
          />
        </Card>
      </div>

      {chosenEvent != undefined ? (
        <EventConfirmDialog
          id={chosenEvent}
          onHide={() => {
            setDialogVisibility(false);
            refresh();
          }}
          visible={dialogVisible}
          dialogTitle={t('eventList.eventDialog.alarmEvent')}
          inputPlaceholder={t('eventList.eventDialog.alarmInput')}
          confirmLabel={t('eventList.eventDialog.alarmAccept')}
          errorLabel={t('eventList.eventDialog.alarmError')}
        />
      ) : undefined}
    </>
  );
};
