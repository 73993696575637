import { Checkbox } from 'primereact/checkbox';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Tag } from '@/components/tag';
import { BoolFilterValue, TagFilterType } from '@/types/filter';
import { GetValueIndex } from '@/utils/filterUtils';

import './style.scss';

interface TagFilterProps {
  tagData: TagFilterType;
  filterValue: BoolFilterValue[];
  setFilterValue: Dispatch<SetStateAction<BoolFilterValue[]>>;
}

export const TagFilter: FC<TagFilterProps> = (props) => {
  const [filterIndex, setIndex] = useState(-1);

  useEffect(() => {
    const index = GetValueIndex(
      props.filterValue,
      props.tagData,
      props.setFilterValue,
      false,
    );

    setIndex(index);
  }, [props]);

  return (
    <div className="ocs-tag-filter">
      <Checkbox
        checked={props.filterValue[filterIndex]?.filteredValue}
        onChange={() => {
          const newFilterValues = [...props.filterValue];
          newFilterValues[filterIndex].filteredValue =
            !props.filterValue[filterIndex].filteredValue;
          props.setFilterValue(newFilterValues);
        }}
      />
      <Tag
        icon={props.tagData.value.icon ? props.tagData.value.icon : undefined}
        label={props.tagData.value.label}
        color={props.tagData.value.color}
      />
    </div>
  );
};
