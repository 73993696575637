import { interfaces } from 'inversify';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MenuItem, TabMenu } from '@/components/tabMenu';
import { ITabMenuLogic } from '@/logic/interfaces/tabMenu';

import { FoldingTile } from './foldingTile';
import './style.scss';

interface TabMenuTileProps {
  model: MenuItem[];
  tabMenuLogic: interfaces.ServiceIdentifier<ITabMenuLogic>;
  logicKey: string;
  accessibleSize: number;
  dynamicSize: number;
  foldSize: number;
  setTabMenuFlag: Dispatch<SetStateAction<boolean>>;

  dynamicContainerRef: MutableRefObject<HTMLDivElement | null>;
}

export const TabMenuTile: FC<TabMenuTileProps> = (props) => {
  const tabsRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [visibleTabs, setVisibleTabs] = useState<MenuItem[]>([]);
  const [hiddenTabs, setHiddenTabs] = useState<MenuItem[]>([]);

  const [dynamicSize, setDynamicSize] = useState(0);
  const [foldedComponentSize, setFoldedComponentSize] = useState(0);

  useEffect(() => {
    const [visibleTabs, hiddenTabs, dynamicSize, foldedComponentSize] = GetTabs(
      props.model,
      tabsRefs,
      props.accessibleSize,
      props.dynamicSize,
    );

    if (hiddenTabs.length != 0) {
      props.setTabMenuFlag(true);
    } else {
      props.setTabMenuFlag(false);
    }

    setVisibleTabs(visibleTabs);
    setHiddenTabs(hiddenTabs);
    setDynamicSize(dynamicSize);
    setFoldedComponentSize(foldedComponentSize);
  }, [props.model, props.accessibleSize]);

  return (
    <div className="OCS-responsive-tile-wrapper">
      <div className="OCS-virtual-responsive-tile">
        <TabMenu
          model={props.model}
          refs={tabsRefs}
          tabMenuLogic={props.tabMenuLogic}
          logicKey={props.logicKey}
        />
      </div>
      <div className="OCS-tabmenu-menu-tile">
        <TabMenu
          model={visibleTabs}
          refs={tabsRefs}
          tabMenuLogic={props.tabMenuLogic}
          logicKey={props.logicKey}
        />
      </div>
      {hiddenTabs.length > 0 ? (
        <FoldingTile
          size={props.foldSize}
          dynamicSize={dynamicSize}
          componentSize={foldedComponentSize}
          dynamicContainerRef={props.dynamicContainerRef}
        >
          <TabMenu
            model={hiddenTabs}
            tabMenuLogic={props.tabMenuLogic}
            logicKey={props.logicKey}
          />
        </FoldingTile>
      ) : (
        <></>
      )}
      <div className="OCS-tabmenu-foldTile"></div>
    </div>
  );
};

function GetTabs(
  model: MenuItem[],
  tabsRefs: MutableRefObject<(HTMLDivElement | null)[]>,
  accessibleSize: number,
  dynamicSize: number,
): [MenuItem[], MenuItem[], number, number] {
  const visibleTabs: MenuItem[] = [];
  const hiddenTabs: MenuItem[] = [];

  let currentTileSize = 0;
  let currentSize = 0;
  let foldedComponentSize = 0;

  for (let i = 0; i < model.length; i++) {
    currentSize = tabsRefs.current[i]?.offsetWidth as number;
    currentTileSize += currentSize;

    if (currentTileSize <= accessibleSize) {
      visibleTabs.push({
        ...model[i],
        index: i,
      });

      dynamicSize -= currentSize;
    } else {
      hiddenTabs.push({
        ...model[i],
        index: i,
      });
      foldedComponentSize += currentSize;
    }
  }

  return [visibleTabs, hiddenTabs, dynamicSize, foldedComponentSize];
}
