import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MGlobal } from '@/types/models';

interface IUnits extends Logic {
  readonly actions: {};
  readonly values: {
    readonly units: Record<number, MGlobal.Unit>;
  };
  readonly selectors: {
    readonly units: (state: any, props?: any) => Record<number, MGlobal.Unit>;
  };
}

export interface IUnitsLogic extends LogicWrapper<IUnits> {}

export namespace IUnitsLogic {
  export const $: interfaces.ServiceIdentifier<IUnitsLogic> = Symbol('IUnits');
}
