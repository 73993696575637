import { Calendar } from 'primereact/calendar';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { useStorybookTranslation } from '@/i18next';
import { BaseFilterType, DateFilterValue } from '@/types/filter';
import { GetValueIndex } from '@/utils/filterUtils';

import './style.scss';

interface DateFilterProps {
  dateData: BaseFilterType;
  filterValue: DateFilterValue[];
  setFilterValue: Dispatch<SetStateAction<DateFilterValue[]>>;
}

export const DateFilter: FC<DateFilterProps> = (props) => {
  const t = useStorybookTranslation();
  const [filterIndex, setIndex] = useState(-1);

  useEffect(() => {
    const index = GetValueIndex(
      props.filterValue,
      props.dateData,
      props.setFilterValue,
      {},
    );

    setIndex(index);
  }, [props]);

  return (
    <div className="ocs-date-filter">
      <Calendar
        placeholder={t('table.filters.beginDate')}
        dateFormat="dd-mm-yy"
        value={
          props.filterValue[filterIndex] != null
            ? props.filterValue[filterIndex].filteredValue?.startDate
            : []
        }
        onChange={(date) => {
          const newFilterValue = [...props.filterValue];
          newFilterValue[filterIndex].filteredValue = {
            startDate: date.value as Date,
            endDate: newFilterValue[filterIndex].filteredValue?.endDate,
          };
          props.setFilterValue(newFilterValue);
        }}
        hideOnDateTimeSelect={true}
        showTime
        showSeconds
      />
      <Calendar
        placeholder={t('table.filters.endDate')}
        dateFormat="dd-mm-yy"
        value={
          props.filterValue[filterIndex] != null
            ? props.filterValue[filterIndex].filteredValue?.endDate
            : []
        }
        onChange={(date) => {
          const newFilterValue = [...props.filterValue];
          newFilterValue[filterIndex].filteredValue = {
            startDate: newFilterValue[filterIndex].filteredValue?.startDate,
            endDate: date.value as Date,
          };
          props.setFilterValue(newFilterValue);
        }}
        hideOnDateTimeSelect={true}
        showTime
        showSeconds
      />
    </div>
  );
};
