import { interfaces } from 'inversify';
import { BuiltLogic, Logic, LogicWrapper } from 'kea';

import { EditorValue } from '@/types/custom/editorTypes';

import { EditorFieldKey } from './../../types/custom/editorTypes';
import { IEditorLogic } from './editor';

export interface IEditorFieldLogicProps extends Record<string, unknown> {
  editorLogic: BuiltLogic<IEditorLogic>;
  editorKey: EditorFieldKey;
}

interface IEditorField extends Logic {
  readonly props: IEditorFieldLogicProps;
  readonly actions: {
    readonly updateValue: (value: EditorValue) => void;
  };
  readonly values: {
    readonly value: any;
  };
}

export interface IEditorFieldLogic extends LogicWrapper<IEditorField> {}

export namespace IEditorFieldLogic {
  export const $: interfaces.ServiceIdentifier<IEditorFieldLogic> =
    Symbol('IEditorFieldLogic');
}
