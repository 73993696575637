import { t } from 'i18next';
import { useValues } from 'kea';
import { Card } from 'primereact/card';
import { FC, useMemo } from 'react';

import { useTranslation } from '@/hooks';
import { IEditorLogic } from '@/logic/interfaces/editor';

import {
  EditorModel,
  EditorTabsLayout,
  EditorTile,
  EditorTypes,
  RecordTypes,
} from '../../types/custom/editorTypes';
import { BooleanEditor } from './fields/bool';
import { ColorEditor } from './fields/color';
import { EnumEditor } from './fields/enum';
import { InputSwitchEditor } from './fields/inputSwitch';
import { StringEditor } from './fields/string';
import './style.scss';
import { ButtonEditor } from './fields/button';
import { EditorDivider } from './fields/divider';

interface EditorProps {
  modelTile: Record<string, Record<string, EditorModel>>;
  modelKey: string;
  editorLogic: IEditorLogic;
}

export const Editor: FC<EditorProps> = (props) => {
  const t = useTranslation();

  const { translationPrefix, tileLayout, editorUuid} = useValues(
    props.editorLogic,
  );

  return (
    <Card
      key={`editor_${props.modelKey}`}
      className="OCS-editor-card"
      style={{
        width:
          tileLayout[props.modelKey].tileLayout.width != undefined
            ? tileLayout[props.modelKey].tileLayout.width
            : '',
      }}
    >
      <span>{t(`editor.${translationPrefix}.${props.modelKey}`)}</span>
      <EditorTile
        key={`${props.modelKey}${props.modelKey}`}
        model={props.modelTile}
        tileSettings={tileLayout[props.modelKey]}
        editorLogic={props.editorLogic}
        editorKey={{
          editorUuid: editorUuid,
          tileKey: props.modelKey,
        }}
      />
    </Card>
  );
};

interface EditorTileProps {
  model: Record<string, Record<string, EditorModel>>;
  tileSettings: EditorTile;
  editorLogic: IEditorLogic;
  editorKey: {
    editorUuid: string;
    tileKey: string;
  };
}

const EditorTile: FC<EditorTileProps> = (props) => {
  return (
    <div className={`OCS-editor-tile-${props.tileSettings.tileLayout.layoutDirection}`} >
      <span className="OCS-editor-tile-name">{}</span>
      <div
        className="OCS-editor-wrapper"
        style={{
          gridTemplateColumns:
            props.tileSettings.tileLayout.gridLayout != undefined
              ? props.tileSettings.tileLayout.gridLayout
              : '',
        }}
      >
        {Object.keys(props.model).map((key, index) => {
          return (
            <EditorTab
              key={`${key}${index}`}
              model={props.model[key]}
              tabSettings={props.tileSettings.tabs[key]}
              editorLogic={props.editorLogic}
              editorKey={{ ...props.editorKey, tabKey: key }}
            />
          );
        })}
      </div>
    </div>
  );
};

interface EditorTabProps {
  model: Record<string, EditorModel>;
  tabSettings: EditorTabsLayout
  editorLogic: IEditorLogic;
  editorKey: {
    editorUuid: string;
    tileKey: string;
    tabKey: string;
  };
}

const EditorTab: FC<EditorTabProps> = (props) => {
  const {enumOptionsMap, enumExcludeOptionsMap, valueUnitMap, editorUuid} = useValues(props.editorLogic)

  const enumOptions = useMemo(() => {
    if(enumOptionsMap != undefined)
      return enumOptionsMap;
    else if(enumExcludeOptionsMap != undefined)
      return enumExcludeOptionsMap;
  },[enumOptionsMap,enumExcludeOptionsMap])

  return (
    <div className={`OCS-editor-tab-${props.tabSettings.layoutDirection}`} 
      style={{
      gridTemplateColumns:
        props.tabSettings.gridLayout != undefined
          ? props.tabSettings.gridLayout
          : '',
    }}>
    {Object.keys(props.model).map((key) => {
        const model = props.model[key];

        switch (model.type) {
          case EditorTypes.string:
            return (
              <StringEditor
                name={t(model.name)}
                leftIcon={model.leftIcon}
                rightIcon={model.rightIcon}
                valueUnit={valueUnitMap != undefined ? valueUnitMap[key] != undefined ? valueUnitMap[key] : model.valueUnit : model.valueUnit} 
                isActive={model.disabled}
                inputValidator={model.inputValidator}
                errorMessage={model.errorMessage}
                editorLogic={props.editorLogic}
                editorKey={{ ...props.editorKey, valueKey: key }}
                key={`field_${key}`}
              />
            );

          case EditorTypes.number:
            return (
              <StringEditor
                name={t(model.name)}
                leftIcon={model.leftIcon}
                rightIcon={model.rightIcon}
                valueUnit={valueUnitMap != undefined ? valueUnitMap[key] != undefined ? valueUnitMap[key] : model.valueUnit : model.valueUnit} 
                isNumber
                isActive={model.disabled}
                inputValidator={model.inputValidator}
                errorMessage={model.errorMessage}
                editorLogic={props.editorLogic}
                editorKey={{ ...props.editorKey, valueKey: key }}
                key={`field_${key}`}
              />
            );

          case EditorTypes.float:
            return (
              <StringEditor
              name={t(model.name)}
              leftIcon={model.leftIcon}
              rightIcon={model.rightIcon}
              valueUnit={valueUnitMap != undefined ? valueUnitMap[key] != undefined ? valueUnitMap[key] : model.valueUnit : model.valueUnit} 
              isNumber
              isFloat
              isActive={model.disabled}
              inputValidator={model.inputValidator}
              errorMessage={model.errorMessage}
              editorLogic={props.editorLogic}
              editorKey={{ ...props.editorKey, valueKey: key }}
              key={`field_${key}`}
            />
            )

          case EditorTypes.checkbox:
            return (
              <BooleanEditor
                name={t(model.name)}
                editorLogic={props.editorLogic}
                editorKey={{ ...props.editorKey, valueKey: key }}
                key={`field_${key}`}
              />
            );

          case EditorTypes.inputSwitch:
            return (
              <InputSwitchEditor
                name={t(model.name)}
                editorLogic={props.editorLogic}
                editorKey={{ ...props.editorKey, valueKey: key }}
                key={`field_${key}`}
              />
            );

          case EditorTypes.color:
            return (
              <ColorEditor
                name={t(model.name)}
                editorLogic={props.editorLogic}
                editorKey={{ ...props.editorKey, valueKey: key }}
                key={`field_${key}`}
              />
            );

          case EditorTypes.enum:
            return (
              <EnumEditor
                name={t(model.name)}
                options={enumOptions != undefined ? enumOptions.type == RecordTypes.EnumOptions ? enumOptions.options[key] : enumOptions.options[props.editorKey.tabKey][key] : []}
                disabled={enumOptions?.disabled != undefined ? enumOptions.disabled[props.editorKey.tabKey] : undefined}
                editorLogic={props.editorLogic}
                editorKey={{ ...props.editorKey, valueKey: key }}
                key={`field_${key}`}
              />
            );

          case EditorTypes.button:
            return (
              <ButtonEditor
                editorLogic={props.editorLogic}
                editorKey={{ ...props.editorKey, valueKey: key }}
                key={`field_${key}`}
              />
            );

          case EditorTypes.divider:
            return <EditorDivider key={`field_${key}`}/>

          default:
            return <></>;
        }
      })}
    </div>
  );
};
