import { EditorModel, EditorTypes } from '@/types/custom/editorTypes';

export const hubsEditorModel: Record<
  string,
  Record<string, Record<string, EditorModel>>
> = {
  primaryData: {
    primaryData: {
      name: {
        name: 'editor.hubs.name',
        type: EditorTypes.string,
        inputValidator: (value) => {
          if (value === '') return false;
          else return true;
        },
        errorMessage: 'editor.validator.emptyFieldMessage',
      },
      readTime: {
        name: 'editor.hubs.readTime',
        type: EditorTypes.number,
        inputValidator: (value) => {
          if (value === '') return false;
          else return true;
        },
        errorMessage: 'editor.validator.emptyFieldMessage',
      },
    },
  },
};
