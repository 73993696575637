import { enableMapSet } from 'immer';
import { resetContext } from 'kea';
import { loadersPlugin } from 'kea-loaders';
import { localStoragePlugin } from 'kea-localstorage';
import { subscriptionsPlugin } from 'kea-subscriptions';
import { compose } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';

import Dependencies from '@/deps';
import {
  IAppLogic,
  IDefinedAlarmsViewContainerLogic,
  IDesktopLogic,
  IDeviceViewContainerLogic,
  IEventAlarmContainerLogic,
  IEventListLogic,
  IGenericDevicesDictsLogic,
  ILoginLogic,
  IObjectViewContainerLogic,
  IUnitsLogic,
} from '@/logic/interfaces';
import { sagaPlugin } from '@/logic/saga';

import { IDeviceDetailContainerLogic } from './interfaces/deviceDetailContainer';
import { IEventDictContainerLogic } from './interfaces/eventDictContainer';
import { IObjectDetailContainerLogic } from './interfaces/objectDetailContainer';

(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

(BigInt.prototype as any).fromJSON = function (value: string) {
  return BigInt(value);
};

export const startKea = () => {
  enableMapSet();
  resetContext({
    plugins: [
      localStoragePlugin(),
      loadersPlugin({}),
      subscriptionsPlugin,
      sagaPlugin,
    ],
    createStore: {
      enhancers:
        process.env.NODE_ENV === 'development'
          ? [devToolsEnhancer({ serialize: true })]
          : [],
      compose: compose,
    },
    debug: false,
  });
};

export const startGlobalLogics = () => {
  Dependencies.get(IDesktopLogic.$).mount();
  Dependencies.get(IAppLogic.$).mount();
  Dependencies.get(ILoginLogic.$).mount();
  Dependencies.get(IEventAlarmContainerLogic.$).mount();
  Dependencies.get(IEventDictContainerLogic.$).mount();

  Dependencies.get(IEventListLogic.$).mount();

  Dependencies.get(IObjectViewContainerLogic.$).mount();
  Dependencies.get(IObjectDetailContainerLogic.$).mount();

  Dependencies.get(IDeviceViewContainerLogic.$).mount();
  Dependencies.get(IDeviceDetailContainerLogic.$).mount();
  Dependencies.get(IDefinedAlarmsViewContainerLogic.$).mount();
  Dependencies.get(IGenericDevicesDictsLogic.$).mount();
  Dependencies.get(IUnitsLogic.$).mount();
};
