import { Table } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { useActions } from 'kea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FC } from 'react';

import { IDesktopLogic, IObjectTableLogic } from '@/logic/interfaces';
import { MObject } from '@/types/models';

import './../mainStyle.scss';

export const ObjectDatatable: FC = () => {
  return (
    <div className="OCSdatatable">
      <Card className="OCSdatatable-card">
        <Table
          tableLogicIdentifier={IObjectTableLogic.$}
          additionalColumns={{ editor: ObjectEditorButton }}
        />
      </Card>
    </div>
  );
};

interface ObjectEditorButtonProps {
  rowData: any;
}

const ObjectEditorButton: FC<ObjectEditorButtonProps> = (props) => {
  const desktopLogic = useInjection(IDesktopLogic.$);

  const { openWindow } = useActions(desktopLogic);

  return (
    <Button
      className="p-button-outlined"
      icon={'ri ri-edit-2-fill'}
      onClick={() => {
        const rowData: MObject.TableView = props.rowData;

        openWindow('objectEditor', {
          initialData: { objectUuid: rowData.objectUuid },
          windowKey: rowData.objectUuid,
          title: rowData.listName ? rowData.listName : '',
        });
      }}
    />
  );
};
