import produce from 'immer';
import {
  actions,
  afterMount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import Dependencies from '@/deps';
import { IDesktopLogic, IObjectTableLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IObjectService } from '@/services/interfaces';
import {
  ConfirmStatus,
  EditorForm,
  EditorTile,
  EditorTypes,
  EditorValue,
  EnumExcludeOptions,
  LoadingStatus,
  RecordTypes,
} from '@/types/custom/editorTypes';
import { MObject } from '@/types/models';
import { delay } from '@/utility/delay';
import { convertWorkStatesToEditorArray } from '@/utility/editor';
import { validateValues } from '@/validators/core/editor';

import type { logicType } from './indexType';
import {
  ObjectButtonKey,
  addToLayout,
  addToModel,
  addToValue,
  convertDataToGrpc,
  convertResponseToModel,
  convertWorkStateToGrpc,
  modelToButtonMap,
  removeFromLayout,
  removeFromModel,
  removeFromValue,
  workStateSettingsToLayout,
  workStateSettingsToModel,
} from './utility';

interface ObjectEditorLogicProps {
  initialData: {
    objectUuid: string;
  };
  deps: {
    objectService: IObjectService;
    objectTableLogic: IObjectTableLogic;
    desktopLogic: IDesktopLogic;
  };
}

const logic = kea<logicType>([
  path(['object', 'editor']),
  props(
    {} as {
      initialData: {
        objectUuid: string;
      };
      deps: {
        objectService: IObjectService;
        objectTableLogic: IObjectTableLogic;
        desktopLogic: IDesktopLogic;
      };
    },
  ),
  key((props) => `objectEditor/${props.initialData.objectUuid}`),
  actions({
    updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: EditorValue,
    ) => ({
      tileKey,
      tabKey,
      valueKey,
      value,
    }),

    loadWorkStates: true,
    setWorkStates: (workStates: Record<number, string>) => ({ workStates }),

    loadObject: true,
    setLoadStatus: (status: LoadingStatus) => ({ status }),

    refresh: true,

    confirm: true,
    setConfirmStatus: (status: ConfirmStatus) => ({ status }),

    setInitialValue: (
      value: Record<string, Record<string, Record<string, EditorValue>>>,
    ) => ({ value }),

    setDataCopy: (value: MObject.Messages.GetOut) => ({ value }),

    setModel: (model: EditorForm) => ({ model }),
    setTileLayout: (layout: Record<string, EditorTile>) => ({ layout }),

    onButtonClick: (tabKey: string, valueKey: string) => ({ tabKey, valueKey }),

    setWorkStateFields: (amount: number) => ({ amount }),
    addWorkStateField: true,
    removeWorkStateField: true,
  }),
  reducers({
    value: [
      {} as Record<string, Record<string, Record<string, EditorValue>>>,
      {
        updateValue: (immutableStore, { tileKey, tabKey, valueKey, value }) => {
          return produce(immutableStore, (state) => {
            state[tileKey][tabKey][valueKey] = value;
          });
        },
        setInitialValue: (_, { value }) => value,
      },
    ],
    dataCopy: [
      {} as MObject.Messages.GetOut,
      {
        setDataCopy: (_, { value }) => value,
      },
    ],
    model: [
      {
        primaryData: {
          primaryData: {
            name: {
              name: 'editor.object.name',
              type: EditorTypes.string,
              inputValidator: (value) => {
                if (value === '') return false;
                else return true;
              },
              errorMessage: 'editor.validator.emptyFieldMessage',
            },
          },
        },
      } as EditorForm,
      {
        setModel: (_, { model }) => model,
      },
    ],
    loadStatus: [
      LoadingStatus.isLoading as LoadingStatus,
      {
        setLoadStatus: (_, { status }) => status,
      },
    ],
    confirmStatus: [
      ConfirmStatus.unknown as ConfirmStatus,
      {
        setConfirmStatus: (_, { status }) => status,
      },
    ],
    isSaving: [
      false,
      {
        confirm: () => true,
        setConfirmStatus: () => false,
      },
    ],
    translationPrefix: ['object', {}],
    tileLayout: [
      {} as Record<string, EditorTile>,
      {
        setTileLayout: (_, { layout }) => layout,
      },
    ],
    workStates: [
      {} as Record<number, string>,
      {
        setWorkStates: (_, { workStates }) => workStates,
      },
    ],
    numberOfWorkStateFields: [
      0,
      {
        setWorkStateFields: (_, { amount }) => amount,
        addWorkStateField: (state) => state + 1,
        removeWorkStateField: (state) => state - 1,
      },
    ],
  }),
  selectors(({ props }) => ({
    editorUuid: [
      () => [
        (_, props: ObjectEditorLogicProps) => props.initialData.objectUuid,
      ],
      (objectUuid) => objectUuid,
    ],
    lastWorkStateName: [
      (selectors) => [selectors.value],
      (value) => {
        const valueKeys = Object.keys(value.primaryData);
        return valueKeys[valueKeys.length - 1];
      },
    ],
    enumExcludeOptionsMap: [
      (selectors) => [
        selectors.value,
        selectors.workStates,
        selectors.lastWorkStateName,
        selectors.numberOfWorkStateFields,
      ],
      (value, workStates, lastWorkStateName, numberOfFields) => {
        const enumOptions: EnumExcludeOptions = {
          type: RecordTypes.EnumExcludeOptions,
          options: {},
          disabled: {},
        };

        if (enumOptions.disabled == undefined) return;

        const hasMaxFields = numberOfFields >= Object.keys(workStates).length;

        for (const key of Object.keys(value.primaryData)) {
          if (key.includes('workState')) {
            enumOptions.options[key] = {
              [`workState`]: convertWorkStatesToEditorArray(
                value.primaryData,
                key,
                workStates,
              ),
            };
            if (hasMaxFields || lastWorkStateName != key)
              enumOptions.disabled[key] = true;
            else enumOptions.disabled[key] = false;
          }
        }

        return enumOptions;
      },
    ],
    buttonMap: [
      (selectors) => [selectors.model],
      (model) => {
        return modelToButtonMap(model);
      },
    ],
  })),
  listeners(({ props, values, actions }) => ({
    confirm: async () => {
      const response = await props.deps.objectService.store({
        objectUuid: props.initialData.objectUuid,
        data: convertDataToGrpc(values.value.primaryData, values.dataCopy.base),
        settings: values.dataCopy.settings,
        info: values.dataCopy.info,
        workStateSettings: convertWorkStateToGrpc(values.value.primaryData),
      });

      if (response.success && validateValues(values.value)) {
        actions.setConfirmStatus(ConfirmStatus.success);

        props.deps.objectTableLogic().actions.refresh();

        await delay(500);
        props.deps.desktopLogic.actions.removeWindow(
          `objectEditor_${props.initialData.objectUuid}`,
        );
      } else {
        actions.setConfirmStatus(ConfirmStatus.failure);
      }
    },
    loadWorkStates: async () => {
      const response = await props.deps.objectService.getWorkStates();

      if (response.success) {
        const workStates: Record<number, string> = {};
        response.data.workStates.forEach((workState) => {
          workStates[workState.workStateId] = workState.name;
        });

        actions.setWorkStates(workStates);
        actions.loadObject();
      } else {
        actions.setLoadStatus(LoadingStatus.failure);
      }
    },
    loadObject: async () => {
      const response = await props.deps.objectService.get({
        objectUuid: props.initialData.objectUuid,
      });

      if (response.success) {
        const [model, numberOfFields] = convertResponseToModel(
          response.data,
          values.workStates,
          Object.keys(values.workStates).length,
        );

        actions.setInitialValue(model);
        actions.setWorkStateFields(numberOfFields);

        actions.setTileLayout(
          workStateSettingsToLayout(
            response.data.workStateSettings,
            values.numberOfWorkStateFields,
            Object.keys(values.workStates).length,
          ),
        );

        actions.setModel(
          workStateSettingsToModel(
            values.model,
            response.data.workStateSettings,
            values.numberOfWorkStateFields,
            Object.keys(values.workStates).length,
          ),
        );
        actions.setDataCopy(response.data);

        actions.setLoadStatus(LoadingStatus.success);
      } else {
        actions.setLoadStatus(LoadingStatus.failure);
      }
    },
    onButtonClick: ({ tabKey, valueKey }) => {
      const key = values.buttonMap[tabKey][valueKey].key;
      const maxFields = Object.keys(values.workStates).length;

      if (key == ObjectButtonKey.Add.toString()) {
        actions.addWorkStateField();
        actions.setTileLayout(
          addToLayout(
            values.tileLayout,
            values.numberOfWorkStateFields,
            maxFields,
          ),
        );
        actions.setModel(
          addToModel(values.model, values.numberOfWorkStateFields, maxFields),
        );
        actions.setInitialValue(
          addToValue(
            values.value,
            values.workStates,
            values.numberOfWorkStateFields,
            maxFields,
          ),
        );
      } else if (key == ObjectButtonKey.Remove.toString()) {
        actions.setTileLayout(removeFromLayout(values.tileLayout, tabKey));
        actions.setModel(removeFromModel(values.model, tabKey));
        actions.setInitialValue(removeFromValue(values.value, tabKey));

        if (values.numberOfWorkStateFields == maxFields) {
          actions.removeWorkStateField();
          actions.setTileLayout(
            addToLayout(
              values.tileLayout,
              values.numberOfWorkStateFields,
              maxFields,
            ),
          );
          actions.setModel(
            addToModel(values.model, values.numberOfWorkStateFields, maxFields),
          );
          actions.setInitialValue(
            addToValue(
              values.value,
              values.workStates,
              values.numberOfWorkStateFields,
              maxFields,
            ),
          );
        } else actions.removeWorkStateField();
      }
    },
    refresh: () => {
      actions.setLoadStatus(LoadingStatus.isLoading);
      actions.loadWorkStates();
    },
  })),
  afterMount(({ actions }) => {
    actions.loadWorkStates();
  }),
]);

export const objectEditorLogic = injectDepsToLogic(logic, () => ({
  objectService: Dependencies.get(IObjectService.$),
  objectTableLogic: Dependencies.get(IObjectTableLogic.$),
  desktopLogic: Dependencies.get(IDesktopLogic.$),
}));
