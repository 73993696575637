import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { MVisualization } from '@/types/models';

export interface IVisualizationService {
  get: (
    input: MVisualization.Messages.GetIn,
  ) => Promise<Response<MVisualization.Messages.GetOut>>;
  store: (
    input: MVisualization.Messages.StoreIn,
  ) => Promise<Response<MVisualization.Messages.StoreOut>>;
  getList: (
    input: MVisualization.Messages.GetListIn,
  ) => Promise<Response<MVisualization.Messages.GetListOut>>;
  open: (
    input: MVisualization.Messages.OpenIn,
  ) => Promise<Response<MVisualization.Messages.OpenOut>>;
}

export namespace IVisualizationService {
  export const $: interfaces.ServiceIdentifier<IVisualizationService> = Symbol(
    'IVisualizationService',
  );
}
