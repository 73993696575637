import { Tooltip } from 'primereact/tooltip';
import { FC, useMemo } from 'react';

import { useStorybookTranslation } from '@/i18next';
import { Icon, SideIcon } from '@/types/filter';

import './style.scss';

export interface IconTemplateProps {
  value: Icon | string | number;
}

export const IconTemplate: FC<IconTemplateProps> = (props) => {
  const t = useStorybookTranslation();

  const tooltipKey = useMemo(() => {
    if (typeof props.value === 'string')
      return props.value.replace(/[. ]/g, '-');
    else if (typeof props.value === 'number')
      return props.value.toString().replace(/[. ]/g, '-');
    else {
      return props.value.name.replace(/[. ]/g, '-');
    }
  }, [props.value]);

  if (typeof props.value === 'string' || typeof props.value === 'number') {
    return <i className={`table-icon${tooltipKey} ${props.value}`} />;
  }

  return (
    <>
      <Tooltip target={`.table-icon${tooltipKey}`} style={{ opacity: '90%' }}>
        <div className="ocs-icon-tooltip-container">
          <span className="ocs-icon-tooltip-label">
            {t('table.template.icon.tooltip')}
          </span>
          <TooltipIcon iconData={{ ...props.value, isActive: true }} />
          {props.value.sideIcons?.map((icon) => {
            return <TooltipIcon key={icon.name} iconData={icon} />;
          })}
        </div>
      </Tooltip>
      <i
        className={`ocs-icon-template table-icon${tooltipKey} ${props.value.icon}`}
        style={{ color: props.value.color }}
      />
    </>
  );
};

interface TooltipIconProps {
  iconData: SideIcon;
}

const TooltipIcon: FC<TooltipIconProps> = (props) => {
  const t = useStorybookTranslation();

  return (
    <div
      className={`ocs-tooltip-container ${
        props.iconData.isActive ? '' : 'ocs-tooltip-opacity'
      }`}
    >
      <i
        className={`ocs-tooltip-icon ${props.iconData.icon}`}
        style={{ color: props.iconData.color }}
      />
      <span className="ocs-tooltip-label">{t(props.iconData.name)}</span>
    </div>
  );
};
