import { Struct, Value } from '@/services/compiled/google/protobuf/struct';
import { Timestamp } from '@/services/compiled/google/protobuf/timestamp';

export class GrpcConverters {
  timezoneOffset: number;

  constructor() {
    this.timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  }

  public timestampToDate(timestamp: Timestamp): Date {
    return new Date(
      Number(timestamp.seconds) * 1000 +
        timestamp.nanos / 1000000 +
        this.timezoneOffset,
    );
  }

  public dateToTimestamp(date: Date): Timestamp {
    const time = date.getTime() - date.getTimezoneOffset() * 60 * 1000;
    return {
      seconds: BigInt(Math.floor(time / 1000)),
      nanos: (time % 1000) * 1000000,
    };
  }

  public timestampToNumber(timestamp: Timestamp): number {
    return (
      Number(timestamp.seconds) * 1000 +
      timestamp.nanos / 1000000 +
      this.timezoneOffset
    );
  }

  public structToRecord(struct: Struct): Record<string, unknown> {
    const record: Record<string, unknown> = {};
    for (const fieldKey in struct.fields) {
      record[fieldKey] = this.valueToUnknown(struct.fields[fieldKey]);
    }
    return record;
  }

  public recordToStruct(record: Record<string, unknown>): Struct {
    const fields: Record<string, Value> = {};

    for (const key in record) {
      fields[key] = this.unknownToValue(record[key]);
    }

    return { fields: fields };
  }

  public valueToUnknown(value: Value): unknown {
    switch (value.kind.oneofKind) {
      case 'structValue':
        return this.structToRecord(value.kind.structValue);
      case 'listValue':
        return value.kind.listValue.values.map((grpcValue) =>
          this.unknownToValue(grpcValue),
        );
      case 'boolValue':
        return value.kind.boolValue;
      case 'numberValue':
        return value.kind.numberValue;
      case 'stringValue':
        return value.kind.stringValue;
      case 'nullValue':
        return null;
      default:
        throw Error('Wrong value provided to valueToUnknown');
    }
  }

  public unknownToValue(value: unknown): Value {
    switch (typeof value) {
      case 'number':
        return { kind: { oneofKind: 'numberValue', numberValue: value } };
      case 'string':
        return { kind: { oneofKind: 'stringValue', stringValue: value } };
      case 'boolean':
        return { kind: { oneofKind: 'boolValue', boolValue: value } };
      case 'object':
        if (value === null) {
          return { kind: { oneofKind: 'nullValue', nullValue: 0 } };
        }
        if (Array.isArray(value)) {
          return {
            kind: {
              oneofKind: 'listValue',
              listValue: {
                values: value.map((mapValue) => this.unknownToValue(mapValue)),
              },
            },
          };
        }
        return {
          kind: {
            oneofKind: 'structValue',
            structValue: this.recordToStruct(value as Record<string, unknown>),
          },
        };
      default:
        throw Error('Wrong value provided to unknownToValue');
    }
  }
}
