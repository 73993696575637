import { Checkbox } from 'primereact/checkbox';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { BoolFilterValue, IconFilterType } from '@/types/filter';
import { GetValueIndex } from '@/utils/filterUtils';

import './style.scss';

interface IconFilterProps {
  iconData: IconFilterType;
  filterValue: BoolFilterValue[];
  setFilterValue: Dispatch<SetStateAction<BoolFilterValue[]>>;
}

export const IconFilter: FC<IconFilterProps> = (props) => {
  const [filterIndex, setIndex] = useState(-1);

  useEffect(() => {
    const index = GetValueIndex(
      props.filterValue,
      props.iconData,
      props.setFilterValue,
      false,
    );

    setIndex(index);
  }, [props]);

  return (
    <div className="ocs-icon-filter-container">
      <Checkbox
        checked={props.filterValue[filterIndex]?.filteredValue}
        onChange={() => {
          const newFilterValues = [...props.filterValue];
          newFilterValues[filterIndex].filteredValue =
            !props.filterValue[filterIndex].filteredValue;
          props.setFilterValue(newFilterValues);
        }}
      />
      <i
        className={`${props.iconData.value.icon} ocs-icon-filter-icon`}
        style={{ color: props.iconData.value.color }}
      />
      <label className="ocs-icon-filter-label">
        {props.iconData.value.name}
      </label>
    </div>
  );
};
