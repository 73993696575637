import { RpcError } from '@protobuf-ts/runtime-rpc';

import { Dependencies } from '@/deps';
import { IVisSystemApiLogic } from '@/logic/interfaces';
import { State } from '@/services/compiled/defined_alarms';
import { IDefinedAlarmsService } from '@/services/interfaces';
import { LiveIn, LiveOut } from '@/types/messages/definedAlarms';

import { IvisSystemApiStream } from '..';
import { StreamError } from './../../../../../services/types/streamErrors';

export const definedAlarmStream = async (
  definedAlarmUuid: string,
  interval: number | undefined,
  logicKey: {
    sourceUuid: string;
    sourceType: 'Object' | 'Device';
    visualizationUuid: string;
    name: string;
    systemapi_name: string;
  },
): Promise<IvisSystemApiStream> => {
  let logic_callback: null | ((data: Record<string, unknown>) => void) = null;

  const definedAlarmService = Dependencies.get(IDefinedAlarmsService.$);

  const onMessage = (event: { value: LiveOut; input: LiveIn }) => {
    const value = event.value;

    if (logic_callback != null) {
      if (
        value.view.temporary.state == State.PendingOffToOn ||
        value.view.temporary.state == State.ConfirmedOn ||
        value.view.temporary.state == State.ConfirmedPendingOnToOff
      ) {
        value.view.temporary.state = State.Off;
      }

      const data: Record<string, unknown> = {};
      for (const [k, v] of Object.entries(value.view)) {
        if (typeof v === 'object') {
          for (const [k2, v2] of Object.entries(v)) {
            data[k + '_' + k2] = v2;
          }
        } else {
          data[k] = v;
        }
      }

      logic_callback(data);
    }
  };

  const onDisconnect = async (error: Error) => {
    const logic = Dependencies.get(IVisSystemApiLogic.$)(logicKey);

    if (error.name === 'RpcError') {
      await new Promise((r) => setTimeout(r, 2000));

      const rpcError = error as RpcError;

      if (
        (rpcError.code === 'INTERNAL' &&
          (rpcError.message === StreamError.NetworkError ||
            rpcError.message === StreamError.FailedToFetch)) ||
        rpcError.code === 'ABORTED' ||
        rpcError.code === 'RESOURCE_EXHAUSTED'
      ) {
        logic.actions.stop();
        logic.actions.start();
        return;
      } else {
        logic.actions.stop();
        return;
      }
    }

    logic.actions.stop();
  };

  await definedAlarmService.stream.listen(
    {
      onMessage,
      onDisconnect,
    },
    {
      uuid: definedAlarmUuid,
      interval: interval !== null ? interval : undefined,
    },
  );

  return {
    cancel() {
      definedAlarmService.stream.cancel(onMessage, {
        uuid: definedAlarmUuid,
        interval: interval !== null ? interval : undefined,
      });
    },
    callback(callback) {
      logic_callback = callback;
    },
  } as IvisSystemApiStream;
};
