import {
  Axis,
  AxisScrollStrategies,
  AxisTickStrategies,
  Band,
  ChartXY,
  Color,
  ColorHEX,
  ColorRGBA,
  Dashboard,
  FontSettings,
  LegendBox,
  LegendBoxEntry,
  LightningChart,
  LightningChartOptions,
  LineSeries,
  Point,
  SolidFill,
  SolidLine,
  Themes,
  TypedArray,
  UIElement,
  UIElementBuilder,
  UIOrigins,
  VisibleTicks,
  customTheme,
  emptyFill,
  lightningChart,
} from '@arction/lcjs';
import {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { dateToDatetimeString } from '@/scripts';

import './style.scss';

const fontSettings = new FontSettings({ style: 'normal', family: 'lato' });

export interface ChartProps {
  id: string;
}

export interface ChartHandle {
  addSeries: (key: string, name?: string, yAxis?: string | null) => void;
  removeSeries: (key: string) => void;
  addValuesToSeries: (key: string, points: Point[]) => void;
  addValueArraysToSeries: (
    key: string,
    xValues: number[] | TypedArray,
    yValues: number[] | TypedArray,
  ) => void;
  setRange: (x1: number, x2: number, fitY?: boolean) => void;
  toggleAutoScroll: (autoScroll: boolean) => void;
  clearSeries: (key: string) => void;
  setDataCleaning: (key: string, dataCleaning: boolean) => void;
  toggleMovement: (movement: boolean) => void;
  toggleLegend: () => void;
  updateLegend: () => void;
  onLegendEntryRightClick: (callback: (seriesKey: string) => void) => void;
  saveToImage: (title: string) => void;
  clearAllSeries: () => void;
  addXAxisBand: (x1: number, x2: number, color: string, name: string) => void;
  addInviniteXAxisBand: (x: number, color: string, name: string) => void;
  removeInviniteXAxisBand: (end: number) => void;
  clearAllAxisBands: () => void;
  setDefaultColumnTitle: (title: string) => void;
}

//const LC = lightningChart({
//  license: process.env.LIGHTNING_CHART_LICENSE_KEY,
//});
let LCInit: null | LightningChart = null;

function ChartInit(license?: string) {
  console.log(license);
  if (license !== undefined) {
    const license_parsed = JSON.parse(license) as LightningChartOptions;
    try {
      LCInit = lightningChart(license_parsed);
    } catch (error) {
      console.error('lightningChart init with license', error);
    }
  } else {
    console.warn('lightningChart init without license');
    LCInit = lightningChart();
  }
}

const Chart = forwardRef<ChartHandle, ChartProps>(function Chart(props, ref) {
  if (LCInit === null) {
    throw Error('LightningChart license not provided');
  }
  const LC = LCInit;
  useImperativeHandle(ref, () => ({
    addSeries: addSeries,
    removeSeries: removeSeries,
    addValuesToSeries: addValuesToSeries,
    addValueArraysToSeries: addValueArraysToSeries,
    setRange: setRange,
    toggleAutoScroll: toggleAutoScroll,
    clearSeries: clearSeries,
    setDataCleaning: setDataCleaning,
    toggleMovement: toggleMovement,
    toggleLegend: toggleLegend,
    updateLegend: updateLegend,
    onLegendEntryRightClick: onLegendEntryRightClick,
    saveToImage: saveToImage,
    clearAllSeries: clearAllSeries,
    addXAxisBand: addXAxisBand,
    addInviniteXAxisBand: addInviniteXAxisBand,
    removeInviniteXAxisBand: removeInviniteXAxisBand,
    clearAllAxisBands: clearAllAxisBands,
    setDefaultColumnTitle: setDefaultColumnTitle,
  }));
  const { t } = useTranslation();

  const DashboardRef = useRef<Dashboard | null>(null);
  const XYChartRef = useRef<ChartXY | null>(null);
  const LegendRef = useRef<(LegendBox & UIElement) | null>(null);
  const BandLegendRef = useRef<(LegendBox & UIElement) | null>(null);

  const BandLegendCreated = useRef(false);

  const LineSeriesRef = useRef<Record<string, LineSeries>>({});
  const XAxisBandRef = useRef<Record<string, Band>>({});
  const LastXAxisBandRef = useRef<Band | null>(null);
  const XAxisBandArrayRef = useRef<Band[]>([]);
  const MovementRef = useRef(true);
  const YAxisRef = useRef<Record<string, Axis>>({});
  const YAxisSeries = useRef<Record<string, string[]>>({});
  const OnLegendEntryRightClick = useRef<(seriesKey: string) => void>(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (_) => {},
  );

  const clearAllSeries = useCallback(() => {
    for (const lineseriesKey in LineSeriesRef.current) {
      const lineseries = LineSeriesRef.current[lineseriesKey];
      lineseries.clear();
    }
  }, []);

  const saveToImage = useCallback((title: string) => {
    XYChartRef.current?.saveToFile(title, 'image/png');
  }, []);

  const toggleLegend = useCallback(() => {
    if (LegendRef.current != null) {
      LegendRef.current.dispose();
      LegendRef.current = null;
      return;
    }
    if (XYChartRef.current != null) {
      LegendRef.current = XYChartRef.current.addLegendBox();
      LegendRef.current.add(XYChartRef.current);
    }
    LegendRef.current?.setOrigin(UIOrigins.RightTop);
    LegendRef.current?.setPosition({ x: 100, y: 100 });
  }, []);

  const onLegendEntryRightClick = useCallback(
    (callback: (seriesKey: string) => void) => {
      OnLegendEntryRightClick.current = callback;
    },
    [],
  );

  const updateLegend = useCallback(() => {
    if (LegendRef.current != null && XYChartRef.current != null) {
      const position = LegendRef.current.getPosition();
      LegendRef.current.dispose();
      LegendRef.current = null;
      LegendRef.current = XYChartRef.current.addLegendBox();
      for (const seriesKey in LineSeriesRef.current) {
        const series = LineSeriesRef.current[seriesKey];
        LegendRef.current.add(series);
      }

      LegendRef.current.setEntries((entry, component) => {
        entry.onMouseUp((_, event) => {
          if (event.button == 2) {
            for (const seriesKey in LineSeriesRef.current) {
              const series = LineSeriesRef.current[seriesKey];
              if (series == component) {
                console.log('clicked', seriesKey);
                OnLegendEntryRightClick.current(seriesKey);
              }
            }
          }
        });
      });

      LegendRef.current.setPosition(position);
      LegendRef.current.setOrigin(UIOrigins.RightTop);
    }
    const bandLegenrRefPosition = BandLegendRef.current?.getPosition();

    if (BandLegendRef.current != null && XYChartRef.current != null) {
      BandLegendRef.current.dispose();
      BandLegendRef.current = null;

      BandLegendRef.current = XYChartRef.current.addLegendBox();
      BandLegendRef.current.setTitle(t('object.workState'));

      for (const bandKey in XAxisBandRef.current) {
        const band = XAxisBandRef.current[bandKey];
        BandLegendRef.current.add(band, { disposeOnClick: false });
      }
    }

    if (
      BandLegendRef.current == null &&
      Object.values(XAxisBandRef.current).length != 0 &&
      XYChartRef.current != null
    ) {
      BandLegendRef.current = XYChartRef.current.addLegendBox();
      BandLegendRef.current.setTitle(t('object.workState'));

      for (const bandKey in XAxisBandRef.current) {
        const band = XAxisBandRef.current[bandKey];
        BandLegendRef.current.add(band, { disposeOnClick: false });
      }
    }
    BandLegendRef.current?.setOrigin(UIOrigins.LeftTop);
    console.log(bandLegenrRefPosition);
    if (!BandLegendCreated.current) {
      if (BandLegendRef.current != null) {
        BandLegendCreated.current = true;
        BandLegendRef.current.setPosition({ x: 5, y: 100 });
      }
    } else {
      if (bandLegenrRefPosition)
        BandLegendRef.current?.setPosition(bandLegenrRefPosition);
    }
  }, [t]);

  const addXAxisBand = useCallback(
    (x1: number, x2: number, color: string | Color, name: string) => {
      if (XYChartRef.current != null) {
        const xAxis = XYChartRef.current.getDefaultAxisX();

        const xBand = xAxis.addBand(false);
        xBand.setMouseInteractions(false);

        xBand.setValueStart(x1).setValueEnd(x2);
        xBand.setFillStyle(
          new SolidFill({
            color:
              typeof color == 'string'
                ? ColorHEX(color).setA(40)
                : color.setA(40),
          }),
        );
        xBand.setStrokeStyle(
          new SolidLine({
            fillStyle: new SolidFill({
              color:
                typeof color == 'string'
                  ? ColorHEX(color).setA(40)
                  : color.setA(25),
            }),
          }),
        );
        xBand.setName(name);
        XAxisBandRef.current[name] = xBand;
        XAxisBandArrayRef.current.push(xBand);
      }
    },
    [],
  );

  const addInviniteXAxisBand = useCallback(
    (x: number, color: string, name: string) => {
      if (XYChartRef.current != null) {
        const xAxis = XYChartRef.current.getDefaultAxisX();

        const xBand = xAxis.addBand(false);
        xBand.setMouseInteractions(false);

        const endDate = new Date(
          new Date().setFullYear(new Date().getFullYear() + 1),
        );

        xBand.setValueStart(x).setValueEnd(endDate.getTime());
        xBand.setFillStyle(new SolidFill({ color: ColorHEX(color).setA(40) }));
        xBand.setStrokeStyle(
          new SolidLine({
            fillStyle: new SolidFill({ color: ColorHEX(color).setA(25) }),
          }),
        );
        xBand.setName(name);
        LastXAxisBandRef.current = xBand;
        XAxisBandArrayRef.current.push(xBand);
      }
    },
    [],
  );

  const clearAllAxisBands = useCallback(() => {
    for (const band of XAxisBandArrayRef.current) {
      band.dispose();
    }
    XAxisBandArrayRef.current = [];
    XAxisBandRef.current = {};
    LastXAxisBandRef.current = null;

    updateLegend();
  }, [updateLegend]);

  const removeInviniteXAxisBand = useCallback(
    (end: number) => {
      const x = LastXAxisBandRef.current?.getValueStart();
      const color = (
        LastXAxisBandRef.current?.getFillStyle() as SolidFill
      ).color.setA(255);
      const name = LastXAxisBandRef.current?.getName();
      if (x != null && color != null && name != null) {
        addXAxisBand(x, end, color, name);
      }
      LastXAxisBandRef.current?.dispose();
      LastXAxisBandRef.current = null;
    },
    [addXAxisBand],
  );

  const clearSeries = useCallback((key: string) => {
    if (Object.prototype.hasOwnProperty.call(LineSeriesRef.current, key)) {
      LineSeriesRef.current[key].clear();
    }
  }, []);

  const toggleMovement = useCallback((movement: boolean) => {
    if (!movement) {
      XYChartRef.current?.getDefaultAxisX().setMouseInteractions(false);
      XYChartRef.current?.setMouseInteractions(false);
    } else {
      XYChartRef.current?.getDefaultAxisX().setMouseInteractions(true);
      XYChartRef.current?.setMouseInteractions(true);
    }
    MovementRef.current = movement;
  }, []);

  const toggleAutoScroll = useCallback((autoScroll: boolean) => {
    if (autoScroll) {
      XYChartRef.current
        ?.getDefaultAxisX()
        .setScrollStrategy(AxisScrollStrategies.progressive);
    } else {
      XYChartRef.current?.getDefaultAxisX().setScrollStrategy(undefined);
    }
  }, []);

  const setRange = useCallback((x1: number, x2: number, fitY = true) => {
    XYChartRef.current?.getDefaultAxisX().setInterval(x1, x2);
    if (fitY) {
      XYChartRef.current?.getDefaultAxisY().fit(true);
    }
  }, []);

  const addSeries = useCallback(
    (key: string, name = '', yAxis: string | null = null) => {
      if (XYChartRef.current != null) {
        let axis: Axis;
        if (yAxis != null) {
          if (!(yAxis in YAxisSeries.current)) {
            YAxisSeries.current[yAxis] = [];
          }
          YAxisSeries.current[yAxis].push(key);
          if (!(yAxis in YAxisRef.current)) {
            axis = XYChartRef.current.addAxisY().setTitle(yAxis);
            YAxisRef.current[yAxis] = axis;
          } else {
            axis = YAxisRef.current[yAxis];
          }
        } else {
          axis = XYChartRef.current.getDefaultAxisY();
        }

        const series = XYChartRef.current.addLineSeries({
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: false,
            allowDataGrouping: true,
          },
          yAxis: axis,
        });
        series.setCursorResultTableFormatter(
          (tableBuilder, series, x, y, dataPoint) => {
            return tableBuilder
              .addRow(`X:`, '', dateToDatetimeString(new Date(dataPoint.x)))
              .addRow(`Y:`, '', dataPoint.y.toFixed(1));
          },
        );
        series.setName(name);
        series.setCursorInterpolationEnabled(false);
        LineSeriesRef.current[key] = series;
      }
    },
    [],
  );

  const removeSeries = useCallback((key: string) => {
    for (const yAxisSeriesKey in YAxisSeries.current) {
      YAxisSeries.current[yAxisSeriesKey] = YAxisSeries.current[
        yAxisSeriesKey
      ].filter((seriesKey) => {
        return seriesKey != key;
      });
      if (YAxisSeries.current[yAxisSeriesKey].length <= 0) {
        YAxisRef.current[yAxisSeriesKey].dispose();
        delete YAxisRef.current[yAxisSeriesKey];
        delete YAxisSeries.current[yAxisSeriesKey];
      }
    }
    if (key in LineSeriesRef.current) {
      const series = LineSeriesRef.current[key];
      series.dispose();
      delete LineSeriesRef.current[key];
    }
  }, []);

  const setDefaultColumnTitle = useCallback((title: string) => {
    if (XYChartRef.current != null) {
      XYChartRef.current.getDefaultAxisY().setTitle(title);
    }
  }, []);

  const addValueArraysToSeries = useCallback(
    (
      key: string,
      xValues: number[] | TypedArray,
      yValues: number[] | TypedArray,
    ) => {
      if (Object.prototype.hasOwnProperty.call(LineSeriesRef.current, key)) {
        const series = LineSeriesRef.current[key];
        series.addArraysXY(xValues, yValues);
      }
    },
    [],
  );

  const addValuesToSeries = useCallback((key: string, points: Point[]) => {
    if (Object.prototype.hasOwnProperty.call(LineSeriesRef.current, key)) {
      const series = LineSeriesRef.current[key];
      series.add(points);
    }
  }, []);

  const setTickStyleFont = useCallback((style: VisibleTicks) => {
    return style.setLabelFont(
      style
        .getLabelFont()
        .setStyle(fontSettings.style)
        .setFamily(fontSettings.family),
    );
  }, []);

  const setXAxisTickStrategy = useCallback(
    (axis: Axis) => {
      axis
        .setTickStrategy(AxisTickStrategies.DateTime, (ticks) =>
          ticks
            .setMinorTickStyle((style: VisibleTicks) => setTickStyleFont(style))
            .setMajorTickStyle((style: VisibleTicks) => setTickStyleFont(style))
            .setGreatTickStyle((style: VisibleTicks) =>
              setTickStyleFont(style),
            ),
        )
        .setScrollStrategy(undefined);
    },
    [setTickStyleFont],
  );

  const setYAxisTickStrategy = useCallback(
    (axis: Axis) => {
      const numberFromater = Intl.NumberFormat('en', { notation: 'compact' });

      axis.setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
        ticks
          .setMinorTickStyle((style: VisibleTicks) => setTickStyleFont(style))
          .setMajorTickStyle((style: VisibleTicks) => setTickStyleFont(style))
          .setFormattingFunction((value) => numberFromater.format(value))
          .setMinorFormattingFunction((value) => numberFromater.format(value))
          .setMajorFormattingFunction((value) => numberFromater.format(value)),
      );
    },
    [setTickStyleFont],
  );

  const setDataCleaning = useCallback((key: string, dataCleaning: boolean) => {
    if (Object.prototype.hasOwnProperty.call(LineSeriesRef.current, key)) {
      const series = LineSeriesRef.current[key];
      if (dataCleaning) {
        series.setDataCleaning({ minDataPointCount: 1000 });
      } else {
        series.setDataCleaning(undefined);
      }
    }
  }, []);

  const startChart = useCallback(() => {
    const dashboard = LC.Dashboard({
      container: props.id,
      numberOfColumns: 1,
      numberOfRows: 1,
    });

    const chart = dashboard.createChartXY({
      defaultAxisX: {
        type: 'linear-highPrecision',
      },
      theme: customTheme(Themes.darkGold, {
        axisLabelFont: fontSettings,
        uiFont: fontSettings,
        uiPointableTextBoxFont: fontSettings,
        spiderAxisLabelFont: fontSettings,
        chartTitleFont: fontSettings,
        axisTitleFont: fontSettings,
        uiTickFont: fontSettings,
        sliceLabelFont: fontSettings,
        resultTableTextFont: fontSettings,
        spiderScaleLabelFont: fontSettings,
      }),
      columnIndex: 0,
      rowIndex: 0,
    });

    setXAxisTickStrategy(chart.getDefaultAxisX());
    setYAxisTickStrategy(chart.getDefaultAxisY());

    chart.engine.setBackgroundFillStyle(emptyFill);
    chart.setBackgroundFillStyle(
      new SolidFill({ color: ColorRGBA(255, 255, 255, 0) }),
    );
    chart.setSeriesBackgroundFillStyle(
      new SolidFill({ color: ColorRGBA(255, 255, 255, 0) }),
    );
    chart.setTitle('');

    chart.onSeriesBackgroundMouseDoubleClick(() => {
      if (MovementRef.current) {
        chart.getAxes().forEach((axe) => axe.fit(true));
      }
    });

    XYChartRef.current = chart;
    DashboardRef.current = dashboard;
  }, [props.id, setXAxisTickStrategy, setYAxisTickStrategy]);

  const stopChart = useCallback(() => {
    XYChartRef.current?.dispose();
    XYChartRef.current = null;
    DashboardRef.current?.dispose();
    DashboardRef.current = null;
  }, []);

  useEffect(() => {
    startChart();
    return stopChart;
  }, [startChart, stopChart]);

  return <div id={props.id} className="chart"></div>;
});

const MemoChart: ForwardRefExoticComponent<
  ChartProps & RefAttributes<ChartHandle>
> = memo(Chart);

export { MemoChart as Chart, ChartInit };
