import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { FC } from 'react';

import { useStorybookTranslation } from '@/i18next';

import './style.scss';

interface BadgeIconProps {
  icon: string;
  iconColor?: string;
  primaryColor?: boolean;

  badgeColor: string;
  badgeTextColor: string;

  value: string;
  onClick: () => void;
}

export const BadgeIcon: FC<BadgeIconProps> = (props) => {
  const t = useStorybookTranslation();

  return (
    <div className="ocs-badgeicon-container p-overlay-badge">
      <Button
        className="p-button-text"
        onClick={props.onClick}
        tooltip={t('tooltips.alarmEvent')}
        tooltipOptions={{
          position: 'left',
          showDelay: 500,
          baseZIndex: 2001,
        }}
      >
        <i
          className={`${props.icon} ocs-badgeicon-icon ${
            props.primaryColor ? 'ocs-badgeicon-primarycolor' : ''
          }`}
          style={props.primaryColor == false ? { color: props.iconColor } : {}}
        ></i>
      </Button>
      <Badge
        value={props.value}
        style={{
          backgroundColor: props.badgeColor,
          color: props.badgeTextColor,
        }}
      />
    </div>
  );
};
