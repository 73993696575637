import { MDevice, MGenericDevice } from '@/types/models';

export interface DataBytesPackage {
  numbers: Record<string, [Float64Array, Float64Array]>;
  booleans: Record<string, [Float64Array, Uint8Array]>;
  strings: Record<string, [Float64Array, Uint8Array[]]>;
  workStates: [Float64Array, Int32Array];
}

export interface GraphIn {
  deviceUuid: string;
  daterange: {
    start: Date;
    end?: Date;
  };
  workStateIds?: number[];
}

export type GraphOut = MGenericDevice.Data[];

export interface GraphBytesIn {
  deviceUuid: string;
  daterange: {
    start: Date;
    end?: Date;
  };
  workStateIds?: number[];
}

export type GraphBytesOut = DataBytesPackage;

export interface GetIn {
  deviceUuid: string;
}

export interface GetOut {
  deviceUuid?: string;
  device?: MDevice.Editor;
  info?: MGenericDevice.Info;
}

export interface GetBrandsOut {
  brands: MGenericDevice.Brand[];
}

export interface StoreIn {
  deviceUuid?: string;
  device?: MDevice.Editor;
  info?: MGenericDevice.Info;
}

export interface StoreOut {
  deviceUuid: string;
}

export interface GetModelsOut {
  models: MGenericDevice.Model[];
}

export interface GetModelColumnsIn {
  modelId: number;
}

export interface GetModelColumnsOut {
  modelColumns: MGenericDevice.ModelColumn[];
}

export interface GetBrandModbusTasksIn {
  brandId: number;
}

export interface GetBrandModbusTasksOut {
  brandModbusTasks: MGenericDevice.BrandModbusTask[];
}

export interface GetBrandModbusTaskColumnsIn {
  brandModbusTaskId: number;
}

export interface GetBrandModbusTaskColumnsOut {
  brandModbusTaskColumns: MGenericDevice.BrandModbusTaskColumn[];
}

export type GetNowOut = MGenericDevice.Data;
export type GetNowIn = MDevice.Messages.GetIn;

export interface LiveIn {
  uuid: string;
  start?: Date;
  interval?: number;
  workStateIds?: number[];
}

export type LiveBytesOut = DataBytesPackage;

export type LiveOut = MGenericDevice.Data;

export enum Lang {
  Pl = 0,
  En = 1,
}

export interface DownloadIn {
  lang: Lang;
  range: {
    device_uuid: string;
    start: Date;
    end: Date;
  };
  workStateIds?: number[];
}

export interface DownloadOut {
  file_uuid: string;
}
