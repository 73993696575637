import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { MGenericDevice } from '@/types/models';

import { IStream } from '../types/stream';

export interface IGenericDeviceService {
  graph(
    input: MGenericDevice.Messages.GraphIn,
  ): Promise<Response<MGenericDevice.Messages.GraphOut>>;
  graphBytes(
    input: MGenericDevice.Messages.GraphBytesIn,
  ): Promise<Response<MGenericDevice.Messages.GraphBytesOut>>;
  get: (
    input: MGenericDevice.Messages.GetIn,
  ) => Promise<Response<MGenericDevice.Messages.GetOut>>;
  store: (
    input: MGenericDevice.Messages.StoreIn,
  ) => Promise<Response<MGenericDevice.Messages.StoreOut>>;
  getBrands: () => Promise<Response<MGenericDevice.Messages.GetBrandsOut>>;
  getBrandModbusTasks: (
    input: MGenericDevice.Messages.GetBrandModbusTasksIn,
  ) => Promise<Response<MGenericDevice.Messages.GetBrandModbusTasksOut>>;
  getBrandModbusTaskColumns: (
    input: MGenericDevice.Messages.GetBrandModbusTaskColumnsIn,
  ) => Promise<Response<MGenericDevice.Messages.GetBrandModbusTaskColumnsOut>>;
  getModels: () => Promise<Response<MGenericDevice.Messages.GetModelsOut>>;
  getModelColumns: (
    input: MGenericDevice.Messages.GetModelColumnsIn,
  ) => Promise<Response<MGenericDevice.Messages.GetModelColumnsOut>>;
  getNow: (
    input: MGenericDevice.Messages.GetNowIn,
  ) => Promise<Response<MGenericDevice.Messages.GetNowOut>>;
  stream: IStream<
    MGenericDevice.Messages.LiveIn,
    MGenericDevice.Messages.LiveOut
  >;
  streamBytes: IStream<
    MGenericDevice.Messages.LiveIn,
    MGenericDevice.Messages.LiveBytesOut
  >;
  download: (
    input: MGenericDevice.Messages.DownloadIn,
  ) => Promise<Response<MGenericDevice.Messages.DownloadOut>>;
}

export namespace IGenericDeviceService {
  export const $: interfaces.ServiceIdentifier<IGenericDeviceService> = Symbol(
    'IGenericDeviceService',
  );
}
