import { shallowEqual } from 'fast-equals';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import Dependencies from '@/deps';
import {
  IGenericDeviceViewLogicProps,
  IGenericDevicesContainerLogic,
  IGenericDevicesDictsLogic,
  IUnitsLogic,
} from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IGenericDeviceService } from '@/services/interfaces';
import { LiveIn, LiveOut } from '@/types/messages/genericdevice';
import { MDevice, MGenericDevice } from '@/types/models';
import { arrayToRecord } from '@/utility/types';

import type { logicType } from './indexType';

export interface GeneridDeviceViewLogicProps
  extends IGenericDeviceViewLogicProps {
  deps: {
    unitsLogic: IUnitsLogic;
    genericDevicesContainerLogic: IGenericDevicesContainerLogic;
    genericDevicesDictsLogic: IGenericDevicesDictsLogic;
    genericDeviceService: IGenericDeviceService;
  };
}

const logic = kea<logicType>([
  props({} as GeneridDeviceViewLogicProps),
  key((props) => props.deviceUuid),
  path((key) => ['genericDeviceView', key]),
  actions({
    connectLiveStream: true,
    disconnectLiveStream: true,
    _load: true,
    _setNow: (now: MGenericDevice.Data) => ({ now }),
    _setGenericDevice: (genericDevice: MGenericDevice.View) => ({
      genericDevice,
    }),
    _setConnected: (connected: boolean) => ({ connected }),
  }),
  reducers({
    now: [
      null as MGenericDevice.Data | null,
      {
        _setNow: (_, { now }) => now,
      },
    ],
    genericDevice: [
      null as MGenericDevice.View | null,
      {
        _setGenericDevice: (_, { genericDevice }) => genericDevice,
      },
    ],
    connected: [
      false,
      {
        _setConnected: (_, { connected }) => connected,
      },
    ],
  }),
  listeners(({ props, actions, cache }) => {
    const onMessage = (event: { value: LiveOut; input: LiveIn }) => {
      actions._setNow(event.value);
    };

    cache.onMessage = onMessage;

    const onDisconnect = () => {
      actions._setConnected(false);
    };

    return {
      _load: () => {
        props.deps.genericDeviceService
          .getNow({
            deviceUuid: props.deviceUuid,
          })
          .then((result_now) => {
            if (result_now.success) {
              actions._setNow(result_now.data);
            }
          })
          .catch(null);
        props.deps.genericDeviceService
          .get({ deviceUuid: props.deviceUuid })
          .then((result_get) => {
            if (
              result_get.success &&
              result_get.data.info != undefined &&
              result_get.data.deviceUuid
            ) {
              actions._setGenericDevice({
                deviceUuid: result_get.data.deviceUuid,
                base: {
                  brandId: result_get.data.info.brandId,
                },
              });
            }
          })
          .catch(null);
      },
      connectLiveStream: async () => {
        await props.deps.genericDeviceService.stream.listen(
          {
            onMessage,
            onDisconnect,
          },
          {
            uuid: props.deviceUuid,
          },
        );
        actions._setConnected(true);
      },
      disconnectLiveStream: () => {
        props.deps.genericDeviceService.stream.cancel(onMessage, {
          uuid: props.deviceUuid,
        });
        actions._setConnected(false);
      },
    };
  }),
  selectors(({ props }) => ({
    units: [() => [props.deps.unitsLogic.selectors.units], (units) => units],
    brand: [
      (s) => [
        props.deps.genericDevicesDictsLogic.selectors.brands,
        s.genericDevice,
      ],
      (brands, genericDevice) => {
        if (genericDevice !== null && genericDevice.base.brandId in brands) {
          return brands[genericDevice.base.brandId];
        } else {
          return null;
        }
      },
    ],
    modelColumns: [
      (s) => [
        props.deps.genericDevicesDictsLogic.selectors.modelColumns,
        s.now,
      ],
      (modelColumns, now) => {
        if (now != null && now.modelId in modelColumns) {
          return arrayToRecord(modelColumns[now.modelId], 'modelColumnId');
        } else {
          return null;
        }
      },
    ],
    device: [
      () => [
        props.deps.genericDevicesContainerLogic({
          objectUuid: props.objectUuid,
        }).selectors.devices,
      ],
      (devices) => {
        if (devices != null) {
          return devices.find(
            (d: MDevice.TableView) => d.deviceUuid == props.deviceUuid,
          );
        } else {
          return null;
        }
      },
      { resultEqualityCheck: shallowEqual },
    ],
  })),
  afterMount(({ actions }) => {
    actions._load();
    //actions.connectLiveStream();
  }),
  beforeUnmount(({ props, cache, values }) => {
    if (values.connected) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.deps.genericDeviceService.stream.cancel(cache.onMessage, {
        uuid: props.deviceUuid,
      });
    }
  }),
]);

export const genericDeviceViewLogic = injectDepsToLogic(logic, () => ({
  unitsLogic: Dependencies.get(IUnitsLogic.$),
  genericDevicesContainerLogic: Dependencies.get(
    IGenericDevicesContainerLogic.$,
  ),
  genericDevicesDictsLogic: Dependencies.get(IGenericDevicesDictsLogic.$),
  genericDeviceService: Dependencies.get(IGenericDeviceService.$),
}));
