import { useActions } from 'kea';
import { Button } from 'primereact/button';
import { FC } from 'react';

import { useTranslation } from '@/hooks';
import { IEditorLogic } from '@/logic/interfaces/editor';

import './mainStyle.scss';

interface EditorRefreshButtonProps {
  editorLogic: IEditorLogic;
}

export const EditorRefreshButton: FC<EditorRefreshButtonProps> = (props) => {
  const t = useTranslation();

  const { refresh } = useActions(props.editorLogic);

  return (
    <div className="OCS-editor-refresh-container">
      <i className="ri ri-error-warning-line" />
      <span>{t('editor.refresh.errorMessage')}</span>
      <Button
        onClick={() => {
          refresh();
        }}
        label={t('editor.refresh.button')}
      />
    </div>
  );
};

export const EditorSpinner: FC = () => {
  return (
    <div className="OCS-editor-spinner-container">
      <i className="pi pi-spin pi-spinner OCS-editor-spinner" />
    </div>
  );
};
