import {
  ConvertToSystemAPI,
  ConvertToVisualizationType,
  VisLogicProps as OCSVisLogicProps,
  SystemAPIDataset,
  VisualizationType,
} from '@ocs/ocs-components';
import produce from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
} from 'kea';

import { Dependencies } from '@/deps';
import { IVisSystemApiLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IObjectService, IVisualizationService } from '@/services/interfaces';
import { MObject } from '@/types/models';

import type { logicType } from './indexType';

export interface VisLogicProps extends OCSVisLogicProps {
  deps: {
    visualizationService: IVisualizationService;
    visSystemApiLogic: IVisSystemApiLogic;
    objectService: IObjectService;
  };
}

const logic = kea<logicType>([
  props({} as VisLogicProps),
  path((key) => ['visualization', key]),
  key(
    (props) =>
      `${props.sourceType}/${props.sourceUuid}/${props.visualizationUuid}/${props.name}`,
  ),
  actions({
    loadJSON: true,
    loadWorkStates: true,
    setWorkStates: (workStates: Record<string, MObject.WorkState>) => ({
      workStates,
    }),
    setVisualization: (visualization: VisualizationType) => ({ visualization }),
    setSystemApis: (systemapis: SystemAPIDataset[]) => ({ systemapis }),
    updateSystemApiValue: (
      systemApiKey: string,
      systemApiValue: Record<string, unknown>,
    ) => ({
      systemApiKey,
      systemApiValue,
    }),
    setLoadSuccess: true,
    setLoadError: true,
  }),
  reducers(({ props, cache }) => ({
    visualization: [
      {} as VisualizationType,
      { setVisualization: (_, { visualization }) => visualization },
    ],
    loadSuccess: [
      false,
      {
        setLoadError: () => false,
        setLoadSuccess: () => true,
      },
    ],
    loadError: [
      false,
      {
        setLoadError: () => true,
        setLoadSuccess: () => false,
      },
    ],
    isStorybook: [false, {}],
    imageBaseURL: [
      process.env.NODE_ENV === 'development'
        ? `https://localhost:8433/api/visualizations/get/${props.visualizationUuid}/${props.name}/`
        : `${window.location.origin}/api/visualizations/get/${props.visualizationUuid}/${props.name}/`,
      {},
      {},
    ],
    systemapis: [
      {} as Record<string, SystemAPIDataset>,
      {
        setSystemApis: (_, { systemapis }) => {
          const systemApiRecords = {} as Record<string, SystemAPIDataset>;
          const systemApiNames = [];

          for (const systemApi of systemapis) {
            systemApiRecords[systemApi.name] = systemApi;
            systemApiNames.push(systemApi.name);
          }

          cache.systemApiNames = systemApiNames;
          return systemApiRecords;
        },
      },
    ],
    workStates: [
      {} as Record<string, MObject.WorkState>,
      {
        setWorkStates: (_, { workStates }) => workStates,
      },
    ],
    systemApiValues: [
      {} as Record<string, Record<string, unknown>>,
      {
        updateSystemApiValue: (
          immutableState,
          { systemApiKey, systemApiValue },
        ) =>
          produce(immutableState, (state) => {
            state[systemApiKey] = systemApiValue;
          }),
      },
    ],
  })),
  listeners(({ actions, props, cache }) => ({
    loadJSON: async () => {
      const response_workstates =
        await props.deps.objectService.getWorkStates();

      if (response_workstates.success) {
        const workStatesDict: Record<string, MObject.WorkState> = {};

        for (const workState of response_workstates.data.workStates) {
          workStatesDict[workState.workStateId.toString()] = workState;
        }

        actions.setWorkStates(workStatesDict);
      }

      const response = await props.deps.visualizationService.open({
        visualizationUuid: props.visualizationUuid,
        filename: props.name,
      });

      if (response.success) {
        const body = ConvertToVisualizationType(JSON.parse(response.data.body));
        const systemapis = ConvertToSystemAPI(
          JSON.parse(response.data.systemapis),
        );

        actions.setVisualization(body);

        if (cache.systemApiNames !== undefined) {
          unmountSystemApis(cache.systemApiNames, props);
        }

        actions.setSystemApis(systemapis);
        mountSystemApis(cache.systemApiNames, props);

        actions.setLoadSuccess();
      } else {
        actions.setLoadError();
      }
    },
  })),
  afterMount(({ actions }) => {
    //actions.loadWorkStates();
    actions.loadJSON();
  }),
  beforeUnmount(({ props, cache }) => {
    if (cache.systemApiNames !== undefined) {
      unmountSystemApis(cache.systemApiNames, props);
    }
  }),
]);

export const visualizationLogic = injectDepsToLogic(logic, () => ({
  visualizationService: Dependencies.get(IVisualizationService.$),
  visSystemApiLogic: Dependencies.get(IVisSystemApiLogic.$),
  objectService: Dependencies.get(IObjectService.$),
}));

function unmountSystemApis(systemApiNames: string[], props: VisLogicProps) {
  for (const systemapi_name of systemApiNames) {
    props.deps.visSystemApiLogic({ ...props, systemapi_name }).unmount();
  }
}

function mountSystemApis(systemApiNames: string[], props: VisLogicProps) {
  for (const systemapi_name of systemApiNames) {
    props.deps.visSystemApiLogic({ ...props, systemapi_name }).mount();
  }
}
