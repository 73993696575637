{
  "eventList": {
    "notifications": "Notifications",
    "remove": "Are you sure you want to remove all notifications?",
    "removeAccept": "Yes",
    "removeReject": "No",
    "eventDialog": {
      "alarmEvent": "Alarm notification",
      "alarmInput": "Comment",
      "alarmAccept": "Accept",
      "alarmError": "Try again"
    },
    "confirmAll": {
      "dialogTitle": "Alarm notification",
      "details": "Confirm alarm notifications",
      "inputPlaceholder": "Comment",
      "confirmLabel": "Accept"
    }
  },
  "table": {
    "template": {
      "icon": {
        "tooltip": "All Alerts:"
      }
    },
    "reload": {
      "error": "An error occured while loading the table.",
      "button": "Try Again"
    },
    "filters": {
      "text": "Search by name",
      "number": "Search by value",
      "tag": "Search by tag",
      "beginDate": "Select begin date",
      "endDate": "Select end date",
      "apply": "Apply",
      "clear": "Clear"
    }
  },
  "tooltips": {
    "alarmEvent": "Notifications"
  }
}
