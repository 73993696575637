import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { ChartHandle } from '@/components/chart';
import { MGenericDevice, MObject } from '@/types/models';

export interface IObjectChartLogicProps extends Record<string, unknown> {
  initialData?: {
    objectUuid: string;
  };
  chart?: ChartHandle | null;
}

interface IObjectChart extends Logic {
  readonly props: IObjectChartLogicProps;
  readonly actions: {
    readonly setDateRange: (dateRange: { start: Date; end: Date }) => void;
    readonly setLoadedDateRange: (dateRange: {
      start: Date;
      end: Date;
    }) => void;
    readonly loadCurrentRange: () => void;
    readonly toggleLive: () => void;
    readonly saveToImage: () => void;
    readonly setDeviceKeySelected: (deviceKey: string, column?: number) => void;
    readonly setDeviceKeyNotSelected: (
      deviceKey: string,
      column?: number,
    ) => void;
    readonly setSelectedWorkStates: (workStates: number[]) => void;
    readonly saveToCSV: () => void;
    readonly setAvgTimeSeconds: (seconds: number | undefined) => void;
  };
  readonly values: {
    readonly dateRange: { start: Date; end: Date };
    readonly loadedDateRange: { start: Date; end: Date } | null;
    readonly loadingState: {
      running: boolean;
      success: boolean;
      error: boolean;
    };
    readonly liveState: {
      loading: boolean;
      error: boolean;
      running: boolean;
    };
    readonly downloadingState: {
      loading: boolean;
      success: boolean;
      error: boolean;
    };
    readonly devices: Record<
      string,
      {
        name: string;
        columns?: MGenericDevice.ModelColumn[];
      }
    > | null;
    readonly selectedDeviceKeys: Record<
      string,
      boolean | Record<string, boolean>
    >;
    readonly workStates: MObject.WorkState[];
    readonly selectedWorkStates: number[];
    readonly avgTimeSeconds: number | undefined;
  };
}

export interface IObjectChartLogic extends LogicWrapper<IObjectChart> {}

export namespace IObjectChartLogic {
  export const $: interfaces.ServiceIdentifier<IObjectChartLogic> =
    Symbol('IObjectChartLogic');
}
