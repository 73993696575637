enum Themes {
  ARYA_BLUE = 'aryaBlue',
  LARA_INDIGO_DARK = 'laraIndigoDark',
  LARA_INDIGO_LIGHT = 'blueTheme',
  SAGA_BLUE = 'sagaBlue',
  VELA_BLUE = 'velaBlue',
}

const themeDict = {
  [Themes.ARYA_BLUE]: 'themes/arya-blue.css',
  [Themes.LARA_INDIGO_DARK]: 'themes/lara-indigo-dark.css',
  [Themes.LARA_INDIGO_LIGHT]: 'themes/lara-indigo-light.css',
  [Themes.SAGA_BLUE]: 'themes/saga-blue.css',
  [Themes.VELA_BLUE]: 'themes/vela-blue.css',
};

type ThemeData = {
  name: string;
  source: URL;
};

const imageSource = new URL(
  '../assets/images/placeholder.png',
  import.meta.url,
);

const UIthemeDict: Record<Themes, ThemeData> = {
  [Themes.ARYA_BLUE]: {
    name: 'Arya Blue',
    source: imageSource,
  },
  [Themes.LARA_INDIGO_DARK]: {
    name: 'Lara Indigo Dark',
    source: imageSource,
  },
  [Themes.LARA_INDIGO_LIGHT]: {
    name: 'Lara Indigo Light',
    source: imageSource,
  },
  [Themes.SAGA_BLUE]: {
    name: 'Saga Blue',
    source: imageSource,
  },
  [Themes.VELA_BLUE]: {
    name: 'Vela Blue',
    source: imageSource,
  },
};

export { Themes, themeDict, ThemeData, UIthemeDict };
