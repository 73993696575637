import { FC, useEffect, useState } from 'react';

import {
  DescriptionType,
  GroupType,
  ImageType,
  LineType,
  MeasurementType,
  NodeType,
} from '@/types/visualization';

import { DescriptionVisualization } from './description';
import { GroupVisualization } from './group';
import { ImageVisualization } from './image';
import { LineVisualization } from './line';
import { MeasurementVisualization } from './measurement';

interface VisRendererProps {
  childs?: (
    | ImageType
    | DescriptionType
    | LineType
    | GroupType
    | MeasurementType
  )[];
}

export const VisualizationRenderer: FC<VisRendererProps> = (props) => {
  return (
    <>
      {props.childs?.map((child, index) => {
        switch (child.type) {
          case NodeType.Image:
            return (
              <ImageVisualization key={child.name} image={child as ImageType} />
            );

          case NodeType.Measurement:
            return (
              <MeasurementVisualization
                key={child.name}
                measurement={child as MeasurementType}
              />
            );

          case NodeType.Group:
            return (
              <GroupVisualization key={child.name} group={child as GroupType} />
            );

          case NodeType.Line:
            return (
              <LineVisualization key={child.name} line={child as LineType} />
            );

          case NodeType.Desc:
            return (
              <DescriptionVisualization
                key={child.name}
                desc={child as DescriptionType}
              />
            );
          default:
            break;
        }
      })}
    </>
  );
};
