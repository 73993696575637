import { EditorValue } from '@/types/custom/editorTypes';
import { MHubs } from '@/types/models/hubs';

export function convertResponseToModel(
  data: MHubs.Messages.GetOut,
): Record<string, Record<string, Record<string, EditorValue>>> {
  return {
    primaryData: {
      primaryData: {
        name: { value: data.device?.data ? data.device.data.name : '' },
        readTime: {
          value: data.settings?.readTimeSecs,
        },
      },
    },
  };
}
