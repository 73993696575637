import { ColumnType, SortOptions } from '@ocs/ocs-components';
import { shallowEqual } from 'fast-equals';
import {
  actions,
  afterMount,
  kea,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import Dependencies from '@/deps';
import { IDesktopLogic, IObjectViewContainerLogic } from '@/logic/interfaces';
import { IDeviceTableLogic } from '@/logic/interfaces/deviceTable';
import { injectDepsToLogic } from '@/logic/utils';
import { IObjectService } from '@/services/interfaces';
import { MObject } from '@/types/models';
import { TableOrder } from '@/types/models/table';
import { StringKeys } from '@/utility/types';

import { desktopLogic } from './../../desktop/index';
import { getFieldIcon } from './iconDefinitions';
import type { logicType } from './indexType';
import { GetSearchInputs } from './searchRequests';

const logic = kea<logicType>([
  path(['objects', 'table']),
  props(
    {} as {
      deps: {
        desktopLogic: IDesktopLogic;
        objectViewContainerLogic: IObjectViewContainerLogic;
        objectService: IObjectService;
      };
    },
  ),
  actions({
    refresh: true,
    load: true,
    loadSuccess: (objects: MObject.View[]) => ({ objects }),
    loadFailure: true,
    setSortOptions: (sortOptions: SortOptions) => ({ sortOptions }),
    setCurrentObjectIds: (objectIds: string[]) => ({ objectIds }),
    onRowClicked: (rowKey: string) => ({ rowKey }),
    setCurrentPage: (page: number) => ({ page }),
    setRowsPerPage: (rows: number) => ({ rows }),
    setAmountOfRecords: (amount: number) => ({ amount }),
    setPaginator: (value: boolean) => ({ value }),
    setWorkStates: (value: Record<string, MObject.WorkState>) => ({ value }),
    setSearchInput: (value: string) => ({ value }),
  }),
  reducers({
    dataKey: ['objectUuid' as 'objectUuid'],
    columns: [
      [
        { field: 'listName', header: 'table.object.listName' },
        { field: 'listRegion', header: 'table.object.listRegion' },
        //{ field: 'listTypeId', header: 'table.object.listTypeId' },
        { field: 'listCustomer', header: 'table.object.listCustomer' },
        { field: 'listCity', header: 'table.object.listCity' },
        { field: 'listAddress', header: 'table.object.listAddress' },
        {
          field: 'temporaryLastDataDate',
          header: 'table.object.temporaryLastDataDate',
        },
        { field: 'workState', header: 'table.object.workState' },
        { field: 'status', header: 'table.object.status' },
      ] as { field: StringKeys<MObject.TableView>; header: string }[],
    ],
    columnTypes: [
      {
        listRegion: ColumnType.STRING,
        listTypeId: ColumnType.NUMBER,
        listCustomer: ColumnType.STRING,
        listCity: ColumnType.STRING,
        listAddress: ColumnType.STRING,
        listName: ColumnType.STRING,
        temporaryLastDataDate: ColumnType.DATE,
        workState: ColumnType.TAG,
        status: ColumnType.ICON,
      } as {
        [key: string]: ColumnType;
      },
    ],
    columnWidths: [{ workState: '0 0 10rem' }],
    valueLists: [{ status: 'test' }],
    sortableColumns: [
      [
        'listName',
        'listRegion',
        'listAddress',
        'listCity',
        'temporaryLastDataDate',
      ],
    ],
    expand: [
      {
        columns: {
          objectUuid: 'objectUuid',
        },
        logic: IDeviceTableLogic.$,
      },
    ],
    loading: [
      false,
      {
        load: () => true,
        loadSuccess: () => false,
        loadFailure: () => false,
      },
    ],
    error: [
      false,
      {
        load: () => false,
        loadSuccess: () => false,
        loadFailure: () => true,
      },
    ],
    objectIds: [
      [] as string[],
      {
        setCurrentObjectIds: (_, { objectIds }) => objectIds,
      },
    ],
    currentPage: [
      0,
      {
        setCurrentPage: (_, { page }) => page as number,
      },
    ],
    rowsPerPage: [
      25,
      {
        setRowsPerPage: (_, { rows }) => rows as number,
      },
    ],
    amountOfRecords: [
      0,
      {
        setAmountOfRecords: (_, { amount }) => amount as number,
      },
    ],
    sortOptions: [
      undefined as SortOptions | undefined,
      {
        setSortOptions: (_, { sortOptions }) => sortOptions,
      },
    ],
    isPaginatorActive: [
      true,
      {
        setPaginator: (_, { value }) => value,
      },
    ],
    workStates: [
      {} as Record<string, MObject.WorkState>,
      {
        setWorkStates: (_, { value }) => value,
      },
    ],
    headerSearchValue: [
      '',
      {
        setSearchInput: (_, { value }) => value,
      },
    ],
  }),
  selectors(({ props, values }) => ({
    objectViews: [
      (selectors) => [
        selectors.objectIds,
        props.deps.objectViewContainerLogic.selectors.objects,
      ],
      (objectIds, objectViews) => {
        return Object.values(objectViews).filter((objectView) => {
          return objectIds.includes(objectView.objectUuid);
        });
      },
      { resultEqualityCheck: shallowEqual },
    ],
    values: [
      (selectors) => [selectors.objectViews],
      (objectViews) => {
        return objectViews.map((objectView) => {
          const workState: MObject.WorkState | undefined =
            values.workStates[objectView.temp.workStateId];

          const workStateTag = {
            label: workState != undefined ? workState.name : '-----',
            color:
              workState != undefined
                ? workState.color
                  ? workState.color
                  : '#b3b3b3'
                : '#b3b3b3',
          };

          return {
            objectUuid: objectView.objectUuid,
            listRegion: objectView.base.region,
            listTypeId: objectView.base.typeId,
            listCustomer: objectView.base.customer,
            listCity: objectView.base.city,
            listAddress: objectView.base.address,
            listName: objectView.base.name,
            temporaryLastDataDate: { startDate: objectView.temp.lastDataDate },
            workState: workStateTag,
            status: getFieldIcon(objectView.status),
          };
        });
      },
    ],
  })),
  listeners(({ props, values, actions }) => ({
    onRowClicked: ({ rowKey }) => {
      desktopLogic.actions.openWindow('objectWindow', {
        initialData: { objectUuid: rowKey },
        windowKey: rowKey,
        title:
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          props.deps.objectViewContainerLogic.values.objects[rowKey].base.name!,
      });
    },
    refresh: async () => {
      actions.load();
    },
    load: async () => {
      let input = {
        count: {
          count: values.rowsPerPage,
          page: values.currentPage,
        },
        searchAll: [],
        searchAny: GetSearchInputs(values.headerSearchValue),
      };

      if (values.sortOptions != undefined) {
        input = Object.assign(input, {
          sort: {
            order:
              values.sortOptions.order == 1 ? TableOrder.Asc : TableOrder.Desc,
            column: values.sortOptions.field,
          },
        });
      }

      const objectsResponse = await props.deps.objectService.getList(input);
      const workStates = await props.deps.objectService.getWorkStates();

      if (objectsResponse.success && workStates.success) {
        const workStatesMap: Record<string, MObject.WorkState> = {};

        workStates.data.workStates.forEach((workState) => {
          workStatesMap[workState.workStateId] = {
            workStateId: workState.workStateId,
            name: workState.name,
            color: workState.color,
          };
        });

        actions.setWorkStates(workStatesMap);
        actions.setAmountOfRecords(objectsResponse.data.total);
        actions.loadSuccess(objectsResponse.data.objects);
      } else {
        actions.loadFailure();
      }
    },
    loadSuccess: async ({ objects }) => {
      const objectIds = objects.map((object) => object.objectUuid);
      props.deps.objectViewContainerLogic.actions.releaseObjects(
        values.objectIds,
      );
      props.deps.objectViewContainerLogic.actions.addObjects(objects);
      props.deps.objectViewContainerLogic.actions.useObjects(objectIds);
      actions.setCurrentObjectIds(objectIds);
    },
  })),
  afterMount(({ actions }) => {
    actions.refresh();
  }),
]);

export const objectTableLogic = injectDepsToLogic(logic, () => ({
  desktopLogic: Dependencies.get(IDesktopLogic.$),
  objectViewContainerLogic: Dependencies.get(IObjectViewContainerLogic.$),
  objectService: Dependencies.get(IObjectService.$),
}));
