import { IEventService } from '@/services/interfaces';
import { EventLevel } from '@/types/models/event';

export async function loadEventLevels(
  service: IEventService,
): Promise<Record<number, EventLevel> | null> {
  const response = await service.getEventLevelsList({
    searchAll: [],
    searchAny: [],
  });
  const responseLevels: Record<number, EventLevel> = {};

  if (response.success) {
    for (const level of response.data.eventLevels) {
      responseLevels[level.eventLevelId] = level;
    }
    return responseLevels;
  }

  return null;
}
