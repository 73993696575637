import { CompositeDate, Icon, Tag } from '@/types/filter';

import { CompositeBool } from './filter';

export enum ColumnType {
  NUMBER,
  STRING,
  BOOL,
  BINARY,
  ICON,
  TAG,
  DATE,
  COLOR,
}

export interface SortOptions {
  field: string;
  order: -1 | 0 | 1;
}

export interface TableValue {
  [key: string]:
    | string
    | number
    | boolean
    | CompositeDate
    | CompositeBool
    | Icon
    | Tag
    | null
    | undefined;
}

export const ColumnClassnames: { [key in ColumnType]: string } = {
  [ColumnType.NUMBER]: 'table-number-column',
  [ColumnType.STRING]: 'table-string-column',
  [ColumnType.BOOL]: 'table-bool-column',
  [ColumnType.BINARY]: 'table-binary-column',
  [ColumnType.ICON]: 'table-icon-column',
  [ColumnType.TAG]: 'table-tag-column',
  [ColumnType.DATE]: 'table-date-column',
  [ColumnType.COLOR]: 'table-color-column',
};

export const ColumnBasicWidths: {
  [key in ColumnType]: {
    flex: string;
    flexBasis: number;
    expanded: boolean;
  };
} = {
  [ColumnType.STRING]: { flex: '1 0', flexBasis: 11, expanded: false },
  [ColumnType.NUMBER]: { flex: '1 0', flexBasis: 11, expanded: false },
  [ColumnType.BOOL]: { flex: '0 0', flexBasis: 5, expanded: true },
  [ColumnType.BINARY]: { flex: '0 0', flexBasis: 10, expanded: false },
  [ColumnType.ICON]: { flex: '0 0', flexBasis: 5, expanded: true },
  [ColumnType.TAG]: { flex: '0 0', flexBasis: 10, expanded: false },
  [ColumnType.DATE]: { flex: '0 0', flexBasis: 15, expanded: false },
  [ColumnType.COLOR]: { flex: '0 0', flexBasis: 5, expanded: false },
};

export interface ColumnDefinition {
  field: string;
  header: string;
}
