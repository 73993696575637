import { interfaces } from "inversify";
import { Logic, LogicWrapper } from "kea";

export interface IAppLogicProps extends Record<string, unknown> {}

interface IApp extends Logic {
  readonly props: IAppLogicProps;
  readonly actions: {
    readonly setAlarmsVisibility: (value: boolean) => void;
  };
  readonly values: {
    readonly alarmsVisible: boolean;
  };
}

export interface IAppLogic extends LogicWrapper<IApp> {}

export const IAppLogicSymbol: interfaces.ServiceIdentifier<IAppLogic> =
  Symbol("IAppLogic");

export namespace IAppLogic {
  export const $: interfaces.ServiceIdentifier<IAppLogic> = IAppLogicSymbol;
}
