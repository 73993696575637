import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useMountedLogic, useValues } from 'kea';
import { Checkbox } from 'primereact/checkbox';
import { FC, useState } from 'react';

import { IEditorLogic } from '@/logic/interfaces/editor';
import { IEditorFieldLogic } from '@/logic/interfaces/editorField';
import { EditorFieldKey } from '@/types/custom/editorTypes';

import './../style.scss';

interface BooleanEditorProps {
  name: string;
  editorLogic: IEditorLogic;
  editorKey: EditorFieldKey;
}

export const BooleanEditor: FC<BooleanEditorProps> = (props) => {
  const editorLogic = useMountedLogic(props.editorLogic);

  const fieldLogic = useInjection(IEditorFieldLogic.$)({
    editorLogic: editorLogic as BuiltLogic<IEditorLogic>,
    editorKey: props.editorKey,
  });

  const { updateValue } = useActions(fieldLogic);
  const { value } = useValues(fieldLogic);

  return (
    <div className="OCS-boolean-editor">
      <label className="OCS-editor-value">{props.name}</label>
      <Checkbox
        checked={value}
        onChange={(e) => updateValue({ value: e.checked })}
        className="OCS-editor-input"
      />
    </div>
  );
};
