import { useInjection } from 'inversify-react';
import { useMountedLogic, useValues } from 'kea';
import { FC, useMemo } from 'react';

import { IVisElementLogic } from '@/logic/interfaces/visualization/visElement';
import {
  Dynamization,
  LineDirection,
  LineStyle,
  LineType,
} from '@/types/visualization';
import { GetRgbString } from '@/utils/colorUtils';
import { GetDynamicProps } from '@/utils/visualizationUtils';

import { visualizationContextLogic } from '.';

interface LineProps {
  line: LineType;
}

export const LineVisualization: FC<LineProps> = (props) => {
  const visLogic = useMountedLogic(visualizationContextLogic);

  const visElementLogic = useInjection(IVisElementLogic.$)({
    sourceUuid: visLogic.props.sourceUuid,
    sourceType: visLogic.props.sourceType,
    visualizationUuid: visLogic.props.visualizationUuid,
    name: visLogic.props.name,
    elementUuid: props.line.name,
    tasks: props.line.tasks,
  });

  const { values } = useValues(visElementLogic);

  const dynamicProps = useMemo(() => {
    if (values != null && values[0])
      return GetDynamicProps(props.line, values[0]) as LineType;
    else return props.line;
  }, [props.line]);

  if (
    dynamicProps.dynamization === Dynamization.Visibility &&
    values != null &&
    values[0] == 0
  )
    return <></>;

  return (
    <svg
      height={dynamicProps.height}
      width={dynamicProps.width}
      style={{
        position: 'absolute',
        top: dynamicProps.top,
        left: dynamicProps.left,
        visibility: dynamicProps.hidden ? 'hidden' : 'visible',
      }}
    >
      {GetDirection(props.line)}
    </svg>
  );
};

function GetDirection(line: LineType) {
  let x1, x2, y1, y2, lineWidth;
  switch (line.direction) {
    case LineDirection.Left:
      x1 = 0;
      y1 = 0;
      x2 = 0;
      y2 = line.height;
      lineWidth = line.lineWidth;
      break;

    case LineDirection.Top:
      x1 = 0;
      y1 = 0;
      x2 = line.width;
      y2 = 0;
      lineWidth = line.lineWidth;
      break;

    case LineDirection.Bottom:
      x1 = 0;
      y1 = line.height;
      x2 = line.width;
      y2 = line.height;
      lineWidth = line.lineWidth;
      break;

    case LineDirection.Right:
      x1 = line.width;
      y1 = 0;
      x2 = line.width;
      y2 = line.height;
      lineWidth = line.lineWidth;
      break;

    case LineDirection.DiagonalUpLeftDownRight:
      x1 = 0;
      y1 = line.height;
      x2 = line.width;
      y2 = 0;
      lineWidth = line.lineWidth - 0.65;
      break;

    case LineDirection.DiagonalUpRightDownLeft:
      x1 = line.width;
      y1 = 0;
      x2 = 0;
      y2 = line.height;
      lineWidth = line.lineWidth - 0.65;
      break;
  }

  return (
    <line
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      stroke={GetRgbString(line.color.red, line.color.green, line.color.blue)}
      strokeWidth={lineWidth}
      strokeDasharray={GetLineStyle(line.style)}
    />
  );
}

function GetLineStyle(style: LineStyle) {
  switch (style) {
    case LineStyle.Continuous:
      return '';
    case LineStyle.Hatched:
      return '4 1';
    case LineStyle.DotDash:
      return '5 5 1 5';
    case LineStyle.DotDotDash:
      return '5 5 1 5 1 5';
  }
}
