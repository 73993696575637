import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Sidebar } from 'primereact/sidebar';
import { Toast, ToastMessage } from 'primereact/toast';
import { FC, useEffect, useRef, useState } from 'react';
import { CgPlayListCheck, CgPlayListRemove } from 'react-icons/cg';

import { ConfirmDialog } from '@/components/confirmDialog';
import { EventViewContent } from '@/components/events/content';
import { useStorybookTranslation } from '@/i18next';
import { IAppLogic } from '@/logic/interfaces/app';
import { IAlarmEventsLogic } from '@/logic/interfaces/event/alarmEvents';
import { darkenColor, isColorDark } from '@/utils/colorUtils';

import { EventViewContainer } from '../container';
import { EventConfirmDialog } from '../dialog';
import './style.scss';

interface EventListProps {
  className?: string;
  maskClassName?: string;
  appendTo?: 'self' | HTMLElement | undefined | null;
}

export const EventList: FC<EventListProps> = (props) => {
  const alarmLogic = useInjection(IAlarmEventsLogic.$);
  const appLogic = useInjection(IAppLogic.$);

  const { setMuted, setDisplayed, confirmAll, removeAll } =
    useActions(alarmLogic);
  const { setAlarmsVisibility } = useActions(appLogic);

  const { isMuted, alarmEvents, alarmEventsToDisplay, isSending } =
    useValues(alarmLogic);
  const { alarmsVisible } = useValues(appLogic);

  const [chosenEventID, chooseEventID] = useState<number | null>(null);
  const toastRef = useRef<Toast>(null);

  const t = useStorybookTranslation();

  const dispatcher = async (messages: ToastMessage[]) => {
    for (const message of messages) {
      await new Promise((r) => setTimeout(r, 250));
      toastRef.current?.show(message);
      await new Promise((r) => setTimeout(r, 250));
    }
  };

  useEffect(() => {
    if (!isMuted && !alarmsVisible) {
      const displayedEvents: string[] = [];
      const messages: ToastMessage[] = [];

      for (const alarmEventView of alarmEventsToDisplay) {
        const ratio = isColorDark(alarmEventView.color) ? 2 : 0.5;

        const borderColor = darkenColor(alarmEventView.color, ratio);
        const textColor = darkenColor(borderColor, ratio);
        messages.push({
          className: 'p-ripple',
          content: (
            <EventViewContent
              event={alarmEventView}
              ripple={true}
              disableSelection={true}
              boxSize="o-box-none"
              textSize="o-text-lg"
            />
          ),
          life: 5000,
          style: {
            backgroundColor: alarmEventView.color,
            color: textColor,
            border: `solid ${borderColor}`,
            borderWidth: '0 0 0 6px',
          },
        });

        displayedEvents.push(alarmEventView.id.toString());
      }
      setDisplayed(displayedEvents);
      dispatcher(messages);
    } else {
      setDisplayed(
        alarmEventsToDisplay.map((alarmEventView) =>
          alarmEventView.id.toString(),
        ),
      );
    }
  }, [alarmEventsToDisplay]);

  useEffect(() => {
    if (alarmsVisible) {
      toastRef.current?.clear();
    }
  }, [alarmsVisible]);

  return (
    <>
      <Toast
        appendTo={props.appendTo}
        className="event-list-toast"
        ref={toastRef}
        onClick={(message) => {
          try {
            // @ts-ignore
            chooseEventID(message.content?.props.event.id);
          } catch (error) {
            console.log('ToastError no id');
          }
        }}
      />
      <Sidebar
        className="event-list-sidebar"
        style={{ width: '23em' }}
        position="right"
        visible={alarmsVisible}
        appendTo={props.appendTo}
        onHide={() => {
          setAlarmsVisibility(false);
        }}
        icons={() => (
          <>
            <EventListTitleBar />
            <MuteButton isMuted={isMuted} setMute={setMuted} />
            <RemoveAllButton onClick={removeAll} />
            <ConfirmAllButton onClick={confirmAll} closable={isSending} />
          </>
        )}
      >
        {alarmEvents.map((currentElement, index) => {
          return (
            <EventViewContainer
              className={'event-list-element'}
              key={`eventView${index}`}
              event={currentElement}
              ripple={true}
              disableSelection={true}
              backgroundOnClick={() => {
                chooseEventID(currentElement.id);
              }}
              closable={false}
              boxSize="o-box-sm"
              textSize="o-text-sm"
            />
          );
        })}
      </Sidebar>
      {chosenEventID != null ? (
        <EventConfirmDialog
          id={chosenEventID}
          onHide={() => {
            chooseEventID(null);
          }}
          visible={chosenEventID != null ? true : false}
          dialogTitle={t('eventList.eventDialog.alarmEvent')}
          inputPlaceholder={t('eventList.eventDialog.alarmInput')}
          confirmLabel={t('eventList.eventDialog.alarmAccept')}
          errorLabel={t('eventList.eventDialog.alarmError')}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

const MuteButton: FC<{
  isMuted: boolean;
  setMute: (value: boolean) => void;
}> = (props) => {
  return (
    <button
      className="p-link p-sidebar-close"
      onClick={() => {
        props.setMute(!props.isMuted);
      }}
    >
      <span
        className={`p-sidebar-close-icon event-list-button-icon pi ${
          props.isMuted ? 'ri-notification-off-line' : 'ri-notification-4-line'
        }`}
      ></span>
    </button>
  );
};

const ConfirmAllButton: FC<{
  onClick: (confirmMessage: string) => void;
  closable: boolean;
}> = (props) => {
  const t = useStorybookTranslation();

  const [dialogVisible, setDialogVisibility] = useState(false);
  const [confirmPressed, setConfirm] = useState(false);

  useEffect(() => {
    if (props.closable === true && confirmPressed === true) {
      setDialogVisibility(false);
    }
  }, [props.closable, dialogVisible]);

  return (
    <>
      <Button
        className="p-link p-sidebar-close"
        onClick={() => {
          setDialogVisibility(true);
        }}
        icon={
          <CgPlayListCheck
            size={'1.5rem'}
            className={'ocs-confirm-button-alignment'}
          />
        }
      />
      <ConfirmDialog
        visible={dialogVisible}
        details={t('eventList.confirmAll.details')}
        dialogTitle={t('eventList.confirmAll.dialogTitle')}
        inputPlaceholder={t('eventList.confirmAll.inputPlaceholder')}
        confirmLabel={t('eventList.confirmAll.confirmLabel')}
        closable={props.closable}
        onConfirm={async (confirmMessage) => {
          props.onClick(confirmMessage);
          setConfirm(true);
        }}
        onHide={() => {
          setDialogVisibility(false);
        }}
        width={25}
      />
    </>
  );
};

const RemoveAllButton: FC<{
  onClick: () => void;
}> = (props) => {
  const t = useStorybookTranslation();

  return (
    <>
      <Button
        className="p-link p-sidebar-close"
        onClick={(event) => {
          confirmPopup({
            target: event.currentTarget,
            message: t('eventList.remove'),
            icon: 'ri ri-error-warning-line',
            accept: props.onClick,
            acceptLabel: t('eventList.removeAccept'),
            rejectLabel: t('eventList.removeReject'),
          });
        }}
        icon={
          <CgPlayListRemove
            size={'1.5rem'}
            className={'ocs-remove-button-alignment'}
          />
        }
      />
      <ConfirmPopup />
    </>
  );
};

const EventListTitleBar: FC = () => {
  const t = useStorybookTranslation();

  return (
    <span className="event-list-title-bar">{t('eventList.notifications')}</span>
  );
};
