import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { MDevice } from '@/types/models';

export interface IDeviceService {
  get: (
    input: MDevice.Messages.GetIn,
  ) => Promise<Response<MDevice.Messages.GetOut>>;
  getByVisalizationAlias: (
    input: MDevice.Messages.GetByVisualizationAliasIn,
  ) => Promise<Response<MDevice.Messages.GetByVisualizationAliasOut>>;
  getList: (
    input: MDevice.Messages.GetListIn,
  ) => Promise<Response<MDevice.Messages.GetListOut>>;
  getSlots: (
    input: MDevice.Messages.GetSlotsIn,
  ) => Promise<Response<MDevice.Messages.GetSlotsOut>>;
  assignSlot: (input: MDevice.Messages.AssignSlotIn) => Promise<Response<void>>;
  updateVisualizationAlias: (
    input: MDevice.Messages.UpdateVisualizationAliasIn,
  ) => Promise<Response<void>>;
}
export namespace IDeviceService {
  export const $: interfaces.ServiceIdentifier<IDeviceService> =
    Symbol('IDeviceService');
}
