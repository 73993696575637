import { shallowEqual } from 'fast-equals';
import { produce } from 'immer';
import {
  actions,
  beforeUnmount,
  events,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';
import { subscriptions } from 'kea-subscriptions';

import Dependencies from '@/deps';
import { IVibroDetectorsViewLogicProps } from '@/logic/interfaces';
import { IDeviceTableLogic } from '@/logic/interfaces/deviceTable';
import { injectDepsToLogic } from '@/logic/utils';
import { IVibrodetectorService } from '@/services/interfaces';
import {
  VibrodetectorGraphLiveInput,
  VibrodetectorGraphMessage,
} from '@/types/messages/vibrodetector';
import { MDevice } from '@/types/models';
import { VibrodetectorData } from '@/types/models/vibrodetector';

import type { logicType } from './indexType';

export interface VibroDetectorsLogicProps
  extends IVibroDetectorsViewLogicProps {
  deps: {
    deviceTableLogic: IDeviceTableLogic;
    vibroDetectorService: IVibrodetectorService;
  };
}

const logic = kea<logicType>([
  props({} as VibroDetectorsLogicProps),
  key((props) => props.objectUuid),
  path((key) => ['vibrodetectorsView', key]),
  actions({
    connectLiveStream: (deviceUuid: string) => ({ deviceUuid }),
    disconnectLiveStream: (deviceUuid: string) => ({ deviceUuid }),
    _connectLiveStream: (deviceUuid: string) => ({ deviceUuid }),
    _disconnectLiveStream: (deviceUuid: string) => ({ deviceUuid }),
    _updateNow: (deviceUuid: string, now: VibrodetectorData) => ({
      deviceUuid,
      now,
    }),
    _disconnectAllLiveStreams: true,
    _loadNow: (devices: MDevice.TableView[] | null) => ({ devices }),
  }),
  reducers({
    now: [
      {} as Record<string, VibrodetectorData>,
      {
        _updateNow: (immutableState, { deviceUuid, now }) =>
          produce(
            immutableState,
            (state: Record<string, VibrodetectorData>) => {
              state[deviceUuid] = now;
            },
          ),
      },
    ],
    liveStreams: [
      [] as string[],
      {
        _connectLiveStream: (state, { deviceUuid }) => [...state, deviceUuid],
        _disconnectLiveStream: (state, { deviceUuid }) =>
          state.filter((s: string) => s != deviceUuid),
      },
    ],
  }),
  listeners(({ props, actions, cache }) => {
    const onMessage = (event: {
      value: VibrodetectorGraphMessage;
      input: VibrodetectorGraphLiveInput;
    }) => {
      const value = event.value;
      const input = event.input;
      const datetime = new Date(value[0][0]);
      const measuringResult = value[1][0];
      actions._updateNow(input.uuid, {
        datetime,
        measuringResult,
      });
    };

    cache.onMessage = onMessage;

    return {
      connectLiveStream: async ({ deviceUuid }) => {
        const onDisconnect = () => {
          actions._disconnectLiveStream(deviceUuid);
        };
        await props.deps.vibroDetectorService.stream.listen(
          {
            onMessage,
            onDisconnect,
          },
          {
            uuid: deviceUuid,
          },
        );
        actions._connectLiveStream(deviceUuid);
      },
      disconnectLiveStream: ({ deviceUuid }) => {
        actions._disconnectLiveStream(deviceUuid);
        props.deps.vibroDetectorService.stream.cancel(onMessage, {
          uuid: deviceUuid,
        });
      },
      _loadNow: ({ devices }) => {
        if (devices !== null) {
          for (const device of devices) {
            props.deps.vibroDetectorService
              .getNow({
                deviceUuid: device.deviceUuid,
              })
              .then((response) => {
                if (response.success) {
                  actions._updateNow(device.deviceUuid, response.data);
                }
              })
              .catch(null);
          }
        }
      },
      _disconnectAllLiveStreams: () => {},
    };
  }),
  selectors(({ props }) => ({
    devices: [
      () => [
        props.deps.deviceTableLogic({ objectUuid: props.objectUuid }).selectors
          .values,
      ],
      (values) => {
        if (values != null) {
          return values.filter(
            (d: MDevice.TableView) => d.listKind == MDevice.Kind.VibroDetector,
          );
        } else {
          return null;
        }
      },
      { resultEqualityCheck: shallowEqual },
    ],
  })),
  subscriptions(({ actions }) => ({
    devices: (value: MDevice.TableView[] | null, _) => actions._loadNow(value),
  })),
  //TODO: this should use kea connect, but kea-typegen can not get over it
  events(({ props }) => ({
    beforeMount: () => {
      props.deps.deviceTableLogic({ objectUuid: props.objectUuid }).mount();
    },
    afterUnmount: () => {
      props.deps.deviceTableLogic({ objectUuid: props.objectUuid }).unmount();
    },
  })),
  beforeUnmount(({ props, cache, values }) => {
    for (const deviceUuid of values.liveStreams) {
      props.deps.vibroDetectorService.stream.cancel(cache.onMessage, {
        uuid: deviceUuid,
      });
    }
  }),
]);

export const vibroDetectorsViewLogic = injectDepsToLogic(logic, () => ({
  deviceTableLogic: Dependencies.get(IDeviceTableLogic.$),
  vibroDetectorService: Dependencies.get(IVibrodetectorService.$),
}));
