import {
  CompositeBool,
  CompositeDate,
  Icon,
  TableValue,
  Tag,
} from '@ocs/ocs-components';

import {
  DefinedAlarmGroupView as GrpcAlarmGroupView,
  DefinedAlarmView as GrpcAlarmView,
} from '@/services/compiled/defined_alarms';
import {
  DefinedAlarmGroupUuid as MDefinedAlarmGroupdUuid,
  MessageDefinedAlarmUuid as MDefinedAlarmUuid,
  GetByVisualizationAliasIn as MGetByVisualizationAliasIn,
  GetByVisualizationAliasOut as MGetByVisualizationAliasOut,
  GetGroupIn as MGetGroupIn,
  GetGroupOut as MGetGroupOut,
  GetListGroupIn as MGetListGroupIn,
  GetListGroupOut as MGetListGroupOut,
  GetListIn as MGetListIn,
  GetListOut as MGetListOut,
  LiveIn as MLiveIn,
  LiveOut as MLiveOut,
  RemoveGroupIn as MRemoveGroupIn,
  StoreGroupIn as MStoreGroupIn,
  StoreGroupOut as MStoreGroupOut,
  StoreIn as MStoreIn,
  StoreOut as MStoreOut,
  UpdateDefinedAlarmAliasIn as MUpdateDefinedAlarmAliasIn,
  UpdateDefinedAlarmGroupAliasIn as MUpdateDefinedAlarmGroupAliasIn,
} from '@/types/messages/definedAlarms';

import { Timestamp } from './../../services/compiled/google/protobuf/timestamp';

export namespace MDefinedAlarms {
  export namespace Messages {
    export type GetListIn = MGetListIn;
    export type GetListOut = MGetListOut;
    export type GetByVisualizationAliasIn = MGetByVisualizationAliasIn;
    export type GetByVisualizationAliasOut = MGetByVisualizationAliasOut;
    export type LiveIn = MLiveIn;
    export type LiveOut = MLiveOut;
    export type StoreIn = MStoreIn;
    export type StoreOut = MStoreOut;
    export type DefinedAlarmUuid = MDefinedAlarmUuid;
    export type StoreGroupIn = MStoreGroupIn;
    export type StoreGroupOut = MStoreGroupOut;
    export type UpdateDefinedAlarmAliasIn = MUpdateDefinedAlarmAliasIn;
    export type UpdateDefinedAlarmGroupAliasIn =
      MUpdateDefinedAlarmGroupAliasIn;
    export type GetListGroupIn = MGetListGroupIn;
    export type GetListGroupOut = MGetListGroupOut;
    export type GetGroupIn = MGetGroupIn;
    export type GetGroupOut = MGetGroupOut;
    export type DefinedAlarmGroupUuid = MDefinedAlarmGroupdUuid;
    export type RemoveGroupIn = MRemoveGroupIn;
  }

  export const TableViewSortKeys: (keyof GrpcAlarmView)[] = [
    'definedAlarmUuid',
    'deviceUuid',
    'objectUuid',
    'definedAlarmGroupUuid',
    'eventLevelId',
    'listDescription',
    'listColor',
    'temporaryState',
    'temporaryStateDatetime',
  ];

  export type TableViewSortKeys = keyof GrpcAlarmView;

  export interface TableView extends TableValue {
    definedAlarmUuid: string;
    deviceUuid: string;
    objectUuid: string;
    definedAlarmGroupUuid: string;
    eventLevelId?: Tag;
    dataDescription?: string;
    dataColor?: string;
    temporaryState?: Icon;
    temporaryStateDateTime?: CompositeDate;
    isActive: CompositeBool;
  }

  export interface View {
    definedAlarmUuid: string;
    isActive: boolean;
    identifier: Identifier;
    data: Data;
    temporary: Temporary;
  }

  export interface Identifier {
    deviceUuid: string;
    objectUuid?: string;
    definedAlarmGroupUuid?: string;
  }

  export interface Data {
    eventLevelId: number;
    description: string;
    color?: string;
  }

  export interface Temporary {
    state: number;
    stateDateTime?: Date;
  }

  export interface Settings {
    priority: number;
    active: boolean;
    sound: boolean;
    col: string;
    rule: Rule;
    value: string;
    math?: Math;
    mathCol?: string;
    onDelay?: number;
    offDelay?: number;
    round?: number;
    deviceUuid: string;
    mathDeviceUuid?: string;
    workStateId?: number;
    definedAlarmGroupUuid?: string;
  }

  //Alarm Groups

  export const TableGroupViewSortKeys: (keyof GrpcAlarmGroupView)[] = [
    'definedAlarmGroupUuid',
    'eventLevelId',
    'objectUuid',
    'listDescription',
    'listColor',
    'groupsTemporaryState',
    'groupsTemporaryStateDatetime',
  ];

  export type TableGroupViewSortKeys = keyof GrpcAlarmGroupView;

  export interface TableGroupView extends TableValue {
    definedAlarmGroupUuid: string;
    objectUuid: string;
    eventLevelId: Tag;
    listDescription: string;
    listColor?: string;
    groupsTemporaryState: Icon;
    groupsTemporaryStateDateTime?: CompositeDate;
  }

  export interface Groups {
    rule: number;
    color?: string;
    description: string;
    visualizationAlias: string;
  }

  export interface TemporaryGroups {
    state: number;
    stateDateTime?: Date;
    currentLogsEventId?: number;
  }

  export enum Rule {
    Equal = 0,
    NotEqual = 1,
    Less = 2,
    LessOrEqual = 3,
    Greater = 4,
    GreaterOrEqual = 5,
    Change = 6,
    NoChange = 7,
  }

  export enum Math {
    Add = 0,
    Sub = 1,
    Mul = 2,
    Div = 3,
    Modulo = 4,
  }
  export interface GroupView {
    definedAlarmGroupUuid: string;
    eventLevelId: number;
    objectUuid: string;
    data: GroupViewData;
    temporary: GroupTemporary;
  }

  export interface GroupViewData {
    description: string;
    color?: string;
  }

  export interface GroupTemporary {
    temporaryState: State;
    temporaryStateDateTime?: Date;
  }

  export enum State {
    Off = 0,
    PendingOnToOff = 1,
    ConfirmedPendingOnToOff = 5,
    PendingOffToOn = 2,
    On = 3,
    ConfirmedOn = 4,
    Error = 99,
  }

  export interface GroupData {
    description: string;
    color?: string;
    rule: GroupRule;
    eventLevelId: number;
    objectUuid: string;
  }

  export enum GroupRule {
    And = 0,
    Or = 1,
  }
}
