import { EventLink } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { memo } from 'react';
import { useThrottle } from 'react-use';

import {
  IDesktopLogic,
  IObjectWindowLogic,
  IVibroDetectorsViewLogic,
} from '@/logic/interfaces';
import { MDevice } from '@/types/models';
import { VibrodetectorData } from '@/types/models/vibrodetector';

export const VibroDetectorsView = memo(function VibroDetectorsView() {
  const viewLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(viewLogic);

  const vibrodetectorsViewLogic = useInjection(IVibroDetectorsViewLogic.$)({
    objectUuid,
  });

  const { devices, liveStreams, now } = useValues(vibrodetectorsViewLogic);
  const { connectLiveStream, disconnectLiveStream } = useActions(
    vibrodetectorsViewLogic,
  );

  const nowThrootled = useThrottle(now, 100);

  return (
    <div className="grid object-grid">
      {devices?.map(function (detector, _) {
        const live = liveStreams.includes(detector.deviceUuid);
        const detectorNow = nowThrootled[detector.deviceUuid];

        return (
          <VibroDetectorView
            key={detector.deviceUuid}
            detector={detector}
            live={live}
            detectorNow={detectorNow}
            connectLiveStream={connectLiveStream}
            disconnectLiveStream={disconnectLiveStream}
          ></VibroDetectorView>
        );
      })}
    </div>
  );
});

interface VibroDetectorViewProps {
  detector: MDevice.TableView;
  live: boolean;
  detectorNow: VibrodetectorData | null;
  connectLiveStream: (deviceUuid: string) => void;
  disconnectLiveStream: (deviceUuid: string) => void;
}

export const VibroDetectorView = memo(function VibroDetectorView(
  props: VibroDetectorViewProps,
) {
  const desktopLogic = useInjection(IDesktopLogic.$);

  const { openWindow } = useActions(desktopLogic);

  const subTitle = (
    <div className="object-view-card-title">
      <EventLink
        details={props.detector.listName}
        detailsOnClick={() => {
          openWindow('vibrodetectorChart', {
            initialData: { deviceUuid: props.detector.deviceUuid },
            windowKey: props.detector.deviceUuid,
            title: props.detector.listName,
          });
        }}
      ></EventLink>
      <Button
        className={`p-button-text p-button-sm vibrodetector-chart-toggle-live-button ${
          props.live
            ? 'live-active p-button-danger'
            : 'live-disabled p-button-success'
        }`}
        onClick={() => {
          if (!props.live) {
            props.connectLiveStream(props.detector.deviceUuid);
          } else {
            props.disconnectLiveStream(props.detector.deviceUuid);
          }
        }}
        icon={props.live ? 'ri-stop-fill' : 'ri-play-fill'}
      />
    </div>
  );
  return (
    <div key={props.detector.deviceUuid} className="col-2">
      <Card subTitle={subTitle}>
        <span className="object-view-card-input p-float-label p-input-icon-right">
          <InputText
            className="p-inputtext-sm block mb-2"
            id={props.detector.deviceUuid + 'date'}
            value={
              props.detectorNow != null
                ? props.detectorNow.datetime
                    .toLocaleString('en-GB')
                    .replace(',', '') +
                  '.' +
                  props.detectorNow.datetime
                    .getMilliseconds()
                    .toString()
                    .padStart(3, '0')
                : '---'
            }
            readOnly={true}
          />
          <label htmlFor={props.detector.deviceUuid + 'date'}>Data</label>
        </span>
        <br />
        <br />
        <span className="object-view-card-input p-float-label p-input-icon-right">
          <span className="p-input-suffix">[mm/s]</span>
          <InputText
            className="p-inputtext-sm block mb-2"
            id={props.detector.deviceUuid + 'value'}
            value={
              props.detectorNow != null
                ? props.detectorNow.measuringResult.toFixed(3)
                : '---'
            }
            readOnly={true}
          />
          <label htmlFor={props.detector.deviceUuid + 'value'}>Pomiar</label>
        </span>
      </Card>
    </div>
  );
});
