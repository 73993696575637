import { FC, useMemo } from 'react';

import { useStorybookTranslation } from '@/i18next';
import { darkenColor } from '@/utils/colorUtils';

import './style.scss';

interface TagProps {
  icon?: string;
  label: string;
  color: string;
  darkenBackground?: boolean;
  className?: string;
}

export const Tag: FC<TagProps> = (props) => {
  const t = useStorybookTranslation();

  const labelColor = useMemo(
    () => darkenColor(props.color, 0.35),
    [props.color],
  );

  return (
    <span
      className={`ocs-tag-container ${props.className}`}
      style={{ backgroundColor: props.color }}
    >
      {props.icon ? (
        <i
          className={`ocs-tag-icon ${props.icon ? props.icon : ''}`}
          style={{ color: labelColor }}
        />
      ) : (
        <></>
      )}
      <span className="ocs-tag-label" style={{ color: labelColor }}>
        {t(props.label)}
      </span>
    </span>
  );
};
