/* eslint-disable react/display-name */
import { Ripple } from 'primereact/ripple';
import { FC, memo } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from '@/hooks';
import { useSettingsContext } from '@/modules/settings';

import { Item } from '../../../modules/settings/model';
import './style.scss';

export interface SidebarItemProps extends Item {}

export const SidebarItem: FC<SidebarItemProps> = memo((props) => {
  const settingsContext = useSettingsContext();

  let itemActiveClass = 'sidebar-item p-ripple';
  if (settingsContext.view === props.view)
    itemActiveClass += ' sidebar-item-active';

  const t = useTranslation();

  return (
    <div
      className={itemActiveClass}
      onClick={() => {
        settingsContext.setView(props.view);
      }}
    >
      <i className={`sidebar-item-icon pi pi-fw ${props.icon}`} />
      <span className="sidebar-item-title">{t(props.title)}</span>
      <Ripple />
    </div>
  );
});
