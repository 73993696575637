import { IPaginationLogic } from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useValues } from 'kea';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from '@/hooks';

import './style.scss';

interface PaginatorProps {
  logicIdentifier?: interfaces.ServiceIdentifier<IPaginationLogic>;
  logic?: IPaginationLogic | BuiltLogic<IPaginationLogic>;
  isDefinedAlarms?: boolean;
}

export const FooterPaginator: FC<PaginatorProps> = (props) => {
  const [visibleRecords, setVisibleRecords] = useState(0);
  let paginationLogic: IPaginationLogic | null = null;

  if (props.logicIdentifier !== undefined) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    paginationLogic = useInjection(props.logicIdentifier);
  } else if (props.logic !== undefined) {
    paginationLogic = props.logic;
  } else {
    throw 'Verify if pagination logic has been passed correctly';
  }

  const { setCurrentPage, refresh } = useActions(paginationLogic);
  const { rowsPerPage, amountOfRecords, isPaginatorActive } =
    useValues(paginationLogic);

  if (props.isDefinedAlarms == undefined && !isPaginatorActive) return null;

  return (
    <div className={`footer-paginator-container`}>
      <Paginator
        first={visibleRecords}
        rows={rowsPerPage}
        totalRecords={amountOfRecords}
        onPageChange={(e) => {
          setVisibleRecords(e.first);
          setCurrentPage(e.page);
          refresh();
        }}
      />
    </div>
  );
};

export const PaginatorDropdown: FC<PaginatorProps> = (props) => {
  let paginationLogic: IPaginationLogic | null = null;

  if (props.logicIdentifier !== undefined) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    paginationLogic = useInjection(props.logicIdentifier);
  } else if (props.logic !== undefined) {
    paginationLogic = props.logic;
  } else {
    throw 'Verify if pagination logic has been passed correctly';
  }

  const t = useTranslation();

  const { setRowsPerPage, refresh } = useActions(paginationLogic);
  const { currentPage, amountOfRecords, rowsPerPage, isPaginatorActive } =
    useValues(paginationLogic);

  const groupedOptions = [
    {
      groupLabel: t('toolbar.paginatorDropdownElements'),
      code: 'el',
      items: [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
      ],
    },
  ];

  if (props.isDefinedAlarms == undefined && !isPaginatorActive) return null;

  return (
    <Dropdown
      className="paginator-dropdown"
      panelClassName="panel-paginator-dropdown"
      value={rowsPerPage}
      valueTemplate={
        <ValueTemplate
          currentRecords={rowsPerPage}
          currentPage={currentPage}
          amountOfRecords={amountOfRecords}
        />
      }
      onChange={(e) => {
        if (typeof e.value === 'number') setRowsPerPage(e.value as number);
        refresh();
      }}
      options={groupedOptions}
      optionLabel="label"
      optionGroupLabel="groupLabel"
      optionGroupChildren="items"
    />
  );
};

interface ValueTemplateProps {
  currentRecords: number;
  currentPage: number;
  amountOfRecords: number;
}

const ValueTemplate: FC<ValueTemplateProps> = (props) => {
  const baseValue = useMemo(() => {
    return props.currentPage * props.currentRecords;
  }, [props.currentPage, props.currentRecords]);

  return (
    <span className="OCS-paginator-value-template">{`${baseValue + 1}-${
      baseValue + props.currentRecords
    } / ${props.amountOfRecords}`}</span>
  );
};
