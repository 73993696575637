import { Icon } from '@ocs/ocs-components';

export const definedAlarmsIcons: Record<number, Icon> = {
  0: {
    icon: 'ri ri-notification-off-fill',
    name: 'table.definedAlarms.icons.off',
    color: '',
  },
  1: {
    icon: 'ri ri-rest-time-line',
    name: 'table.definedAlarms.icons.pendingOff',
    color: '#3FA1E3',
  },
  2: {
    icon: 'ri-timer-flash-line',
    name: 'table.definedAlarms.icons.pendingOn',
    color: '#3FA1E3',
  },
  3: {
    icon: 'ri ri-notification-4-fill',
    name: 'table.definedAlarms.icons.on',
    color: '#cc2e3a',
  },
  4: {
    icon: 'ri ri-notification-off-fill',
    name: 'table.definedAlarms.icons.off',
    color: '',
  },
  5: {
    icon: 'ri ri-notification-off-fill',
    name: 'table.definedAlarms.icons.off',
    color: '',
  },
  99: {
    icon: 'ri ri-error-warning-fill',
    name: 'table.definedAlarms.icons.error',
    color: '#E74C3C',
  },
};
