import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import {
  ConfirmStatus,
  EditorTile,
  EditorValue,
  LoadingStatus,
} from '@/types/custom/editorTypes';

export interface IVibrodetectorEditorProps extends Record<string, unknown> {
  initialData: {
    deviceUuid: string;
  };
}

interface IVibrodetectorEditor extends Logic {
  readonly props: IVibrodetectorEditorProps;
  readonly actions: {
    readonly updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: any,
    ) => void;
    readonly confirm: () => void;
    readonly setConfirmStatus: (value: ConfirmStatus) => void;
    readonly refresh: () => void;
  };
  readonly values: {
    readonly editorUuid: string;
    readonly translationPrefix: string;
    readonly tileLayout: Record<string, EditorTile>;
    readonly isSaving: boolean;
    readonly confirmStatus: ConfirmStatus;
    readonly loadStatus: LoadingStatus;
  };
  readonly selectors: {
    readonly value: (
      value: unknown,
    ) => Record<string, Record<string, Record<string, EditorValue>>>;
  };
}

export interface IVibrodetectorEditorLogic
  extends LogicWrapper<IVibrodetectorEditor> {}

export namespace IVibrodetectorEditorLogic {
  export const $: interfaces.ServiceIdentifier<IVibrodetectorEditorLogic> =
    Symbol('IVibrodetectorEditorLogic');
}
