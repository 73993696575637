import { current } from 'immer';
import { merge } from 'lodash';

import { config } from '@/config';
import {
  Desktop,
  Layout,
  Window,
  WindowGeometry,
} from '@/types/models/desktop';
import { RecursivePartial } from '@/utility/types';

export const createLayout = (state: Desktop, name: string, id: number) => {
  state.layouts[id] = {
    name: name,
    windows: {},
  };
  return state;
};

export const updateCurrentLayout = (
  state: Desktop,
  reducer: (currentLayout: Layout) => void,
) => {
  if (
    state.currentLayoutId !== undefined &&
    state.currentLayoutId in state.layouts
  ) {
    const currentLayout = state.layouts[state.currentLayoutId];
    reducer(currentLayout);
  }
};

export const createWindow = (
  state: Desktop,
  windowKey: string,
  windowData: Window,
) => {
  updateCurrentLayout(state, (currentLayout) => {
    if (!(windowKey in currentLayout.windows)) {
      currentLayout.windows[windowData.type + '_' + windowKey] = windowData;
    }
  });
};

export const removeWindow = (state: Desktop, windowKey: string) => {
  updateCurrentLayout(state, (currentLayout) => {
    if (windowKey in currentLayout.windows) {
      delete currentLayout.windows[windowKey];
    }
  });
};

export const updateWindow = (
  state: Desktop,
  windowKey: string,
  reducer: (window: Window) => void,
) => {
  updateCurrentLayout(state, (currentLaout) => {
    if (windowKey in currentLaout.windows) {
      const window = currentLaout.windows[windowKey];
      reducer(window);
    }
  });
};

export const updateWindowGeometry = (
  state: Desktop,
  windowKey: string,
  geometry: RecursivePartial<WindowGeometry>,
) => {
  updateWindow(state, windowKey, (window) => {
    window.geometry = merge({}, window.geometry, geometry);
  });
};

export const updateMultipleGeometries = (
  state: Desktop,
  geometries: { [id: string]: RecursivePartial<WindowGeometry> },
) => {
  for (const windowKey in geometries) {
    updateWindowGeometry(state, windowKey, geometries[windowKey]);
  }
};

export const focusWindow = (state: Desktop, windowKey: string) => {
  if (state.lastFocus === null || Date.now() - state.lastFocus > 100) {
    state.lastFocus = Date.now();
    updateCurrentLayout(state, (currentLayout) => {
      if (windowKey in currentLayout.windows) {
        const window = currentLayout.windows[windowKey];
        for (const key in currentLayout.windows) {
          const myWindow = currentLayout.windows[key];
          if (myWindow.geometry.zIndex < window.geometry.zIndex) {
            myWindow.geometry.zIndex += 1;
          }
        }
        for (const key in currentLayout.windows) {
          const myWindow = currentLayout.windows[key];
          myWindow.geometry.zIndex -= 1;
        }
        window.geometry.zIndex = config.desktop.maxZIndex;
      } else {
        console.error(
          'missing windowKey in currentLayout',
          windowKey,
          current(currentLayout),
        );
      }
    });
  } else {
    console.warn('debounced focusWindows', current(state));
  }
};
