import { MDefinedAlarms } from '@/types/models/definedAlarms';
import { TableSearch, TableSearchRule } from '@/types/models/table';
import { composeRequestInput } from '@/utility/inputRequest';

export function getSearchInputs(value: string) {
  return [
    {
      column: 'listDescription' as MDefinedAlarms.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
    {
      column: 'temporaryStateDatetime' as MDefinedAlarms.TableViewSortKeys,
      rule: TableSearchRule.BeginsWith,
      value: [value],
    },
  ];
}

interface DefinedAlarmsRequest {
  rowsPerPage: number;
  currentPage: number;
  searchInputs: string;
  props?: {
    objectUuid?: string;
    groupUuid?: string;
  };
}

export function getRequestInput(definedAlarmsRequest: DefinedAlarmsRequest) {
  const input = composeRequestInput({
    rowsPerPage: definedAlarmsRequest.rowsPerPage,
    currentPage: definedAlarmsRequest.currentPage,
    searchAny: getSearchInputs(definedAlarmsRequest.searchInputs),
  });

  if (definedAlarmsRequest.props?.objectUuid) {
    Object.assign(input, {
      searchAll: [
        {
          column: 'objectUuid' as MDefinedAlarms.TableViewSortKeys,
          rule: TableSearchRule.Equal,
          value: [definedAlarmsRequest.props.objectUuid],
        },
      ],
    });
  }

  if (definedAlarmsRequest.props?.groupUuid) {
    Object.assign(input, {
      searchAll: [
        {
          column: 'definedAlarmGroupUuid' as MDefinedAlarms.TableViewSortKeys,
          rule: TableSearchRule.Equal,
          value: [definedAlarmsRequest.props.groupUuid],
        },
      ],
    });
  }

  return input;
}
