export interface LocalStorage {
  localStorageId: number;
  name: string;
  value?: string;
}

export enum LoginStatus {
  NOT_CHECKED = 'not_checked',
  LOGGING_IN = 'logging_in',
  LOGGED_IN = 'logged_in',
  LOGGED_OUT = 'logged_out',
  LOGIN_ERROR = 'login_error',
}

export enum LoginCheckStatus {
  IDLE = 'idle',
  CHECKING = 'checking',
  CHECKED = 'checked',
  ERROR = 'error',
}
