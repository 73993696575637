import { EditorValue } from '@/types/custom/editorTypes';
import { MDevice, MGenericDevice, MGlobal } from '@/types/models';

export function convertResponseToModel(
  data: MGenericDevice.Messages.GetOut,
): Record<string, Record<string, Record<string, EditorValue>>> {
  return {
    primaryData: {
      primaryData: {
        name: { value: data.device?.data ? data.device.data.name : '' },
        hysteresis: {
          value: data.device?.settings?.valuesHysteresis,
        },
        hysteresisMode: {
          value: data.device?.settings?.hysteresisMode,
        },
        timeoutHysteresis: {
          value: data.device?.settings?.timeoutHysteresis,
        },
      },
    },
  };
}

export function getValueUnit(
  modelColumn: MGenericDevice.ModelColumn[],
  unitList: MGlobal.Unit[],
  hysteresisMode: MDevice.HysteresisMode,
): string {
  switch (hysteresisMode) {
    case MDevice.HysteresisMode.Absolute:
      return modelColumn.length == 1
        ? `[${unitList[0].shortDescription}]`
        : 'editor.genericDevice.multipleValueUnits';
    case MDevice.HysteresisMode.Percentage:
      return '[%]';
    default:
      return '';
  }
}
