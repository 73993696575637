/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { snakeCase } from 'lodash';

import {
  MessageTableRequest,
  Order,
  SearchRule,
  TableSearch,
} from '@/services/compiled/table';
import { TableRequestInput } from '@/types/messages/table';

import { GrpcConverters } from './grpc';

export class TableConverters {
  private grpcConverters: GrpcConverters;

  constructor() {
    this.grpcConverters = new GrpcConverters();
  }

  public tableRequestInputToGrpc(
    input: TableRequestInput,
  ): MessageTableRequest {
    const message: MessageTableRequest = {
      searchAll: [],
      searchAny: [],
      count:
        input.count != null
          ? {
              count: input.count.count,
              page: input.count.page,
            }
          : undefined,
      sort:
        input.sort != null
          ? {
              column: snakeCase(input.sort.column),
              order: input.sort.order as unknown as Order,
            }
          : undefined,
    };

    for (const search of input.searchAll) {
      const grpcSearch: TableSearch = {
        column: snakeCase(search.column),
        rule: search.rule as unknown as SearchRule,
        value: [],
      };

      for (const value of search.value) {
        grpcSearch.value.push(this.grpcConverters.unknownToValue(value));
      }

      message.searchAll.push(grpcSearch);
    }

    for (const search of input.searchAny) {
      const grpcSearch: TableSearch = {
        column: snakeCase(search.column),
        rule: search.rule as unknown as SearchRule,
        value: [],
      };

      for (const value of search.value) {
        grpcSearch.value.push(this.grpcConverters.unknownToValue(value));
      }

      message.searchAny.push(grpcSearch);
    }

    return message;
  }
}
