import { RpcError } from '@protobuf-ts/runtime-rpc';

import { Dependencies } from '@/deps';
import { IVisSystemApiLogic } from '@/logic/interfaces';
import { IVibrodetectorService } from '@/services/interfaces';
import { StreamError } from '@/services/types/streamErrors';
import {
  VibrodetectorGraphLiveInput,
  VibrodetectorGraphMessage,
} from '@/types/messages/vibrodetector';

import { IvisSystemApiStream } from '..';

export const vibrodetectorStream = async (
  deviceUuid: string,
  interval: number | undefined,
  logicKey: {
    sourceUuid: string;
    sourceType: 'Object' | 'Device';
    visualizationUuid: string;
    name: string;
    systemapi_name: string;
  },
): Promise<IvisSystemApiStream> => {
  let logic_callback: null | ((data: Record<string, unknown>) => void) = null;

  const vibroDetectorService = Dependencies.get(IVibrodetectorService.$);
  const onMessage = (event: {
    value: VibrodetectorGraphMessage;
    input: VibrodetectorGraphLiveInput;
  }) => {
    const value = event.value;
    const datetime = new Date(value[0][0]);
    const measuringResult = value[1][0];
    if (logic_callback != null) {
      logic_callback({
        datetime,
        measuring_result: measuringResult,
      });
    }
  };

  const onDisconnect = async (error: Error) => {
    const logic = Dependencies.get(IVisSystemApiLogic.$)(logicKey);

    if (error.name === 'RpcError') {
      await new Promise((r) => setTimeout(r, 2000));

      const rpcError = error as RpcError;

      if (
        (rpcError.code === 'INTERNAL' &&
          (rpcError.message === StreamError.NetworkError ||
            rpcError.message === StreamError.FailedToFetch)) ||
        rpcError.code === 'ABORTED' ||
        rpcError.code === 'RESOURCE_EXHAUSTED'
      ) {
        logic.actions.stop();
        logic.actions.start();
        return;
      } else {
        logic.actions.stop();
        return;
      }
    }

    logic.actions.stop();
  };

  await vibroDetectorService.stream.listen(
    {
      onMessage,
      onDisconnect,
    },
    {
      uuid: deviceUuid,
      interval: interval !== null ? interval : undefined,
    },
  );

  return {
    cancel() {
      vibroDetectorService.stream.cancel(onMessage, {
        uuid: deviceUuid,
        interval: interval !== null ? interval : undefined,
      });
    },
    callback(callback) {
      logic_callback = callback;
    },
  } as IvisSystemApiStream;
};
