import { FC } from 'react';

import { DateView } from '@/components/dateView';
import { CompositeDate } from '@/types/filter';

import './style.scss';

interface DateTemplateProps {
  value: CompositeDate;
}

export const DateTemplate: FC<DateTemplateProps> = (props) => {
  return (
    <DateView
      className={'ocs-date-template'}
      beginDate={props.value.startDate}
      endDate={props.value.endDate}
    ></DateView>
  );
};
