import { Unit as GrpcUnit } from '@/services/compiled/globals';
import {
  GetLightningChartLicenseOut as MGetLightningChartLicenseOut,
  GetUnitsListIn as MGetUnitsListIn,
  GetUnitsListOut as MGetUnitsListOut,
} from '@/types/messages/global';

export namespace MGlobal {
  export namespace Messages {
    export type GetUnitsListIn = MGetUnitsListIn;
    export type GetUnitsListOut = MGetUnitsListOut;
    export type GetLightningChartLicenseOut = MGetLightningChartLicenseOut;
  }

  export const TableUnitsSortKeys: (keyof GrpcUnit)[] = [
    'unitId',
    'longDescription',
    'shortDescription',
  ];
  export type TableUnitsSortKeys = keyof GrpcUnit;

  export interface Unit {
    unitId: number;
    shortDescription: string;
    longDescription: string;
  }
}
