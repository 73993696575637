import { ColumnType, SortOptions } from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDevice } from '@/types/models';
import { StringKeys } from '@/utility/types';

export interface IDeviceTableLogicProps extends Record<string, unknown> {
  objectUuid: string | null;
}

interface IDeviceTable extends Logic {
  readonly props: IDeviceTableLogicProps;
  readonly actions: {
    readonly setCurrentPage: (currentPage: number) => void;
    readonly setRowsPerPage: (rowsPerPage: number) => void;
    readonly refresh: () => void;
    readonly setPaginator?: (value: boolean) => void;
  };
  readonly values: {
    readonly dataKey: 'deviceUuid';
    readonly columns: {
      field: StringKeys<MDevice.TableView>;
      header: string;
    }[];
    readonly columnTypes: { [key: string]: ColumnType };
    readonly values: MDevice.TableView[] | null;
    readonly valueLists: { status: any };
    readonly loading: boolean;
    readonly error: boolean;
    readonly currentPage: number;
    readonly rowsPerPage: number;
    readonly amountOfRecords: number;
    readonly isPaginatorActive?: boolean;
    readonly sortOptions?: SortOptions;
  };
  readonly selectors: {
    readonly values: (
      deviceViews: MDevice.View[],
    ) => MDevice.TableView[] | null;
  };
}

export type IDeviceTableLogicIdentifier =
  interfaces.ServiceIdentifier<IDeviceTableLogic>;

export interface IDeviceTableLogic extends LogicWrapper<IDeviceTable> {
  build: any;
}

export namespace IDeviceTableLogic {
  export const $: IDeviceTableLogicIdentifier = Symbol('IDeviceTableLogic');
}
