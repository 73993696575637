import { useInjection } from 'inversify-react';
import { useActions, useValues } from 'kea';
import { FC } from 'react';

import { useTranslation } from '@/hooks';
import { IGroupAlarmsAddFormLogic } from '@/logic/interfaces/groupAlarmsAddForm';
import { FooterPopup } from '@/widgets/toolBar/footerPopup';

import './style.scss';

export const AddGroupConfirmButton: FC = () => {
  const t = useTranslation();

  const formLogic = useInjection(IGroupAlarmsAddFormLogic.$);

  const { confirm } = useActions(formLogic);
  const { isSaving } = useValues(formLogic);

  return (
    <FooterPopup
      popupMessage={t('editor.footer.confirmMessage')}
      popupIcon={'pi pi-check-circle'}
      buttonLabel={t('editor.footer.confirm')}
      accept={confirm}
      acceptLabel={t('editor.footer.acceptLabel')}
      rejectLabel={t('editor.footer.rejectLabel')}
      disabled={isSaving}
      buttonIcon={'ri ri-check-line'}
    />
  );
};

export const AddGroupRemoveButton: FC = () => {
  const t = useTranslation();

  const formLogic = useInjection(IGroupAlarmsAddFormLogic.$);

  const { remove } = useActions(formLogic);
  const { isSaving, isRemoveActive } = useValues(formLogic);

  return isRemoveActive ? (
    <FooterPopup
      buttonClassname="p-button-text OCS-footer-button p-button-plain ocs-remove-button"
      popupMessage={t('editor.footer.removeMessage')}
      popupIcon={'ri ri-delete-bin-2-line'}
      buttonIcon={'ri ri-delete-bin-2-line'}
      accept={remove}
      acceptLabel={t('editor.footer.acceptLabel')}
      rejectLabel={t('editor.footer.rejectLabel')}
      disabled={isSaving}
      tooltipContent={t('tooltips.removeGroupButton')}
    />
  ) : (
    <></>
  );
};
