/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useMountedLogic, useValues } from 'kea';
import { FC, useState } from 'react';

import { useTranslation } from '@/hooks';
import { IEditorLogic } from '@/logic/interfaces/editor';
import { IEditorFieldLogic } from '@/logic/interfaces/editorField';
import { EditorFieldKey } from '@/types/custom/editorTypes';

import { TextField } from '../../textField';
import './../style.scss';

interface StringEditorProps {
  name: string;
  leftIcon?: string;
  rightIcon?: string;
  valueUnit?: string;
  isNumber?: boolean;
  isFloat?: boolean;
  isActive?: boolean;
  inputValidator?: (input: string | number) => boolean;
  errorMessage?: string;
  editorLogic: IEditorLogic;
  editorKey: EditorFieldKey;
}

export const StringEditor: FC<StringEditorProps> = (props) => {
  const [validationOutput, setValidationOutput] = useState(false);

  const t = useTranslation();

  const editorLogic = useMountedLogic(props.editorLogic);

  const fieldLogic = useInjection(IEditorFieldLogic.$)({
    editorLogic: editorLogic as BuiltLogic<IEditorLogic>,
    editorKey: props.editorKey,
  });

  const { updateValue } = useActions(fieldLogic);
  const { value } = useValues(fieldLogic);

  return (
    <div className="OCS-string-editor">
      <TextField
        id={props.name}
        leftIcon={props.leftIcon}
        rightIcon={props.rightIcon}
        valueUnit={props.valueUnit ? t(props.valueUnit) : props.valueUnit}
        label={props.name}
        className="OCS-editor-input"
        inputClassName="p-inputtext-sm"
        value={value === 0 ? '0' : value || ''}
        onChange={(value) => {
          if (value == null || value == '') {
            updateValue({
              value: undefined,
              error:
                props.inputValidator != null &&
                props.inputValidator(value) == false
                  ? props.errorMessage
                  : undefined,
            });

            return;
          }
          if (props.isNumber) {
            updateValue({
              value: parseFloat(value),
              error:
                props.inputValidator != null &&
                props.inputValidator(parseFloat(value)) == false
                  ? props.errorMessage
                  : undefined,
            });
          } else {
            updateValue({
              value: value.toString(),
              error:
                props.inputValidator != null &&
                props.inputValidator(value) == false
                  ? props.errorMessage
                  : undefined,
            });
          }
        }}
        inputValidator={props.inputValidator}
        validatorSetter={setValidationOutput}
        errorMessage={props.errorMessage}
        disabled={props.isActive}
        type={props.isNumber === true ? 'number' : ''}
        step={props.isFloat === true ? '0.001' : undefined}
        min={props.isNumber === true ? '0' : ''}
      />
    </div>
  );
};
