import { useInjection } from 'inversify-react';
import { useMountedLogic, useValues } from 'kea';
import { FC, useMemo } from 'react';

import { IVisElementLogic } from '@/logic/interfaces/visualization/visElement';
import {
  DescriptionType,
  Dynamization,
  GroupType,
} from '@/types/visualization';
import { GetRgbString, convertRgbToHex, darkenColor } from '@/utils/colorUtils';
import { GetDynamicProps } from '@/utils/visualizationUtils';

import { visualizationContextLogic } from '.';
import { VisualizationRenderer } from './visRenderer';

interface GroupProps {
  group: GroupType;
}

export const GroupVisualization: FC<GroupProps> = (props) => {
  const visLogic = useMountedLogic(visualizationContextLogic);

  const visElementLogic = useInjection(IVisElementLogic.$)({
    sourceUuid: visLogic.props.sourceUuid,
    sourceType: visLogic.props.sourceType,
    visualizationUuid: visLogic.props.visualizationUuid,
    name: visLogic.props.name,
    elementUuid: props.group.name,
    tasks: props.group.tasks,
  });

  const { values } = useValues(visElementLogic);

  const borderColor = useMemo(() => {
    const hex = convertRgbToHex(
      props.group.color.red,
      props.group.color.green,
      props.group.color.blue,
    );

    return darkenColor(hex, 0.6);
  }, [props.group.color]);

  const dynamicProps = useMemo(() => {
    if (values != null && values[0])
      return GetDynamicProps(props.group, values[0]) as GroupType;
    else return props.group;
  }, [props.group]);

  if (
    dynamicProps.dynamization === Dynamization.Visibility &&
    values != null &&
    values[0] == 0
  )
    return <></>;

  return (
    <div
      style={{
        position: 'absolute',
      }}
    >
      <div
        style={{
          position: 'relative',
          border: `1px solid ${borderColor}`,
          top: dynamicProps.top,
          left: dynamicProps.left,
          width: dynamicProps.width,
          height: dynamicProps.height,
          backgroundColor: GetRgbString(
            dynamicProps.color.red,
            dynamicProps.color.green,
            dynamicProps.color.blue,
          ),
          visibility: dynamicProps.hidden ? 'hidden' : 'visible',
          opacity: dynamicProps.transparent ? '50%' : '100%',
        }}
      >
        <VisualizationRenderer childs={dynamicProps.childs} />
      </div>
    </div>
  );
};
