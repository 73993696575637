import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { IModules, IModulesKeys } from '@/modules';
import {
  Window,
  WindowGeometry,
  WindowPosition,
  WindowSize,
} from '@/types/models/desktop';

export interface IWindowLogicProps {
  key: string;
}

interface IWindow extends Logic {
  readonly props: IWindowLogicProps;
  readonly actions: {
    readonly setPosition: (x: number, y: number) => void;
    readonly setSize: (width: number, height: number) => void;
    readonly setMoving: (moving: boolean) => void;
    readonly setResizing: (resizing: boolean) => void;
    readonly toggle: () => void;
    readonly minimize: () => void;
    readonly maximize: () => void;
    readonly focus: () => void;
    readonly close: () => void;
  };
  readonly values: {
    readonly windowKey: string;
    readonly data: Window;
    readonly title: string;
    readonly type: IModulesKeys;
    readonly initialData?: Record<string, unknown>;
    readonly icon: string;
    readonly geometry: WindowGeometry;
    readonly position: WindowPosition;
    readonly size: WindowSize;
    readonly zIndex: number;
    readonly minWidth: number;
    readonly minHeight: number;
    readonly focused: boolean;
    readonly minimized: boolean;
    readonly maximized: boolean;
    readonly pinned: boolean;
    readonly onTop: boolean;
    readonly moving: boolean;
    readonly resizing: boolean;
  };
}

export interface IWindowLogic extends LogicWrapper<IWindow> {}

export namespace IWindowLogic {
  export const $: interfaces.ServiceIdentifier<IWindowLogic> =
    Symbol('IWindowLogic');
}
