import { ColumnType, SortOptions } from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDefinedAlarms } from '@/types/models/definedAlarms';
import { StringKeys } from '@/utility/types';

export interface IDefinedAlarmsLogicProps extends Record<string, unknown> {
  alarmGroupUuid?: string;
  objectUuid?: string;
}

export interface IDefinedAlarmsTable extends Logic {
  readonly props: IDefinedAlarmsLogicProps;
  readonly actions: {
    readonly setCurrentPage: (currentPage: number) => void;
    readonly setRowsPerPage: (rowsPerPage: number) => void;
    readonly refresh: () => void;
    readonly setPaginator?: (value: boolean) => void;
  };
  readonly values: {
    readonly dataKey: 'definedAlarmUuid';
    readonly columns?: {
      field: StringKeys<MDefinedAlarms.TableView>;
      header: string;
    }[];
    readonly columnTypes: Record<string, ColumnType>;
    readonly values: MDefinedAlarms.TableView[] | null;
    readonly loading: boolean;
    readonly error: boolean;
    readonly currentPage: number;
    readonly rowsPerPage: number;
    readonly amountOfRecords: number;
    readonly isPaginatorActive?: boolean;
    readonly sortOptions?: SortOptions;
  };
  readonly selectors: {
    readonly values: (
      definedAlarmsViews: MDefinedAlarms.View[],
    ) => MDefinedAlarms.TableView[] | null;
  };
}

export type IDefinedAlarmsTableLogicIdentifier =
  interfaces.ServiceIdentifier<IDefinedAlarmsTableLogic>;

export interface IDefinedAlarmsTableLogic
  extends LogicWrapper<IDefinedAlarmsTable> {
  build: any;
}

export namespace IDefinedAlarmsTableLogic {
  export const $: IDefinedAlarmsTableLogicIdentifier = Symbol(
    'IDefinedAlarmsTableLogic',
  );
}
