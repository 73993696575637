import { useInjection } from 'inversify-react';
import { useValues } from 'kea';
import { Button } from 'primereact/button';
import {
  CSSProperties,
  FC,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from 'react';

import { config } from '@/config';
import { useTranslation } from '@/hooks';
import { IAppLogic } from '@/logic/interfaces';
import { detectOutsideClick } from '@/utility/component';
import { convertToRem } from '@/utility/style';

interface FoldingTileProps {
  components?: JSX.Element[];
  children?: JSX.Element;
  size: number;
  dynamicSize: number;
  componentSize: number;
  foldAlignment?: 'left' | 'right';

  dynamicContainerRef: MutableRefObject<HTMLDivElement | null>;
}

export const FoldingTile: FC<FoldingTileProps> = (props) => {
  const t = useTranslation();

  const appLogic = useInjection(IAppLogic.$);

  const { fontSize } = useValues(appLogic);

  const foldRef = useRef<HTMLDivElement>(null);

  const [isVisible, setVisible] = useState(false);
  const [isCollapsed, setCollapsed] = useState(true);
  const [zIndex] = useState(501);

  detectOutsideClick(
    foldRef,
    () => {
      setVisible(false);
    },
    [props.dynamicContainerRef],
  );

  const absoluteStyle = useMemo(() => {
    let absoluteStyle: CSSProperties = {};

    if (props.foldAlignment === 'right') {
      absoluteStyle = {
        flexFlow: 'row-reverse',
        left: `${convertToRem(-props.dynamicSize + props.size, fontSize)}rem`,
        minWidth: `${convertToRem(
          props.dynamicSize - props.size,
          fontSize,
        )}rem`,
      };
    } else {
      absoluteStyle = {
        left: `${convertToRem(props.size, fontSize)}rem`,
        minWidth: props.dynamicSize - props.size,
      };
    }

    return absoluteStyle;
  }, [props.dynamicSize, props.componentSize]);

  const containerStyle = useMemo(() => {
    let alignmentStyle: CSSProperties = {};
    let visibleStyle: CSSProperties = {};

    const containerZIndex =
      props.foldAlignment != undefined ? { zIndex: 501 } : { zIndex: 502 };

    if (props.foldAlignment === 'right') {
      alignmentStyle = {
        flexFlow: 'row-reverse',
        left: 0,
        minWidth: `${convertToRem(
          props.dynamicSize - props.size,
          fontSize,
        )}rem`,
      };

      if (!isVisible) {
        visibleStyle = {
          left: `${convertToRem(props.dynamicSize - props.size, fontSize)}rem`,
          minWidth: 0,
        };
      }
    } else {
      alignmentStyle = {
        minWidth: `${convertToRem(
          props.dynamicSize - props.size,
          fontSize,
        )}rem`,
      };

      if (!isVisible) {
        visibleStyle = {
          left: `${convertToRem(-props.dynamicSize, fontSize)}rem`,
          minWidth: 0,
        };
      }
    }

    return { ...alignmentStyle, ...visibleStyle, ...containerZIndex };
  }, [props.dynamicSize, props.componentSize, isVisible]);

  return (
    <div ref={foldRef} className="OCS-folding-tile">
      <Button
        onClick={() => {
          setVisible(!isVisible);
          setCollapsed(false);
        }}
        className="p-button-text p-button-plain OCS-folding-tile-button"
        style={isVisible ? { zIndex: zIndex } : {}}
        icon={isVisible ? 'ri ri-menu-fold-line' : 'ri ri-menu-unfold-line'}
        tooltip={isVisible ? t('tooltips.fold') : t('tooltips.unfold')}
        tooltipOptions={{
          position: 'bottom',
          showDelay: config.tooltip.showDelay,
          baseZIndex: config.tooltip.zIndex,
          appendTo: config.tooltip.appendTo,
        }}
      />
      <div
        // ref={foldRef}
        className={`${isCollapsed ? 'OCS-v-hidden' : ''} OCS-absolute`}
        style={absoluteStyle}
      >
        <div
          onTransitionEnd={(event) => {
            if (!isVisible && event.propertyName == 'left') setCollapsed(true);
          }}
          className={`${
            props.foldAlignment === 'right'
              ? 'OCS-folding-component-container-right'
              : 'OCS-folding-component-container-left'
          } OCS-folding-component-container`}
          style={containerStyle}
        >
          {props.components != null
            ? props.components.map((component) => {
                return component;
              })
            : props.children}
        </div>
      </div>
    </div>
  );
};
