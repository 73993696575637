import { useActions, useMountedLogic, useValues } from 'kea';
import { Button } from 'primereact/button';
import { FC } from 'react';

import { IEditorLogic } from '@/logic/interfaces/editor';
import { EditorFieldKey } from '@/types/custom/editorTypes';

interface ButtonEditorProps {
  editorLogic: IEditorLogic;
  editorKey: EditorFieldKey;
}

export const ButtonEditor: FC<ButtonEditorProps> = (props) => {
  const editorLogic = useMountedLogic(props.editorLogic);

  const { onButtonClick } = useActions(editorLogic);
  const { buttonMap } = useValues(editorLogic);

  return (
    <Button
      className="p-button-text OCS-button-editor"
      icon={
        buttonMap != undefined
          ? buttonMap[props.editorKey.tabKey][props.editorKey.valueKey].icon
          : ''
      }
      onClick={() => {
        if (onButtonClick != undefined)
          onButtonClick(props.editorKey.tabKey, props.editorKey.valueKey);
      }}
    />
  );
};
