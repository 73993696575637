import { shallowEqual } from 'fast-equals';
import { actions, kea, key, listeners, props, selectors } from 'kea';

import { config } from '@/config';
import Dependencies from '@/deps';
import { IDesktopLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IModules, IModulesKeys } from '@/modules';

import type { logicType } from './indexType';

export interface ModuleLogicProps {
  windowType: IModulesKeys;
  deps: {
    modules: IModules;
    desktopLogic: IDesktopLogic;
  };
}

const logic = kea<logicType>([
  key((props) => props.windowType),
  props({} as ModuleLogicProps),
  actions({ minimize: true }),
  listeners(({ props, values }) => ({
    minimize: () => {
      const updatingWindows: Record<string, { minimized: boolean }> = {};
      for (const windowKey of values.windowKeys) {
        updatingWindows[windowKey] = { minimized: !values.minimized };
      }
      props.deps.desktopLogic.actions.updateMultipleGeometries(updatingWindows);
    },
  })),
  selectors(({ props }) => ({
    windowType: [
      () => [(_, props: ModuleLogicProps) => props.windowType],
      (windowType) => windowType,
    ],
    taskbarData: [
      (selectors) => [selectors.windowType],
      (windowType) => props.deps.modules[windowType].taskbarData,
    ],
    windows: [
      (selectors) => [
        selectors.windowType,
        props.deps.desktopLogic.selectors.currentLayout,
      ],
      (windowType, currentLayout) => {
        const windows = currentLayout?.windows;
        if (windows !== undefined) {
          return Object.fromEntries(
            Object.entries(windows).filter(
              (entry) => entry[1].type == windowType,
            ),
          );
        }
        return {};
      },
      { resultEqualityCheck: shallowEqual },
    ],
    windowKeys: [
      (selectors) => [selectors.windows],
      (windows) => Object.keys(windows),
      { resultEqualityCheck: shallowEqual },
    ],
    windowCount: [
      (selectors) => [selectors.windows],
      (windows) => Object.keys(windows).length,
    ],
    firstWindowKey: [
      (selectors) => [selectors.windows, selectors.windowCount],
      (windows, windowCount) => {
        if (windowCount > 0) {
          return Object.keys(windows)[0];
        }
        return null;
      },
    ],
    firstWindow: [
      (selectors) => [selectors.windows, selectors.firstWindowKey],
      (windows, firstWindowKey) => {
        if (firstWindowKey != null) {
          return windows[firstWindowKey];
        }
        return null;
      },
    ],
    minimized: [
      (selectors) => [selectors.windows],
      (windows) => {
        for (const key in windows) {
          const window = windows[key];
          if (!window.geometry.minimized) {
            return false;
          }
        }
        return true;
      },
    ],
    focused: [
      (selectors) => [selectors.windows],
      (windows) => {
        for (const key in windows) {
          const window = windows[key];
          if (window.geometry.zIndex == config.desktop.maxZIndex) {
            return true;
          }
        }
        return false;
      },
    ],
  })),
]);

export const moduleLogic = injectDepsToLogic(logic, () => ({
  desktopLogic: Dependencies.get(IDesktopLogic.$),
  modules: Dependencies.get(IModules.$),
}));
