import {
  actions,
  afterMount,
  kea,
  listeners,
  path,
  props,
  reducers,
} from 'kea';

import Dependencies from '@/deps';
import { injectDepsToLogic } from '@/logic/utils';
import { IEventService } from '@/services/interfaces';
import { AlarmEvent, EventLevel } from '@/types/models/event';

import type { logicType } from './indexType';
import { loadEventLevels } from './loaders';

const logic = kea<logicType>([
  path(['events', 'dictionary']),
  props(
    {} as {
      deps: { eventService: IEventService };
    },
  ),
  actions({
    loadEventLevels: true,
    loadEventLevelsSuccess: (eventLevels: { [id: number]: EventLevel }) => ({
      eventLevels,
    }),
    loadEventLevelsFailure: true,
  }),
  reducers({
    eventLevels: [
      {} as { [id: string]: EventLevel },
      {
        loadEventLevelsSuccess: (immutableState, { eventLevels }) => ({
          ...immutableState,
          ...eventLevels,
        }),
      },
    ],
    loadSuccess: [
      false,
      {
        loadEventLevelsSuccess: () => true,
        loadEventLevelsFailure: () => false,
      },
    ],
    loadFailure: [
      false,
      {
        loadEventLevelsFailure: () => true,
        loadEventLevelsSuccess: () => false,
      },
    ],
    events: [new Map() as Map<string, AlarmEvent>, {}],
  }),
  listeners(({ props, actions }) => ({
    loadEventLevels: async () => {
      const eventLevels = await loadEventLevels(props.deps.eventService);
      if (eventLevels !== null) {
        actions.loadEventLevelsSuccess(eventLevels);
      } else {
        actions.loadEventLevelsFailure();
      }
    },
    loadEventLevelsFailure: async () => {
      await new Promise((r) => setTimeout(r, 1000));
      actions.loadEventLevels();
    },
    loadEventLevelsSuccess: async () => {
      await new Promise((r) => setTimeout(r, 60000));
      actions.loadEventLevels();
    },
  })),
  afterMount(({ actions }) => {
    actions.loadEventLevels();
  }),
]);

export const eventDictContainerLogic = injectDepsToLogic(logic, () => ({
  eventService: Dependencies.get(IEventService.$),
}));
