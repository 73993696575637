import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { EventView } from '@/types/eventView';

export interface IAlarmEventsLogicProps extends Record<string, unknown> {}

export interface IAlarmEvents extends Logic {
  readonly props: IAlarmEventsLogicProps;
  readonly actions: {
    readonly setMuted: (value: boolean) => void;
    readonly setDisplayed: (alarmEventsIds: string[]) => void;
    readonly confirmAll: (confirmMessage: string) => void;
    readonly removeAll: () => void;
  };
  readonly values: {
    readonly isMuted: boolean;
    readonly alarmEvents: EventView[];
    readonly alarmEventsToDisplay: EventView[];
    readonly isSending: boolean;
  };
  readonly selectors: {
    readonly alarmEvents: (state: any) => EventView[];
  };
}

export interface IAlarmEventsLogic extends LogicWrapper<IAlarmEvents> {}

export const IAlarmsLogicSymbol: interfaces.ServiceIdentifier<IAlarmEventsLogic> =
  Symbol('IAlarmEventsLogic');

export namespace IAlarmEventsLogic {
  export const $: interfaces.ServiceIdentifier<IAlarmEventsLogic> =
    IAlarmsLogicSymbol;
}
