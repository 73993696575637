import produce from 'immer';
import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  listeners,
  path,
  reducers,
} from 'kea';

import { injectDepsToLogic } from '@/logic/utils';
import { MDefinedAlarms } from '@/types/models/definedAlarms';

import type { logicType } from './indexType';

const logic = kea<logicType>([
  path(['definedAlarms', 'container', 'view']),
  actions({
    startGarbageCollection: true,
    stopGarbageCollection: true,
    addDefinedAlarms: (definedAlarms: MDefinedAlarms.View[]) => ({
      definedAlarms,
    }),
    useDefinedAlarms: (definedAlarmsIds: string[]) => ({ definedAlarmsIds }),
    releaseDefinedAlarms: (definedAlarmsIds: string[]) => ({
      definedAlarmsIds,
    }),
    removeDefinedAlarms: (definedAlarmsIds: string[]) => ({ definedAlarmsIds }),
  }),
  reducers({
    definedAlarms: [
      {} as { [key: string]: MDefinedAlarms.View },
      {
        addDefinedAlarms: (immutableState, { definedAlarms }) =>
          produce(immutableState, (draftState) => {
            for (const definedAlarm of definedAlarms) {
              draftState[definedAlarm.definedAlarmUuid] = definedAlarm;
            }
          }),
        removeDefinedAlarms: (immutableStore, { definedAlarmsIds }) =>
          produce(immutableStore, (draftState) => {
            for (const definedAlarmId of definedAlarmsIds) {
              if (definedAlarmId in draftState) {
                delete draftState[definedAlarmId];
              }
            }
          }),
      },
    ],
    usageCounters: [
      {} as Record<
        string,
        {
          counter: number;
          lastUseTime: Date;
        }
      >,
      {
        addDefinedAlarms: (immutableStore, { definedAlarms }) =>
          produce(immutableStore, (draftState) => {
            for (const definedAlarm of definedAlarms) {
              if (!(definedAlarm.definedAlarmUuid in draftState)) {
                draftState[definedAlarm.definedAlarmUuid] = {
                  counter: 0,
                  lastUseTime: new Date(),
                };
              }
            }
          }),
        useDefinedAlarms: (immutableStore, { definedAlarmsIds }) =>
          produce(immutableStore, (draftState) => {
            for (const id of definedAlarmsIds) {
              if (id in draftState) {
                draftState[id].counter += 1;
                draftState[id].lastUseTime = new Date();
              } else {
                throw Error('Using an definedAlarm not present in container');
              }
            }
          }),
        releaseDefinedAlarms: (immutableStore, { definedAlarmsIds }) =>
          produce(immutableStore, (draftState) => {
            for (const id of definedAlarmsIds) {
              if (id in draftState) {
                if (draftState[id].counter > 0) {
                  draftState[id].counter -= 1;
                  draftState[id].lastUseTime = new Date();
                }
              }
            }
          }),
        removeDefinedAlarms: (immutableStore, { definedAlarmsIds }) =>
          produce(immutableStore, (draftState) => {
            for (const id of definedAlarmsIds) {
              if (id in draftState) {
                delete draftState[id];
              }
            }
          }),
      },
    ],
    collectingGarbage: [
      false as boolean,
      {
        startGarbageCollection: (_) => true,
        stopGarbageCollection: (_) => false,
      },
    ],
  }),
  listeners(({ values, actions }) => ({
    startGarbageCollection: async (_, breakpoint) => {
      breakpoint();
      while (values.collectingGarbage) {
        const currentTime = new Date();
        const definedAlarmsToRemove: string[] = [];

        for (const definedAlarmKey in values.usageCounters) {
          const usageCounter = values.usageCounters[definedAlarmKey];
          if (
            usageCounter.counter <= 0 &&
            currentTime.getTime() - usageCounter.lastUseTime.getTime() >= 5000
          ) {
            definedAlarmsToRemove.push(definedAlarmKey);
          }
        }
        if (definedAlarmsToRemove.length > 0) {
          actions.removeDefinedAlarms(definedAlarmsToRemove);
        }
        await breakpoint(5000);
      }
    },
  })),
  afterMount(({ actions }) => {
    actions.startGarbageCollection();
  }),
  beforeUnmount(({ actions }) => {
    actions.stopGarbageCollection();
  }),
]);

export const definedAlarmsViewContainerLogic = injectDepsToLogic(
  logic,
  () => ({}),
);
