import { EditorModel, EditorTypes } from '@/types/custom/editorTypes';

export const definedAlarmsEditorModel: Record<
  string,
  Record<string, Record<string, EditorModel>>
> = {
  data: {
    data: {
      description: {
        name: 'editor.definedAlarms.name',
        type: EditorTypes.string,
      },
      group: {
        name: 'editor.definedAlarms.group',
        type: EditorTypes.enum,
      },
      eventLevelId: {
        name: 'editor.definedAlarms.eventLevel',
        leftIcon: 'ri ri-bar-chart-line',
        enumOptionsName: 'eventLevelId',
        type: EditorTypes.enum,
      },
      device: {
        name: 'editor.definedAlarms.device',
        enumOptionsName: 'device',
        type: EditorTypes.enum,
      },
    },
    inputSwitch: {
      active: {
        name: 'editor.definedAlarms.active',
        type: EditorTypes.inputSwitch,
      },
      sound: {
        name: 'editor.definedAlarms.sound',
        type: EditorTypes.inputSwitch,
      },
    },
    color: {
      color: {
        name: 'editor.definedAlarms.color',
        type: EditorTypes.color,
      },
    },
  },
  advancedSettings: {
    advancedSettings: {
      col: {
        name: 'editor.definedAlarms.col',
        type: EditorTypes.enum,
        enumOptionsName: 'col',
      },
      rule: {
        name: 'editor.definedAlarms.rule',
        type: EditorTypes.enum,
        enumOptionsName: 'rule',
      },
      value: {
        name: 'editor.definedAlarms.value',
        type: EditorTypes.float,
      },
      // math: {
      //   type: EditorTypes.enum,
      //   enumOptions: ConvertEnumToEditorArray(
      //     MDefinedAlarms.Math,
      //     'math',
      //   ),
      // },
      // mathCol: {
      //   name: 'editor.definedAlarms.mathCol',
      //   type: EditorTypes.string,
      // },
      onDelay: {
        name: 'editor.definedAlarms.onDelay',
        type: EditorTypes.float,
        valueUnit: '[ms]',
      },
      offDelay: {
        name: 'editor.definedAlarms.offDelay',
        type: EditorTypes.float,
        valueUnit: '[ms]',
      },
      round: {
        name: 'editor.definedAlarms.round',
        type: EditorTypes.number,
      },
    },
  },
};
