import { translations } from '@ocs/ocs-components';
import i18next from 'i18next';
import { FC } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';

import frontendEn from '@/i18n/en.json';
import frontendPl from '@/i18n/pl.json';

export const getLanguage = () =>
  (i18next.language || window.localStorage.i18nextLng) as string;

const lang = navigator.language.split('-')[0];
i18next.use(initReactI18next).init({
  resources: {
    pl: { frontend: frontendPl, storybook: translations.storybookPl },
    en: { frontend: frontendEn, storybook: translations.storybookEn },
  },
  lng: lang,
  fallbackNS: ['frontend', 'storybook'],
});

export const TranslationProvider: FC<{
  children?: React.ReactNode;
}> = (props) => {
  return <I18nextProvider i18n={i18next}>{props.children}</I18nextProvider>;
};
