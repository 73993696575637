import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

export interface ITableColumnProps extends Record<string, unknown> {}

export interface ITableColumn extends Logic {
  readonly props: ITableColumnProps;
  readonly actions: {
    readonly setVisibleColumns: (columns: string[]) => void;
  };
  readonly values: {
    readonly visibleColumns?: string[] | null;
  };
}

export interface ITableColumnLogic extends LogicWrapper<ITableColumn> {}

export const ITableColumnLogicSymbol: interfaces.ServiceIdentifier<ITableColumnLogic> =
  Symbol('ITableColumnLogic');

export namespace ITableColumnLogic {
  export const $: interfaces.ServiceIdentifier<ITableColumnLogic> =
    ITableColumnLogicSymbol;
}
