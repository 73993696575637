import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

export interface IHeaderSearchLogicProps extends Record<string, unknown> {}

interface IHeaderSearch extends Logic {
  readonly props: IHeaderSearchLogicProps;
  readonly actions: {
    setSearchInput: (value: string) => void;
    refresh: () => void;
  };
  readonly values: {
    headerSearchValue: string;
  };
}

export type IHeaderSearchLogicIdentifier =
  interfaces.ServiceIdentifier<IHeaderSearchLogic>;

export interface IHeaderSearchLogic extends LogicWrapper<IHeaderSearch> {}

export namespace IHeaderSearchLogic {
  export const $: IHeaderSearchLogicIdentifier = Symbol('IHeaderSearchLogic');
}
