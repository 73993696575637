import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { Window } from '@/types/models/desktop';
import { IModulesKeys } from '@/modules';

export interface IModuleLogicProps {
  windowType: IModulesKeys;
}

interface IModule extends Logic {
  readonly props: IModuleLogicProps;
  readonly actions: {
    readonly minimize: () => void;
  };
  readonly values: {
    readonly windowType: IModulesKeys;
    readonly taskbarData: { icon: string; title: string };
    readonly windows: Record<string, Window>;
    readonly windowKeys: string[];
    readonly windowCount: number;
    readonly firstWindowKey: string | null;
    readonly firstWindow: Window | null;
    readonly minimized: boolean;
    readonly focused: boolean;
  };
}

export interface IModuleLogic extends LogicWrapper<IModule> {}

export namespace IModuleLogic {
  export const $: interfaces.ServiceIdentifier<IModuleLogic> =
    Symbol('IModuleLogic');
}
