import { RpcError } from '@protobuf-ts/runtime-rpc';

import { Dependencies } from '@/deps';
import { IVisSystemApiLogic } from '@/logic/interfaces';
import { IGenericDeviceService } from '@/services/interfaces';
import { LiveIn, LiveOut } from '@/types/messages/genericdevice';

import { IvisSystemApiStream } from '..';
import { StreamError } from './../../../../../services/types/streamErrors';

export const genericDeviceStream = async (
  deviceUuid: string,
  interval: number | undefined,
  logicKey: {
    sourceUuid: string;
    sourceType: 'Object' | 'Device';
    visualizationUuid: string;
    name: string;
    systemapi_name: string;
  },
): Promise<IvisSystemApiStream> => {
  let logic_callback: null | ((data: Record<string, unknown>) => void) = null;

  const genericDeviceService = Dependencies.get(IGenericDeviceService.$);
  const onMessage = (event: { value: LiveOut; input: LiveIn }) => {
    const value = event.value;
    value.data.datetime = value.datetime;

    if (logic_callback != null) {
      logic_callback(value.data);
    }
  };

  const onDisconnect = async (error: Error) => {
    const logic = Dependencies.get(IVisSystemApiLogic.$)(logicKey);

    if (error.name === 'RpcError') {
      await new Promise((r) => setTimeout(r, 2000));

      const rpcError = error as RpcError;

      if (
        (rpcError.code === 'INTERNAL' &&
          (rpcError.message === StreamError.NetworkError ||
            rpcError.message === StreamError.FailedToFetch)) ||
        rpcError.code === 'ABORTED' ||
        rpcError.code === 'RESOURCE_EXHAUSTED'
      ) {
        logic.actions.stop();
        logic.actions.start();
        return;
      } else {
        logic.actions.stop();
        return;
      }
    }

    logic.actions.stop();
  };

  await genericDeviceService.stream.listen(
    {
      onMessage,
      onDisconnect,
    },
    {
      uuid: deviceUuid,
      interval: interval !== null ? interval : undefined,
    },
  );

  return {
    cancel() {
      genericDeviceService.stream.cancel(onMessage, {
        uuid: deviceUuid,
        interval: interval !== null ? interval : undefined,
      });
    },
    callback(callback) {
      logic_callback = callback;
    },
  } as IvisSystemApiStream;
};
