/* eslint-disable react/display-name */
import { DateView } from "@/components/dateView";
import { EventView } from "@/types/eventView";
import { Ripple } from "primereact/ripple";
import { FC, memo, useEffect } from "react";
import { EventLink } from "../link";

import "./style.scss";

export interface EventViewContentProps {
  event?: EventView;
  children?: JSX.Element;

  disableSelection?: boolean;
  ripple?: boolean;
  closable?: boolean;

  boxSize?: "o-box-none" | "o-box-sm" | "o-box-md" | "o-box-lg";
  textSize?: "o-text-sm" | "o-text-md" | "o-text-lg";
}

export const EventViewContent: FC<EventViewContentProps> = memo((props) => {
  useEffect(() => {
    if (
      (props.event == null && props.children == null) ||
      (props.event != null && props.children != null)
    )
      throw new Error("Only event or children should be given");
  }, []);

  return (
    <div
      className={`eventView-content 
      ${props.disableSelection ? "ocs-disable-text-selection" : ""}
      ${props.closable == true ? "closable" : ""}
       ${props.boxSize}`}
    >
      <div className={`eventView-message-icon ${props.event?.icon}`}></div>
      <div className="eventView-data">
        <span className={`eventView-title ${props.textSize}`}>
          {props.event?.title}
        </span>
        <DateView
          className={`eventView-date ${props.textSize}`}
          beginDate={props.event?.startDatetime}
          endDate={props.event?.endDatetime}
        />
        <div className={`eventView-details ${props.textSize}`}>
          {props.event?.details != null ? (
            <EventLink
              details={props.event.details}
              detailsOnClick={props.event.detailsOnClick}
            />
          ) : (
            props.children
          )}
        </div>
      </div>
      {props.ripple ? <Ripple /> : ""}
    </div>
  );
});
