import { RpcError } from '@protobuf-ts/runtime-rpc';
import { injectable } from 'inversify';
import { Pool, registerSerializer, spawn } from 'threads';

import { ErrorResponse } from '@/services/types/response';
import type { ApiWorker } from '@/services/workers/api';

import { MySerializer } from '../workers/serializer';

@injectable()
export class BaseService {
  protected static apiPool = BaseService.createApiPool();

  private static createApiPool() {
    this.prepareThreadsJs();
    return Pool(
      () =>
        spawn<typeof ApiWorker>(
          new Worker(new URL('../workers/api/index', import.meta.url), {
            type: 'module',
          }),
        ),
      8,
    );
  }

  private static prepareThreadsJs() {
    registerSerializer(MySerializer);

    import('threads/register');
  }

  protected parseRequestError(error: unknown): ErrorResponse {
    if (error instanceof RpcError) {
      return {
        success: false,
        error: {
          status: error.code,
          message: error.message,
        },
      };
    }
    return { success: false, error: {} };
  }
}
