import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

export interface ISearchbarLogicProps extends Record<string, unknown> {}

export interface ISearchbar extends Logic {
  readonly props: ISearchbarLogicProps;
  readonly actions: {
    readonly setSearchText: (searchText: string) => void;
  };
  readonly values: {
    readonly searchText?: string | null;
  };
}

export interface ISearchbarLogic extends LogicWrapper<ISearchbar> {}

export const ISearchbarLogicSymbol: interfaces.ServiceIdentifier<ISearchbarLogic> =
  Symbol('ISearchbarLogic');

export namespace ISearchbarLogic {
  export const $: interfaces.ServiceIdentifier<ISearchbarLogic> =
    ISearchbarLogicSymbol;
}
