import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import {
  ConfirmStatus,
  EditorTile,
  EditorValue,
  LoadingStatus,
} from '@/types/custom/editorTypes';

export interface IHubsEditorProps extends Record<string, unknown> {
  initialData: {
    deviceUuid: string;
  };
}

interface IHubsEditor extends Logic {
  readonly props: IHubsEditorProps;
  readonly actions: {
    readonly updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: any,
    ) => void;
    readonly confirm: () => void;
    readonly setConfirmStatus: (value: ConfirmStatus) => void;
    readonly refresh: () => void;
  };
  readonly values: {
    readonly editorUuid: string;
    readonly translationPrefix: string;
    readonly tileLayout: Record<string, EditorTile>;
    readonly isSaving: boolean;
    readonly confirmStatus: ConfirmStatus;
    readonly loadStatus: LoadingStatus;
  };
  readonly selectors: {
    readonly value: (
      value: unknown,
    ) => Record<string, Record<string, Record<string, EditorValue>>>;
  };
}

export interface IHubsEditorLogic extends LogicWrapper<IHubsEditor> {}

export namespace IHubsEditorLogic {
  export const $: interfaces.ServiceIdentifier<IHubsEditorLogic> = Symbol(
    'IGenericDeviceEditorLogic',
  );
}
