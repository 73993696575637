import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MDevice } from '@/types/models';

export type IDeviceDetailContainerLogicProps = Record<string, unknown>;

interface IDeviceDetailContainer extends Logic {
  readonly props: IDeviceDetailContainerLogicProps;
  readonly actions: {
    readonly addDevices: (devices: (MDevice.Detail | MDevice.Full)[]) => void;
    readonly useDevices: (deviceIds: string[]) => void;
    readonly releaseDevices: (deviceIds: string[]) => void;
  };
  readonly values: {
    devices: Record<string, MDevice.Full>;
  };
  readonly selectors: {
    readonly devices: (state: unknown) => Record<string, MDevice.Full>;
  };
}

export type IDeviceDetailContainerLogic = LogicWrapper<IDeviceDetailContainer>;

export namespace IDeviceDetailContainerLogic {
  export const $: interfaces.ServiceIdentifier<IDeviceDetailContainerLogic> =
    Symbol('IDeviceDetailContainerLogic');
}
