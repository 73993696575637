import { EditorValue } from '@/types/custom/editorTypes';
import { VibrodetectorGetOut } from '@/types/messages/vibrodetector';
import { MDevice } from '@/types/models';

export function convertResponseToModel(
  data: VibrodetectorGetOut,
): Record<string, Record<string, Record<string, EditorValue>>> {
  return {
    primaryData: {
      primaryData: {
        name: { value: data.device?.data ? data.device.data.name : '' },
        hysteresis: {
          value: data.device?.settings?.valuesHysteresis,
        },
        hysteresisMode: {
          value: data.device?.settings?.hysteresisMode,
        },
        timeoutHysteresis: {
          value: data.device?.settings?.timeoutHysteresis,
        },
      },
    },
  };
}

export function getValueUnit(value: MDevice.HysteresisMode): string {
  switch (value) {
    case MDevice.HysteresisMode.Absolute:
      return '[mm/s]';
    case MDevice.HysteresisMode.Percentage:
      return '[%]';
    default:
      return '';
  }
}
