import { interfaces } from 'inversify';

import type { UnvalidatedWindow, Window } from '@/types/models/desktop';

export interface IDesktopValidators {
  validateWindow: (window: UnvalidatedWindow) => window is Window;
}
export namespace IDesktopValidators {
  export const $: interfaces.ServiceIdentifier<IDesktopValidators> =
    Symbol('IDesktopValidators');
}
