/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IEventService } from '@/services/interfaces/event';
import {
  ConfirmAlarmEventInput,
  ConfirmAllAlarmEvents,
  GetAlarmEventsListInput,
  GetAlarmEventsListMessage,
  GetEventLevelsListInput,
} from '@/types/messages/event';

import { ServerStream } from '../stream';
import { Response } from '../types/response';
import { GetEventLevelsListMessage } from './../../types/messages/event';
import { BaseService } from './base';

@injectable()
export class EventService extends BaseService implements IEventService {
  public async getAlarmEventsList(
    input: GetAlarmEventsListInput,
  ): Promise<Response<GetAlarmEventsListMessage>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.eventGetAlarmEventsList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getEventLevelsList(
    input: GetEventLevelsListInput,
  ): Promise<Response<GetEventLevelsListMessage>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.eventGetEventLevelsList(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async confirmAlarmEvent(
    input: ConfirmAlarmEventInput,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.eventConfirmAlarmEvent(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public stream = new ServerStream(() =>
    BaseService.apiPool.queue(async (worker) => {
      return { observer: worker.eventLiveAlarmEvent() };
    }),
  );

  public async confirmAllAlarmEvents(
    input: ConfirmAllAlarmEvents,
  ): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.eventConfirmAllAlarmEvents(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async removeAllAlarmEvents(): Promise<Response<void>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.eventRemoveAllAlarmEvents();
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
