import { RpcError } from '@protobuf-ts/runtime-rpc';

import Dependencies from '@/deps';
import { IVisSystemApiLogic } from '@/logic/interfaces';
import { IObjectService } from '@/services/interfaces';
import { LiveWorkStateIn } from '@/types/messages/object';
import { MObject } from '@/types/models';

import { IvisSystemApiStream } from '..';
import { StreamError } from './../../../../../services/types/streamErrors';

export const objectStream = async (
  uuid: string,
  workStates: Record<string, MObject.WorkState>,

  logicKey: {
    sourceUuid: string;
    sourceType: 'Object' | 'Device';
    visualizationUuid: string;
    name: string;
    systemapi_name: string;
  },

  interval?: number,
): Promise<IvisSystemApiStream> => {
  let logicCallback: null | ((data: Record<string, unknown>) => void) = null;

  const objectService = Dependencies.get(IObjectService.$);

  const onMessage = (event: {
    value: MObject.WorkStateData;
    input: LiveWorkStateIn;
  }) => {
    const value = event.value;
    console.log('newWorkStateId', value);
    if (value.newWorkStateId != null) {
      const newWorkStateId = value.newWorkStateId.toString();
      if (newWorkStateId in workStates) {
        const workState = workStates[value.newWorkStateId.toString()];
        const workStateId = workState.workStateId;
        const workStateName = workState.name;
        const workStateDesc = workState.desc;
        const workStateColor = workState.color;
        const workStateDate = value.datetime;

        if (logicCallback != null) {
          logicCallback({
            work_state_id: workStateId,
            work_state_name: workStateName,
            work_state_desc: workStateDesc,
            work_state_color: workStateColor,
            datetime: workStateDate,
          });
        }
      } else {
        console.error('work state missing', newWorkStateId, workStates);
      }
    } else {
      if (logicCallback != null) {
        logicCallback({
          work_state_id: null,
          work_state_name: '---',
          work_state_desc: '---',
          work_state_color: undefined,
          datetime: value.datetime,
        });
      }
    }
  };

  const onDisconnect = async (error: Error) => {
    const logic = Dependencies.get(IVisSystemApiLogic.$)(logicKey);

    if (error.name === 'RpcError') {
      await new Promise((r) => setTimeout(r, 2000));

      const rpcError = error as RpcError;

      if (
        (rpcError.code === 'INTERNAL' &&
          (rpcError.message === StreamError.NetworkError ||
            rpcError.message === StreamError.FailedToFetch)) ||
        rpcError.code === 'ABORTED' ||
        rpcError.code === 'RESOURCE_EXHAUSTED'
      ) {
        logic.actions.stop();
        logic.actions.start();
        return;
      } else {
        logic.actions.stop();
        return;
      }
    }

    logic.actions.stop();
  };

  const liveWorkStateIn: LiveWorkStateIn = {
    uuid: uuid,
    interval: interval,
    workStateId: [],
  };

  await objectService.stream.listen(
    {
      onMessage,
      onDisconnect,
    },
    liveWorkStateIn,
  );

  return {
    cancel() {
      objectService.stream.cancel(onMessage, liveWorkStateIn);
    },
    callback(callback) {
      logicCallback = callback;
    },
  } as IvisSystemApiStream;
};
