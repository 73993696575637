import { FC } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from '@/hooks';

import { Group, sidebarModel } from '../../modules/settings/model';
import { SidebarItem } from './item';
import './style.scss';

export const Sidebar: FC = (props) => {
  return (
    <div className="sidebar-wide select-none">
      <ul className="sidebar-container">
        {sidebarModel.map((groupModel) => {
          return <SidebarGroup key={groupModel.title} {...groupModel} />;
        })}
      </ul>
    </div>
  );
};

interface SidebarWideGroupProps extends Group {}

const SidebarGroup: FC<SidebarWideGroupProps> = (props) => {
  const t = useTranslation();

  return (
    <li className="sidebar-group">
      <span className="sidebar-group-title">{t(props.title)}</span>
      <div className="sidebar-group-container">
        {props.items.map((item) => {
          return <SidebarItem key={item.title} {...item} />;
        })}
      </div>
      {props.spacer && <div className="sidebar-spacer" />}
    </li>
  );
};
