import {
  IVisualization as OCSIVisualization,
  IVisualizationElement as OCSIVisualizationElement,
  IVisualizationLogicProps as OCSIVisualizationLogicProps,
  SystemAPIDataset as OCSSystemAPIDataset,
} from '@ocs/ocs-components';
import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MObject } from '@/types/models';

export type SystemAPIDataset = OCSSystemAPIDataset;

// begin visualzation logic
interface IVisualization extends OCSIVisualization {
  readonly selectors: {
    readonly systemApiValues: (
      state: Record<string, Record<string, unknown>>,
    ) => Record<string, Record<string, unknown>>;
    systemapis: (state: any, props?: any) => Record<string, SystemAPIDataset>;
    readonly workStates: (
      workStates: Record<string, MObject.WorkState>,
    ) => Record<string, MObject.WorkState>;
  };
}

export interface IVisualizationLogic extends LogicWrapper<IVisualization> {}
export namespace IVisualizationLogic {
  export const $: interfaces.ServiceIdentifier<IVisualizationLogic> = Symbol(
    'IVisualizationLogic',
  );
}

// begin element logic

interface IVisualizationElement extends OCSIVisualizationElement {
  readonly actions: {
    updateTaskValue: (
      taskIndex: number,
      taskValue: string | number | null,
    ) => void;
  };
}

export interface IVisualizationElementLogic
  extends LogicWrapper<IVisualizationElement> {}
export namespace IVisualizationElementLogic {
  export const $: interfaces.ServiceIdentifier<IVisualizationElementLogic> =
    Symbol('IVisualizationElementLogic');
}

// end element logic

// begin system api logic

export interface IVisSystemApiLogicProps extends OCSIVisualizationLogicProps {
  systemapi_name: string;
}

interface IVisSystemApi extends Logic {
  readonly props: IVisSystemApiLogicProps;
  readonly actions: {
    readonly start: () => void;
    readonly stop: () => void;
  };
  readonly values: {};
}

export interface IVisSystemApiLogic extends LogicWrapper<IVisSystemApi> {}

export namespace IVisSystemApiLogic {
  export const $: interfaces.ServiceIdentifier<IVisSystemApiLogic> =
    Symbol('IVisSystemApiLogic');
}
