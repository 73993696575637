import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { EventView } from '@/types/eventView';

export interface IEventLogicProps extends Record<string, unknown> {
  eventId: string;
}

export interface IEvent extends Logic {
  readonly props: IEventLogicProps;
  readonly actions: {
    readonly confirm: (value: string) => void;
  };
  readonly values: {
    readonly eventView: EventView | null;
    readonly confirmSending: boolean;
    readonly confirmSuccess: boolean;
    readonly confirmError: boolean;
  };
}

export interface IEventLogic extends LogicWrapper<IEvent> {}

export const IEventLogicSymbol: interfaces.ServiceIdentifier<IEventLogic> =
  Symbol('IEventLogic');

export namespace IEventLogic {
  export const $: interfaces.ServiceIdentifier<IEventLogic> = IEventLogicSymbol;
}
