import { interfaces } from 'inversify';
import { BuiltLogic, Logic, LogicWrapper } from 'kea';

import { EventView } from '@/types/models/event';

export interface IEventViewLogicProps {
  eventId: string;
}

interface IEventView extends Logic {
  readonly props: IEventViewLogicProps;
  readonly actions: {
    readonly confirm: (value: string) => void;
  };
  readonly values: {
    readonly eventView: EventView | null;
    readonly confirmSending: boolean;
    readonly confirmSuccess: boolean;
    readonly confirmError: boolean;
  };
  readonly selectors: {
    readonly eventView: (state: unknown) => EventView | null;
  };
}

export type IEventViewLogic = LogicWrapper<IEventView>;

export interface IEventViewLogicBuilt extends BuiltLogic<IEventView> {}

export namespace IEventViewLogic {
  export const $: interfaces.ServiceIdentifier<IEventViewLogic> =
    Symbol('IEventViewLogic');
}
