import { useInjection } from 'inversify-react';
import { useValues } from 'kea';
import { FC, useMemo, useState } from 'react';
import { CSSProperties } from 'styled-components';

import { IAppLogic } from '@/logic/interfaces';
import { convertToRem } from '@/utility/style';

import './style.scss';

interface CenterFoldingTileProps {
  components: JSX.Element[];
  size: number;
  dynamicSize: number;
}

export const CenterFoldingTile: FC<CenterFoldingTileProps> = (props) => {
  const appLogic = useInjection(IAppLogic.$);

  const { fontSize } = useValues(appLogic);

  const [isVisible, setVisible] = useState(false);
  const [isCollapsed, setCollapsed] = useState(true);

  const absoluteStyle = useMemo(() => {
    const left = -props.dynamicSize / 2 - props.size / 2;
    const minWidth = props.dynamicSize + props.size;

    return {
      left: `${convertToRem(left, fontSize)}rem`,
      minWidth: `${convertToRem(minWidth, fontSize)}rem`,
    } as CSSProperties;
  }, [props.dynamicSize]);

  const containerStyle = useMemo(() => {
    let alignmentStyle: CSSProperties = {};
    let visibleStyle: CSSProperties = {};

    alignmentStyle = {
      left: `${convertToRem(props.size / 3, fontSize)}rem`,
      minWidth: `${convertToRem(
        props.dynamicSize + props.size / 3,
        fontSize,
      )}rem`,
    };

    if (!isVisible) {
      visibleStyle = {
        left: `${convertToRem(
          props.dynamicSize / 2 + Math.floor(props.size / 1.3),
          fontSize,
        )}rem`,
        minWidth: 0,
      };
    }

    return { ...alignmentStyle, ...visibleStyle };
  }, [props.dynamicSize, isVisible]);

  return (
    <div
      onMouseLeave={() => {
        setVisible(false);
      }}
      onMouseOver={() => {
        setVisible(true);
        setCollapsed(false);
      }}
      className="OCS-folding-tile"
    >
      <i className="ri ri-menu-line" />
      <div
        className={`${isCollapsed ? 'OCS-v-hidden' : ''} OCS-absolute`}
        style={absoluteStyle}
      >
        <div
          onTransitionEnd={(event) => {
            if (!isVisible && event.propertyName == 'left') setCollapsed(true);
          }}
          className="OCS-folding-component-container OCS-folding-component-container-center"
          style={containerStyle}
        >
          <div className="OCS-absolute">
            {props.components.map((component) => {
              return component;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
