import React, {
  FunctionComponent,
  Suspense,
  memo,
  useEffect,
  useState,
} from 'react';

interface LazyLoaderProps {
  promise: () => Promise<{ default: FunctionComponent<any> }>;
  setLoaded: () => void;
  loaded: boolean;
  widgetProps?: object;
}

const LazyLoader: FunctionComponent<LazyLoaderProps> = memo((props) => {
  const [retry, setRetry] = useState(true);

  const Lazy = React.useMemo(() => {
    return React.lazy(async () => {
      try {
        const widget = await props.promise();
        if (!props.loaded) {
          await new Promise((r) => setTimeout(r, 1500));
          props.setLoaded();
        }
        return widget;
      } catch {
        setRetry(!retry);
      }
      return { default: () => <div /> };
    });
  }, [props.promise, retry]);

  return (
    <Suspense
      fallback={(() => {
        return <div />;
      })()}
    >
      <Lazy {...props.widgetProps} />
    </Suspense>
  );
});

export default LazyLoader;
