import { IvisSystemApiStream } from '..';
import { definedAlarmStream } from '../streams/definedAlarm';
import { AlarmAlias } from './../index';

export const getDefinedAlarmStream = async (
  alarmAlias: AlarmAlias,
  logicKey: {
    sourceUuid: string;
    sourceType: 'Object' | 'Device';
    visualizationUuid: string;
    name: string;
    systemapi_name: string;
  },
): Promise<IvisSystemApiStream | null> => {
  return await definedAlarmStream(
    alarmAlias.sourceUuid,
    alarmAlias.interval,
    logicKey,
  );
};
