import { deepEqual, shallowEqual } from 'fast-equals';
import { t } from 'i18next';
import {
  actions,
  afterMount,
  beforeUnmount,
  events,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from 'kea';

import Dependencies from '@/deps';
import { TobjectWindowTabsKeys, objectWindowTabs } from '@/logic/interfaces';
import {
  IDeviceTableLogic,
  IObjectDetailContainerLogic,
} from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IObjectService, IVisualizationService } from '@/services/interfaces';
import { MObject } from '@/types/models';

import type { logicType } from './indexType';

export interface ObjectWindowLogicProps {
  initialData: {
    objectUuid: string;
  };
  deps: {
    objectDetailContainerLogic: IObjectDetailContainerLogic;
    deviceTableLogic: IDeviceTableLogic;
    objectService: IObjectService;
    visualizationService: IVisualizationService;
  };
}

const logic = kea<logicType>([
  props({} as ObjectWindowLogicProps),
  key((props) => props.initialData.objectUuid),
  path((key) => ['objectWindow', key]),
  actions({
    setActiveTab: (tab: TobjectWindowTabsKeys) => ({ tab }),
    setObjectLoaded: (loaded: boolean) => ({ loaded }),
    setVisualizationName: (name: string) => ({ name }),
    loadVisualization: (visualizationUuid?: string) => ({ visualizationUuid }),
    loadResources: true,
    loadObject: true,
    useResources: true,
    releaseResources: true,
  }),
  reducers({
    tab: [
      'visualization' as TobjectWindowTabsKeys,
      { setActiveTab: (_, { tab }) => tab },
    ],
    objectLoaded: [false, { setObjectLoaded: (_, { loaded }) => loaded }],
    visualizationName: ['', { setVisualizationName: (_, { name }) => name }],
  }),
  listeners(({ props, actions, values }) => ({
    loadResources: () => {
      actions.loadObject();
    },
    loadObject: async () => {
      if (values.object == null) {
        const objectResponse = await props.deps.objectService.get({
          objectUuid: values.objectUuid,
        });

        if (objectResponse.success && objectResponse.data != null) {
          props.deps.objectDetailContainerLogic.actions.addObjects([
            objectResponse.data,
          ]);
          if (objectResponse.data?.settings.visualizationUuid != null) {
            actions.loadVisualization(
              objectResponse.data?.settings.visualizationUuid,
            );
          }
          actions.setObjectLoaded(true);
        } else {
          await await new Promise((r) => setTimeout(r, 100));
          actions.loadObject();
        }
      } else {
        actions.setObjectLoaded(true);
      }
    },
    loadVisualization: async ({ visualizationUuid }) => {
      if (values.object !== null || visualizationUuid !== undefined) {
        const uuid = visualizationUuid
          ? visualizationUuid
          : values.object.settings.visualizationUuid;

        const response = await props.deps.visualizationService.get({
          visualizationUuid: uuid,
        });

        if (response.success) {
          if (response.data?.data.filename)
            actions.setVisualizationName(response.data.data.filename);
        }
      }
    },
    useResources: async () => {},
    releaseResources: () => {},
  })),
  selectors(({ props }) => ({
    objectUuid: [
      () => [
        (_, props: ObjectWindowLogicProps) => props.initialData.objectUuid,
      ],
      (objectUuid) => objectUuid,
    ],
    object: [
      (selectors) => [
        selectors.objectUuid,
        props.deps.objectDetailContainerLogic.selectors.objects,
      ],
      (objectUuid, objects) => {
        if (objectUuid in objects) {
          return objects[objectUuid] as MObject.Full;
        }
        return null;
      },
    ],
    devices: [
      () => [
        props.deps.deviceTableLogic({
          objectUuid: props.initialData.objectUuid,
        }).selectors.values,
      ],
      (values) => {
        if (values != null) {
          return values;
        } else {
          return [];
        }
      },
      { resultEqualityCheck: shallowEqual },
    ],
    currentTab: [
      (selectors) => [selectors.tab, selectors.visableTabs],
      (tab, visableTabs) => {
        if (visableTabs.length == 0) {
          return null;
        }
        return visableTabs.find((visableTab) => visableTab.key === tab) != null
          ? tab
          : (visableTabs[0].key as TobjectWindowTabsKeys);
      },
      // visableTabs.find((v) => v == tab) !== undefined ? tab : visableTabs[0],
    ],
    visableTabs: [
      (selectors) => [selectors.object, selectors.devices],
      (object, devices) =>
        object != null
          ? Object.keys(objectWindowTabs)
              .filter((tabKey) => {
                const test = objectWindowTabs[
                  tabKey as TobjectWindowTabsKeys
                ].visable(object, devices);

                return test;
              })
              .map((tabKey) => {
                const tab = objectWindowTabs[tabKey as TobjectWindowTabsKeys];
                return {
                  key: tabKey,
                  label: t(`object.view.${tabKey}`),
                  icon: tab.icon,
                  index: tab.index,
                };
              })
          : [],
      { resultEqualityCheck: deepEqual },
    ],
  })),
  events(({ props }) => ({
    beforeMount: () => {
      props.deps
        .deviceTableLogic({ objectUuid: props.initialData.objectUuid })
        .mount();
    },
    afterUnmount: () => {
      props.deps
        .deviceTableLogic({ objectUuid: props.initialData.objectUuid })
        .unmount();
    },
  })),
  afterMount(({ actions, values }) => {
    actions.loadResources();
    actions.loadVisualization();
    actions.useResources();
  }),
  beforeUnmount(({ actions }) => {
    actions.releaseResources();
  }),
]);

export const objectWindowLogic = injectDepsToLogic(logic, () => ({
  objectDetailContainerLogic: Dependencies.get(IObjectDetailContainerLogic.$),
  deviceTableLogic: Dependencies.get(IDeviceTableLogic.$),
  objectService: Dependencies.get(IObjectService.$),
  visualizationService: Dependencies.get(IVisualizationService.$),
}));
