import { injectable } from 'inversify';

import { Dependencies } from '@/deps';
import { IModules } from '@/modules';
import type { UnvalidatedWindow, Window } from '@/types/models/desktop';

@injectable()
export class DesktopValidators {
  public validateWindow(window: UnvalidatedWindow): window is Window {
    const modules = Dependencies.get(IModules.$);

    if (!(window.type in modules)) {
      return false;
    }

    const module = modules[window.type as keyof typeof modules];
    if (
      module.logicData?.logicIdentifier &&
      module.logicData.logicPropsValidator
    ) {
      if (!window.initialData) {
        return false;
      }
      if (!module.logicData.logicPropsValidator(window.initialData)) {
        return false;
      }
    }
    return true;
  }
}
