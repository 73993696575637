import { FC } from 'react';

import { Tag } from '@/components/tag';

import './style.scss';

interface ColorTemplateProps {
  value: string;
}

export const ColorTemplate: FC<ColorTemplateProps> = (props) => {
  return <Tag label={''} color={props.value} className={'ocs-tag-color'} />;
};
