import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import {
  ConfirmStatus,
  EditorValue,
  LoadingStatus,
} from '@/types/custom/editorTypes';

export interface IGroupAlarmsAddFormProps extends Record<string, unknown> {
  initialData: {
    objectUuid: string;
    alarmGroupUuid?: string;
  };
}

interface IGroupAlarmsAddForm extends Logic {
  readonly props: IGroupAlarmsAddFormProps;
  readonly actions: {
    readonly updateValue: (
      tileKey: string,
      tabKey: string,
      valueKey: string,
      value: any,
    ) => void;
    readonly confirm: () => void;
    readonly setConfirmStatus: (value: ConfirmStatus) => void;
    readonly remove: () => void;
    readonly setRemoveStatus: (value: ConfirmStatus) => void;
    readonly refresh: () => void;
  };
  readonly values: {
    readonly isSaving: boolean;
    readonly confirmStatus: ConfirmStatus;
    readonly removeStatus: ConfirmStatus;
    readonly loadStatus: LoadingStatus;
    readonly isRemoveActive: boolean;
  };
  readonly selectors: {
    readonly value: (
      value: unknown,
    ) => Record<string, Record<string, Record<string, EditorValue>>>;
  };
}

export interface IGroupAlarmsAddFormLogic
  extends LogicWrapper<IGroupAlarmsAddForm> {}

export namespace IGroupAlarmsAddFormLogic {
  export const $: interfaces.ServiceIdentifier<IGroupAlarmsAddFormLogic> =
    Symbol('IGroupAlarmsAddFormLogic');
}
