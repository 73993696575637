/* eslint-disable @typescript-eslint/require-await */
import { injectable } from 'inversify';

import { IGenericDeviceService } from '@/services/interfaces';
import { DownloadIn, DownloadOut } from '@/types/messages/genericdevice';
import { MGenericDevice } from '@/types/models';

import { ServerStream } from '../stream';
import { Response } from '../types/response';
import { BaseService } from './base';

@injectable()
export class GenericDeviceService
  extends BaseService
  implements IGenericDeviceService
{
  public async graph(
    input: MGenericDevice.Messages.GraphIn,
  ): Promise<Response<MGenericDevice.Messages.GraphOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGraph(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async graphBytes(
    input: MGenericDevice.Messages.GraphBytesIn,
  ): Promise<Response<MGenericDevice.Messages.GraphBytesOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGraphBytes(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async get(
    input: MGenericDevice.Messages.GetIn,
  ): Promise<Response<MGenericDevice.Messages.GetOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGet(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async store(
    input: MGenericDevice.Messages.StoreIn,
  ): Promise<Response<MGenericDevice.Messages.StoreOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceStore(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getBrands(): Promise<
    Response<MGenericDevice.Messages.GetBrandsOut>
  > {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGetBrands();
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getBrandModbusTasks(
    input: MGenericDevice.Messages.GetBrandModbusTasksIn,
  ): Promise<Response<MGenericDevice.Messages.GetBrandModbusTasksOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGetBrandModbusTasks(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getBrandModbusTaskColumns(
    input: MGenericDevice.Messages.GetBrandModbusTaskColumnsIn,
  ): Promise<Response<MGenericDevice.Messages.GetBrandModbusTaskColumnsOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGetBrandModbusTaskColumns(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getModels(): Promise<
    Response<MGenericDevice.Messages.GetModelsOut>
  > {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGetModels();
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getModelColumns(
    input: MGenericDevice.Messages.GetModelColumnsIn,
  ): Promise<Response<MGenericDevice.Messages.GetModelColumnsOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGetModelColumns(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public async getNow(
    input: MGenericDevice.Messages.GetNowIn,
  ): Promise<Response<MGenericDevice.Messages.GetNowOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceGetNow(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }

  public stream = new ServerStream((input: MGenericDevice.Messages.LiveIn) =>
    BaseService.apiPool.queue(async (worker) => {
      return { observer: worker.genericDeviceLive(input) };
    }),
  );

  public streamBytes = new ServerStream(
    (input: MGenericDevice.Messages.LiveIn) =>
      BaseService.apiPool.queue(async (worker) => {
        return { observer: worker.genericDeviceLiveBytes(input) };
      }),
  );

  public async download(input: DownloadIn): Promise<Response<DownloadOut>> {
    try {
      const task = BaseService.apiPool.queue(async (worker) => {
        return worker.genericDeviceDownload(input);
      });

      return {
        success: true,
        data: await task,
      };
    } catch (error: unknown) {
      return this.parseRequestError(error);
    }
  }
}
