import { Visualization as OCSVisualization } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { useValues } from 'kea';
import { memo } from 'react';

import { IObjectWindowLogic, IVisualizationLogic } from '@/logic/interfaces';

export const VisualizationView = memo(function ObjectView() {
  const viewLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid, object, visualizationName } = useValues(viewLogic);
  const visualizationUuid = object?.settings.visualizationUuid;

  if (visualizationUuid != null) {
    return (
      <div className="visualization-view">
        <OCSVisualization
          visualizationLogicIdentifier={IVisualizationLogic.$}
          properties={{
            sourceUuid: objectUuid,
            sourceType: 'Object',
            visualizationUuid,
            name: visualizationName,
          }}
        ></OCSVisualization>
      </div>
    );
  } else {
    return <div></div>;
  }
});
