import { IPaginationLogic, ITableLogic, Table } from '@ocs/ocs-components';
import { useInjection } from 'inversify-react';
import { BuiltLogic, useActions, useValues } from 'kea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FC } from 'react';

import { config } from '@/config';
import { useTranslation } from '@/hooks';
import { IDesktopLogic, IObjectWindowLogic } from '@/logic/interfaces';
import { IDefinedAlarmsTableLogic } from '@/logic/interfaces/definedAlarmsTable';
import { IGroupAlarmsTableLogic } from '@/logic/interfaces/groupAlarmsTable';
import { MDefinedAlarms } from '@/types/models';
import { CardFooter } from '@/widgets/cardFooter';
import {
  FooterPaginator,
  PaginatorDropdown,
} from '@/widgets/toolBar/footerPaginator';
import { TableRefreshButton } from '@/widgets/toolBar/tableRefreshButton';

import './style.scss';

export const DefinedAlarmsTab: FC = () => {
  const windowLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(windowLogic);

  const definedAlarmsLogic = useInjection(IDefinedAlarmsTableLogic.$)({
    definedAlarmsUuid: 'definedAlarms',
    objectUuid: objectUuid,
  });

  const groupAlarmsLogic = useInjection(IGroupAlarmsTableLogic.$)({
    objectUuid: objectUuid,
  });

  return (
    <div className="ocs-definedAlarms-table">
      <Card className="ocs-datatable-card">
        <div className="ocs-table-wrapper">
          <Table
            tableLogic={
              definedAlarmsLogic as unknown as BuiltLogic<ITableLogic>
            }
            additionalColumns={{
              editor: DefinedAlarmsEditorButton,
            }}
          />
        </div>
        <div className="ocs-footer-wrapper">
          <CardFooter
            leftElements={[
              <PaginatorDropdown
                key={`definedAlarmsDropdown-${objectUuid}`}
                logic={definedAlarmsLogic as unknown as IPaginationLogic}
              />,
              <TableRefreshButton
                key={`definedAlarmsRefresh-${objectUuid}`}
                logic={definedAlarmsLogic as unknown as IPaginationLogic}
              />,
            ]}
            centerElements={[
              <FooterPaginator
                key={`definedAlarmsPaginator-${objectUuid}`}
                logic={definedAlarmsLogic as unknown as IPaginationLogic}
              />,
            ]}
          />
        </div>
      </Card>
      <Card className="ocs-datatable-card">
        <div className="ocs-table-wrapper">
          <Table
            tableLogic={groupAlarmsLogic as unknown as BuiltLogic<ITableLogic>}
            additionalColumns={{ editor: GroupAlarmsEditorButton }}
          />
        </div>
        <div className="ocs-footer-wrapper">
          <CardFooter
            leftElements={[
              <PaginatorDropdown
                key={`groupAlarmsDropdown-${objectUuid}`}
                logic={
                  groupAlarmsLogic as unknown as BuiltLogic<IPaginationLogic>
                }
              />,
              <TableRefreshButton
                key={`groupAlarmsRefresh-${objectUuid}`}
                logic={groupAlarmsLogic as unknown as IPaginationLogic}
              />,
            ]}
            centerElements={[
              <FooterPaginator
                key={`groupAlarmsPaginator-${objectUuid}`}
                logic={
                  groupAlarmsLogic as unknown as BuiltLogic<IPaginationLogic>
                }
              />,
            ]}
            rightElements={[
              <AddGroupButton key={`groupAlarmsAddButton-${objectUuid}`} />,
            ]}
          />
        </div>
      </Card>
    </div>
  );
};

export const AddGroupButton: FC = () => {
  const t = useTranslation();

  const desktopLogic = useInjection(IDesktopLogic.$);
  const { openWindow } = useActions(desktopLogic);

  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const { objectUuid } = useValues(objectWindowLogic);

  return (
    <Button
      icon={'ocs-button-size ri ri-add-box-line'}
      className="p-button-text p-button-plain OCS-footer-button"
      onClick={() => {
        openWindow('alarmsGroupAddForm', {
          initialData: {
            objectUuid: objectUuid,
          },
          windowKey: `addGroupForm/${objectUuid}`,
          title: 'windowTypes.addGroupForm',
        });
      }}
      tooltip={t('tooltips.addGroup')}
      tooltipOptions={{
        position: 'left',
        showDelay: config.tooltip.showDelay,
        baseZIndex: config.tooltip.zIndex,
      }}
    />
  );
};

interface DefinedAlarmsEditorButtonProps {
  rowData: MDefinedAlarms.TableView;
}

const DefinedAlarmsEditorButton: FC<DefinedAlarmsEditorButtonProps> = (
  props,
) => {
  const desktopLogic = useInjection(IDesktopLogic.$);

  const { openWindow } = useActions(desktopLogic);

  return (
    <Button
      className="p-button-outlined"
      icon={'ri ri-edit-2-fill'}
      onClick={() => {
        openWindow('definedAlarmsEdit', {
          initialData: {
            definedAlarmsUuid: props.rowData.definedAlarmUuid,
            editorUuid: props.rowData.definedAlarmUuid,
            objectUuid: props.rowData.objectUuid,
          },
          windowKey: props.rowData.definedAlarmUuid,
          title: 'windowTypes.definedAlarmsEdit',
        });
      }}
    />
  );
};

interface GroupAlarmsEditorButton {
  rowData: MDefinedAlarms.TableGroupView;
}

const GroupAlarmsEditorButton: FC<GroupAlarmsEditorButton> = (props) => {
  const desktopLogic = useInjection(IDesktopLogic.$);

  const { openWindow } = useActions(desktopLogic);

  return (
    <Button
      className="p-button-outlined"
      icon={'ri ri-edit-2-fill'}
      onClick={() => {
        openWindow('alarmsGroupAddForm', {
          initialData: {
            objectUuid: props.rowData.objectUuid,
            alarmGroupUuid: props.rowData.definedAlarmGroupUuid,
          },
          windowKey: `editGroupForm/${props.rowData.objectUuid}/${props.rowData.definedAlarmGroupUuid}`,
          title: 'windowTypes.editGroupForm',
        });
      }}
    />
  );
};
