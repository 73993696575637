import { IVisElementLogicProps as OCSVisElementLogicProps } from '@ocs/ocs-components';
import { actions, kea, key, path, props, selectors } from 'kea';

import Dependencies from '@/deps';
import { IVisualizationLogic } from '@/logic/interfaces';
import { injectDepsToLogic } from '@/logic/utils';
import { IVisualizationService } from '@/services/interfaces';

import type { logicType } from './indexType';

export interface VisElementLogicProps extends OCSVisElementLogicProps {
  deps: {
    visualizationService: IVisualizationService;
    visualizationLogic: IVisualizationLogic;
  };
}

const logic = kea<logicType>([
  props({} as VisElementLogicProps),
  key(
    (props) =>
      `${props.sourceType}/${props.sourceUuid}/${props.visualizationUuid}/${props.name}/${props.elementUuid}`,
  ),
  path((key) => ['viselement', key]),
  actions({
    updateTaskValue: (
      taskIndex: number,
      taskValue: string | number | null,
    ) => ({ taskIndex, taskValue }),
  }),
  selectors(({ props }) => ({
    values: [
      () => [
        props.deps.visualizationLogic({
          sourceType: props.sourceType,
          sourceUuid: props.sourceUuid,
          visualizationUuid: props.visualizationUuid,
          name: props.name,
        }).selectors.systemApiValues,
      ],
      (systemApiValues) => {
        const values = [] as (string | number | null)[];

        if (props.tasks != null) {
          for (const task of props.tasks) {
            if (
              task.systemApiDataset != null &&
              task.datasetField != null &&
              systemApiValues[task.systemApiDataset] != null
            ) {
              let value = systemApiValues[task.systemApiDataset][
                task.datasetField
              ] as number;
              if (task.scale) {
                value = (value * task.multiplier) / task.divider;
              }
              values.push(
                task.precision != null ? value.toFixed(task.precision) : value,
              );
            }
          }
        }
        return values;
      },
    ],
  })),
]);

export const visElementLogic = injectDepsToLogic(logic, () => ({
  visualizationService: Dependencies.get(IVisualizationService.$),
  visualizationLogic: Dependencies.get(IVisualizationLogic.$),
}));
