import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import { IStream } from '@/services/types/stream';
import {
  VibrodetectorGetIn,
  VibrodetectorGetOut,
  VibrodetectorGraphInput,
  VibrodetectorGraphLiveInput,
  VibrodetectorGraphMessage,
  VibrodetectorStoreIn,
  VibrodetectorStoreOut,
} from '@/types/messages/vibrodetector';
import { MDevice, MGenericDevice } from '@/types/models';
import { VibrodetectorData } from '@/types/models/vibrodetector';

export interface IVibrodetectorService {
  getGraphData: (
    input: VibrodetectorGraphInput,
  ) => Promise<Response<VibrodetectorGraphMessage>>;
  stream: IStream<VibrodetectorGraphLiveInput, VibrodetectorGraphMessage>;
  getNow: (
    input: MDevice.Messages.GetIn,
  ) => Promise<Response<VibrodetectorData>>;
  download: (
    input: MGenericDevice.Messages.DownloadIn,
  ) => Promise<Response<MGenericDevice.Messages.DownloadOut>>;
  get: (input: VibrodetectorGetIn) => Promise<Response<VibrodetectorGetOut>>;
  store: (
    input: VibrodetectorStoreIn,
  ) => Promise<Response<VibrodetectorStoreOut>>;
}

export namespace IVibrodetectorService {
  export const $: interfaces.ServiceIdentifier<IVibrodetectorService> = Symbol(
    'IVibrodetectorService',
  );
}
