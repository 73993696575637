import { actions, events, kea, listeners, props, reducers } from 'kea';

import Dependencies from '@/deps';
import { startGlobalLogics } from '@/logic';
import { injectDepsToLogic } from '@/logic/utils';
import { IUserService } from '@/services/interfaces';
import { LoginCheckStatus, LoginStatus } from '@/types/models/user';

import type { logicType } from './indexType';

const logic = kea<logicType>([
  props(
    {} as {
      deps: {
        userService: IUserService;
      };
    },
  ),
  actions({
    checkLogin: true,
    logIn: (login: string, password: string) => ({ login, password }),
    logOut: true,
    setLoggedIn: true,
    setLoggedOut: true,
    setLoginError: true,
    setCheckError: true,
    setHomeLoaded: true,
  }),
  reducers({
    loginCheckStatus: [
      LoginCheckStatus.IDLE as LoginCheckStatus,
      {
        checkLogin: (state) =>
          state != LoginCheckStatus.ERROR ? LoginCheckStatus.CHECKING : state,
        setLoggedIn: () => LoginCheckStatus.CHECKED,
        setLoggedOut: () => LoginCheckStatus.CHECKED,
        setCheckError: () => LoginCheckStatus.ERROR,
      },
    ],
    loginStatus: [
      LoginStatus.NOT_CHECKED as LoginStatus,
      {
        logIn: () => LoginStatus.LOGGING_IN,
        checkLogin: () => LoginStatus.NOT_CHECKED,
        setLoggedIn: () => LoginStatus.LOGGED_IN,
        setLoggedOut: () => LoginStatus.LOGGED_OUT,
        setLoginError: () => LoginStatus.LOGIN_ERROR,
      },
    ],
    homeLoaded: [
      false,
      {
        setHomeLoaded: () => true,
      },
    ],
  }),
  listeners(({ actions, props }) => ({
    checkLogin: async () => {
      const response = await props.deps.userService.logged();
      await new Promise((r) => setTimeout(r, 1000));

      if (response.success) {
        actions.setLoggedIn();
        return;
      }

      if (response.error.status != '10') {
        actions.setLoggedOut();
        return;
      }

      actions.setCheckError();
      actions.checkLogin();
    },
    logIn: async ({ login, password }) => {
      const response = await props.deps.userService.login({ login, password });
      await new Promise((r) => setTimeout(r, 500));

      if (response.success) {
        actions.setLoggedIn();
        return;
      }
      await new Promise((r) => setTimeout(r, 1500));

      actions.setLoginError();
      await new Promise((r) => setTimeout(r, 2500));
      actions.setLoggedOut();
    },
    logOut: async () => {
      const response = await props.deps.userService.logout();
      if (response.success) {
        actions.setLoggedOut();
        return;
      }
    },
    setLoggedIn: async () => {
      startGlobalLogics();
    },
  })),
  events(({ actions }) => ({ afterMount: [actions.checkLogin] })),
]);

export const loginLogic = injectDepsToLogic(logic, () => ({
  userService: Dependencies.get(IUserService.$),
}));
