export interface Group {
  icon: string;
  title: string;
  items: Item[];
  spacer: boolean;
}

export interface Item {
  icon: string;
  title: string;
  view: Views;
}

export enum Views {
  Appearances,
  Test,
}

export const sidebarModel: Group[] = [
  {
    icon: 'pi-users',
    title: 'settings.visualization.visualization',
    items: [
      {
        icon: 'pi pi-images',
        title: 'settings.visualization.appearance',
        view: Views.Appearances,
      },
    ],
    spacer: true,
  },
];
