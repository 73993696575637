import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { MObject } from '@/types/models';

export type IObjectViewContainerLogicProps = Record<string, unknown>;

interface IObjectViewContainer extends Logic {
  readonly props: IObjectViewContainerLogicProps;
  readonly actions: {
    readonly addObjects: (objects: MObject.View[]) => void;
    readonly useObjects: (objectIds: string[]) => void;
    readonly releaseObjects: (objectIds: string[]) => void;
  };
  readonly values: {
    objects: Record<string, MObject.View>;
  };
  readonly selectors: {
    readonly objects: (state: unknown) => Record<string, MObject.View>;
  };
}

export type IObjectViewContainerLogic = LogicWrapper<IObjectViewContainer>;

export namespace IObjectViewContainerLogic {
  export const $: interfaces.ServiceIdentifier<IObjectViewContainerLogic> =
    Symbol('IObjectViewContainerLogic');
}
