import { FC, useCallback, useEffect, useRef } from "react";

import "./style.scss";

interface EventLinkProps {
  details: string;
  detailsOnClick?: () => void;
}

export const EventLink: FC<EventLinkProps> = (props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onMouseDown = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }, []);

  useEffect(() => {
    containerRef.current?.addEventListener("mousedown", onMouseDown);
    return () => {
      containerRef.current?.removeEventListener("mousedown", onMouseDown);
    };
  }, []);

  return (
    <>
      {props.detailsOnClick === undefined ? (
        <span>{props.details}</span>
      ) : (
        <div
          ref={containerRef}
          className={
            props.detailsOnClick != null ? "ocs-eventLink-clickable" : ""
          }
          onClick={(e) => {
            if (props.detailsOnClick != null) {
              props.detailsOnClick();
              e.stopPropagation();
            }
          }}
        >
          {props.details}
          <i className="ocs-eventLink-icon pi pi-link" />
        </div>
      )}
    </>
  );
};
