import { interfaces } from 'inversify';
import { Logic, LogicWrapper } from 'kea';

import { Themes } from '@/themes';

export interface IAppLogicProps extends Record<string, unknown> {}

interface IApp extends Logic {
  readonly props: IAppLogicProps;
  readonly actions: {
    readonly setTheme: (theme: Themes) => void;
    readonly setFontSize: (fontSize: number) => void;
    readonly setAlarmsVisibility: (value: boolean) => void;
  };
  readonly values: {
    readonly theme: Themes;
    readonly fontSize: number;
    readonly alarmsVisible: boolean;
  };
}

export interface IAppLogic extends LogicWrapper<IApp> {}

export namespace IAppLogic {
  export const $: interfaces.ServiceIdentifier<IAppLogic> = Symbol('IAppLogic');
}
