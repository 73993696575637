import { interfaces } from 'inversify';
import { useContainer, useInjection } from 'inversify-react';
import { BindLogic, useActions, useValues } from 'kea';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowToggleParams } from 'primereact/datatable';
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useStorybookTranslation } from '@/i18next';
import { ITableLogic } from '@/logic/interfaces/table/table';
import { ColumnBasicWidths, ColumnType } from '@/types/table';

import { Reload } from '../reload';
import { FilterType } from './filters';
import './style.scss';
import { ColumnTemplate } from './templates/core';

export interface AdditionalColumnsProps {
  rowData: any;
}

export interface TableProps {
  tableLogicIdentifier?: interfaces.ServiceIdentifier<ITableLogic>;
  tableLogic?: ITableLogic;
  innerTable?: boolean;
  additionalColumns?: Record<
    string,
    React.FunctionComponent<AdditionalColumnsProps>
  >;
}

export const Table: FC<TableProps> = (props) => {
  let tableLogic: ITableLogic | null = null;
  const container = useContainer();

  if (props.tableLogicIdentifier !== undefined) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    tableLogic = useInjection(props.tableLogicIdentifier);
  } else if (props.tableLogic !== undefined) {
    tableLogic = props.tableLogic;
  } else {
    throw 'Table needs to have a logic to work properly.';
  }

  const t = useStorybookTranslation();
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const onApply = useRef<(() => void) | null>(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const { refresh, onRowClicked, setSortOptions, setExpandedRowKey } =
    useActions(tableLogic);

  const {
    columns,
    columnTypes,
    sortableColumns,
    filterableColumns,
    values,
    filters,
    expand,
    dataKey,
    columnWidths,
    sortOptions,
    loading,
    error,
  } = useValues(tableLogic);

  const onRowToggle = useCallback(
    (e: DataTableRowToggleParams) => {
      if (expandedRow != null && expandedRow in e.data) {
        delete e.data[expandedRow as any];
      }
      const row = Object.keys(e.data)[0];
      setExpandedRow(row);
      if (setExpandedRowKey != null && row != null) setExpandedRowKey(row);
    },
    [expandedRow],
  );

  const rowExpansionTemplate = (rowData: Record<string, any>) => {
    if (expand != null) {
      const expandTableLogic = container.get(expand.logic);
      const props: { [key: string]: any } = {};

      for (const key in expand.columns) {
        props[key] = rowData[expand.columns[key]];
      }

      return (
        <BindLogic logic={expandTableLogic} props={props}>
          <Table innerTable tableLogic={expandTableLogic} />
        </BindLogic>
      );
    }
    return <span> WYSTĄPIŁ BŁĄD </span>;
  };

  return (
    <div className="table-container">
      <DataTable
        tableClassName={
          props.innerTable ? 'ocs-table-innertable' : 'ocs-table-outertable'
        }
        className="table-body"
        showGridlines={true}
        expandedRows={
          expand != null && expandedRow != null
            ? { [expandedRow]: true }
            : undefined
        }
        onRowToggle={expand !== null ? onRowToggle : undefined}
        rowExpansionTemplate={rowExpansionTemplate}
        loading={loading}
        value={values !== null ? values : undefined}
        sortField={sortOptions != undefined ? sortOptions.field : undefined}
        sortOrder={sortOptions != undefined ? sortOptions.order : undefined}
        onSort={(e) => {
          if (
            setSortOptions != null &&
            e.sortField != null &&
            e.sortOrder != null
          ) {
            setSortOptions({ field: e.sortField, order: e.sortOrder });
          }
          refresh();
        }}
        dataKey={dataKey}
        scrollable
        stripedRows
        selection={onRowClicked != null ? selectedRow : undefined}
        onSelectionChange={
          onRowClicked != null ? (e) => setSelectedRow(e.value) : undefined
        }
        selectionMode={onRowClicked != null ? 'single' : undefined}
        onRowClick={(e) => {
          if (onRowClicked != null) {
            onRowClicked(e.data[dataKey]);
          }
        }}
      >
        {expand != null ? (
          <Column className="table-expander-column" key={'expander'} expander />
        ) : null}
        {props.additionalColumns != null
          ? Object.entries(props.additionalColumns).map(([key, Element]) => {
              return (
                <Column
                  className="ocs-additional-column"
                  key={key}
                  body={(rowData) => {
                    return <Element rowData={rowData} />;
                  }}
                  style={{ flex: '0 0', flexBasis: '3.5rem', expanded: true }}
                />
              );
            })
          : null}
        {columns?.map((columnDef) => {
          return (
            <Column
              filterMenuClassName="ocs-no-table-filters"
              key={columnDef.field}
              sortable={
                sortableColumns != null &&
                sortableColumns.includes(columnDef.field)
                  ? true
                  : false
              }
              filter={
                filterableColumns != null &&
                filterableColumns.includes(columnDef.field)
                  ? true
                  : false
              }
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              filterElement={() => {
                return columnTypes != null ? (
                  <FilterType
                    onApply={onApply}
                    filters={filters != null ? filters[columnDef.field] : []}
                    tableLogic={props.tableLogic}
                    tableLogicIdentifier={props.tableLogicIdentifier}
                  />
                ) : undefined;
              }}
              filterApply={(options) => {
                //TODO: NAPRAWIĆ ZAMYKANIE FILTRÓW
                onApply.current = options.filterApplyCallback;
                return <></>;
              }}
              showClearButton={false}
              header={t(columnDef.header)}
              field={columnDef.field}
              style={{
                flex: defineColumnStyle(
                  columnWidths,
                  columnTypes,
                  columnDef.field,
                ),
              }}
              body={(rowData) => (
                <ColumnTemplate
                  type={
                    columnTypes != null
                      ? columnTypes[columnDef.field]
                      : undefined
                  }
                  value={rowData[columnDef.field]}
                />
              )}
            />
          );
        })}
      </DataTable>
      {error ? (
        <div className="p-component-overlay ocs-table-overlay">
          <Reload
            errorLabel={t('table.reload.error')}
            buttonLabel={t('table.reload.button')}
            stateSetter={() => {
              refresh();
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

function defineColumnStyle(
  columnWidths: { [key: string]: string } | null | undefined,
  columnTypes: { [key: string]: ColumnType } | null,
  field: string,
) {
  if (columnWidths != null && field in columnWidths)
    return `${columnWidths[field]}`;
  else if (columnTypes != null) {
    if (field in columnTypes && columnTypes[field] in ColumnBasicWidths) {
      const column = ColumnBasicWidths[columnTypes[field]];

      return `${column.flex} ${
        column.flexBasis + (column.expanded ? 2.2 : 0)
      }rem`;
    } else {
      return '0 0 12rem';
    }
  }
}
