import { interfaces } from 'inversify';

import { Response } from '@/services/types/response';
import {
  CreateLayoutInput,
  CreateLayoutMessage,
  RemoveLayoutInput,
  SetCurrentLayoutInput,
  UpdateLayoutInput,
} from '@/types/messages/desktop';
import { Desktop } from '@/types/models/desktop';

export interface IDesktopService {
  getDesktop: () => Promise<Response<Desktop>>;
  setCurrentLayout: (input: SetCurrentLayoutInput) => Promise<Response<void>>;
  updateLayout: (input: UpdateLayoutInput) => Promise<Response<void>>;
  createLayout: (
    input: CreateLayoutInput,
  ) => Promise<Response<CreateLayoutMessage>>;
  removeLayout: (input: RemoveLayoutInput) => Promise<Response<void>>;
}

export namespace IDesktopService {
  export const $: interfaces.ServiceIdentifier<IDesktopService> =
    Symbol('IDesktopService');
}
