import { deepEqual } from 'fast-equals';
import { useInjection } from 'inversify-react';
import { useActions, useMountedLogic, useValues } from 'kea';
import { isObject } from 'lodash';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import TreeNode from 'primereact/treenode';
import {
  TreeSelect,
  TreeSelectChangeParams,
  TreeSelectSelectionKeys,
} from 'primereact/treeselect';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Chart, ChartHandle } from '@/components/chart';
import { DateRangePicker } from '@/components/dateRangePicker';
import { TextField } from '@/components/textField';
import { useTranslation } from '@/hooks';
import {
  IDesktopLogic,
  IObjectChartLogic,
  IObjectWindowLogic,
} from '@/logic/interfaces';
import { ChartConfirmOverlayPanel } from '@/modules/charts/confirmOverlayPanel';
import { MGenericDevice } from '@/types/models';

export const GraphContainer = memo(function GraphContainer() {
  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const objectChartLogic = useInjection(IObjectChartLogic.$);

  const { objectUuid } = useValues(objectWindowLogic);
  const [chartHandle, setChartHandle] = useState<ChartHandle | null>(null);

  const { selectedDeviceKeys, loadingState, liveState } = useValues(
    objectChartLogic({
      initialData: { objectUuid: objectUuid },
      chart: chartHandle,
    }),
  );
  const { setDeviceKeyNotSelected } = useActions(
    objectChartLogic({
      initialData: { objectUuid: objectUuid },
      chart: chartHandle,
    }),
  );

  useEffect(() => {
    for (const key in selectedDeviceKeys) {
      const deviceKey = selectedDeviceKeys[key];
      if (typeof deviceKey == 'boolean') {
        setDeviceKeyNotSelected(key);
      } else {
        for (const columnKey in deviceKey) {
          setDeviceKeyNotSelected(key, columnKey as any);
        }
      }
    }
  }, [setDeviceKeyNotSelected]);

  return (
    <>
      <BlockUI
        blocked={
          loadingState.running ||
          loadingState.error ||
          liveState.loading ||
          liveState.error
        }
        template={
          <i
            className="ri-loader-2-line pi-spin"
            style={{ fontSize: '5rem' }}
          />
        }
      >
        <Chart
          ref={(handle) => {
            if (!deepEqual(handle, chartHandle)) {
              setChartHandle(handle);
            }
          }}
          id={objectUuid}
        />
      </BlockUI>
    </>
  );
});

export const GraphDeviceTreeSelect = memo(function GraphDeviceTreeSelect() {
  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);

  const { objectUuid, currentTab } = useValues(objectWindowLogic);
  const objectChartLogic = useMountedLogic(
    objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
  );
  const { devices, selectedDeviceKeys, liveState, loadingState } =
    useValues(objectChartLogic);
  const { setDeviceKeySelected, setDeviceKeyNotSelected } =
    useActions(objectChartLogic);

  const model: TreeNode[] = useMemo(() => {
    const model: TreeNode[] = [];

    for (const deviceId in devices) {
      const device = devices[deviceId];
      model.push({
        key: deviceId,
        label: device.name,
        children:
          device.columns != null
            ? device.columns.map((column) => {
                return {
                  key: deviceId + '#' + column.modelColumnId.toString(),
                  label: column.name,
                };
              })
            : undefined,
      });
    }

    return model;
  }, [devices]);

  const selectedOptions: TreeSelectSelectionKeys = useMemo(() => {
    const options: TreeSelectSelectionKeys = {};

    for (const deviceKey in selectedDeviceKeys) {
      const deviceStatus = selectedDeviceKeys[deviceKey];
      if (typeof deviceStatus == 'boolean') {
        options[deviceKey] = { checked: deviceStatus, partialChecked: false };
      } else {
        let isOnlyTrue = true;
        let isOnlyFalse = true;
        for (const columnKey in deviceStatus) {
          const columnStatus = deviceStatus[columnKey];
          options[deviceKey + '#' + columnKey] = {
            checked: columnStatus,
            partialChecked: false,
          };
          if (columnStatus) {
            isOnlyFalse = false;
          }
          if (!columnStatus) {
            isOnlyTrue = false;
          }
        }
        if (isOnlyFalse) {
          options[deviceKey] = {
            checked: false,
            partialChecked: false,
          };
        } else if (isOnlyTrue) {
          options[deviceKey] = {
            checked: true,
            partialChecked: false,
          };
        } else {
          options[deviceKey] = {
            checked: false,
            partialChecked: true,
          };
        }
      }
    }

    return options;
  }, [selectedDeviceKeys]);

  const onChange = useCallback(
    (e: TreeSelectChangeParams) => {
      if (devices == null) {
        return;
      }
      if (!(isObject(e.value) && !Array.isArray(e.value))) {
        return;
      }

      for (const deviceKey in devices) {
        const device: {
          name: string;
          columns?: MGenericDevice.ModelColumn[] | undefined;
        } = devices[deviceKey];
        if (device.columns == null) {
          if (deviceKey in e.value) {
            const deviceStatus = e.value[deviceKey];
            if (typeof deviceStatus != 'boolean') {
              if (selectedDeviceKeys[deviceKey] != deviceStatus.checked) {
                if (deviceStatus.checked) {
                  setDeviceKeySelected(deviceKey);
                } else {
                  setDeviceKeyNotSelected(deviceKey);
                }
              }
            }
          } else {
            if (selectedDeviceKeys[deviceKey] == true) {
              setDeviceKeyNotSelected(deviceKey);
            }
          }
        } else {
          for (const deviceColumn of device.columns) {
            const columnDeviceKey =
              deviceKey + '#' + deviceColumn.modelColumnId.toString();
            if (columnDeviceKey in e.value) {
              const columnDeviceStatus = e.value[columnDeviceKey];
              if (typeof columnDeviceStatus != 'boolean') {
                const columnStatus = selectedDeviceKeys[deviceKey];

                if (
                  columnStatus == undefined ||
                  (typeof columnStatus != 'boolean' &&
                    columnStatus[deviceColumn.modelColumnId.toString()] !=
                      columnDeviceStatus.checked)
                ) {
                  if (columnDeviceStatus.checked) {
                    setDeviceKeySelected(deviceKey, deviceColumn.modelColumnId);
                  } else {
                    setDeviceKeyNotSelected(
                      deviceKey,
                      deviceColumn.modelColumnId,
                    );
                  }
                }
              }
            } else {
              if (!(deviceKey in selectedDeviceKeys)) {
                continue;
              }
              const selectedDevice = selectedDeviceKeys[deviceKey];
              if (
                typeof selectedDevice != 'boolean' &&
                deviceColumn.modelColumnId.toString() in selectedDevice &&
                selectedDevice[deviceColumn.modelColumnId.toString()]
              ) {
                setDeviceKeyNotSelected(deviceKey, deviceColumn.modelColumnId);
              }
            }
          }
        }
      }
    },
    [
      devices,
      selectedDeviceKeys,
      setDeviceKeyNotSelected,
      setDeviceKeySelected,
    ],
  );
  if (currentTab != 'graph') {
    return <div></div>;
  }

  return (
    <BlockUI
      blocked={
        loadingState.running ||
        liveState.error ||
        liveState.loading ||
        liveState.running
      }
    >
      <TreeSelect
        className="object-chart-device-select"
        valueTemplate={(e) => {
          if (Array.isArray(e)) {
            return <span>{e.length}</span>;
          }
          return <div></div>;
        }}
        value={selectedOptions}
        onChange={onChange}
        selectionMode="checkbox"
        multiple
        options={model}
      />
    </BlockUI>
  );
});

export const ObjectChartDatePicker = memo(function ObjectChartDatePicker() {
  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);

  const { objectUuid, currentTab } = useValues(objectWindowLogic);
  const objectChartLogic = useMountedLogic(
    objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
  );

  const { dateRange, liveState, loadingState } = useValues(objectChartLogic);
  const { setDateRange } = useActions(objectChartLogic);

  const onStartDateChange = useCallback(
    (date: Date) => {
      setDateRange({ start: date, end: dateRange.end });
    },
    [dateRange.end, setDateRange],
  );
  const onEndDateChange = useCallback(
    (date: Date) => {
      setDateRange({ start: dateRange.start, end: date });
    },
    [dateRange.start, setDateRange],
  );
  if (currentTab != 'graph') {
    return <div></div>;
  }
  return (
    <div className="vibrodetector-chart-date-picker">
      <BlockUI
        blocked={
          liveState.error ||
          liveState.loading ||
          liveState.running ||
          loadingState.running
        }
      >
        <DateRangePicker
          startDate={dateRange.start}
          endDate={dateRange.end}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
        />
      </BlockUI>
    </div>
  );
});

export const ObjectChartLoadButton = memo(function ObjectChartLoadButton() {
  const t = useTranslation();

  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);

  const { objectUuid, currentTab } = useValues(objectWindowLogic);
  const objectChartLogic = useMountedLogic(
    objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
  );

  const { dateRange, loadedDateRange, loadingState, liveState } =
    useValues(objectChartLogic);
  const { loadCurrentRange } = useActions(objectChartLogic);

  const isDateLoaded = useMemo(() => {
    return (
      dateRange.start.getTime() == loadedDateRange?.start.getTime() &&
      dateRange.end.getTime() == loadedDateRange.end.getTime()
    );
  }, [dateRange, loadedDateRange]);
  if (currentTab != 'graph') {
    return <div></div>;
  }
  return (
    <BlockUI
      blocked={
        loadingState.running ||
        liveState.error ||
        liveState.loading ||
        liveState.running
      }
    >
      <Button
        className="vibrodetector-chart-load-button p-button-text"
        icon={
          loadingState.running
            ? 'ri-loader-2-line pi-spin'
            : isDateLoaded
            ? `ri-refresh-fill`
            : 'ri-refresh-fill'
        }
        onClick={loadCurrentRange}
        disabled={loadingState.running}
        tooltip={t('tooltips.chart.buttons.reload')}
        tooltipOptions={{
          showDelay: 500,
          position: 'top',
        }}
      />
    </BlockUI>
  );
});

export const ObjectChartLiveButton = memo(function ObjectChartLiveButton() {
  const t = useTranslation();

  const objectWindowLogic = useInjection(IObjectWindowLogic.$);
  const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);

  const { objectUuid, currentTab } = useValues(objectWindowLogic);
  const objectChartLogic = useMountedLogic(
    objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
  );

  const { liveState, loadingState } = useValues(objectChartLogic);
  const { toggleLive } = useActions(objectChartLogic);
  if (currentTab != 'graph') {
    return <div></div>;
  }
  return (
    <BlockUI
      blocked={
        loadingState.running ||
        loadingState.error ||
        liveState.loading ||
        liveState.error
      }
    >
      <Button
        icon={
          liveState.loading
            ? 'ri-loader-2-line pi-spin'
            : liveState.running
            ? 'ri-stop-fill'
            : 'ri-play-fill'
        }
        className={`vibrodetector-chart-live-button p-button-text ${
          liveState.running ? 'p-button-danger' : 'p-button-success'
        }`}
        onClick={toggleLive}
        tooltip={t('tooltips.chart.buttons.live')}
        tooltipOptions={{
          showDelay: 500,
          position: 'top',
        }}
      />
    </BlockUI>
  );
});

export const GenericDeviceChartSaveToImageButton = memo(
  function GenericDeviceChartLiveButton() {
    const t = useTranslation();

    const objectWindowLogic = useInjection(IObjectWindowLogic.$);
    const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);

    const { objectUuid, currentTab } = useValues(objectWindowLogic);
    const objectChartLogic = useMountedLogic(
      objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
    );
    const { saveToImage } = useActions(objectChartLogic);

    if (currentTab != 'graph') {
      return <div></div>;
    }

    return (
      <Button
        className={`generic-device-save-to-image-button p-button-text`}
        icon={'ri-image-fill'}
        onClick={saveToImage}
        tooltip={t('tooltips.chart.buttons.saveToImage')}
        tooltipOptions={{
          showDelay: 500,
          position: 'top',
        }}
      />
    );
  },
);

export const ObjectChartSaveToCSVButton = memo(
  function GenericDeviceChartLiveButton() {
    const t = useTranslation();

    const objectWindowLogic = useInjection(IObjectWindowLogic.$);
    const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);

    const { objectUuid, currentTab } = useValues(objectWindowLogic);
    const objectChartLogic = useMountedLogic(
      objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
    );
    const { saveToCSV, setAvgTimeSeconds } = useActions(objectChartLogic);
    const { downloadingState, avgTimeSeconds } = useValues(objectChartLogic);

    const overlayPanel = useRef<OverlayPanel>(null);

    if (currentTab != 'graph') {
      return <div></div>;
    }

    return (
      <>
        <Button
          className={`generic-device-save-to-image-button p-button-text`}
          icon={
            downloadingState.loading
              ? 'ri-loader-2-line pi-spin'
              : 'ri-file-download-fill'
          }
          onClick={(e) => overlayPanel.current?.toggle(e)}
          tooltip={t('tooltips.chart.buttons.saveToCSV')}
          tooltipOptions={{
            showDelay: 500,
            position: 'top',
          }}
          disabled={downloadingState.loading}
        />
        <OverlayPanel className="p-confirm-popup" ref={overlayPanel}>
          <ChartConfirmOverlayPanel
            dropdownValue={avgTimeSeconds}
            onDropdownChange={setAvgTimeSeconds}
            onConfirm={saveToCSV}
            overlayPanel={overlayPanel}
          />
        </OverlayPanel>
      </>
    );
  },
);

export const ObjectWorkStateSelectButton = memo(
  function ObjectWorkStateSelectButton() {
    const objectWindowLogic = useInjection(IObjectWindowLogic.$);
    const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);

    const { objectUuid, currentTab } = useValues(objectWindowLogic);
    const objectChartLogic = useMountedLogic(
      objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
    );

    const { liveState, loadingState, selectedWorkStates, workStates } =
      useValues(objectChartLogic);
    const { setSelectedWorkStates } = useActions(objectChartLogic);
    if (currentTab != 'graph') {
      return <div></div>;
    }

    const options = workStates.map((workState) => {
      return { label: workState.name, value: workState.workStateId };
    });

    return (
      <BlockUI
        className="test"
        blocked={
          loadingState.running ||
          loadingState.error ||
          liveState.loading ||
          liveState.error
        }
      >
        <MultiSelect
          value={selectedWorkStates}
          options={options}
          className="object-chart-workstate-select"
          showSelectAll={false}
          onChange={(e) => {
            setSelectedWorkStates(e.value as number[]);
          }}
          selectedItemTemplate={(e) => {
            if ((e as unknown as number) == selectedWorkStates[0]) {
              return <span>{selectedWorkStates.length}</span>;
            }
            return <div></div>;
          }}
        />
      </BlockUI>
    );
  },
);

export const ObjectOpenInNewChartButton = memo(
  function ObjecOpenInNewChartButton() {
    const objectWindowLogic = useInjection(IObjectWindowLogic.$);
    const objectChartLogicBuilder = useInjection(IObjectChartLogic.$);
    const desktopLogic = useInjection(IDesktopLogic.$);

    const { objectUuid, currentTab } = useValues(objectWindowLogic);
    const objectChartLogic = useMountedLogic(
      objectChartLogicBuilder({ initialData: { objectUuid: objectUuid } }),
    );

    const [visible, setVisible] = useState(false);
    const [windowName, setWindowName] = useState('');

    const { liveState, loadingState } = useValues(objectChartLogic);
    const {} = useActions(objectChartLogic);
    const { openWindow } = useActions(desktopLogic);

    if (currentTab != 'graph') {
      return <div></div>;
    }

    return (
      <BlockUI
        blocked={
          loadingState.running ||
          loadingState.error ||
          liveState.loading ||
          liveState.error
        }
      >
        {/*<Button
          onClick={() => setVisible(true)}
          className="new-window-button"
          label="Nowe okno"
        />
        <Dialog
          header="Nowe okno"
          visible={visible}
          onHide={() => setVisible(false)}
        >
          <p>Podaj nazwę nowego okna z wykresem:</p>
          <TextField value={windowName} onChange={setWindowName} />
          <div className="dialog-buttons-chart">
            <Button onClick={() => setVisible(false)} label="Anuluj" />
            <Button
              onClick={() => {
                if (windowName != '') {
                  openWindow('objectChartNamed', {
                    windowKey: windowName,
                    title: windowName,
                    initialData: {
                      name: windowName,
                      objectUuid: objectUuid,
                    },
                  });
                  setVisible(false);
                }
              }}
              label="Akceptuj"
            />
          </div>
        </Dialog>*/}
      </BlockUI>
    );
  },
);
